import { requestByGET } from 'lib/requests';
import { buildPathParams } from 'lib/utils/url';
import { mapTAReviews } from './mappers';

const END_POINTS = {
  TRIPADVISOR: '/reviews/location/{reviewsId}',
};

export function fetchTAReviews(params) {
  const { reviewsId, language, currency } = params.data;

  return requestByGET({
    data: { lang: language, currency },
    endpoint: buildPathParams(END_POINTS.TRIPADVISOR, { reviewsId }),
    mapper: mapTAReviews,
  });
}
