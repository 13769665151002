import { requestByPOST } from 'lib/requests';
import { buildSuggestedPropertiesPayload } from './mappers';

const OPTIONS = {
  headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
  timeout: 30000,
  withCredentials: true,
};

const END_POINTS = {
  SUGGESTED: '/v2/suggested',
  SUGGESTED_AUTH: '/v2/suggested/member'
};

export function fetchSuggestedProperties(params) {
  return requestByPOST({
    data: buildSuggestedPropertiesPayload(params),
    endpoint: END_POINTS.SUGGESTED,
  });
}

export function fetchSuggestedPropertiesAuthorized(params) {
  return requestByPOST({
    data: buildSuggestedPropertiesPayload(params),
    endpoint: END_POINTS.SUGGESTED_AUTH,
    options: OPTIONS,
  });
}
