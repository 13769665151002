import { PAYMENT_STATE } from './constants';

const state = {
  [PAYMENT_STATE]: {
    error: null,
    isFetching: true,
    paymentStatus: null,
    securePayment: null,
  },
};

export default state;
