export const VERIFY_EMAIL_STATE = 'verifyEmail';

export const VERIFY_EMAIL_REQUEST = 'VERIFY_EMAIL_REQUEST';
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_FAILURE = 'VERIFY_EMAIL_FAILURE';
export const VERIFY_EMAIL_REJECTED = 'VERIFY_EMAIL_REJECTED';

export const STATUS_OK = 'STATUS_OK';
export const STATUS_REJECTED = 'STATUS_REJECTED';
export const STATUS_NOT_OK = 'STATUS_NOT_OK';

export const HTTP_CODE_VERIFY_UNAUTHORIZED = 401;
