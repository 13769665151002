import { SUMMARY_STATE } from './constants';

const state = {
  [SUMMARY_STATE]: {
    error: null,
    isFetching: false,
    data: {},
  },
};

export default state;
