import styled from 'styled-components';
import { colors } from 'client/styles';

export const CapacityBase = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.grey2};
  font-size: 14px;

  > p {
    margin-right: 10px;

    ::after {
      content: ':';
    }
  }
`;

export const Paxes = styled.div<{ showIcons: boolean; showTitle: boolean }>`
  display: flex;
  flex-wrap: wrap;

  margin-left: ${props => (props.showIcons && props.showTitle ? '8px' : 0)};
  margin-right: 8px;

  > svg {
    fill: ${colors.grey2};
    flex: 0 0 auto;
    width: 20px;
    height: 20px;
  }
`;

export const Adults = styled.div`
  display: flex;
  align-items: center;
  margin: 0 2px;
`;

export const Children = styled.div`
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.div`
  margin-right: 8px;

  > svg {
    fill: ${colors.grey2};
    flex: 0 0 auto;
    width: 20px;
    height: 20px;
  }
`;

export const Separator = styled.div`
  display: flex;
  align-items: center;
`;
