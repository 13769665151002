import {
  RESET_PASSWORD_STATE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
} from './constants';
import stateBase from './state';

const initialState = stateBase[RESET_PASSWORD_STATE];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST: {
      return {
        ...state,
        error: null,
        isFetching: true,
        passwordReset: null,
      };
    }

    case RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        error: null,
        isFetching: false,
        passwordReset: true,
      };
    }

    case RESET_PASSWORD_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        isFetching: false,
        passwordReset: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
