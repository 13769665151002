import { getSearchParamsLocalStorage } from 'lib/storage';
import { getValidFiltersFromUrl } from './utils';
import { DEFAULT_FILTERS, FILTERS_STATE, FILTER_OPTIONS } from './constants';

const location = typeof window !== 'undefined' && window.location;
const { filters: localFilters } = getSearchParamsLocalStorage();

const validFilters = getValidFiltersFromUrl(location.search);

const filtersFromUrl = FILTER_OPTIONS.reduce((result, currentFilter) => {
  const validCurrentFilter = validFilters[currentFilter];

  if (!validCurrentFilter) {
    return result;
  }

  /* eslint-disable-next-line no-param-reassign */
  result[currentFilter] = validFilters[currentFilter];

  if (['facilities', 'stars', 'accommodationTypes', 'ratings'].includes(currentFilter)) {
    /* eslint-disable-next-line no-param-reassign */
    result[currentFilter] = validFilters[currentFilter].split(',');

    return result;
  }

  return result;
}, {});

const state = {
  [FILTERS_STATE]: {
    ...DEFAULT_FILTERS,
    ...localFilters,
    ...filtersFromUrl,
  },
};

export default state;
