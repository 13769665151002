import {
  PROPERTY_STATE,
  FETCH_PROPERTY_REQUEST,
  FETCH_PROPERTY_SUCCESS,
  FETCH_PROPERTY_FAILURE,
  CLEAR_PROPERTY,
} from './constants';
import stateBase from './state';

const initialState = stateBase[PROPERTY_STATE];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROPERTY_REQUEST:
      return {
        ...state,
        error: null,
        isFetching: true,
      };

    case FETCH_PROPERTY_SUCCESS:
      return {
        ...state,
        error: null,
        isFetching: false,
        property: action.payload,
      };

    case FETCH_PROPERTY_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isFetching: false,
      };

    case CLEAR_PROPERTY: {
      const { payload } = action;
      // If the slug of the search is different from the citySlug of the property
      // we need to clear the state to avoid to send hightlighted attribute
      if (state.property?.citySlug !== payload.slug) {
        return initialState;
      }
      return state;
    }


    default:
      return state;
  }
};

export default reducer;
