import { LOGIN_STATE } from './constants';

const state = {
  [LOGIN_STATE]: {
    data: {},
    error: null,
    isFetching: true,
    isLoggedIn: false,
    status: null,
  },
};

export default state;
