export const COOKIE_AFFILIATES = 'track';
export const COOKIE_AUTHENTICATION = 'sfl_user';
export const COOKIE_LANGUAGE = 'lang';
export const COOKIE_MARKET = 'sfl_mkt';
export const COOKIE_PURCHASE = 'purchase';
export const COOKIE_TRACKING = 'tracking';
export const COOKIE_EXPERIMENT = 'exp';
export const COOKIE_RETURN_URL = 'return_url';
export const COOKIE_MOBILE_RATE = 'mobile_rate';
export const COOKIE_QUERY_TYPE = 'query_type';
export const COOKIE_GEOIP_CODE = 'geoip_code';

export const COOKIE_EXP_30D = 60 * 60 * 24 * 30;
export const COOKIE_EXP_1D = 60 * 60 * 24;
