import { MODIFY_DATES_STATE } from './constants';

const state = {
  [MODIFY_DATES_STATE]: {
    boardSelected: null,
    bookingRules: [],
    error: null,
    isFetching: false,
    results: {},
    status: null,
    step: 'CHECK_AVAILABILITY',
  },
};

export default state;
