import styled from 'styled-components';
import { colors, updatedMedia } from 'client/styles';
import { InputGroup, Label } from 'client/components/InputReadOnly/styles';

export const DateSelectorBase = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  align-items: center;
  background-color: ${colors.white};
  border-radius: 8px;
  border: 2px solid;
  border-color: ${props => (props.isFocus ? colors.grey1 : colors.grey4)};
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 100%;

  ${updatedMedia.desktop} {
    &:hover {
      border-color: ${colors.grey1};
    }
  }

  ${props =>
    props.type === 'inline' &&
    `
      flex: 0 0 auto;
      margin-right: .75em;
      width: 300px;
      padding: 0 1rem;
  `};

  .datepicker-default {
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    z-index: 11;
  }

  ${InputGroup}:first-of-type {
    border-right: 2px solid ${colors.grey4};
  }
  ${Label} {
    background-color: transparent;
    padding: 6px 12px;
  }
`;
