export const CREATED = {
  status: 201,
  message: '201 Created',
};

export const ACCEPTED = {
  status: 202,
  message: '202 Accepted',
};

export const NO_CONTENT = {
  status: 204,
  message: '204 No Content',
};

export const UNAUTHORIZED = {
  status: 401,
  message: '401 Unauthorized Error',
};

export const NOT_FOUND = {
  status: 404,
  message: '404 Not Found',
};

export const CONFLICT = {
  status: 409,
  message: '409 Conflict',
};

export const GONE = {
  status: 410,
  message: '410 Gone',
};

export const TOO_MANY_REQUESTS = {
  status: 429,
  message: '429 Too Many Requests',
};
