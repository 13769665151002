import styled, { css } from 'styled-components';
import { colors, updatedMedia } from 'client/styles';

import { LoginNavigatorBase } from 'src/comps/LoginNavigator/styles';
import { SidebarMenuBase } from 'src/comps/SidebarMenu/styles';
import { LoginButtonBase } from 'src/comps/LoginButton/styles';
import { CountrySelectorBase } from 'client/components/CountrySelector/styles';

export const UserActions = styled.div`
  align-items: center;
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-end;
  position: relative;

  ${updatedMedia.tablet} {
    ${LoginNavigatorBase}, ${SidebarMenuBase} {
      position: absolute;
      top: 38px;
      z-index: 20;
      background-color: ${colors.white};
    }

    ${LoginNavigatorBase} {
      left: calc(100% - 170px);
      width: 200px;
      box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.125);
    }

    ${SidebarMenuBase} {
      left: calc(100% - 345px);
      width: 350px;
      border: 2px solid ${colors.grey4};
      border-radius: 8px;
    }
  }
`;

export const RightSideWrapper = styled.div<{ isSearch?: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ${({ isSearch }) => isSearch && css`
    ${LoginButtonBase} {
      margin-right: 0;
    }
    ${updatedMedia.tablet} {
      ${CountrySelectorBase} {
        margin-right: 16px;
      }
    }
  `}
`;

export const Section = styled.div`
  display: flex;
  align-items: center;
`;

export const SectionLabel = styled.div<{ isEbooking?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  color: ${props => (props.isEbooking ? colors.white : colors.grey2)};
  margin-right: 20px;

  svg {
    fill: ${props => (props.isEbooking ? colors.white : colors.grey2)};
  }
`;

export const CallUsLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
`;

export const SectionLink = styled.a`
  cursor: pointer;
  text-decoration: none;
`;

export const Text = styled.div`
  font-size: 16px;
  padding-left: 8px;
`;
