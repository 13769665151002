import React, { ReactNode } from 'react';

import AlertMessage from 'src/comps/AlertMessage/container';
import Notifications from 'src/comps/Notifications';
import PurchaseReminder from 'src/comps/Notifications/PurchaseReminder/container';

import { HeaderBase, HeaderInner } from './styles';
import { THEMES, THEME_PRIMARY } from 'client/components/Header/constants';
import { SearchMode } from 'src/types/search';
import { useBusiness } from 'src/hooks/useBusiness';
import { AFIILIATE_EBOOKING } from 'src/constants/affiliates';

export interface HeaderWithSlotsProps {
  left: ReactNode;
  center?: ReactNode;
  right: ReactNode;
  bottom?: ReactNode;
  theme?: (typeof THEMES)[number];
  mode?: SearchMode;
  fixed?: boolean;
}

const HeaderWithSlots: React.FC<HeaderWithSlotsProps> = ({
  left,
  right,
  center,
  bottom,
  theme = THEME_PRIMARY,
  mode,
  fixed,
}) => {
  const { affiliate } = useBusiness();
  const isEbooking = affiliate === AFIILIATE_EBOOKING;

  return (
    <HeaderBase headerTheme={theme} fixed={fixed} isEbooking={isEbooking}>
      <AlertMessage />
      <Notifications>
        <PurchaseReminder />
      </Notifications>

      <HeaderInner className={mode}>
        <>{left}</>
        <>{center}</>
        <>{right}</>
      </HeaderInner>
      {bottom}
    </HeaderBase>
  );
};

export default HeaderWithSlots;
