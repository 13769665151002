export const USER_STATE = 'user';

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const FETCH_CUSTOMER_CREDIT_CARD_REQUEST = 'FETCH_CUSTOMER_CREDIT_CARD_REQUEST';
export const FETCH_CUSTOMER_CREDIT_CARD_SUCCESS = 'FETCH_CUSTOMER_CREDIT_CARD_SUCCESS';
export const FETCH_CUSTOMER_CREDIT_CARD_FAILURE = 'FETCH_CUSTOMER_CREDIT_CARD_FAILURE';

export const RESET_USER = 'RESET_USER';

export const BRAND_MAPPING = {
  VIS: 'Visa',
  Mas: 'Mastercard',
  Ame: 'AMEX',
};
