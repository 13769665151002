export const SUBSCRIPTION_STATUS_STATE = 'subscriptionStatus';

export const SUBSCRIPTION_STATUS_REQUEST = 'SUBSCRIPTION_STATUS_REQUEST';
export const SUBSCRIPTION_STATUS_SUCCESS = 'SUBSCRIPTION_STATUS_SUCCESS';
export const SUBSCRIPTION_STATUS_FAILURE = 'SUBSCRIPTION_STATUS_FAILURE';

export const SUBSCRIPTION_SUBSCRIBE_REQUEST = 'SUBSCRIPTION_SUBSCRIBE_REQUEST';
export const SUBSCRIPTION_SUBSCRIBE_SUCCESS = 'SUBSCRIPTION_SUBSCRIBE_SUCCESS';
export const SUBSCRIPTION_SUBSCRIBE_FAILURE = 'SUBSCRIPTION_SUBSCRIBE_FAILURE';

export const SUBSCRIPTION_UNSUBSCRIBE_REQUEST = 'SUBSCRIPTION_UNSUBSCRIBE_REQUEST';
export const SUBSCRIPTION_UNSUBSCRIBE_SUCCESS = 'SUBSCRIPTION_UNSUBSCRIBE_SUCCESS';
export const SUBSCRIPTION_UNSUBSCRIBE_FAILURE = 'SUBSCRIPTION_UNSUBSCRIBE_FAILURE';

export const SUBSCRIPTION_CONFIRM_REQUEST = 'SUBSCRIPTION_CONFIRM_REQUEST';
export const SUBSCRIPTION_CONFIRM_SUCCESS = 'SUBSCRIPTION_CONFIRM_SUCCESS';
export const SUBSCRIPTION_CONFIRM_FAILURE = 'SUBSCRIPTION_CONFIRM_FAILURE';

export const ACTIVATE_SUBSCRIPTION_TOPIC_REQUEST = 'ACTIVATE_SUBSCRIPTION_TOPIC_REQUEST';
export const ACTIVATE_SUBSCRIPTION_TOPIC_SUCCESS = 'ACTIVATE_SUBSCRIPTION_TOPIC_SUCCESS';
export const ACTIVATE_SUBSCRIPTION_TOPIC_FAILURE = 'ACTIVATE_SUBSCRIPTION_TOPIC_FAILURE';

export const DEACTIVATE_SUBSCRIPTION_TOPIC_REQUEST = 'DEACTIVATE_SUBSCRIPTION_TOPIC_REQUEST';
export const DEACTIVATE_SUBSCRIPTION_TOPIC_SUCCESS = 'DEACTIVATE_SUBSCRIPTION_TOPIC_SUCCESS';
export const DEACTIVATE_SUBSCRIPTION_TOPIC_FAILURE = 'DEACTIVATE_SUBSCRIPTION_TOPIC_FAILURE';

export const SUBSCRIPTION = 'subscription';
export const SUBSCRIBED = 'subscribed';
export const PENDING = 'pending';
export const UNSUBSCRIBED = 'unsubscribed';
export const ACTIVE = 'active';
export const INACTIVE = 'inactive';

export const DIRECT_OPT_IN_ALLOWED_MARKETS = [
  'at',
  'be',
  'br',
  'ca',
  'ch',
  'dk',
  'es',
  'fi',
  'fr',
  'gr',
  'ie',
  'it',
  'mx',
  'nl',
  'no',
  'pl',
  'pt',
  'ro',
  'se',
  'sk',
  'tr',
  'uk',
  'us',
  'za',
];
