import {
  SPECIAL_REQUESTS_REQUEST,
  SPECIAL_REQUESTS_SUCCESS,
  SPECIAL_REQUESTS_FAILURE,
} from './constants';

export const fetchSpecialRequestsRequestAction = payload => ({
  type: SPECIAL_REQUESTS_REQUEST,
  payload,
});

export const fetchSpecialRequestsSuccessAction = payload => ({
  type: SPECIAL_REQUESTS_SUCCESS,
  payload,
});

export const fetchSpecialRequestsFailureAction = payload => ({
  type: SPECIAL_REQUESTS_FAILURE,
  payload,
});
