import md5 from 'md5';
import { createSelector } from 'reselect';

import { LOGIN_STATE } from './constants';

export const getIsFetching = state => state[LOGIN_STATE].isFetching;
export const getIsPrime = state => state[LOGIN_STATE].data?.subscriptionPrime;
export const getLoginData = state => state[LOGIN_STATE].data || {};
export const getLoginStatus = state => state[LOGIN_STATE].isLoggedIn;
export const getStatus = state => state[LOGIN_STATE].status;

export const getLoggedIn = createSelector([getLoginData, getLoginStatus], (data, isLoggedIn) => {
  return !!data?.email && isLoggedIn;
});

export const getIsUserDataReady = createSelector(
  [getLoginData, getLoginStatus, getIsFetching],
  (user, isLoggedIn, isFetching) => {
    return isLoggedIn && user.email && !isFetching;
  }
);

export const getIsPrimeUserReady = createSelector(
  [getIsUserDataReady, getIsPrime],
  (isUserReady, isPrime) => {
    return isUserReady && isPrime;
  }
);

export const getEmailHash = createSelector([getLoginData], user => {
  return user.email ? md5(user.email) : undefined;
});
