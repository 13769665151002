import { all, takeLatest, takeEvery } from 'redux-saga/effects';

import {
  FETCH_AVAILABILITY_REQUEST,
  FETCH_BOOKING_RULES_REQUEST,
} from 'src/effects/Availability/constants';

import {
  LOGIN_GOOGLE_REQUEST,
  LOGIN_REQUEST,
  FETCH_CUSTOMER_DATA_REQUEST,
  LOGOUT_REQUEST,
} from 'client/contexts/Login/constants';

import {
  FETCH_APPLIED_WALLET_REQUEST,
  FETCH_WALLET_REQUEST,
  CHECK_PURCHASE_WALLET_REQUEST,
  UNCHECK_PURCHASE_WALLET_REQUEST,
} from 'client/contexts/Wallet/constants';

import {
  SUBSCRIPTION_STATUS_REQUEST,
  SUBSCRIPTION_SUBSCRIBE_REQUEST,
  SUBSCRIPTION_UNSUBSCRIBE_REQUEST,
  SUBSCRIPTION_CONFIRM_REQUEST,
  ACTIVATE_SUBSCRIPTION_TOPIC_REQUEST,
  DEACTIVATE_SUBSCRIPTION_TOPIC_REQUEST,
} from 'src/effects/SubscriptionStatus/constants';

import {
  MODIFY_DATES_REQUEST,
  MODIFY_DATES_CONFIRMATION_REQUEST,
} from 'src/effects/ModifyDates/constants';

import {
  CHANGE_PASSWORD_REQUEST,
  CREATE_USER_REQUEST,
  FETCH_CUSTOMER_CREDIT_CARD_REQUEST,
  UPDATE_USER_REQUEST,
} from 'src/effects/User/constants';

import {
  PRIME_CANCEL_REQUEST,
  PRIME_REACTIVATE_REQUEST,
} from 'src/effects/PrimeSubscription/constants';

import { ACTIVATE_ACCOUNT_REQUEST } from 'src/effects/ActivateAccount/constants';
import { CANCEL_BOOKING_REQUEST } from 'client/contexts/CancelBooking/constants';
import { CONFIRMATION_REQUEST } from 'src/effects/Payment/constants';
import { FETCH_MAP_PROPERTIES_REQUEST } from 'src/effects/SearchMap/constants';
import { FETCH_PROPERTIES_RECOMMENDED_REQUEST } from 'src/effects/PropertiesRecommended/constants';
import { FETCH_PROPERTIES_REQUEST } from 'src/effects/Properties/constants';
import { FETCH_PROPERTIES_VISITED_REQUEST } from 'src/effects/PropertiesVisited/constants';
import { FETCH_SUGGESTED_PROPERTIES_REQUEST } from 'src/effects/Suggested/constants';
import { FETCH_SUGGESTIONS_REQUEST } from 'src/effects/Suggester/constants';
import { FETCH_SUMMARY_REQUEST } from 'client/contexts/Summary/constants';
import { FETCH_TA_REVIEWS_REQUEST } from 'src/effects/TAReviews/constants';
import { FETCH_VOUCHER_REQUEST } from 'src/comps/Voucher/constants';
import { FORGOT_PASSWORD_REQUEST } from 'src/effects/ForgotPassword/constants';
import { PAY_NOW_REQUEST } from 'src/comps/PayNow/constants';
import { PRIME_RECEIPTS_REQUEST } from 'src/effects/PrimeReceipts/constants';
import { REFUND_CREDIT_CARD_REQUEST } from 'src/effects/RefundCreditCard/constants';
import { REFUND_WALLET_REQUEST } from 'src/effects/RefundWallet/constants';
import { RESET_PASSWORD_REQUEST } from 'src/effects/ResetPassword/constants';
import { SEND_VOUCHER_REQUEST } from 'src/comps/SendVoucher/constants';
import { SPECIAL_REQUESTS_REQUEST } from 'src/effects/SpecialRequests/constants';
import { USER_BOOKINGS_REQUEST } from 'client/contexts/UserBookings/constants';
import { USER_WALLET_REQUEST } from 'client/contexts/UserWallet/constants';
import { VERIFY_EMAIL_REQUEST } from 'src/effects/VerifyEmail/constants';

import { activateAccountSaga } from 'src/effects/ActivateAccount/sagas';
import { addSpecialRequestsSaga } from 'src/effects/SpecialRequests/sagas';
import { cancelBookingSaga } from 'client/contexts/CancelBooking/sagas';
import { confirmationSaga } from 'src/effects/Payment/sagas';
import { fetchBookingRulesSaga, fetchAvailabilitySaga } from 'src/effects/Availability/sagas';
import { fetchMapPropertiesSaga } from 'src/effects/SearchMap/sagas';
import { fetchPropertiesRecommendedSaga } from 'src/effects/PropertiesRecommended/sagas';
import { fetchPropertiesSaga } from 'src/effects/Properties/sagas';
import { fetchPropertiesVisitedSaga } from 'src/effects/PropertiesVisited/sagas';
import { fetchRefundCreditCardSaga } from 'src/effects/RefundCreditCard/sagas';
import { fetchRefundWalletSaga } from 'src/effects/RefundWallet/sagas';
import { fetchSuggestedPropertiesSaga } from 'src/effects/Suggested/sagas';
import { fetchSuggestionsSaga } from 'src/effects/Suggester/sagas';
import { fetchSummarySaga } from 'client/contexts/Summary/sagas';
import { fetchTAReviewsSaga } from 'src/effects/TAReviews/sagas';
import { fetchUserWalletAvailableSaga } from 'client/contexts/UserWallet/sagas';
import { fetchVoucherSaga } from 'src/comps/Voucher/sagas';
import { forgotPasswordSaga } from 'src/effects/ForgotPassword/sagas';
import { getUserBookingsSaga } from 'client/contexts/UserBookings/sagas';
import { modifyDatesSaga, modifyDatesConfirmationSaga } from 'src/effects/ModifyDates/sagas';
import { payNowVoucherSaga } from 'src/comps/PayNow/sagas';
import { primeCancelSaga, primeReactivateSaga } from 'src/effects/PrimeSubscription/sagas';
import { primeReceiptsSaga } from 'src/effects/PrimeReceipts/sagas';
import { resetPasswordSaga } from 'src/effects/ResetPassword/sagas';
import { sendVoucherSaga } from 'src/comps/SendVoucher/sagas';
import { verifyEmailSaga } from 'src/effects/VerifyEmail/sagas';

import {
  createUserSaga,
  updateUserSaga,
  changePasswordSaga,
  fetchCustomerCreditCardSaga,
} from 'src/effects/User/sagas';

import {
  loginSaga,
  loginGoogleSaga,
  fetchCustomerDataSaga,
  logoutSaga,
} from 'client/contexts/Login/sagas';

import {
  activateSubscriptionTopicSaga,
  confirmSaga,
  deactivateSubscriptionTopicSaga,
  subscribeSaga,
  subscriptionStatusSaga,
  unsubscribeSaga,
} from 'src/effects/SubscriptionStatus/sagas';

import {
  fetchAppliedWalletCreditSaga,
  fetchWalletCreditSaga,
  checkWalletByPurchaseSaga,
  uncheckWalletByPurchaseSaga,
} from 'client/contexts/Wallet/sagas';

export default function* rootSaga() {
  yield all([
    takeEvery(FETCH_BOOKING_RULES_REQUEST, fetchBookingRulesSaga),
    takeLatest(ACTIVATE_ACCOUNT_REQUEST, activateAccountSaga),
    takeLatest(CANCEL_BOOKING_REQUEST, cancelBookingSaga),
    takeLatest(CHANGE_PASSWORD_REQUEST, changePasswordSaga),
    takeLatest(CHECK_PURCHASE_WALLET_REQUEST, checkWalletByPurchaseSaga),
    takeLatest(CONFIRMATION_REQUEST, confirmationSaga),
    takeLatest(CREATE_USER_REQUEST, createUserSaga),
    takeLatest(FETCH_APPLIED_WALLET_REQUEST, fetchAppliedWalletCreditSaga),
    takeLatest(FETCH_AVAILABILITY_REQUEST, fetchAvailabilitySaga),
    takeLatest(FETCH_CUSTOMER_CREDIT_CARD_REQUEST, fetchCustomerCreditCardSaga),
    takeLatest(FETCH_CUSTOMER_DATA_REQUEST, fetchCustomerDataSaga),
    takeLatest(FETCH_MAP_PROPERTIES_REQUEST, fetchMapPropertiesSaga),
    takeLatest(FETCH_PROPERTIES_RECOMMENDED_REQUEST, fetchPropertiesRecommendedSaga),
    takeLatest(FETCH_PROPERTIES_REQUEST, fetchPropertiesSaga),
    takeLatest(FETCH_PROPERTIES_VISITED_REQUEST, fetchPropertiesVisitedSaga),
    takeLatest(FETCH_SUGGESTED_PROPERTIES_REQUEST, fetchSuggestedPropertiesSaga),
    takeLatest(FETCH_SUGGESTIONS_REQUEST, fetchSuggestionsSaga),
    takeLatest(FETCH_SUMMARY_REQUEST, fetchSummarySaga),
    takeLatest(FETCH_TA_REVIEWS_REQUEST, fetchTAReviewsSaga),
    takeLatest(FETCH_VOUCHER_REQUEST, fetchVoucherSaga),
    takeLatest(FETCH_WALLET_REQUEST, fetchWalletCreditSaga),
    takeLatest(FORGOT_PASSWORD_REQUEST, forgotPasswordSaga),
    takeLatest(LOGIN_GOOGLE_REQUEST, loginGoogleSaga),
    takeLatest(LOGIN_REQUEST, loginSaga),
    takeLatest(LOGOUT_REQUEST, logoutSaga),
    takeLatest(MODIFY_DATES_CONFIRMATION_REQUEST, modifyDatesConfirmationSaga),
    takeLatest(MODIFY_DATES_REQUEST, modifyDatesSaga),
    takeLatest(PAY_NOW_REQUEST, payNowVoucherSaga),
    takeLatest(PRIME_CANCEL_REQUEST, primeCancelSaga),
    takeLatest(PRIME_REACTIVATE_REQUEST, primeReactivateSaga),
    takeLatest(PRIME_RECEIPTS_REQUEST, primeReceiptsSaga),
    takeLatest(REFUND_CREDIT_CARD_REQUEST, fetchRefundCreditCardSaga),
    takeLatest(REFUND_WALLET_REQUEST, fetchRefundWalletSaga),
    takeLatest(RESET_PASSWORD_REQUEST, resetPasswordSaga),
    takeLatest(SEND_VOUCHER_REQUEST, sendVoucherSaga),
    takeLatest(SPECIAL_REQUESTS_REQUEST, addSpecialRequestsSaga),
    takeLatest(SUBSCRIPTION_CONFIRM_REQUEST, confirmSaga),
    takeLatest(SUBSCRIPTION_STATUS_REQUEST, subscriptionStatusSaga),
    takeLatest(SUBSCRIPTION_SUBSCRIBE_REQUEST, subscribeSaga),
    takeLatest(SUBSCRIPTION_UNSUBSCRIBE_REQUEST, unsubscribeSaga),
    takeLatest(ACTIVATE_SUBSCRIPTION_TOPIC_REQUEST, activateSubscriptionTopicSaga),
    takeLatest(DEACTIVATE_SUBSCRIPTION_TOPIC_REQUEST, deactivateSubscriptionTopicSaga),
    takeLatest(UNCHECK_PURCHASE_WALLET_REQUEST, uncheckWalletByPurchaseSaga),
    takeLatest(UPDATE_USER_REQUEST, updateUserSaga),
    takeLatest(USER_BOOKINGS_REQUEST, getUserBookingsSaga),
    takeLatest(USER_WALLET_REQUEST, fetchUserWalletAvailableSaga),
    takeLatest(VERIFY_EMAIL_REQUEST, verifyEmailSaga),
  ]);
}
