import { REFUND_WALLET_STATE } from './constants';

const state = {
  [REFUND_WALLET_STATE]: {
    error: null,
    isFetching: true,
    status: null,
  },
};

export default state;
