import { useContext } from "react"
import { DeviceContext } from "src/context/device/"
import { DeviceType } from "src/types/device";


export const useDevice = () => {
    const device: DeviceType | null = useContext(DeviceContext)

    if (!device) {
        throw new Error("Missing DeviceContext provider")
    }

    return device;
}