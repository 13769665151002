import { useContext } from "react"
import { BusinessContext } from "src/context/business/"

export const useBusiness = () => {
    const business = useContext(BusinessContext);

    if (!business) {
        throw new Error("Missing BusinessContext provider");
    }

    return business;
}