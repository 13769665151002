import styled from 'styled-components';

import { LAYOUT_RESPONSIVE, LAYOUT_CONTAIN, LAYOUT_COVER } from './constants';

export const ImageBase = styled.div`
  img {
    object-fit: cover;
    object-position: center;
    pointer-events: none;
    user-select: none;
    vertical-align: bottom;
    ${props => props.layout === LAYOUT_RESPONSIVE && `width: 100%;`}
    ${props => props.layout === LAYOUT_CONTAIN && `object-fit: contain;`}
    ${props => props.layout === LAYOUT_COVER && `object-fit: cover;`}
    ${props => props.aspectRatio && `aspect-ratio: ${props.aspectRatio};`}
  }
`;
