import styled from 'styled-components';
import { colors } from 'client/styles';

export const UserDetailBase = styled.div`
  display: flex;
  width: 100%;
  height: 125px;
  padding-right: 20px;
`;

export const ImageWrapper = styled.div`
  display: flex;
  ${props => !props.image && `background-color: ${colors.grey5}`};

  border-radius: 50px;
  margin: 30px 20px;

  svg {
    fill: ${colors.grey2};
    margin: 12px 14px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  word-break: break-all;

  padding: 30px 0;
`;

export const Name = styled.div`
  font-weight: 700;
  font-size: 21px;
  line-height: 130%;
  padding-bottom: 8px;

  color: ${colors.grey1};
`;

export const Email = styled.span`
  font-size: 16px;
  line-height: 150%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  color: ${colors.grey2};
`;
