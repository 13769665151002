import { requestByGET } from 'lib/requests';
import { buildPropertiesVisitedRequest } from './mappers';
import { mapProperties } from 'client/contexts/Property/mappers';

const END_POINTS = {
  PROPERTIES_VISITED: '/properties/ids',
};

export function fetchPropertiesVisited(params) {
  return requestByGET({
    data: buildPropertiesVisitedRequest(params.data),
    endpoint: END_POINTS.PROPERTIES_VISITED,
    mapper: mapProperties,
  });
}
