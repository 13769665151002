import { z } from 'zod';
import { uniq } from 'lodash';

import { roomSchema as propertyRoomSchema } from 'src/pages/api/trpc/property/schema/embeddable-property';
import { CENTER_RADIUS } from 'src/comps/Property/constants';
import { LocationTypes } from 'src/types/search';

export const DEFAULT_PAGE_SIZE = 300;

export const coordinatesSchema = z.object({
  lat: z.number(),
  lon: z.number(),
});

export const boxShapeSchema = z.object({
  topLeft: coordinatesSchema,
  bottomRight: coordinatesSchema,
});

export const geoLocationSchema = z.object({
  boxShape: z.optional(boxShapeSchema),
});

export const locationSchema = z.object({
  country: z.string(),
  type: z.string(),
  slug: z.string(),
});

export const dateRangeSchema = z.object({
  checkIn: z.string(),
  checkOut: z.string(),
});

export const roomSchema = z.object({
  adults: z.number(),
  children: z.array(z.number()).optional(),
});

export const hotelStarsSchema = z.number().min(1).max(5);

export const familyFriendlySchema = z.boolean();

export const priceRangeSchema = z
  .object({
    min: z.number(),
    max: z.number().optional(),
  })
  .refine(
    priceRange => {
      return priceRange.max ? priceRange.min < priceRange.max : true;
    },
    { message: 'max can not greater than min' }
  );

export enum RatingOptions {
  Excellent = '9',
  VeryGood = '8',
  Good = '7',
  Fair = '6',
  Any = 'any',
}

export enum AccommodationOptions {
  Hotel = 'hotel',
  Apartment = 'apartment',
  Villa = 'villa',
  Home = 'homes',
  Hostel = 'hostel',
  Resort = 'resort',
  ApartHotel = 'apthotel',
}

export enum FacilitiesOptions {
  CarPark = 'car-park',
  Gym = 'gym',
  IndoorPool = 'indoor-pool',
  OutdoorPool = 'outdoor-pool',
  AirportShuttle = 'airport-shuttle',
  Spa = 'spa',
  WiFi = 'wi-fi',
  WheelChairAccess = 'wheelchair-access',
}

const filtersSchema = z.object({
  stars: z.array(hotelStarsSchema).optional(),
  rating: z.nativeEnum(RatingOptions).optional(),
  familyFriendly: familyFriendlySchema.optional().default(false),
  accommodations: z.array(z.nativeEnum(AccommodationOptions)).optional(),
  facilities: z.array(z.nativeEnum(FacilitiesOptions)).optional(),
  priceRange: priceRangeSchema.optional(),
});

export enum SortOptions {
  Recommended = 'rank6.2',
  Price = 'price',
  Stars = 'stars',
  Distance = 'distance',
  Reviews = 'reviews',
  Rating = 'ratings',
}

// The input validation serve a dual purpose, one validate our own inputs, and
// second convert as close as possible to the `search` API; so we don't need to
// transform within the body of the fetch method
export const inputValidation = z
  .object({
    location: locationSchema.optional(),
    geoLocation: geoLocationSchema.optional(),
    dateRange: dateRangeSchema,
    rooms: z.array(roomSchema),
    page: z.number().optional().default(1),
    cursor: z.number().nullish(), // <-- cursor must be nullish
    filters: filtersSchema.optional(),
    highlightedProperty: z.number().optional(),
    pageSize: z.number().default(DEFAULT_PAGE_SIZE),
    sortBy: z.nativeEnum(SortOptions).default(SortOptions.Distance),
    catalogue: z.string().optional(),
    excludedIDs: z.array(z.number()).optional(),
    affiliate: z.string().optional(),
    source: z.string().optional(),
  })
  .transform(input => {
    return {
      // Affiliate
      ...(input.affiliate && { affiliate: input.affiliate }),
      // Source
      ...(input.source && { source: input.source }),
      // Location data
      ...(input.location?.country && { country: input.location?.country }),
      ...(input.location?.type && { locationType: input.location?.type }),
      ...(input.location?.slug && { location: input.location?.slug }),

      // Geo location data
      ...(input.geoLocation?.boxShape && {
        locationType: LocationTypes.Geo,
        box_shape: {
          top_left: input.geoLocation?.boxShape.topLeft,
          bottom_right: input.geoLocation?.boxShape.bottomRight,
        },
      }),

      // Date range
      checkin: input.dateRange.checkIn,
      checkout: input.dateRange.checkOut,

      // Paxes
      rooms: input.rooms,

      // Filters
      // When filtering by hotel stars the API expect the number fo stars as a
      // string, however is easier to treat them as numbers and convert it here
      // before consuming the API.
      ...(input.filters?.stars && {
        stars: uniq(input.filters.stars.map(star => star.toString())),
      }),
      // When searching the ui presents as default the value "any"; however the
      // the API does not understand that, basically we are removing the value
      // when building the query params
      ...(input.filters?.rating &&
        input.filters.rating !== RatingOptions.Any && {
          rating: input.filters.rating,
        }),
      // When filtering by the family friendly status the API only wants that
      // param to exist when is actually true, however to keep the API to the
      // client easier it can send either true or false
      ...(input.filters?.familyFriendly &&
        input.filters.familyFriendly === true && {
          isFamiliar: true,
        }),
      // Accommodation
      ...(input.filters?.accommodations && {
        accommodations: uniq(input.filters.accommodations),
      }),
      // Facilities
      ...(input.filters?.facilities && {
        facilities: uniq(input.filters.facilities),
      }),
      // Price Range
      ...(input.filters?.priceRange?.min && {
        minPrice: input.filters.priceRange.min,
      }),
      ...(input.filters?.priceRange?.max && {
        maxPrice: input.filters.priceRange.max,
      }),

      // Catalogue
      ...(input.catalogue && { catalogue: input.catalogue }),

      // Features
      ...(input.highlightedProperty && { highlightedProperty: input.highlightedProperty }),
      // Pagination
      // page: input.page,
      page: input.cursor ?? input.page,
      offset: input.pageSize,
      // Sort
      sortBy: input.sortBy,
      // Excluded IDs
      ...(input.excludedIDs && { excludedIDs: input.excludedIDs }),
    };
  });
export type SearchInput = z.infer<typeof inputValidation>;

export const roomsSchema = z.array(propertyRoomSchema);

const pricingSchema = z.object({
  price: z.number(),
  priceOld: z.number().optional(),
  discount: z.number(),
  avgRate: z.number().optional(),
  additionalFees: z.number(),
  priceWithFees: z.number(),
  pricePerNight: z.number().optional(),
  pricePerNightWithFees: z.number().optional(),
  pricePerNightSubtotal: z.number().optional(),
  subtotal: z.number().optional(),
});

export type PropertyPricing = z.infer<typeof pricingSchema>;
const availableImagePaths = ['thumb', 'medium', 'big', 'raw'] as const;
const AvailableImagePath = z.enum(availableImagePaths);

const roomImage = z.object({
  code: z.string(),
  height: z.number(),
  id: z.string(),
  width: z.number(),
  availablePaths: z.array(AvailableImagePath),
});

const searchPropertySchema = z
  .object({
    address: z.string(),
    board: z.string().optional(),
    boardCode: z.string().optional(),
    cancellation: z.boolean().optional(),
    comments: z.number().optional(),
    country: z.string().optional(),
    currency: z.string().optional(),
    distance: z
      .object({
        value: z.number(),
        unit: z.string(),
      })
      .optional(),
    distanceToCenter: z
      .object({
        value: z.number(),
        unit: z.string(),
      })
      .optional(),
    facilities: z.array(
      z.object({
        id: z.string(),
        codeGroup: z.string(),
      })
    ),
    highlighted: z.boolean().optional(),
    id: z.number(),
    isInCenter: z.boolean().optional(),
    isLoadingContent: z.boolean().optional(),
    lazyLoad: z.boolean().optional(),
    locations: z.array(
      z.object({
        idLocation: z.string(),
        name: z.string(),
        nameSlug: z.string(),
        type: z.string(),
        coordinates: z.object({
          lat: z.number(),
          lon: z.number(),
        }),
      })
    ),
    name: z.string(),
    neighborhood: z.string().optional(),
    nights: z.number(),
    isOutsideCity: z.boolean().optional(),
    accommodationType: z.string(),
    isPrime: z.boolean().optional(),
    isMobile: z.boolean().optional(),
    propertyLocation: z.object({
      coordinates: z.object({
        lat: z.number(),
        lon: z.number(),
      }),
      countryCode: z.string(),
    }),
    images: z.array(roomImage).default([]),
    sellingRate: z.number(),
    bigheadRate: z.number().optional(),
    pricePerNight: z.number().optional(),
    rating: z.number(),
    rooms: roomsSchema,
    avgRate: z.number().optional(),
    score: z.number().optional(),
    slug: z.string(),
    stars: z.number(),
  })
  .transform(property => {
    const {
      id,
      address,
      accommodationType,
      name,
      nights,
      rating,
      comments,
      stars,
      slug,
      images,
      distanceToCenter,
      locations,
      propertyLocation,
      highlighted,
      rooms,
    } = property;

    const photos = images.map(image => ({
      id: image.id,
      code: image.code,
      height: image.height,
      width: image.width,
      availablePaths: image.availablePaths,
    }));

    const distanceUnit = distanceToCenter?.unit ?? 'm';
    const distanceValue = distanceToCenter?.value ?? 0;
    const distanceInKms = distanceUnit === 'm' ? distanceValue / 1000 : distanceValue;
    const distance = distanceValue !== 0 ? distanceInKms : null;
    const cancellation = rooms?.[0]?.isFreeCancellation || false;

    const city = locations?.find(location => location.type === 'city');

    const isInCenter = distance ? CENTER_RADIUS > distance : false;

    let additionalFees = 0;
    let priceWithFees = 0;
    let pricePerNightWithFees = 0;
    let subtotal = 0;
    let pricePerNightSubtotal = 0;

    rooms?.forEach(room => {
      const summary = room?.summary;
      additionalFees += summary?.additionalFees?.amount ?? 0;
      priceWithFees += summary?.totalWithFees?.amount ?? 0;
      pricePerNightWithFees += summary?.totalNightlyWithFees?.amount ?? 0;
      subtotal += summary?.subtotal?.amount ?? 0;
      pricePerNightSubtotal += summary?.subtotalNightly?.amount ?? 0;
    });

    return {
      id,
      address,
      accommodationType,
      name,
      nights,
      highlighted,
      stars,
      score: rating,
      comments,
      slug,
      facilities: property.facilities.map(facility => ({
        name: facility.id,
        icon: facility.id,
      })),
      photos,
      pricing: {
        price: property.sellingRate,
        priceOld: property.bigheadRate,
        discount: property.rating,
        avgRate: property.avgRate,
        additionalFees,
        priceWithFees: priceWithFees || property.sellingRate,
        pricePerNightWithFees: pricePerNightWithFees || property.avgRate,
        pricePerNightSubtotal: pricePerNightSubtotal || property.avgRate,
        subtotal,
      },
      latitude: propertyLocation?.coordinates?.lat,
      longitude: propertyLocation?.coordinates?.lon,
      distance,
      isInCenter,
      country: propertyLocation?.countryCode ?? property.country ?? null,
      city: city?.name ?? '',
      citySlug: city?.nameSlug ?? city?.name ?? '',
      rooms,
      cancellation,
    };
  });

export const searchPropertiesResultsSchema = z.array(searchPropertySchema);

export type SearchProperty = z.infer<typeof searchPropertySchema>;

export type SearchPropertiesResult = z.infer<typeof searchPropertiesResultsSchema>;

export const searchMetadataSchemaFilters = z.object({
  count: z.number(),
  price: z.object({
    min: z.number(),
    max: z.number(),
    distribution: z.array(
      z.object({
        min: z.number(),
        count: z.number().optional(),
      })
    ),
  }),
});

export type SearchMetadataFilters = z.infer<typeof searchMetadataSchemaFilters>;
