import React from 'react';
import PropTypes from 'prop-types';

import Head from 'next/head';

import { VALID_IMAGE_LAYOUTS, LAYOUT_RESPONSIVE, IMAGE_LOADING } from './constants';
import { ImageBase } from './styles';

export const getPreloadProps = ({ srcSet = [], sizes = [], imageSrcSet, imageSizes, src }) => {
  const linkProps = {
    rel: 'preload',
    as: 'image',
  };

  if (srcSet.length > 0 && sizes.length > 0) {
    linkProps.imagesrcset = imageSrcSet;
    linkProps.imagesizes = imageSizes;
  } else {
    linkProps.href = src;
  }

  return linkProps;
};

const Image = ({
  src,
  alt,
  width,
  height,
  layout,
  onClick,
  srcSet = [],
  sizes = [],
  preload = false,
  loading = 'eager',
  aspectRatio = '',
  style = {},
  className,
  dataTestId,
}) => {
  const imageDimensions = layout === LAYOUT_RESPONSIVE ? null : { width, height };
  const imageSrcSet = srcSet.length > 0 ? `${srcSet.join(', ')}` : null;
  const imageSizes = sizes.length > 0 ? `${sizes.join(', ')}` : null;

  function handleClick(event) {
    if (onClick) onClick(event);
  }

  return (
    <ImageBase
      className={className}
      layout={layout}
      onClick={handleClick}
      aspectRatio={aspectRatio}
      data-testid={dataTestId}
    >
      {preload && (
        <Head>
          <link {...getPreloadProps({ srcSet, sizes, imageSrcSet, imageSizes, src })} />
        </Head>
      )}
      <img
        src={src}
        alt={alt}
        {...imageDimensions}
        srcSet={imageSrcSet}
        sizes={imageSizes}
        {...(loading === 'eager' ? {} : { loading })}
        style={style}
      />
    </ImageBase>
  );
};

Image.propTypes = {
  alt: PropTypes.string.isRequired,
  layout: PropTypes.oneOf(VALID_IMAGE_LAYOUTS),
  onClick: PropTypes.func,
  src: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  srcSet: PropTypes.arrayOf(PropTypes.string),
  sizes: PropTypes.arrayOf(PropTypes.string),
  preload: PropTypes.bool,
  loading: PropTypes.oneOf(IMAGE_LOADING),
  aspectRatio: PropTypes.string,
  id: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  className: PropTypes.string,
  dataTestId: PropTypes.string,
};

export default Image;
