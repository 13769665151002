import { call, put } from 'redux-saga/effects';

import { displayAlertAction } from 'src/comps/AlertMessage/actions';
import { payNowFailureAction, payNowSetSecureCode, payNowSuccessAction } from './actions';
import { refetchVoucherAction } from 'src/comps/Voucher/actions';

import { payNowVoucher } from './requests';
import { ERROR_CODES } from 'client/pages/constants';

export function* payNowVoucherSaga(action) {
  try {
    const response = yield call(payNowVoucher, action.payload);

    if (response.status >= 400) {
      yield put(payNowFailureAction({ error: response.data }));
      yield put(displayAlertAction({ messageId: 'errorDefault' }));
      yield put(refetchVoucherAction());
      return;
    }

    if (response.status === 307) {
      const secureCode = response.data.secure_html_raw;
      yield put(payNowSetSecureCode({ secureCode }));
      return;
    }

    const { code, subCode } = response?.data;

    if (code === ERROR_CODES.PURCHASE_LOCKED) {
      yield put(payNowFailureAction({ paymentFailed: false }));
      yield put(displayAlertAction({ messageId: 'errorDefault' }));
      yield put(refetchVoucherAction());
      return;
    }

    if (code === ERROR_CODES.PARTIAL_PAYMENT_NOT_ALLOWED) {
      yield put(payNowFailureAction({ paymentFailed: true }));
      yield put(displayAlertAction({ messageId: 'errorPaymentVoucher' }));
      yield put(refetchVoucherAction());
      return;
    }

    if (code === ERROR_CODES.PAYMENT_PROVIDER && subCode === ERROR_CODES.INVALID_CARD) {
      yield put(payNowFailureAction({ paymentFailed: true }));
      yield put(displayAlertAction({ messageId: 'errorCardMessage' }));
      yield put(refetchVoucherAction());
      return;
    }

    if (code === ERROR_CODES.PAYMENT || code === ERROR_CODES.PAYMENT_PROVIDER) {
      yield put(payNowFailureAction({ paymentFailed: true }));
      yield put(displayAlertAction({ messageId: 'errorPaymentVoucher' }));
      yield put(refetchVoucherAction());
      return;
    }

    yield put(payNowSuccessAction());

    const { amount, creditCardNumber } = action.payload;
    yield put(
      displayAlertAction({
        messageId: 'successPayNow',
        severity: 'success',
        values: { amount, credit_card_number: creditCardNumber },
      })
    );

    yield put(refetchVoucherAction());
  } catch (error) {
    yield put(payNowFailureAction({ error: error.message }));
    yield put(displayAlertAction({ messageId: 'errorDefault' }));
    yield put(refetchVoucherAction());
  }
}
