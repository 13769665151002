import { requestByPOST } from 'lib/requests';

const END_POINTS = {
  SPECIAL_REQUESTS: '/customer/booking/v2/hotel-request',
};

export function addSpecialRequests({ hash, hotelRequest }) {
  return requestByPOST({
    data: { booking_hash: hash, hotel_request: hotelRequest },
    endpoint: END_POINTS.SPECIAL_REQUESTS,
  });
}
