import { call, put } from 'redux-saga/effects';

import { CREATED } from 'src/constants/httpcodes';
import { displayAlertAction } from 'src/comps/AlertMessage/actions';
import { fetchForgottenPassword } from './requests';
import { forgotPasswordSuccessAction, forgotPasswordFailureAction } from './actions';

export function* forgotPasswordSaga(action) {
  try {
    const response = yield call(fetchForgottenPassword, action.payload);

    if (response.status === CREATED.status) {
      yield put(forgotPasswordSuccessAction());
      return;
    }

    yield put(displayAlertAction({ messageId: 'errorDefault' }));
    yield put(forgotPasswordFailureAction({ error: response.data || response.status }));
  } catch (error) {
    yield put(displayAlertAction({ messageId: 'errorDefault' }));
    yield put(forgotPasswordFailureAction({ error: error.message }));
  }
}
