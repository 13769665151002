import {
  PAYMENT_STATE,
  CONFIRMATION_REQUEST,
  CONFIRMATION_SUCCESS,
  CONFIRMATION_SUCCESS_3DS,
  CONFIRMATION_FAILURE,
  RESET_PAYMENT,
  STATUS_OK,
  STATUS_KO,
  STATUS_3DS_REQUIRED,
} from './constants';
import stateBase from './state';

const initialState = stateBase[PAYMENT_STATE];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CONFIRMATION_REQUEST:
      return {
        ...state,
        error: null,
        isFetching: true,
        paymentStatus: null,
        securePayment: null,
      };

    case CONFIRMATION_SUCCESS:
      return {
        ...state,
        error: null,
        isFetching: false,
        paymentStatus: STATUS_OK,
        securePayment: null,
      };

    case CONFIRMATION_SUCCESS_3DS:
      return {
        ...state,
        error: null,
        isFetching: false,
        paymentStatus: STATUS_3DS_REQUIRED,
        securePayment: action.payload?.securePayment,
      };

    case CONFIRMATION_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isFetching: false,
        paymentStatus: STATUS_KO,
        securePayment: null,
      };

    case RESET_PAYMENT:
      return {
        ...state,
        error: null,
        isFetching: false,
        paymentStatus: null,
        securePayment: null,
      };

    default:
      return state;
  }
};

export default reducer;
