import { requestByGET } from 'lib/requests';

const END_POINTS = {
  VERIFY_EMAIL: '/booking/verification',
};

export function verifyEmail({ hash, email }) {
  return requestByGET({
    data: { booking_hash: hash, user_email: email },
    endpoint: END_POINTS.VERIFY_EMAIL,
  });
}
