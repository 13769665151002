import type { NextPageContext } from 'next';
import { ErrorType } from '../withSession/errorType';
import { getOriginalPageProps } from './utils';

export const getInitialProps = (ctx: NextPageContext) => {
  const { err } = ctx;
  // This file is specific for the client
  // Since we are use withSession everywhere, it means that all unexpected
  // errors are going to be cached by it, thus leaving only render error to be
  // handled by _error.
  if (err?.statusCode === 500) {
    // when there is a call to _next (transitioning between pages) its
    // getServerSideProps might fail, in those cases we are returning a 500
    // but since those 500 do not have all the config readily available to
    // bootstrap everything in _app we read the original pageProps and pull its
    // config.
    const pageProps = getOriginalPageProps();

    return {
      config: pageProps.config,
      error: ErrorType.ServerError,
    };
  }

  if (err?.statusCode === 404) {
    // when there is a call to _next (transitioning between pages) its
    // getServerSideProps might fail, in those cases we are returning a 500
    // but since those 400 do not have all the config readily available to
    // bootstrap everything in _app we read the original pageProps and pull its
    // config.
    const pageProps = getOriginalPageProps();

    return {
      config: pageProps.config,
      error: ErrorType.NotFound,
    };
  }

  return {};
};
