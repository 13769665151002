export const FETCH_MAP_PROPERTIES_REQUEST = 'FETCH_MAP_PROPERTIES_REQUEST';
export const FETCH_MAP_PROPERTIES_SUCCESS = 'FETCH_MAP_PROPERTIES_SUCCESS';
export const FETCH_MAP_PROPERTIES_FAILURE = 'FETCH_MAP_PROPERTIES_FAILURE';

export const RESET_MAP = 'RESET_MAP';

export const MAP_STATE = 'map';
export const DEFAULT_MAP_RADIO = 2;
export const PROPERTIES_PER_KM = 50;
export const MIN_PROPERTIES_PER_REQUEST = 32;
export const MAX_PROPERTIES_PER_REQUEST = 1200;
