import format from 'date-fns-tz/format';
import toDate from 'date-fns-tz/toDate';

import {
  addDays,
  differenceInDays,
  isBefore,
  isPast,
  isSameDay,
  isValid,
  startOfToday,
  startOfTomorrow,
} from 'date-fns';

import { getDaysOfBookings } from 'src/constants/business';

export function formatISO(date) {
  return format(date, 'yyyy-MM-dd'); // ISO date format
}

export function checkDates(checkIn, checkOut, market) {
  if (!checkIn || !checkOut) {
    return false;
  }

  const today = startOfToday();

  const checkInDate = toDate(checkIn);
  const checkOutDate = toDate(checkOut);

  if (!isValid(checkInDate) || !isValid(checkOutDate)) {
    return false;
  }

  if (isPast(checkInDate) && !isSameDay(today, checkInDate)) {
    return false;
  }

  if (['au'].includes(market) && isSameDay(today, checkInDate)) {
    return false;
  }

  if (isSameDay(checkInDate, checkOutDate)) {
    return false;
  }

  if (isBefore(checkOutDate, checkInDate)) {
    return false;
  }

  if (differenceInDays(checkOutDate, checkInDate) < getDaysOfBookings(market)) {
    return false;
  }

  return true;
}

export function checkStartDate(checkIn, market) {
  if (!checkIn) {
    return false;
  }

  const today = startOfToday();
  const tomorrow = startOfTomorrow();
  const checkInDate = toDate(checkIn);

  if (!isValid(checkInDate)) {
    return false;
  }

  if (isPast(checkInDate) && !isSameDay(today, checkInDate)) {
    return false;
  }

  if (market === 'us' && isSameDay(today, checkInDate)) {
    return false;
  }

  if (market === 'us' && isSameDay(tomorrow, checkInDate)) {
    return false;
  }

  return true;
}

export function checkRelativeDays(daysForCheckIn, daysForCheckOut) {
  if (!daysForCheckIn || !daysForCheckOut) {
    return false;
  }

  const daysForCheckInParsed = parseInt(daysForCheckIn, 10);
  const daysForCheckOutParsed = parseInt(daysForCheckOut, 10);

  if (Number.isNaN(daysForCheckInParsed) || Number.isNaN(daysForCheckOutParsed)) {
    return false;
  }

  if (daysForCheckInParsed >= daysForCheckOutParsed) {
    return false;
  }

  return true;
}

export function getValidDates({ checkIn, checkOut, daysForCheckIn, daysForCheckOut, market }) {
  if (checkDates(checkIn, checkOut, market)) {
    return { checkIn, checkOut };
  }

  if (checkStartDate(checkIn, market)) {
    const checkOutFromCheckIn = addDays(toDate(checkIn), getDaysOfBookings(market));
    return { checkIn, checkOut: formatISO(checkOutFromCheckIn) };
  }

  if (checkRelativeDays(daysForCheckIn, daysForCheckOut)) {
    const today = new Date();
    const relativeCheckIn = addDays(today, daysForCheckIn);
    const relativeCheckOut = addDays(today, daysForCheckOut);

    return {
      checkIn: formatISO(relativeCheckIn),
      checkOut: formatISO(relativeCheckOut),
    };
  }

  return {};
}
