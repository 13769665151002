import React from 'react';
import PropTypes from 'prop-types';

import IconClose from 'client/icons/Close';

import { NotificationBase, NotificationClose, NotificationContent } from './styles';

const Notification = ({ onClose, children }) => {
  return (
    <NotificationBase>
      <NotificationClose>
        <IconClose onClick={onClose} data-testid="notification-close" />
      </NotificationClose>
      <NotificationContent>{children}</NotificationContent>
    </NotificationBase>
  );
};

Notification.defaultProps = {
  onClose: () => {},
};

Notification.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
};

export default Notification;
