import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AlertMessage from '.';
import { getAlert } from './selectors';
import { removeAlertAction } from './actions';

const AlertMessageContainer = () => {
  const dispatch = useDispatch();
  const { messageId, severity, values } = useSelector(getAlert);

  function handleClose() {
    dispatch(removeAlertAction());
    clearTimeout();
  }

  useEffect(() => {
    if (messageId) {
      setTimeout(() => dispatch(removeAlertAction()), 10000);
    }
  }, [dispatch, messageId, severity]);

  return (
    <AlertMessage
      messageId={messageId}
      severity={severity}
      handleClose={handleClose}
      values={values}
    />
  );
};

export default AlertMessageContainer;
