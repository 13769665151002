import { call, put } from 'redux-saga/effects';

import { fetchUserWalletFailedAction, fetchUserWalletSuccessAction } from './actions';
import { fetchUserWalletAvailable } from './requests';

export function* fetchUserWalletAvailableSaga(action) {
  try {
    const response = yield call(fetchUserWalletAvailable, action.payload);

    if (response.status >= 400) {
      throw new Error(response.data || response.status);
    }

    yield put(fetchUserWalletSuccessAction({ currencies: response.data.currencies }));
  } catch (error) {
    yield put(
      fetchUserWalletFailedAction({
        error: error.message,
      })
    );
  }
}
