import { call, put } from 'redux-saga/effects';

import {
  confirmationSuccessAction,
  confirmationSuccess3DSAction,
  confirmationFailureAction,
} from './actions';
import { fetchPrimeConfirmation } from './requests';
import { sendTrackingRequestAction } from 'client/contexts/Tracking/actions';
import { successPrimePayment } from 'client/contexts/Tracking/events';

export function* confirmationSaga(action) {
  try {
    const { data, status } = yield call(fetchPrimeConfirmation, action.payload);

    if (status >= 400) {
      yield put(confirmationFailureAction({ error: data }));
      return;
    }

    if (status === 200 && data?.securePayment) {
      yield put(confirmationSuccess3DSAction({ securePayment: data?.securePayment }));
      yield put(sendTrackingRequestAction({ event: successPrimePayment() }));
      return;
    }

    yield put(confirmationSuccessAction());
    yield put(sendTrackingRequestAction({ event: successPrimePayment() }));
  } catch (error) {
    yield put(confirmationFailureAction({ error: error.message }));
  }
}
