import { ALERT_DISPLAY, ALERT_REMOVE } from './constants';

export const displayAlertAction = payload => ({
  type: ALERT_DISPLAY,
  payload,
});

export const removeAlertAction = payload => ({
  type: ALERT_REMOVE,
  payload,
});
