import {
  PROPERTIES_VISITED_STATE,
  FETCH_PROPERTIES_VISITED_REQUEST,
  FETCH_PROPERTIES_VISITED_SUCCESS,
  FETCH_PROPERTIES_VISITED_FAILURE,
  REMOVE_PROPERTY,
} from './constants';
import stateBase from './state';

const initialState = stateBase[PROPERTIES_VISITED_STATE];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROPERTIES_VISITED_REQUEST: {
      return {
        ...state,
        error: null,
        isFetching: true,
        data: [],
      };
    }

    case FETCH_PROPERTIES_VISITED_SUCCESS: {
      return {
        ...state,
        error: null,
        isFetching: false,
        data: action.payload.properties || [],
      };
    }

    case FETCH_PROPERTIES_VISITED_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };
    }

    case REMOVE_PROPERTY:
      return {
        ...state,
        data: state.data.filter(property => property.id !== action.payload.propertyId),
      };

    default: {
      return state;
    }
  }
};

export default reducer;
