import {
  SAVE_CUSTOMER_DATA,
  SAVE_HOLDERS,
  SAVE_INSURANCE_IDS,
  SAVE_ADDITIONAL_DATA,
  CLEAN_CUSTOMER_DATA,
} from './constants';

export const saveCustomerDataAction = payload => ({
  type: SAVE_CUSTOMER_DATA,
  payload,
});

export const saveHoldersAction = payload => ({
  type: SAVE_HOLDERS,
  payload,
});

export const saveInsuranceIdsAction = payload => ({
  type: SAVE_INSURANCE_IDS,
  payload,
});

export const saveAdditionalDataAction = payload => ({
  type: SAVE_ADDITIONAL_DATA,
  payload,
});

export const cleanCustomerDataAction = payload => ({
  type: CLEAN_CUSTOMER_DATA,
  payload,
});
