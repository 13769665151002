import {
  FORGOT_PASSWORD_STATE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
} from './constants';
import stateBase from './state';

const initialState = stateBase[FORGOT_PASSWORD_STATE];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD_REQUEST: {
      return {
        ...state,
        error: null,
        isFetching: true,
        accountExist: null,
      };
    }

    case FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        error: null,
        isFetching: false,
        accountExist: true,
      };
    }

    case FORGOT_PASSWORD_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        isFetching: false,
        accountExist: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
