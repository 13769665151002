import { RESET_PASSWORD_STATE } from './constants';

const state = {
  [RESET_PASSWORD_STATE]: {
    error: null,
    isFetching: false,
    passwordReset: null,
  },
};

export default state;
