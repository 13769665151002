import { PAGES_STATE, CHANGE_PAGE } from './constants';
import stateBase from './state';

const initialState = stateBase[PAGES_STATE];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_PAGE:
      return {
        ...state,
        pageId: action.payload.pageId || state.pageId,
        pageNumber: action.payload.pageNumber || state.pageNumber,
      };

    default:
      return state;
  }
};

export default reducer;
