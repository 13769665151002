import { requestByGET, requestByPOST } from 'lib/requests';
import { buildPathParams } from 'lib/utils/url';

import { buildModifyDatesRequest, mapConfirmation, mapDataToAvailabilities } from './mappers';

const END_POINTS = {
  MODIFY_DATES: '/booking/{bookingHash}/dates/change',
  MODIFY_DATES_CONFIRMATION: '/booking/confirm-dates-change',
};

export function modifyDates(params) {
  return requestByGET({
    data: buildModifyDatesRequest(params),
    endpoint: buildPathParams(END_POINTS.MODIFY_DATES, { bookingHash: params.bookingHash }),
    mapper: mapDataToAvailabilities,
  });
}

export function modifyDatesConfirmation({ hash, availabilityId }) {
  return requestByPOST({
    data: { hash, availability: availabilityId },
    endpoint: END_POINTS.MODIFY_DATES_CONFIRMATION,
    mapper: mapConfirmation,
  });
}
