import { css } from 'styled-components';

const isVisuallyHidden = css`
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  visibility: hidden;
`;

const ellipsis = css`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const helpers = {
  ellipsis,
  isVisuallyHidden,
};
