import addDays from 'date-fns/addDays';
import { formatISO } from 'lib/dates';

import { DAYS_TO_START, DAYS_OF_BOOKING } from 'src/constants/business';

export const DEFAULT_SEARCH = {
  adults: [2],
  children: [[]],
  checkIn: formatISO(addDays(new Date(), DAYS_TO_START)),
  checkOut: formatISO(addDays(new Date(), DAYS_TO_START + DAYS_OF_BOOKING)),
};

export const LOCALSTORAGE_SEARCH_KEY = 'sfl_search_criteria';
