import { z } from 'zod';

export const descriptionSchema = z
  .object({
    activity: z.string().nullish(),
    additional: z.string().nullish(),
    amenities: z.string().nullish(),
    attractions: z.string().nullish(),
    businessAmenities: z.string().nullish(),
    dining: z.string().nullish(),
    general: z.string().nullish(),
    generic: z.string().nullish(),
    location: z.string().nullish(),
    pool: z.string().nullish(),
    renovations: z.string().nullish(),
    rooms: z.string().nullish(),
  })
  .nullable();

export const PropertyLocationSchema = z.object({
  locationType: z.string(),
  locationId: z.union([z.string(), z.number()]),
  locationName: z.string(),
  locationSlug: z.string(),
  coordinates: z.object({
    latitude: z.number(),
    longitude: z.number(),
  }),
});

export const sharedPropertySchema = z.object({
  accommodationType: z.string().nullish(),
  address: z.string().nullish(),
  cancellationPolicies: z.array(z.string()).nullish(),
  city: z.string().nullish(),
  citySlug: z.string().nullish(),
  cityUrl: z.string().nullish(),
  country: z.string().nullish(),
  description: z.record(descriptionSchema.or(z.array(z.unknown()))).nullish(),
  distance: z.number().nullish(),
  facilitiesRoom: z.array(
    z.object({
      featured: z.boolean(),
      free: z.boolean(),
      name: z.string(),
      slug: z.string(),
    })
  ),
  highlighted: z.boolean().nullish(),
  isInCenter: z.boolean().nullish(),
  isOutsideCity: z.boolean().nullish(),
  locations: z.array(PropertyLocationSchema).nullish(),
  name: z.string().nullish(),
  neighborhood: z.string().nullish(),
  newPath: z.string().nullish(),
  images: z.array(z.object({
    height: z.number(),
    id: z.string(),
    code: z.string(),
    width: z.number(),
    availablePaths: z.array(z.string())
  })),
  postalCode: z.string().nullish(),
  ratings: z
    .object({
      idTripadvisor: z.string(),
      rating: z.number(),
      ratingCount: z.number(),
      subratings: z.array(
        z.object({
          name: z.string(),
          value: z.number(),
          localizedName: z.string(),
        })
      ),
    })
    .or(z.object({ rating: z.string() }))
    .nullish(),
  score: z.number().nullish(),
  slug: z.string().nullish(),
  stars: z.number().nullish(),
  url: z.string().nullish(),
});
