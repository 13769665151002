import { any, func, shape, string, instanceOf, oneOfType } from 'prop-types';

export const typeRef = oneOfType([func, shape({ current: instanceOf(Object) })]);

export const typeSearchLocationData = shape({
  city: string.isRequired,
  citySlug: string.isRequired,
  region: string.isRequired,
  zone: string,
});

const userSearchParams = {
  adultsByRoom: string,
  checkIn: string,
  checkOut: string,
  childrenAges: string,
  childrenByRoom: string,
};

export const typePropertiesSearchParams = shape({
  ...userSearchParams,
  highlightedProperty: string,
  orderBy: string,
  page: string,
});

export const typeSerpRouteParams = shape({
  country: string.isRequired,
  slug: string.isRequired,
  type: string.isRequired,
  city: string,
});

export const typeLocationHotelPage = shape({
  city: string.isRequired,
  country: string.isRequired,
  slug: string.isRequired,
});

export { any as typeAny };
export { typeProperty } from './property';
export { typeBoard } from './room';
export { typeSearch } from './search';
export { typeCheckoutBoard } from './checkout';
