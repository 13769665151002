import React, { useState, useContext, useEffect } from 'react';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useMedia } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import noop from 'lodash/noop';

import { DEFAULT_SEARCH } from 'src/constants/search';
import { LOCATION_TYPES } from './constants';

import { buildLocation } from 'lib/utils/page';
import { getBaseParams } from 'src/comps/Page/selectors';
import { getSearch, getDates } from 'src/effects/Search/selectors';
import { getSearchParamsLocalStorage, setSearchParamsLocalStorage } from 'lib/storage';
import { PageContext } from 'src/comps/Page/context';
import { routes } from 'src/constants/routes';
import { typeRef, typeSearch } from 'client/types';
import { updateSearchAction } from 'src/effects/Search/actions';
import { getFilters } from 'client/components/FiltersContainer/selectors';

import DateSelector from 'client/components/DateSelector';
import InputReadOnly from 'client/components/InputReadOnly';
import Loader from 'src/comps/Atom/Loader';
import LocationSelector from 'client/components/LocationSelector/LocationSelector';
import Modal from 'client/components/Modal';
import IconSearch from 'client/icons/Search';

import messages from './messages';
import { updatedBreakpoint } from 'client/styles';
import { ButtonSearch, Form, Paxes } from './styles';
import { addError } from 'src/utils/clientErrorReporter';
import { clearPropertyAction } from 'client/contexts/Property/actions';

const PaxSelector = dynamic(
  () => import(/* webpackChunkName: "pax-selector" */ 'client/components/PaxSelector'),
  { loading: Loader }
);

const SearchForm = props => {
  const {
    initSearch = {},
    closeModal,
    linkQueryParams = {},
    onSubmit,
    searchRef,
    detail = false,
    unFreezeScroll = false,
    setIsSuggesterOpen = noop,
  } = props;
  const forwardProps = omit(props, [
    'initSearch',
    'closeModal',
    'linkQueryParams',
    'onSubmit',
    'searchRef',
  ]);
  const dispatch = useDispatch();
  const intl = useIntl();
  const router = useRouter();
  const isDetail = router.asPath?.includes('hotel');

  const { device } = useContext(PageContext);
  const { checkIn, checkOut } = useSelector(getDates);
  const { language, market } = useSelector(getBaseParams);
  const searchParams = useSelector(getSearch);
  const filters = useSelector(getFilters);
  const isWide = useMedia(updatedBreakpoint.desktop, device === 'desktop');

  const { adults, children, city, country, type, slug, latitude, longitude, term } = searchParams;
  const paxes = { adults, children };

  const [coordinates, setCoordinates] = useState({
    latitude: initSearch?.latitude || latitude,
    longitude: initSearch?.longitude || longitude,
  });

  const [pathParams, setPathParams] = useState({
    city: initSearch?.city || city,
    country: initSearch?.country || country,
    slug: initSearch?.slug || slug,
    type: initSearch?.type || type,
  });

  const [isDisabled, setIsDisabled] = useState(true);
  const [isPaxesOpen, setPaxesOpen] = useState(false);
  const [isDatePickerOpen, setDatePickerOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState(initSearch?.term || initSearch?.searchTerm || term);

  useEffect(() => {
    dispatch(updateSearchAction(initSearch));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsDisabled(!searchTerm);
  }, [searchTerm]);

  function handleSelectLocation(item) {
    setSearchTerm(item.term);

    const { lat, lon } = item?.coordinates || {};
    setCoordinates({ latitude: lat, longitude: lon });
    setPathParams({
      city: item.citySlug || '',
      country: item.country || '',
      slug: item.slug || '',
      type: item.type || '',
    });
  }

  function handleKeyDown(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      setPaxesOpen(true);
    }
  }

  function saveSearchToLocalStorage(updatedParams) {
    const searchStored = getSearchParamsLocalStorage();

    setSearchParamsLocalStorage({
      ...searchStored,
      ...updatedParams,
    });
  }

  function redirectTo() {
    const isProperty = !LOCATION_TYPES.includes(pathParams.type);
    const isMap = router.asPath.includes('map');
    const isPropertyMap = router.asPath.includes('map') && router.asPath.includes('hotel');

    if (isPropertyMap) {
      return routes.map.page;
    }

    // TODO: remove this when API returns the right type !!
    if (pathParams.type === 'province_state') {
      pathParams.type = 'zone';
      return isMap ? routes.map.page : routes.list.page;
    }

    if (isProperty) {
      return routes.detail.page;
    }

    if (isMap) {
      return routes.map.page;
    }

    return routes.list.page;
  }

  function handleSubmitForm(event) {
    event.preventDefault();

    setPaxesOpen(false);
    setDatePickerOpen(false);

    const query = {
      ...linkQueryParams,
      ...pathParams,
      lang: language,
      market,
    };

    const updatedParams = {
      ...DEFAULT_SEARCH,
      ...searchParams,
      ...pathParams,
      ...coordinates,
      term: searchTerm,
      filters,
    };

    dispatch(clearPropertyAction(updatedParams));
    dispatch(updateSearchAction(updatedParams));
    saveSearchToLocalStorage(updatedParams);
    const target = buildLocation({
      page: redirectTo(),
      path: pathParams,
      query,
    });

    if (target.as !== router.asPath.split('?')[0]) {
      const url = new URL(target.as, document.location.origin);
      const context = {
        ...pathParams,
        query,
      };

      if (url.pathname === '/hotel/[country]/[slug]' || !target.as) {
        addError(new Error('[Suggester] Bad URL'), context);
      }

      router.push(target.href, target.as);
    }

    if (target.as === router.asPath && isDetail) {
      const targetForSameLocation = buildLocation({
        page: redirectTo(),
        path: pathParams,
        query,
        isDetail,
        isSameLocation: true,
      });

      router.push(targetForSameLocation.href, targetForSameLocation.as);
    }

    if (onSubmit) {
      onSubmit({ ...pathParams, clean: true });
    }

    if (closeModal) {
      closeModal();
    }
  }

  /* @todo: this is the same that the component <Paxes /> */
  function translatePaxes(paxesNumber, singular, plural) {
    return `${paxesNumber} ${intl
      .formatMessage(paxesNumber > 1 ? plural : singular)
      .toLowerCase()}`;
  }

  const textRooms = translatePaxes(adults.length, messages.room, messages.rooms);
  const textAdults = translatePaxes(
    adults.reduce((a, b) => parseInt(a) + parseInt(b)),
    messages.adult,
    messages.adults
  );

  const textChildrenLocalized = translatePaxes(
    children.flat().filter(f => f >= 0).length,
    messages.child,
    messages.children
  );
  const textChildren = children.flat().length ? `, ${textChildrenLocalized}` : '';
  const textPaxes = `${textRooms}, ${textAdults}${textChildren}`;
  /* @todo end */

  return (
    <Form data-testid="searchform-form" onSubmit={handleSubmitForm} {...forwardProps}>
      <LocationSelector
        search={searchTerm}
        handleSelectLocation={handleSelectLocation}
        setIsSuggesterOpen={setIsSuggesterOpen}
      />
      <DateSelector
        checkIn={checkIn}
        checkOut={checkOut}
        isDatePickerOpen={isDatePickerOpen}
        setDatePickerOpen={setDatePickerOpen}
        searchRef={searchRef}
        unFreezeScroll={unFreezeScroll}
      />
      <Paxes isFocus={isPaxesOpen}>
        <InputReadOnly
          dataTestId="input-paxes"
          inputName="pax"
          labelMessage={messages.guests}
          onClick={() => setPaxesOpen(true)}
          onKeyDown={handleKeyDown}
          value={textPaxes}
        />
        {isWide && device !== 'tablet' && isPaxesOpen && (
          <PaxSelector onSave={() => setPaxesOpen(false)} initialPaxes={paxes} />
        )}
      </Paxes>
      {(!isWide || device === 'tablet') && (
        <Modal
          handleClose={() => setPaxesOpen(false)}
          show={isPaxesOpen}
          title={intl.formatMessage(messages.guests)}
          variant="fullscreen"
        >
          <PaxSelector onSave={() => setPaxesOpen(false)} initialPaxes={paxes} inModal />
        </Modal>
      )}

      <ButtonSearch
        type="submit"
        onClick={handleSubmitForm}
        disabled={isDisabled}
        data-track-evt={detail ? 'search_button_click' : ''}
      >
        <IconSearch size={24} fill="white" />
        <FormattedMessage {...messages.search} tagName="span" />
      </ButtonSearch>
    </Form>
  );
};

SearchForm.propTypes = {
  closeModal: PropTypes.func,
  initSearch: typeSearch,
  linkQueryParams: PropTypes.objectOf(PropTypes.any),
  onSubmit: PropTypes.func,
  searchRef: typeRef,
  detail: PropTypes.bool,
  unFreezeScroll: PropTypes.bool,
  setIsSuggesterOpen: PropTypes.func,
};

export default SearchForm;
