import { USER_WALLET_REQUEST, USER_WALLET_SUCCESS, USER_WALLET_FAILED } from './constants';
import defaultState from './state';

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case USER_WALLET_REQUEST: {
      return {
        ...state,
        isFetching: true,
        status: null,
        availableCredit: [],
      };
    }

    case USER_WALLET_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        availableCredit: action.payload?.currencies,
      };
    }

    case USER_WALLET_FAILED: {
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
        availableCredit: [],
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
