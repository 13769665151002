import { VOUCHER_STATE } from './constants';

const state = {
  [VOUCHER_STATE]: {
    email: null,
    error: null,
    isFetching: false,
    refetch: false,
    voucher: {},
  },
};

export default state;
