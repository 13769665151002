import { getCheckoutTracking, getTrackingFromSource, getKafkaRoomsAvailability } from './utils';

export const pageView = {
  home: eventData => ({
    kafka: {
      eventName: 'homepage',
      eventType: 'pageview',
      eventData,
      appStateItems: ['isLoggedIn', 'userId'],
    },
    dataLayer: {
      sfl: {
        appStateItems: [
          'adults',
          'checkIn',
          'checkOut',
          'children',
          'country',
          'currency',
          'emailLogin',
          'language',
          'market',
          'slug',
        ],
      },
    },
  }),
  list: eventData => ({
    kafka: {
      eventName: 'hotel_list_results',
      eventType: 'pageview',
      eventData,
      appStateItems: [
        'isLoggedIn',
        'checkIn',
        'checkOut',
        'search',
        'adults',
        'children',
        'rooms',
        'userId',
      ],
    },
    dataLayer: {
      sfl: {
        appStateItems: [
          'adults',
          'checkIn',
          'checkOut',
          'children',
          'city',
          'country',
          'currency',
          'emailLogin',
          'language',
          'market',
          'nights',
          'slug',
          'searchTerm',
        ],
      },
    },
  }),
  map: eventData => ({
    kafka: {
      eventName: 'map',
      eventType: 'pageview',
      eventData,
      appStateItems: [
        'isLoggedIn',
        'checkIn',
        'checkOut',
        'mapSearch',
        'adults',
        'children',
        'rooms',
        'userId',
      ],
    },
  }),
  detail: eventData => ({
    kafka: {
      eventName: 'hotel_details',
      eventType: 'pageview',
      eventData,
      appStateItems: [
        'isLoggedIn',
        'adults',
        'checkIn',
        'checkOut',
        'children',
        'nights',
        'rooms',
        'userId',
      ],
    },
  }),
  propertyMap: eventData => ({
    kafka: {
      eventName: 'hotel_map',
      eventType: 'pageview',
      eventData,
      appStateItems: [
        'isLoggedIn',
        'adults',
        'checkIn',
        'checkOut',
        'children',
        'nights',
        'rooms',
        'userId',
      ],
    },
  }),
  login: eventData => ({
    kafka: {
      eventName: 'login',
      eventType: 'pageview',
      eventData,
      appStateItems: ['isLoggedIn', 'userId'],
    },
  }),
  signUp: eventData => ({
    kafka: {
      eventName: 'signup',
      eventType: 'pageview',
      eventData,
      appStateItems: ['isLoggedIn', 'userId'],
    },
  }),
  forgotPassword: eventData => ({
    kafka: {
      eventName: 'forgot-password',
      eventType: 'pageview',
      eventData,
      appStateItems: ['isLoggedIn', 'userId'],
    },
  }),
  staywallet: eventData => ({
    kafka: {
      eventName: 'staywallet',
      eventType: 'pageview',
      eventData,
      appStateItems: ['isLoggedIn', 'userId'],
    },
  }),
  landings: eventData => ({
    kafka: {
      eventName: 'page_view_landing',
      eventType: 'pageview',
      eventData,
      appStateItems: ['isLoggedIn', 'userId'],
    },
    dataLayer: {
      sfl: {
        appStateItems: [
          'adults',
          'checkIn',
          'checkOut',
          'children',
          'country',
          'currency',
          'language',
          'market',
          'slug',
        ],
      },
    },
  }),
  promos: eventData => ({
    kafka: {
      eventName: 'black_friday',
      eventType: 'pageview',
      eventData,
      appStateItems: ['isLoggedIn', 'userId'],
    },
    dataLayer: {
      sfl: {
        appStateItems: [
          'adults',
          'checkIn',
          'checkOut',
          'children',
          'country',
          'currency',
          'language',
          'market',
          'slug',
        ],
      },
    },
  }),
  blackFriday: eventData => ({
    kafka: {
      eventName: 'black_friday',
      eventType: 'pageview',
      eventData,
      appStateItems: ['isLoggedIn', 'userId'],
    },
    dataLayer: {
      sfl: {
        appStateItems: [
          'adults',
          'checkIn',
          'checkOut',
          'children',
          'country',
          'currency',
          'language',
          'market',
          'slug',
        ],
      },
    },
  }),
  preBlackFriday: eventData => ({
    kafka: {
      eventName: 'pre_black_friday',
      eventType: 'pageview',
      eventData,
      appStateItems: ['isLoggedIn', 'userId'],
    },
    dataLayer: {
      sfl: {
        appStateItems: [
          'adults',
          'checkIn',
          'checkOut',
          'children',
          'country',
          'currency',
          'language',
          'market',
          'slug',
        ],
      },
    },
  }),
  newYearDeals: eventData => ({
    kafka: {
      eventName: 'new_year_deals',
      eventType: 'pageview',
      eventData,
      appStateItems: ['isLoggedIn', 'userId'],
    },
    dataLayer: {
      sfl: {
        appStateItems: [
          'adults',
          'checkIn',
          'checkOut',
          'children',
          'country',
          'currency',
          'language',
          'market',
          'slug',
        ],
      },
    },
  }),
  error: eventData => ({
    kafka: {
      eventName: 'error',
      eventType: 'pageview',
      eventData,
      appStateItems: ['isLoggedIn', 'userId'],
    },
  }),
  checkout: ({ affiliateId, board, device, ip, isMandatory, language, market, price, property }) =>
    getCheckoutTracking({
      affiliateId,
      board,
      device,
      ip,
      isMandatory,
      language,
      market,
      price,
      property,
      eventName: 'customer_checkout',
    }),
  payment: ({ affiliateId, board, device, ip, language, market, price, property }) => ({
    kafka: {
      eventName: 'payment_checkout',
      eventType: 'pageview',
      eventData: { affiliateId, board, device, ip, language, market, price, property },
      appStateItems: [
        'isLoggedIn',
        'adults',
        'checkIn',
        'checkOut',
        'currency',
        'children',
        'nights',
        'rooms',
        'userId',
      ],
    },
    dataLayer: {
      sfl: {
        data: {
          language,
          market,
          ip,
          device,
          property,
          affiliateId,
          board,
          price,
        },
        appStateItems: [
          'isLoggedIn',
          'adults',
          'checkIn',
          'checkOut',
          'children',
          'country',
          'currency',
          'slug',
          'purchaseEmail',
        ],
      },
      ecommerce: {
        data: {
          board,
          property,
          price,
        },
        appStateItems: ['adults', 'bestPriceData', 'currency', 'property'],
      },
    },
  }),
  thanks: ({
    affiliateId,
    board,
    bookingId,
    device,
    generatedWallet,
    ip,
    language,
    market,
    paymentMethod,
    price,
    property,
    purchaseEmail,
    purchaseId,
    firstClickAttribution,
  }) => ({
    kafka: {
      eventName: 'booking_thanks',
      eventType: 'pageview',
      eventData: {
        affiliateId,
        board,
        bookingId,
        device,
        generatedWallet,
        ip,
        language,
        market,
        paymentMethod,
        price,
        property,
        purchaseId,
        ...(firstClickAttribution && {
          first_click_attribution: {
            aid: firstClickAttribution,
          },
        }),
      },
      appStateItems: [
        'affiliateData',
        'isLoggedIn',
        'adults',
        'checkIn',
        'checkOut',
        'currency',
        'children',
        'nights',
        'rooms',
        'userId',
        'purchaseEmailHash',
      ],
    },
    dataLayer: {
      sfl: {
        data: {
          affiliateId,
          board,
          bookingId,
          device,
          generatedWallet,
          ip,
          language,
          market,
          paymentMethod,
          price,
          property,
          purchaseEmail,
        },
        appStateItems: [
          'adults',
          'checkIn',
          'checkOut',
          'children',
          'country',
          'currency',
          'purchaseEmail',
          'slug',
        ],
      },
      ecommerce: {
        data: {
          board,
          property,
          price,
        },
        appStateItems: ['adults', 'bestPriceData', 'currency', 'property'],
      },
      conversionGA: {
        eventName: 'conversionGA',
        eventData: {
          affiliateId,
          price,
          property,
          purchaseId,
        },
      },
      iterable: {
        eventName: 'iterable',
      },
      tripadvisor: {
        eventName: 'tripadvisor',
      },
      conversionAffiliate: {
        eventName: 'conversionAffiliate',
      },
    },
  }),
  voucher: eventData => {
    const {
      affiliateId,
      board,
      bookingId,
      device,
      generatedWallet,
      ip,
      language,
      market,
      paymentMethod,
      price,
      property,
      purchaseEmail,
      purchaseId,
    } = eventData;

    return {
      dataLayer: {
        sfl: {
          data: {
            affiliateId,
            board,
            bookingId,
            device,
            generatedWallet,
            ip,
            language,
            market,
            paymentMethod,
            price,
            property,
            purchaseEmail,
          },
          appStateItems: [
            'adults',
            'checkIn',
            'checkOut',
            'children',
            'country',
            'currency',
            'purchaseEmail',
            'slug',
          ],
        },
      },
      kafka: {
        eventName: 'voucher',
        eventType: 'pageview',
        eventData: {
          affiliateId,
          board,
          bookingId,
          device,
          generatedWallet,
          ip,
          language,
          market,
          paymentMethod,
          price,
          property,
          purchaseEmail,
          purchaseId,
        },
        appStateItems: ['isLoggedIn', 'userId'],
      },
    };
  },
  userProfile: eventData => ({
    kafka: {
      eventName: 'user_profile',
      eventType: 'pageview',
      eventData,
      appStateItems: ['userId'],
    },
  }),
  myBookings: eventData => ({
    kafka: {
      eventName: 'my_bookings',
      eventType: 'pageview',
      eventData,
      appStateItems: ['userId'],
    },
  }),
  hotelsSeen: eventData => ({
    kafka: {
      eventName: 'hotels_seen',
      eventType: 'pageview',
      eventData,
      appStateItems: ['userId'],
    },
  }),
  myStaywallet: eventData => ({
    kafka: {
      eventName: 'my_staywallet',
      eventType: 'pageview',
      eventData,
      appStateItems: ['userId'],
    },
  }),
  myDetails: eventData => ({
    kafka: {
      eventName: 'my_details',
      eventType: 'pageview',
      eventData,
      appStateItems: ['userId'],
    },
  }),
  myCommunicationPreferences: eventData => ({
    kafka: {
      eventName: 'my_communication_prefences',
      eventType: 'pageview',
      eventData,
      appStateItems: ['userId'],
    },
  }),
  activateAccount: eventData => ({
    kafka: {
      eventName: 'activate_account',
      eventType: 'pageview',
      eventData,
      appStateItems: ['userId'],
    },
  }),
  changePassword: eventData => ({
    kafka: {
      eventName: 'change_password',
      eventType: 'pageview',
      eventData,
      appStateItems: ['userId'],
    },
  }),
  resetPassword: eventData => ({
    kafka: {
      eventName: 'reset_password',
      eventType: 'pageview',
      eventData,
      appStateItems: ['userId'],
    },
  }),
  primeLanding: eventData => ({
    kafka: {
      eventName: 'prime_landing',
      eventType: 'pageview',
      eventData,
      appStateItems: ['userId'],
    },
  }),
  primeThanks: eventData => ({
    kafka: {
      eventName: 'prime_thanks',
      eventType: 'pageview',
      eventData,
      appStateItems: ['userId'],
    },
  }),
  primeCheckout: eventData => ({
    kafka: {
      eventName: 'prime_checkout',
      eventType: 'pageview',
      eventData,
      appStateItems: ['userId'],
    },
  }),
  prime: eventData => ({
    kafka: {
      eventName: 'prime',
      eventType: 'pageview',
      eventData,
      appStateItems: ['userId'],
    },
  }),
  primeReceipts: eventData => ({
    kafka: {
      eventName: 'prime_receipts',
      eventType: 'pageview',
      eventData,
      appStateItems: ['userId'],
    },
  }),
  primeCancel: eventData => ({
    kafka: {
      eventName: 'prime_cancel',
      eventType: 'pageview',
      eventData,
      appStateItems: ['userId'],
    },
  }),
  primeCancelled: eventData => ({
    kafka: {
      eventName: 'prime_cancelled',
      eventType: 'pageview',
      eventData,
      appStateItems: ['userId'],
    },
  }),
  invoice: eventData => ({
    kafka: {
      eventName: 'invoice',
      eventType: 'pageview',
      eventData,
      appStateItems: ['userId'],
    },
  }),
  reviews: eventData => ({
    kafka: {
      eventName: 'reviews',
      eventType: 'pageview',
      eventData,
      appStateItems: ['isLoggedIn', 'userId'],
    },
  }),
  privacy: eventData => ({
    kafka: {
      eventName: 'privacy',
      eventType: 'pageview',
      eventData,
      appStateItems: ['isLoggedIn', 'userId'],
    },
  }),
  generalConditions: eventData => ({
    kafka: {
      eventName: 'generalConditions',
      eventType: 'pageview',
      eventData,
      appStateItems: ['isLoggedIn', 'userId'],
    },
  }),
  cookies: eventData => ({
    kafka: {
      eventName: 'cookies',
      eventType: 'pageview',
      eventData,
      appStateItems: ['isLoggedIn', 'userId'],
    },
  }),
};

export const loadProperties = () => ({
  dataLayer: {
    sfl: {
      appStateItems: [
        'adults',
        'checkIn',
        'checkOut',
        'children',
        'city',
        'country',
        'currency',
        'emailLogin',
        'language',
        'market',
        'nights',
        'pageId',
        'properties',
        'slug',
        'searchTerm',
      ],
    },
  },
});

export const loadPropertyWithPrice = () => ({
  dataLayer: {
    sfl: {
      appStateItems: [
        'adults',
        'bestPriceData',
        'checkIn',
        'checkOut',
        'children',
        'city',
        'country',
        'currency',
        'emailLogin',
        'language',
        'market',
        'nights',
        'property',
        'searchTerm',
        'slug',
      ],
    },
    ecommerce: {
      appStateItems: ['adults', 'bestPriceData', 'currency', 'property'],
    },
  },
});

export const loadEmailLogin = () => ({
  dataLayer: {
    sfl: {
      appStateItems: [
        'adults',
        'checkIn',
        'checkOut',
        'children',
        'city',
        'country',
        'currency',
        'device',
        'emailLogin',
        'language',
        'market',
        'nights',
        'slug',
      ],
    },
  },
});

export const loadHotelAvailabilityWithBookingRules = ({ boards, bestPriceBoard, dateFeed }) => ({
  kafka: {
    eventName: 'hotel_availability',
    eventType: 'impression',
    eventData: {
      roomsAvailability: getKafkaRoomsAvailability(boards),
      bestPrice: {
        board_index: bestPriceBoard,
      },
      date_feed: dateFeed,
    },
    appStateItems: [
      'adults',
      'children',
      'checkIn',
      'checkOut',
      'nights',
      'occupancies',
      'rooms',
      'property',
      'userId',
      'catalogue',
    ],
  },
});

export const clickTAReviews = ({ propertyId }) => ({
  kafka: {
    eventName: 'click',
    eventType: 'click',
    appStateItems: [
      'adults',
      'children',
      'rooms',
      'checkIn',
      'checkOut',
      'nights',
      'occupancies',
      'userId',
    ],
    eventData: {
      page: 'hotel_details',
      section: 'tripadvisor_reviews',
      propertyId,
    },
  },
});

export const clickAvailabilityRoom = ({ board, availabilityCount, propertyId }) => ({
  kafka: {
    eventName: 'click',
    eventType: 'click',
    eventData: {
      page: 'hotel_details',
      section: 'room_selected',
      propertyId,
      position: board.position || board.boardIndex,
      rateClass: board.paymentPolicy,
      totalRates: availabilityCount,
      roomImages: board.photos.length,
    },
    appStateItems: ['userId'],
  },
});

export const clickCountrySelector = ({ page }) => ({
  kafka: {
    eventName: 'click',
    eventType: 'click',
    appStateItems: ['clickin', 'visited', 'userId'],
    eventData: {
      page,
      section: 'dropdown_market',
    },
  },
});

export const clickMarket = destinationMarket => ({
  kafka: {
    eventName: 'click',
    eventType: 'click',
    appStateItems: ['clickin', 'visited', 'userId'],
    eventData: {
      page: 'homepage',
      section: 'market',
      clickDetails: {
        destination_market: destinationMarket,
      },
    },
  },
});

export const loadSearchResults = searchResults => ({
  kafka: {
    eventName: 'search_results',
    eventType: 'impression',
    eventData: {
      searchResults,
    },
    appStateItems: [
      'adults',
      'checkIn',
      'checkOut',
      'children',
      'filters',
      'nights',
      'rooms',
      'search',
      'userId',
    ],
  },
});

export const loadSearchResultsV2 = ({
  searchResults,
  adults,
  checkIn,
  checkOut,
  children,
  filters,
  nights,
  rooms,
  search,
  userId,
}) => ({
  kafka: {
    eventName: 'search_results',
    eventType: 'impression',
    eventData: {
      searchResults,
      adults,
      checkIn,
      checkOut,
      children,
      filters,
      nights,
      rooms,
      search,
      userId,
    },
  },
});

export const loadMapSearchResults = searchResults => ({
  kafka: {
    eventName: 'map_search_results',
    eventType: 'impression',
    eventData: {
      searchResults,
    },
    appStateItems: [
      'adults',
      'checkIn',
      'checkOut',
      'children',
      'filters',
      'mapSearch',
      'nights',
      'rooms',
      'userId',
    ],
  },
});

export const clickLogoHeader = () => ({
  kafka: {
    eventName: 'click',
    eventType: 'click',
    eventData: {
      page: 'homepage',
      section: 'logo_header',
    },
    appStateItems: ['clickin', 'visited', 'userId'],
  },
});

export const clickFooterLink = id => ({
  kafka: {
    eventName: 'click',
    eventType: 'click',
    eventData: {
      page: 'homepage',
      section: 'footer',
      clickDetails: {
        section: id,
      },
    },
    appStateItems: ['clickin', 'visited', 'userId'],
  },
});

export const clickAuthentication = () => ({
  kafka: {
    eventName: 'click',
    eventType: 'click',
    eventData: {
      page: 'homepage',
      section: 'login',
    },
    appStateItems: ['clickin', 'visited', 'userId'],
  },
});

export const clickProperty = (source, { id, position }) => {
  const { page, section } = getTrackingFromSource(source);

  return {
    kafka: {
      eventName: 'click',
      eventType: 'click',
      eventData: {
        page,
        section,
        clickDetails: {
          id_property: id,
          position,
        },
      },
      appStateItems: ['adults', 'children', 'childrenAges', 'search', 'userId'],
    },
  };
};

export const clickSubmitCustomerCheckout = () => ({
  kafka: {
    eventName: 'click',
    eventType: 'click',
    appStateItems: ['clickin', 'userId'],
    eventData: {
      page: 'customer_checkout',
      section: 'proceed_to_last_step',
    },
  },
});

export const paymentConfirmation = ({ errorCode, redirect3DS }) => ({
  kafka: {
    eventName: 'payment_confirmation',
    eventType: 'impression',
    eventData: {
      errorCode,
      redirect3DS,
    },
    appStateItems: ['userId'],
  },
});

export const aliasCreation = ({ success }) => ({
  kafka: {
    eventName: 'alias_creation',
    eventType: 'impression',
    eventData: {
      success,
    },
    appStateItems: ['userId'],
  },
});

export const clickZoomCarouselDesktop = () => ({
  kafka: {
    eventName: 'photos_zoom',
    eventType: 'click',
    appStateItems: ['checkin', 'checkout', 'clickin', 'nights', 'occupancies', 'rooms', 'userId'],
  },
});

export const showModalSubscription = ({ action, source, url }) => ({
  kafka: {
    eventName: 'subscription_pop_up_show',
    eventType: 'impression',
    eventData: {
      action,
      source,
      url,
    },
    appStateItems: ['userId'],
  },
});

export const closeModalSubscription = ({ action, source, url }) => ({
  kafka: {
    eventName: 'subscription_pop_up_close',
    eventType: 'click',
    eventData: {
      action,
      source,
      url,
    },
    appStateItems: ['userId'],
  },
});

export const subscriptionGdpr = ({ action, email, source, url }) => ({
  kafka: {
    eventName: 'subscription_gdpr',
    eventType: 'click',
    eventData: {
      action,
      email,
      source,
      url,
    },
    appStateItems: ['userId'],
  },
});

export const sendExperimentView = ({ experiment, pageId }) => {
  const { id, key, variant } = experiment;
  return {
    dataLayer: {
      experiment: {
        expId: id,
        expVar: variant,
      },
    },
    kafka: {
      eventName: 'experiment',
      eventType: 'experiment',
      eventData: {
        experiments: [{ experiment_id: key, experiment_variant: variant }],
        page: pageId,
      },
      appStateItems: ['isLoggedIn'],
    },
  };
};

export const clickPurchaseReminderLink = ({ propertyId }) => ({
  kafka: {
    eventName: 'purchase_reminder',
    eventType: 'click',
    eventData: {
      propertyId,
    },
    appStateItems: ['userId'],
  },
});

export const clickPrimeLandingCTA = () => ({
  kafka: {
    eventName: 'prime_landing_cta',
    eventType: 'click',
    appStateItems: ['userId'],
  },
});

export const successPrimePayment = () => ({
  kafka: {
    eventName: 'prime_success_payment',
    eventType: 'impression',
    appStateItems: ['userId'],
  },
});

export const clickPrimeThanksCTA = () => ({
  kafka: {
    eventName: 'prime_thanks_cta',
    eventType: 'click',
    appStateItems: ['userId'],
  },
});

export const clickPrimeBanner = () => ({
  kafka: {
    eventName: 'prime_banner',
    eventType: 'click',
    appStateItems: ['userId'],
  },
});

export const impressionPrimeBanner = () => ({
  kafka: {
    eventName: 'prime_banner',
    eventType: 'impression',
    appStateItems: ['userId'],
  },
});

export const showPurchaseReminder = ({ propertyId }) => ({
  kafka: {
    eventName: 'purchase_reminder',
    eventType: 'impression',
    eventData: {
      propertyId,
    },
    appStateItems: ['userId'],
  },
});

export const checkoutError = ({ error }) => {
  // This assumes an error we are interested mainly in axios errors that is why
  // we are checking for the particular object shape
  const errorMessage = error?.response?.data?.error || null;
  const errorCode = error?.response?.data?.code || null;
  const httpCode = error?.response?.status || null;
  return {
    kafka: {
      eventName: 'checkout_error',
      eventType: 'error',
      eventData: {
        errorMessage,
        errorCode,
        httpCode,
      },
      appStateItems: ['pageId', 'device', 'userId', 'market'],
    },
  };
};

export const checkoutErrorFrom200 = ({ response }) => {
  // This assumes an error we are interested mainly in axios errors that is why
  // we are checking for the particular object shape
  const errorMessage = response?.error || null;
  const errorCode = response?.code || null;
  const httpCode = 200;
  return {
    kafka: {
      eventName: 'checkout_error',
      eventType: 'error',
      eventData: {
        errorMessage,
        errorCode,
        httpCode,
      },
      appStateItems: ['pageId', 'device', 'userId', 'market'],
    },
  };
};

export const checkoutIntent = ({ reason, context, status, purchaseId, details }) => {
  return {
    kafka: {
      eventName: 'purchase_attempt',
      eventType: 'impression',
      eventData: {
        reason,
        context,
        status,
        purchaseId,
        details,
      },
      appStateItems: [
        'adults',
        'affiliateId',
        'checkIn',
        'checkOut',
        'children',
        'device',
        'isLoggedIn',
        'market',
        'pageId',
        'property',
        'userId',
        'rooms',
      ],
    },
  };
};

export const pageviewIterableHotelPage = () => ({
  emt: {
    eventName: 'page_view_hotel',
    eventType: 'pageview',
    appStateItems: [
      'adults',
      'affiliateId',
      'alertMessage',
      'bestPriceData',
      'checkIn',
      'checkOut',
      'children',
      'childrenAges',
      'city',
      'country',
      'currency',
      'emailLogin',
      'isLoggedIn',
      'language',
      'market',
      'property',
      'purchaseEmail',
      'slug',
    ],
  },
});

export const pageviewIterableResultsPage = eventData => ({
  emt: {
    eventName: 'page_view_destination',
    eventType: 'pageview',
    eventData,
    appStateItems: [
      'adults',
      'affiliateId',
      'checkIn',
      'checkOut',
      'children',
      'childrenAges',
      'city',
      'citySlug',
      'country',
      'currency',
      'emailLogin',
      'isLoggedIn',
      'language',
      'market',
      'purchaseEmail',
      'slug',
    ],
  },
});

export const kafkaDirectEvent = ({ eventName, ...eventData }) => ({
  kafkaDirect: {
    eventName,
    eventType: null,
    eventData,
    appStateItems: [
      'adults',
      'affiliateData',
      'affiliateId',
      'boards',
      'checkIn',
      'checkOut',
      'children',
      'childrenAges',
      'city',
      'citySlug',
      'country',
      'currency',
      'emailLogin',
      'isLoggedIn',
      'language',
      'market',
      'purchaseEmail',
      'slug',
      'voucher',
    ],
  },
});
