/* eslint-disable no-nested-ternary */
import React, { useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { useIntl } from 'react-intl';
import { useClickAway, useMedia } from 'react-use';

import Loader from 'src/comps/Atom/Loader';
import Modal from 'client/components/Modal';

import messages from 'src/comps/LoginForm/messages';
import { breakpoint } from 'client/styles';
import { PageContext } from 'src/comps/Page/context';

const LoginForm = dynamic(
  () => import(/* webpackChunkName: "login-form" */ 'src/comps/LoginForm'),
  { loading: Loader }
);

const SidebarMenu = dynamic(
  () => import(/* webpackChunkName: "sidebar-menu" */ 'src/comps/SidebarMenu'),
  { loading: Loader }
);

const LoginModal = ({ handleClose, handleLogOut, isLoggedIn, isOpen }) => {
  const intl = useIntl();
  const { device } = useContext(PageContext);

  const isWide = useMedia(breakpoint.tablet, device !== 'mobile');

  const title = intl.formatMessage(messages[isLoggedIn ? 'myAccount' : 'login']);
  const variant = isWide ? 'default' : 'fullscreen';

  const container = useRef();
  useClickAway(
    container,
    event => {
      event.preventDefault();
      handleClose();
    },
    ['click']
  );

  if (isWide && isLoggedIn && isOpen) {
    return (
      <div ref={container}>
        <SidebarMenu handleLogOut={handleLogOut} onClickOutside={handleClose} withLogOut />
      </div>
    );
  }

  return (
    <>
      {isLoggedIn ? (
        <Modal
          displayHeader={false}
          displayHeaderPage
          displayCloseButton={false}
          handleClose={handleClose}
          show={isOpen}
          title={title}
          variant={variant}
        >
          <SidebarMenu handleLogOut={handleLogOut} onClickOutside={handleClose} withLogOut />
        </Modal>
      ) : (
        <Modal handleClose={handleClose} show={isOpen} title={title} variant={variant}>
          <LoginForm handleClose={handleClose} />
        </Modal>
      )}
    </>
  );
};

LoginModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleLogOut: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default LoginModal;
