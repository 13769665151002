import { CENTER_RADIUS } from 'src/comps/Property/constants';
import { z } from 'zod';
import { sharedPropertySchema } from './shared-property';

export const facilitySearchSchema = z.object({
  id: z.string(),
});

export const coordinatesSchema = z.object({
  lat: z.number(),
  lon: z.number(),
});

export const propertyLocationSchema = z.object({
  countryCode: z.string(),
  coordinates: coordinatesSchema,
});

export const propertyLocationsSchema = z.array(
  z.object({
    type: z.string(),
    idLocation: z.string(),
    name: z.string(),
    nameSlug: z.string(),
    coordinates: coordinatesSchema,
  })
);

export const summarySchema = z
  .object({
    subtotal: z.object({
      amount: z.number(),
      currency: z.string(),
    }),
    subtotalNightly: z
      .object({
        amount: z.number(),
        currency: z.string(),
      })
      .nullish(),
    taxes: z
      .object({
        amount: z.number(),
        currency: z.string(),
      })
      .nullish(),
    total: z.object({
      amount: z.number(),
      currency: z.string(),
    }),
    additionalFees: z
      .object({
        amount: z.number(),
        currency: z.string(),
        local: z
          .object({
            amount: z.number(),
            currency: z.string(),
          })
          .nullish(),
      })
      .nullish(),
    totalWithFees: z
      .object({
        amount: z.number(),
        currency: z.string(),
      })
      .nullish(),
    totalNightlyWithFees: z
      .object({
        amount: z.number(),
        currency: z.string(),
      })
      .nullish(),
  })
  .nullish();

export const roomSchema = z.object({
  avgRate: z.number(),
  sellingRate: z.number(),
  bigheadRate: z.number(),
  boardName: z.string(),
  boardCode: z.string(),
  roomName: z.string(),
  roomCode: z.string(),
  isFreeCancellation: z.boolean(),
  isPrime: z.boolean(),
  rateDiscounts: z.object({ prime: z.boolean(), mobile: z.boolean() }),
  subtotal: z.number().nullish(),
  catalogue: z.string().nullish(),
  providerName: z.string().nullish(),
  rateClass: z.string().nullish(),
  summary: summarySchema,
});

export const embeddableProperty = sharedPropertySchema
  .extend({
    avgRate: z.number(),
    bigheadRate: z.number(),
    comments: z.number(),
    coordinates: coordinatesSchema.nullish(),
    currency: z.string(),
    description: z.string().nullish(),
    distance: z.object({ value: z.number().nullish(), unit: z.string() }),
    facilities: z.array(facilitySearchSchema),
    facilitiesRoom: z.array(z.object({})).nullish(),
    id: z.number(),
    locations: propertyLocationsSchema,
    propertyLocation: propertyLocationSchema.nullish(),
    rating: z.number(),
    rooms: z.array(roomSchema),
    sellingRate: z.number(),
    nights: z.number(),
  })
  .transform(property => {
    const {
      avgRate,
      bigheadRate,
      images,
      locations,
      propertyLocation,
      rating,
      rooms,
      sellingRate,
      ...rest
    } = property;

    const { unit: distanceUnit = 'm', value: distanceValue = null } = property.distance;
    const distanceInKms =
      distanceUnit === 'm' && distanceValue ? distanceValue / 1000 : distanceValue;
    const distance = distanceValue !== null ? distanceInKms : null;

    const { rateDiscounts, isFreeCancellation = false } = rooms[0];
    const { mobile: isMobile = false, prime: isPrime = false } = rateDiscounts;

    const neighborhood = locations?.find(location => location.type === 'neighborhood');
    const city = locations?.find(location => location.type === 'city');

    const facilities = property.facilities.map(({ id }) => ({
      name: id,
      icon: id,
    }));

    const isInCenter = distance !== null ? CENTER_RADIUS > distance : false;

    const country = propertyLocation?.countryCode;
    const latitude = propertyLocation?.coordinates?.lat;
    const longitude = propertyLocation?.coordinates.lon;

    let priceWithFees = 0;
    let pricePerNightWithFees = 0;
    let subtotal = 0;
    let pricePerNightSubtotal = 0;

    rooms?.forEach(room => {
      const summary = room?.summary;
      priceWithFees += summary?.totalWithFees?.amount ?? 0;
      pricePerNightWithFees += summary?.totalNightlyWithFees?.amount ?? 0;
      subtotal += summary?.subtotal?.amount ?? 0;
      pricePerNightSubtotal += summary?.subtotalNightly?.amount ?? 0;
    });

    return {
      ...rest,
      cancellation: isFreeCancellation,
      city: city?.name,
      citySlug: city?.nameSlug,
      cityUrl: '',
      country,
      description: '',
      distance,
      facilities,
      facilitiesRoom: [],
      isInCenter,
      isMobile,
      isPrime,
      isOutsideCity: property.isOutsideCity,
      latitude,
      longitude,
      neighborhood: neighborhood?.name || null,
      photo: images[0] || '',
      photos: images,
      postalCode: property.postalCode || null,
      price: sellingRate,
      priceOld: bigheadRate,
      pricePerNight: avgRate,
      priceWithFees: priceWithFees || sellingRate,
      pricePerNightWithFees: pricePerNightWithFees || avgRate || null,
      pricePerNightSubtotal: pricePerNightSubtotal || avgRate || null,
      ratings: null,
      rooms,
      score: rating,
      searchTerm: null,
      subtotal: subtotal || null,
      url: null,
    };
  });
