import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import { DeviceType, BreakpointSizes, Devices } from 'src/types/device';

export const widthToBreakpoint = (width: number): BreakpointSizes => {
  if (width < BreakpointSizes.S) {
    return BreakpointSizes.XS;
  }
  if (width < BreakpointSizes.M) {
    return BreakpointSizes.S;
  }
  if (width < BreakpointSizes.L) {
    return BreakpointSizes.M;
  }

  return BreakpointSizes.L;
};

export const deviceToBreakpoint = (device: Devices): BreakpointSizes => {
  if (device === 'mobile') return BreakpointSizes.S;
  if (device === 'tablet') return BreakpointSizes.M;
  return BreakpointSizes.L;
};

export const getDeviceContextValue = (config: unknown) => {
  const contextValue = pick(config, ['device', 'ip', 'metadevice', 'isBot']) as DeviceType;

  if (isEmpty(contextValue)) {
    return null;
  }

  if (typeof window !== 'undefined') {
    contextValue.viewport = {
      width: window.innerWidth,
      height: window.innerHeight,
    };
    contextValue.activeBreakpoint = widthToBreakpoint(window.innerWidth);
  } else {
    contextValue.activeBreakpoint = deviceToBreakpoint(contextValue.device);
  }

  return contextValue;
};

export const getTimezoneOffset = () => {
  return new Date().getTimezoneOffset();
};
