import React, { ReactNode } from 'react';

import ErrorPage from 'pages/_error';
import { ErrorType } from 'src/pages/withSession/errorType';
import ErrorBoundary from 'src/comps/ErrorBoundary';

type ErrorHandlerProps = {
  error?: ErrorType;
  translations?: Record<string, string>;
  config: any;
  children: ReactNode;
};

export const ErrorHandler = (props: ErrorHandlerProps) => {
  const { error, translations, config, children } = props;

  if (error === ErrorType.NotFound)
    return <ErrorPage config={config} statusCode={404} translations={translations} />;
  if (error === ErrorType.ServerError)
    return <ErrorPage config={config} statusCode={500} translations={translations} />;

  return (
    <ErrorBoundary
      fallback={<ErrorPage config={config} statusCode={500} translations={translations} />}
    >
      {children}
    </ErrorBoundary>
  );
};
