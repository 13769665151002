/* eslint-disable react/jsx-props-no-spreading, react/prop-types */
import React, { useEffect } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { GoogleOAuthProvider } from '@react-oauth/google';
import builder from '@builder.io/react';

// Polyfills
// In theory flat() is polyfilled https://github.com/vercel/next.js/blob/canary/packages/next-polyfill-nomodule/src/index.js
// but Edge gives error "Object doesn't support property or method 'flat'"
// https://github.com/vercel/next.js/pull/13766
import 'array-flat-polyfill';
import 'intersection-observer';
import 'client/polyfills/lastIndexOf';
import 'client/polyfills/scrollEnd';

// Global styles
import 'styles/global.css';

import theme from 'src/styles/themes/lightTheme';

import useMockServer from 'src/hooks/useMockServer';
import useStore from 'src/hooks/useStore';
import GoogleTagManager from 'src/comps/GoogleTagManager';
import Datadog from 'src/comps/Datadog';

import { ErrorHandler } from 'src/pages/_app/ErrorHandler';
import { DeviceProvider } from 'src/context/device';
import { MarketProvider } from 'src/context/market';
import { SystemProvider } from 'src/context/system';
import { BusinessProvider } from 'src/context/business/';
import { SessionProvider } from 'src/context/session';
import { BusProvider } from 'src/context/bus';
import { ExperimentProvider } from 'src/context/experiment';
import { getDeviceContextValue } from 'src/context/device/helpers';
import { getMarketContextValue } from 'src/context/market/helpers';
import { getBusinessContextValue } from 'src/context/business/helpers';
import { getSessionContextValue } from 'src/context/session/helpers';
import { getSystemContextValue } from 'src/context/system/helpers';
import { getExperimentContextValue } from 'src/context/experiment/helpers';

import { withLayouts } from '../src/pages/_app/withLayouts';
import { IntlCustomProvider } from 'src/context/intl';
import { reportPageView } from 'src/utils/clientErrorReporter';
import { ErrorType } from 'src/pages/withSession/errorType';

// Avoid scroll restoration
if (typeof window !== 'undefined' && 'scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual';
}

export const usePageViewName = pathName => {
  useEffect(() => {
    reportPageView(pathName);
  }, [pathName]);
};

const { NEXT_PUBLIC_BUILDER_API_KEY } = process.env;
builder.init(NEXT_PUBLIC_BUILDER_API_KEY);

const App = ({ Component, pageProps }) => {
  const { ready } = useMockServer();
  const { config, translations, error } = pageProps;
  const locale = config?.locale || 'en';
  const { customer: customerData, isLogged, ...restConfig } = config;

  const isError =
    pageProps.error === ErrorType.NotFound || pageProps.error === ErrorType.ServerError;
  usePageViewName(isError ? '_error' : Component.pagePathView);

  const initialState = {
    login: {
      ...customerData,
    },
    config: {
      ...restConfig,
      pageId: Component.pageId,
    },
    ...(pageProps.property && {
      property: {
        property: pageProps.property,
      },
    }),
  };

  const { store } = useStore({ initialState });

  if (!ready || !store) return <></>;

  const marketValue = getMarketContextValue(config);
  const systemContextValue = getSystemContextValue(config);
  const businessValue = getBusinessContextValue(config);
  const sessionData = getSessionContextValue(config);
  const deviceContextValue = getDeviceContextValue(config);
  const experimentValue = getExperimentContextValue(config);

  return (
    <BusProvider>
      <ReduxProvider store={store}>
        <MarketProvider value={marketValue}>
          <SystemProvider value={systemContextValue}>
            <BusinessProvider value={businessValue}>
              <SessionProvider value={sessionData}>
                <DeviceProvider value={deviceContextValue}>
                  <ExperimentProvider value={experimentValue}>
                    <ThemeProvider theme={theme}>
                      <IntlCustomProvider key={locale} locale={locale} messages={translations}>
                        <GoogleOAuthProvider
                          clientId={process.env.NEXT_PUBLIC_GOOGLE_LOGIN_CLIENT_ID}
                        >
                          <GoogleTagManager />
                          <Datadog />
                          <ErrorHandler {...pageProps}>
                            {!error && <Component {...pageProps} />}
                          </ErrorHandler>
                        </GoogleOAuthProvider>
                      </IntlCustomProvider>
                    </ThemeProvider>
                  </ExperimentProvider>
                </DeviceProvider>
              </SessionProvider>
            </BusinessProvider>
          </SystemProvider>
        </MarketProvider>
      </ReduxProvider>
    </BusProvider>
  );
};

export default withLayouts(App);
