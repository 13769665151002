import styled from 'styled-components';
import IconCountry from 'client/icons/Country';
import { PATH_IMAGES_CDN } from 'src/constants/environment';

export const FlagBase = styled.span`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 1 0 auto;
  outline: none;
  text-decoration: none;
  padding: 5px 0;
`;

export const Name = styled.span`
  color: ${props => props.theme.colors.grey1};
  flex: 1 1 auto;
  line-height: 1.5;
  margin-left: ${props => (props.isUserNav ? '1rem' : '0.75rem')};
  font-weight: ${props => (props.isUserNav ? '700' : '400')};
  text-align: left;
`;

export const Item = styled.i`
  background-image: url(${PATH_IMAGES_CDN}/240604_flags.png);
  background-size: 120px 144px; /* Scale it down to 50% rather using 240x240 */
  background-color: transparent;
  background-repeat: no-repeat;
  flex: 0 0 auto;
  height: 24px;
  width: 24px;

  /* 1th row in flag sprite */
  &.flag-be {
    background-position: 0px 0px;
  }
  &.flag-dk {
    background-position: -24px 0px;
  }
  &.flag-de {
    background-position: -48px 0px;
  }
  &.flag-es {
    background-position: -72px 0px;
  }
  &.flag-gr {
    background-position: -96px 0px;
  }

  /* 2th row in flag sprite */
  &.flag-fr {
    background-position: 0px -24px;
  }
  &.flag-ie {
    background-position: -24px -24px;
  }
  &.flag-it {
    background-position: -48px -24px;
  }
  &.flag-nl {
    background-position: -72px -24px;
  }
  &.flag-no {
    background-position: -96px -24px;
  }

  /* 3th row in flag sprite */
  &.flag-at {
    background-position: 0px -48px;
  }
  &.flag-ru {
    background-position: -24px -48px;
  }
  &.flag-pl {
    background-position: -48px -48px;
  }
  &.flag-pt {
    background-position: -72px -48px;
  }
  &.flag-ro {
    background-position: -96px -48px;
  }

  /* 4th row in flag sprite */
  &.flag-ch {
    background-position: 0px -72px;
  }
  &.flag-sk {
    background-position: -24px -72px;
  }
  &.flag-za {
    background-position: -48px -72px;
  }
  &.flag-fi {
    background-position: -72px -72px;
  }
  &.flag-se {
    background-position: -96px -72px;
  }

  /* 5th row in flag sprite */
  &.flag-tr {
    background-position: 0px -96px;
  }
  &.flag-gb {
    background-position: -24px -96px;
  }
  &.flag-us {
    background-position: -48px -96px;
  }
  &.flag-ca {
    background-position: -72px -96px;
  }
  &.flag-au {
    background-position: -96px -96px;
  }

  /* 6th row in flag sprite */
  &.flag-br {
    background-position: 0px -120px;
  }
  &.flag-mx {
    background-position: -24px -120px;
  }
`;

export const IconPlaceholder = styled(IconCountry)`
  fill: ${props => props.theme.colors.grey1};
  margin-right: 0.5em;
`;
