import React, { useState, useEffect } from 'react';
import CookieClient from 'js-cookie';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';

import { routes } from 'src/constants/routes';
import { useInterval } from 'src/hooks/useInterval';
import { sendTrackingRequestAction } from 'client/contexts/Tracking/actions';
import { clickPurchaseReminderLink, showPurchaseReminder } from 'client/contexts/Tracking/events';
import { getAlert } from 'src/comps/AlertMessage/selectors';

import Notification from 'src/comps/Notifications/Notification';

import PurchaseReminder from '.';

import {
  COOKIE_INTERVAL_CHECK,
  PURCHASE_REMINDER_COOKIE,
  PURCHASE_REMINDER_PAGES_TO_DISPLAY,
} from './constants';
import { buildPathParams } from 'lib/utils/url';

const PurchaseReminderContainer = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { messageId } = useSelector(getAlert);
  const [purchase, setPurchase] = useState({});

  const isBookingError = messageId === 'warningAlreadyBooked' || messageId === 'errorAvailability';

  function shouldDisplay() {
    if (PURCHASE_REMINDER_PAGES_TO_DISPLAY.includes(router.pathname)) {
      const currentSlug = router.query?.slug || '';
      return !(
        routes.detail.pathname === router.pathname &&
        currentSlug === `${purchase.slug}_${purchase.city}`
      );
    }

    return false;
  }

  function onClick() {
    dispatch(
      sendTrackingRequestAction({
        event: clickPurchaseReminderLink({
          propertyId: purchase.propertyId,
        }),
      })
    );

    router.push({
      pathname: buildPathParams(routes.detail.pathname, {
        country: purchase.country,
        slug: `${purchase.slug}_${purchase.city}`,
      }),
      query: {
        checkin: purchase.checkIn,
        checkout: purchase.checkOut,
      },
    });
  }

  function onClose() {
    CookieClient.remove(PURCHASE_REMINDER_COOKIE);
    setPurchase({});
  }

  function checkCookie() {
    if (!shouldDisplay()) {
      return;
    }

    const cookie = CookieClient.get(PURCHASE_REMINDER_COOKIE);

    if (cookie && isBookingError) {
      CookieClient.remove(PURCHASE_REMINDER_COOKIE);
      setPurchase({});
      return;
    }

    if (cookie) {
      setPurchase(JSON.parse(cookie));
      return;
    }

    setPurchase({});
  }

  useEffect(() => {
    if (purchase.id) {
      dispatch(
        sendTrackingRequestAction({
          event: showPurchaseReminder({
            propertyId: purchase.propertyId,
          }),
        })
      );
    }
  }, [purchase.id]); // eslint-disable-line react-hooks/exhaustive-deps

  useInterval(checkCookie, COOKIE_INTERVAL_CHECK);

  if (Object.keys(purchase).length === 0 || !shouldDisplay() || isBookingError) {
    return null;
  }

  return (
    <Notification onClose={onClose}>
      <PurchaseReminder {...purchase} onClick={onClick} />
    </Notification>
  );
};

export default PurchaseReminderContainer;
