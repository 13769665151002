import {
  mapDatesParams,
  mapFilterParams,
  mapLocationParams,
  mapMarketParams,
  mapPaginationParams,
  mapPaxesParams,
  mapSortByParams,
} from 'src/effects/Search/mappers';
import { mapDataToProperty } from 'client/contexts/Property/mappers';

export function buildSuggestedPropertiesPayload({
  excludedIDs,
  distance,
  reservationParams,
  filtersParams,
  offset,
  device,
  affiliate,
  source,
}) {
  const params = { ...reservationParams, ...filtersParams, offset };

  return {
    affiliate,
    source,
    excludedIDs,
    distance,
    device,
    ...mapDatesParams(params),
    ...mapLocationParams(params),
    ...mapMarketParams(params),
    ...mapPaxesParams(params),
    ...mapFilterParams(params),
    ...mapSortByParams(params),
    ...mapPaginationParams(params),
  };
}

export function mapProperty(data) {
  return mapDataToProperty({ ...data.property, new_path: data.new_path });
}

export function mapProperties(data) {
  return data.properties.map(property => mapDataToProperty(property));
}
