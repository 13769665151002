import {
  FETCH_MAP_PROPERTIES_REQUEST,
  FETCH_MAP_PROPERTIES_SUCCESS,
  FETCH_MAP_PROPERTIES_FAILURE,
  RESET_MAP,
} from './constants';

const initialState = {
  properties: [],
  isFetchingProperties: true,
  error: null,
  offset: 30,
};

const mapReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MAP_PROPERTIES_REQUEST:
      return {
        ...state,
        isFetchingProperties: true,
      };

    case FETCH_MAP_PROPERTIES_SUCCESS:
      return {
        ...state,
        error: null,
        isFetchingProperties: false,
        properties: action.payload,
      };

    case FETCH_MAP_PROPERTIES_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isFetchingProperties: false,
      };

    case RESET_MAP:
      return {
        ...state,
        isFetchingProperties: true,
        properties: [],
      };

    default:
      return state;
  }
};

export default mapReducer;
