import { LOGIN_EVENT_NAME, LOGIN_STATE } from './constants';
import fixtureSubscribed from './fixtures/subscribed.json';
import fixtureNotSubscribed from './fixtures/notSubscribed.json';
import { mapUserData } from './mappers';
import { sendTrackingRequestAction } from '../Tracking/actions';

export const responseSubscribed = fixtureSubscribed;
export const responseNotSubscribed = fixtureNotSubscribed;

export const responseSubscribedMapped = mapUserData(fixtureSubscribed);
export const responseNotSubscribedMapped = mapUserData(fixtureNotSubscribed);

export const stateSubscribed = {
  [LOGIN_STATE]: {
    error: null,
    isFetching: false,
    isLoggedIn: true,
    status: 0,
    data: responseSubscribedMapped,
  },
};

export const stateLogged = {
  [LOGIN_STATE]: {
    error: null,
    isFetching: false,
    isLoggedIn: true,
    status: 0,
    data: responseNotSubscribedMapped,
  },
};

export const stateNoData = {
  [LOGIN_STATE]: {
    error: null,
    isFetching: false,
    isLoggedIn: true,
    status: 0,
    data: {},
  },
};

export const stateLoading = {
  [LOGIN_STATE]: {
    error: null,
    isFetching: true,
    isLoggedIn: false,
    status: 0,
    data: {},
  },
};

export const sendLoginEvent = () => sendTrackingRequestAction({ event: { dataLayer: { event: LOGIN_EVENT_NAME } } })
