export function getPageTarget(pageId) {
  if (pageId === 'map' || pageId === 'detail') {
    return 'list';
  }

  if (pageId === 'propertyMap') {
    return 'detail';
  }

  if (pageId === 'checkout' || pageId === 'payment') {
    return 'detail';
  }

  return 'home';
}
