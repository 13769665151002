
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';

import { Experiment } from 'src/types/experiment';

export const getExperimentContextValue = (config: Record<string, unknown> = {}) => {
  const contextValue = pick(config.experiment, [
    'id',
    'key',
    'variant'
  ]);

  if (isEmpty(contextValue)) {
    return {};
  }

  return contextValue as Experiment;
}
