import {
  PRIME_RECEIPTS_FAILURE,
  PRIME_RECEIPTS_REQUEST,
  PRIME_RECEIPTS_SUCCESS,
} from './constants';

export const fetchPrimeReceiptsRequestAction = () => ({
  type: PRIME_RECEIPTS_REQUEST,
});

export const fetchPrimeReceiptsSuccessAction = payload => ({
  type: PRIME_RECEIPTS_SUCCESS,
  payload,
});

export const fetchPrimeReceiptsFailureAction = payload => ({
  type: PRIME_RECEIPTS_FAILURE,
  payload,
});
