export const PROPERTIES_RECOMMENDED_STATE = 'propertiesRecommended';

export const FETCH_PROPERTIES_RECOMMENDED_REQUEST = 'FETCH_PROPERTIES_RECOMMENDED_REQUEST';
export const FETCH_PROPERTIES_RECOMMENDED_SUCCESS = 'FETCH_PROPERTIES_RECOMMENDED_SUCCESS';
export const FETCH_PROPERTIES_RECOMMENDED_FAILURE = 'FETCH_PROPERTIES_RECOMMENDED_FAILURE';

export const PROPERTIES_RECOMMENDED_IDS = [
  124626,
  114484,
  293110,
  61684,
  48026,
  31300,
  37888,
  51442,
];
