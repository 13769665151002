import { PAY_NOW_STATE, PAY_NOW_REQUEST, PAY_NOW_SUCCESS, PAY_NOW_FAILURE, PAY_NOW_SET_SECURECODE } from './constants';
import stateBase from './state';

const initialState = stateBase[PAY_NOW_STATE];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PAY_NOW_REQUEST:
      return {
        ...state,
        error: null,
        isFetching: true,
        paymentFailed: null,
      };

    case PAY_NOW_SUCCESS:
      return {
        ...state,
        error: null,
        isFetching: false,
        paymentFailed: false,
      };

    case PAY_NOW_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isFetching: false,
        paymentFailed: action.payload.paymentFailed,
      };

    case PAY_NOW_SET_SECURECODE:
      return {
        ...state,
        error: null,
        isFetching: false,
        secureCode: action.payload.secureCode,
      }

    default:
      return state;
  }
};

export default reducer;
