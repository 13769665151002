import { useContext } from "react"
import { MarketContext } from "src/context/market"

export const useMarket = () => {
    const market = useContext(MarketContext)

    if (!market) {
        throw new Error("Missing MarketContext provider")
    }

    return market;
}