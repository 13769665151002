import { PAY_NOW_STATE } from './constants';

const state = {
  [PAY_NOW_STATE]: {
    error: null,
    isFetching: false,
    paymentFailed: null,
    secureCode: null,
  },
};

export default state;
