import { USER_PROFILE_STATE } from './constants';

const state = {
  [USER_PROFILE_STATE]: {
    isFetching: false,
    error: null,
    bookingList: null,
  },
};

export default state;
