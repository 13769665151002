import {
  FETCH_PROPERTIES_VISITED_REQUEST,
  FETCH_PROPERTIES_VISITED_SUCCESS,
  FETCH_PROPERTIES_VISITED_FAILURE,
  REMOVE_PROPERTY,
} from './constants';

export const fetchPropertiesVisitedRequestAction = payload => ({
  type: FETCH_PROPERTIES_VISITED_REQUEST,
  payload,
});

export const fetchPropertiesVisitedSuccessAction = payload => ({
  type: FETCH_PROPERTIES_VISITED_SUCCESS,
  payload,
});

export const fetchPropertiesVisitedFailureAction = payload => ({
  type: FETCH_PROPERTIES_VISITED_FAILURE,
  payload,
});

export const removePropertyAction = payload => ({
  type: REMOVE_PROPERTY,
  payload,
});
