import {
  ENABLE_INSURANCE,
  FETCH_AVAILABILITY_REQUEST,
  FETCH_AVAILABILITY_SUCCESS,
  FETCH_AVAILABILITY_FAILURE,
  FETCH_BOOKING_RULES_REQUEST,
  FETCH_BOOKING_RULES_SUCCESS,
  FETCH_BOOKING_RULES_FAILURE,
} from './constants';

export const fetchAvailabilityRequestAction = payload => ({
  type: FETCH_AVAILABILITY_REQUEST,
  payload,
});

export const fetchAvailabilitySuccessAction = payload => ({
  type: FETCH_AVAILABILITY_SUCCESS,
  payload,
});

export const fetchAvailabilityFailureAction = payload => ({
  type: FETCH_AVAILABILITY_FAILURE,
  payload,
});

export const fetchBookingRulesRequestAction = payload => ({
  type: FETCH_BOOKING_RULES_REQUEST,
  payload,
});

export const fetchBookingRulesSuccessAction = payload => ({
  type: FETCH_BOOKING_RULES_SUCCESS,
  payload,
});

export const fetchBookingRulesFailureAction = payload => ({
  type: FETCH_BOOKING_RULES_FAILURE,
  payload,
});

export const enableInsuranceAction = payload => ({
  type: ENABLE_INSURANCE,
  payload,
});
