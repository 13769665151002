import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { addMonths } from 'date-fns';

import { getMonthsToRender } from 'client/utils';
import IconArrowDown from 'client/icons/ArrowDown';

import Calendar from './Calendar';

import { DatePicker, AddMonths } from './styles';

const MobileDates = ({
  startDate,
  endDate,
  dateFocused = 'checkin',
  onDatesSelected,
  market,
  setDateFocused,
  currentDate = new Date(),
  minBookingDate,
}) => {
  const [visibleMonths, setVisibleMonths] = useState(getMonthsToRender(startDate, currentDate));
  const [selectedDates, setSelectedDates] = useState({ startDate, endDate });
  const [checkInMonth, setCheckInMonth] = useState();

  function addMoreMonths() {
    const { date: lastVisibleMonthDate } = visibleMonths[visibleMonths.length - 1];
    const monthsToAdd = Array.from({ length: 3 }, (item, index) => ({
      date: addMonths(lastVisibleMonthDate, index + 1),
      isCheckinMonth: false,
    }));
    setVisibleMonths([...visibleMonths, ...monthsToAdd]);
  }

  function onSelect({ startDate: start, endDate: end, isCheckIn }) {
    const targetFocus = isCheckIn ? 'checkin' : 'checkout';
    setSelectedDates({ startDate: start, endDate: end });
    setDateFocused(targetFocus);
    onDatesSelected({ startDate: start, endDate: end }, targetFocus);
  }

  const setScrollableArea = useCallback(() => {
    if (checkInMonth && checkInMonth.scrollIntoView) {
      checkInMonth.scrollIntoView()
    }
  }, [checkInMonth]);

  function scrollToMonth(node) {
    setCheckInMonth(node);
  }

  return (
    <>
      <DatePicker ref={setScrollableArea}>
        {visibleMonths.map((month, index) => (
          <Calendar
            index={index}
            isCheckInMonth={month.isCheckinMonth}
            isFocusedCheckIn={dateFocused === 'checkin'}
            key={month.date}
            onSelect={onSelect}
            scrollToMonth={scrollToMonth}
            selectedDates={selectedDates}
            market={market}
            dataTestId="calendar-mobile"
            currentDate={currentDate}
            minBookingDate={minBookingDate}
          />
        ))}

        <AddMonths type="button" onClick={addMoreMonths}>
          <IconArrowDown />
        </AddMonths>
      </DatePicker>
    </>
  );
};

MobileDates.propTypes = {
  endDate: PropTypes.instanceOf(Object),
  startDate: PropTypes.instanceOf(Object).isRequired,
  onDatesSelected: PropTypes.func.isRequired,
  market: PropTypes.string,
  setDateFocused: PropTypes.func,
  dateFocused: PropTypes.string,
  currentDate: PropTypes.instanceOf(Date),
  minBookingDate: PropTypes.instanceOf(Date),
};

export default MobileDates;
