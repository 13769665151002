import { requestByPOST } from 'lib/requests';
import { buildPathParams } from 'lib/utils/url';

const END_POINTS = {
  REFUND_CREDIT_CARD: '/refund-on-hold/{hash}/creditcard',
};

export function refundCreditCardRequest(params) {
  return requestByPOST({
    ...params,
    endpoint: buildPathParams(END_POINTS.REFUND_CREDIT_CARD, params),
  });
}
