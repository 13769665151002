import {
  VERIFY_EMAIL_REQUEST,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILURE,
  VERIFY_EMAIL_REJECTED,
} from './constants';

export const fetchVerifyEmailRequestAction = payload => ({
  type: VERIFY_EMAIL_REQUEST,
  payload,
});

export const fetchVerifyEmailSuccessAction = payload => ({
  type: VERIFY_EMAIL_SUCCESS,
  payload,
});

export const fetchVerifyEmailFailureAction = payload => ({
  type: VERIFY_EMAIL_FAILURE,
  payload,
});

export const fetchVerifyEmailRejectedAction = payload => ({
  type: VERIFY_EMAIL_REJECTED,
  payload,
});
