import {
  SEND_VOUCHER_STATE,
  SEND_VOUCHER_FAILURE,
  SEND_VOUCHER_REQUEST,
  SEND_VOUCHER_SUCCESS,
} from './constants';
import stateBase from './state';

const initialState = stateBase[SEND_VOUCHER_STATE];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SEND_VOUCHER_REQUEST:
      return {
        ...state,
        error: null,
        isFetching: true,
      };

    case SEND_VOUCHER_SUCCESS:
      return {
        ...state,
        error: null,
        isFetching: false,
      };

    case SEND_VOUCHER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isFetching: false,
      };

    default: {
      return state;
    }
  }
};

export default reducer;
