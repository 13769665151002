import { REFUND_CREDIT_CARD_STATE } from './constants';

const state = {
  [REFUND_CREDIT_CARD_STATE]: {
    error: null,
    isFetching: true,
    status: null,
  },
};

export default state;
