import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { COUNTRIES } from 'src/constants/countries';
import { THEMES, THEME_DEFAULT, THEME_PRIMARY_FULL } from './constants';

import { buildLocationURL, buildSearchQuery } from './utils';
import { clickCountrySelector, clickMarket } from 'client/contexts/Tracking/events';
import { getSearch } from 'src/effects/Search/selectors';
import { sendTrackingRequestAction } from 'client/contexts/Tracking/actions';

import { useClickAway } from 'react-use';

import Flag from 'client/components/Flag';
import { CountrySelectorBase, SelectedItem, CountryName, List, Arrow } from './styles';
import { useMarket } from 'src/hooks/useMarket';

const CountrySelector = ({
  isUserNav = false,
  pageId,
  theme = THEME_DEFAULT,
  toHomepage = false,
}) => {
  const ref = useRef();
  const dispatch = useDispatch();

  const marketConfig = useMarket();
  const search = useSelector(getSearch);

  const [selected, setSelected] = useState(marketConfig);
  const [listOpen, setListOpen] = useState(false);

  useEffect(() => {
    if (listOpen) {
      dispatch(sendTrackingRequestAction({ event: clickCountrySelector({ page: pageId }) }));
    }
  }, [pageId, listOpen, dispatch]);

  function selectItem(selectedCountry) {
    setSelected(selectedCountry);

    dispatch(sendTrackingRequestAction({ event: clickMarket(selectedCountry.market) }));

    const marketURL = buildLocationURL(selectedCountry, toHomepage);
    const searchParams = buildSearchQuery(search);

    window.location.href = `${marketURL}&${searchParams}`;
  }

  function closeList() {
    setListOpen(false);
  }

  function toggleList() {
    setListOpen(!listOpen);
  }

  useClickAway(ref, closeList);

  return (
    <CountrySelectorBase isUserNav={isUserNav} theme={theme} ref={ref}>
      <SelectedItem
        data-testid="country-selector"
        isUserNav={isUserNav}
        listOpen={listOpen}
        onClick={toggleList}
        onKeyDown={toggleList}
        role="button"
        tabIndex={0}
      >
        <Flag country={selected} isUserNav={isUserNav} textLabel={isUserNav} />
        {theme === THEME_PRIMARY_FULL && <CountryName>{selected.countryName}</CountryName>}
        <Arrow open={listOpen} size={12} />
      </SelectedItem>

      {listOpen && (
        <List isUserNav={isUserNav} listOpen={listOpen} data-testid="country-selector-list">
          {Object.keys(COUNTRIES)
            .filter(key => key !== 'zz')
            .map(key => {
              const country = COUNTRIES[key];

              return (
                <li role="menuitem" key={country.locale}>
                  <Flag
                    country={country}
                    isUserNav={isUserNav}
                    onClick={() => selectItem(country)}
                  />
                </li>
              );
            })}
        </List>
      )}
    </CountrySelectorBase>
  );
};

CountrySelector.propTypes = {
  isUserNav: PropTypes.bool,
  pageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  theme: PropTypes.oneOf(THEMES),
  toHomepage: PropTypes.bool,
};

export default CountrySelector;
