import { shape, number, string, objectOf } from 'prop-types';

export const typeSuggesterItem = shape({
  citySlug: string,
  coordinates: objectOf(number),
  country: string,
  description: string,
  id: string,
  slug: string,
  stars: number,
  text: string,
  type: string,
});
