import { call, put } from 'redux-saga/effects';

import { fetchRefundCreditCardFailureAction, fetchRefundCreditCardSuccessAction } from './actions';
import { refundCreditCardRequest } from './requests';
import { refetchVoucherAction } from 'src/comps/Voucher/actions';

export function* fetchRefundCreditCardSaga(action) {
  try {
    const response = yield call(refundCreditCardRequest, action.payload);

    if (response.status >= 400) {
      throw new Error(response.data || response.status);
    }

    yield put(fetchRefundCreditCardSuccessAction());
    yield put(refetchVoucherAction());
  } catch (error) {
    yield put(fetchRefundCreditCardFailureAction({ error: error.message }));
    yield put(refetchVoucherAction());
  }
}
