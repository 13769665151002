import React, { createContext } from 'react';

const PageContext = createContext();

// eslint-disable-next-line react/prop-types
const PageProvider = ({ children, value }) => {
  return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
};

export { PageContext, PageProvider };
