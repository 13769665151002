import { useContext, useMemo } from 'react';
import { SessionContext } from 'src/context/session/';

import { SessionType } from 'src/types/session';

export const useSession = () => {
  const session: SessionType | null = useContext(SessionContext);

  if (!session) {
    throw new Error('Missing SessionContext provider');
  }

  const value = useMemo(
    () => ({
      ...session,
      get currentUserHasPrimeSubscription() {
        return (String(session.customer?.subscriptionPrime).toLowerCase() === 'true');
      },
    }),
    [session]
  );

  return value;
};
