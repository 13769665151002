export const LINK_ATTRIBUTES = {
  target: '_blank',
  rel: 'nofollow, noopener',
};

export const SOCIAL_PAGES = {
  facebook: 'https://www.facebook.com/stayforlong/',
  instagram: 'https://www.instagram.com/stayforlong/',
};

export const EXTERNAL_LINKS = {
  reviews: 'https://www.trustpilot.com/review/stayforlong.com',
};
