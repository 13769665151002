import { defineMessages } from 'react-intl';

const messages = defineMessages({
  errorDefault: {
    id: 'global.general-error',
  },
  errorInvalidCredentials: {
    id: 'error.invalid-credentials',
  },
  errorAvailability: {
    id: 'global.availability-has-gone',
  },
  errorSubscription: {
    id: 'error.request-try-later',
  },
  errorPaymentVoucher: {
    id: 'payment.try_again_other_card',
  },
  errorCardCTA: {
    id: 'payment.try-again-cta',
  },
  errorCardMessage: {
    id: 'payment.try-again-message',
  },
  errorResetLinkUsed: {
    id: 'global.reset-link-used',
  },
  errorResetLinkExpired: {
    id: 'global.reset-link-expired',
  },
  errorAlreadyRegistered: {
    id: 'email.already-registered',
  },
  warningAlreadyBooked: {
    id: 'step-error.already-booked',
  },
  warningPurchaseNotFound: {
    id: 'step-error.purchase-not-found',
  },
  successVoucherPayment: {
    id: 'global.general-success',
  },
  successSubscribed: {
    id: 'subscription.successfully-subcribed',
  },
  successUnsubscribed: {
    id: 'subscription.successfully-unsubscribed',
  },
  successPayNow: {
    id: 'global.pay-now-success',
  },
  successRefund: {
    id: 'global.refund_ok',
  },
  resetLinkUsed: {
    id: 'global.reset-link-used',
  },
  successChangePassword: {
    id: 'global.password-update',
  },
  successUpdatedUser: {
    id: 'global.profile-update',
  },
  successVoucherSent: {
    id: 'voucher.send-voucher-ok',
  },
  accountCreated: {
    id: 'global.account-created',
  },
  willReceiveEmail: {
    id: 'subscription.we-have-sent-you-an-email',
  },
  thankYouSubscription: {
    id: 'subscription.thank-you-for-being-part-of-our-community',
  },
  prime: {
    id: 'global.prime',
  },
  stayforlong: {
    id: 'global.stayforlong',
  },
  successPrimeReactivation: {
    id: 'prime.resume-successfully',
  },
});

export default messages;
