export const FILTERS_STATE = 'filters';

export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const RESET_FILTERS = 'RESET_FILTERS';

export const ORDER_OPTIONS = ['relevance', 'distance', 'price', 'stars', 'ratings'];
export const DEFAULT_FILTERS = { orderBy: 'relevance' };

export const FILTER_OPTIONS = [
  'stars',
  'family',
  'facilities',
  'orderBy',
  'accommodationTypes',
  'ratings',
  'minPrice',
  'maxPrice',
];
