import {
  USER_BOOKINGS_REQUEST,
  USER_BOOKINGS_FAILED,
  USER_BOOKINGS_SUCCESS,
  USER_PROFILE_STATE,
} from './constants';
import stateBase from './state';

const initialState = stateBase[USER_PROFILE_STATE];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_BOOKINGS_REQUEST: {
      return {
        ...state,
        isFetching: true,
        error: null,
        bookingList: null,
      };
    }

    case USER_BOOKINGS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        bookingList: action.payload.userBookings,
      };
    }

    case USER_BOOKINGS_FAILED: {
      return {
        ...state,
        error: action.payload.error,
        errorCode: action.payload.errorCode,
        isFetching: false,
        bookingList: null,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
