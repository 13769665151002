const defaultColors = {
  black: '#000',
  blue2: '#2e96a9',
  brown: '#856404',
  green1: '#cdf0dd',
  green2: '#d4edda',
  green3: '#75e1c9',
  green4: '#1d8549',
  green5: '#dff0d8',
  green6: '#15c506',
  red2: '#c92a2a',
  red3: '#f22525',
  red4: '#f8d7da',
  red5: '#f5c6cb',
  red6: '#721c24',
  red7: '#ff3907',
  white: '#fff',
  yellow2: '#fff3cd',
  yellow3: '#ffeeba',
  yellow4: '#ffd04a',
};

const brandingColors = {
  blue: '#0078cc',
  green: '#27ae60',
  grey0: '#333',
  grey1: '#444444',
  grey2: '#666',
  grey3: '#a8a8a8',
  grey4: '#e0e0e0',
  grey5: '#f2f2f2',
  grey6: '#f5f5f5',
  grey7: '#fafafa',
  magenta: 'var(--color--primary--500)',
  magenta2: 'var(--color--primary--50)',
  magenta3: 'var(--color--primary--700)',
  orange: '#f2994a',
  purple: '#f2e6fc',
  purple2: '#7802de',
  purple3: '#6002b2',
  purple4: '#a14ee8',
  red: '#eb5757',
  yellow: '#f2c94c',
};

const specificColors = {
  facebook: '#3b5998',
  tripadvisor: '#00aa6c',
  twitter: '#1da1f2',
  whatsapp: '#25d366',
  tripadvisorContent: '#013b45',
};

export const colors = {
  ...defaultColors,
  ...brandingColors,
  ...specificColors,
};
