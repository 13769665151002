import React from "react"

export const withLayouts = (App) => {
  return (props) => {
    // eslint-disable-next-line react/prop-types
    const { Component, ...rest } = props
    if ('standalone' in Component) {
      return (<Component {...rest} />)
    }

    return (<App {...props} />)
  }
}