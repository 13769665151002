import { defineMessages } from 'react-intl';

const messages = defineMessages({
  checkIn: {
    id: 'search.checkin',
  },
  checkOut: {
    id: 'search.checkout',
  },
  ready: {
    id: 'dates.ready',

    days: '3',
  },
  nights: {
    id: 'global.nights',
  },
});

export default messages;
