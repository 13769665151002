import { shape, number, string, arrayOf } from 'prop-types';

const paxesTypes = {
  adults: arrayOf(number),
  children: arrayOf(arrayOf(number)),
};

const destinationTypes = {
  city: string,
  country: string,
  slug: string,
  type: string,
};

export const typePaxes = shape(paxesTypes);
export const typeDestination = shape(destinationTypes);

export const typeSearch = shape({
  ...paxesTypes,
  ...destinationTypes,
  searchTerm: string,
});
