import {
  LOCATION_STATE,
  FETCH_LOCATION_SUCCESS,
  FETCH_LOCATION_FAILED,
} from './constants';
import stateBase from './state';

const initialState = stateBase[LOCATION_STATE];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LOCATION_SUCCESS:
      return {
        ...state,
        error: null,
        isFetching: false,
        data: action.payload,
      };

    case FETCH_LOCATION_FAILED:
      return {
        ...state,
        error: action.payload.error,
        isFetching: false,
      };

    default:
      return state;
  }
};

export default reducer;
