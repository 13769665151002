import Cookies from 'js-cookie';
import { COOKIE_PROPERTIES_VISITED, PROPERTIES_VISITED_MAX } from './constants';

export function getPropertiesIdsFromCookie() {
  const cookie = Cookies.get(COOKIE_PROPERTIES_VISITED) || '';
  return cookie ? cookie.split(',').map(Number) : [];
}

export function setPropertiesIdsCookie(propertiesIds) {
  Cookies.set(COOKIE_PROPERTIES_VISITED, propertiesIds.join());
}

export function transformPropertiesIds({ properties, propertyId, isDelete = false }) {
  const propertiesFiltered = properties.filter(id => id !== propertyId);

  return isDelete || !propertyId
    ? propertiesFiltered
    : [propertyId, ...propertiesFiltered].slice(0, PROPERTIES_VISITED_MAX);
}

export function updatePropertiesCookie({ propertyId, isDelete }) {
  const propertiesTransformed = transformPropertiesIds({
    properties: getPropertiesIdsFromCookie(),
    propertyId,
    isDelete,
  });

  setPropertiesIdsCookie(propertiesTransformed);
}
