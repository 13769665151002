import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { buildExternalLink } from './utils';

import { ExternalLinkBase } from './styles';
import messages from './messages';
import { useMarket } from 'src/hooks/useMarket';

const ExternalLink = ({ id, url, handleTracking }) => {
  const intl = useIntl();
  const { language, market } = useMarket();
  
  const targetURL = url || buildExternalLink(language, market, id);

  return (
    <ExternalLinkBase
      href={targetURL}
      id={id}
      onClick={handleTracking}
      rel="nofollow, noopener, noreferrer"
      target="_blank"
    >
      {intl.formatMessage({ ...messages[id] })}
    </ExternalLinkBase>
  );
};

ExternalLink.propTypes = {
  handleTracking: PropTypes.func,
  id: PropTypes.string,
  url: PropTypes.string,
};

export default ExternalLink;
