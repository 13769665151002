import { requestByPOST } from 'lib/requests';

const END_POINTS = {
  FORGOT_PASSWORD: '/users/forgot',
};

export function fetchForgottenPassword(params) {
  return requestByPOST({
    data: { email: params.data.email },
    endpoint: END_POINTS.FORGOT_PASSWORD,
    hostname: params.hostname,
  });
}
