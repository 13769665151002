import { bool, shape, number, string, arrayOf, oneOfType, oneOf } from 'prop-types';

import { typeInsurance } from 'client/types/room';
import { typePhoto } from 'client/types/property';
import { PaymentMethodEnum } from 'client/contexts/Purchase/mappers';

export const typeCheckoutBoard = shape({
  adults: number,
  allotment: number,
  availabilityId: string,
  boardName: string,
  cancellation: arrayOf(shape({ from: string, amount: number, currency: string })),
  checkIn: string,
  checkOut: string,
  children: number,
  childrenAges: oneOfType([number, string]),
  code: string,
  currency: string,
  discount: number,
  finalPrice: number,
  initialPrice: number,
  insurance: typeInsurance,
  isBestPrice: bool,
  isRecheck: bool,
  language: string,
  market: string,
  paxes: string,
  paymentPolicy: string,
  payRate: number,
  photos: arrayOf(oneOfType([typePhoto, string])),
  pricePerNight: number,
  roomName: string,
  roomsNumber: number,
  roomIndex: number,
  roomUniqueId: oneOfType([number, string]),
  stayWalletCredit: number,
  taxes: oneOfType([arrayOf(shape({ amount: number, included: bool, currency: string })), number]),
});

export const typeCreditCard = shape({
  holder: string,
  brand: string,
  number: string,
  expiryDate: string,
});

export const typeAPM = shape({ kind: string, value: string });

export const typePaymentOgone = shape({
  kind: oneOf([PaymentMethodEnum.Ogone]),
  creditCard: shape({
    url: string,
  }).isRequired,
  apm: arrayOf(typeAPM),
});

export const typePaymentWordline = shape({
  kind: oneOf([PaymentMethodEnum.Worldline]),
  creditCard: shape({
    url: string,
  }).isRequired,
  apm: arrayOf(typeAPM),
});

export const typePaymentPaycomet = shape({
  kind: oneOf([PaymentMethodEnum.Paycomet]),
  creditCard: shape({
    clientId: string,
  }).isRequired,
  apm: arrayOf(typeAPM),
});

export const typePaymentMethods = oneOfType([
  typePaymentOgone,
  typePaymentWordline,
  typePaymentPaycomet,
]);
