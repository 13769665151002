import styled from 'styled-components';
import { colors, fonts, media } from 'client/styles';

export const ButtonBase = styled.button`
  appearance: none;
  border: none;
  cursor: pointer;
  font-family: ${fonts.default};
  font-size: 16px;
  line-height: 1;

  &:active {
    opacity: 0.8;
  }

  &:focus {
    outline: 0;
  }

  &[disabled] {
    background-color: ${colors.grey4} !important;
    border-color: ${colors.grey4} !important;
    color: ${colors.grey3} !important;
    cursor: not-allowed;
    pointer-events: none;

    &:hover {
      background-color: inherit;
      opacity: 1;
    }
  }
`;

export const ButtonDefault = styled(ButtonBase)`
  border-radius: 8px;
  padding: 1em;
  font-size: 18px;
  font-weight: 700;
  width: 100%;
`;

export const ButtonPrimary = styled(ButtonDefault)`
  background-color: ${colors.magenta};
  color: ${colors.white};

  &:hover {
    background-color: ${colors.magenta3};
  }
`;

export const ButtonSecondary = styled(ButtonDefault)`
  background-color: transparent;
  border: 2px solid ${colors.magenta};
  color: ${colors.magenta};

  &:hover {
    background-color: ${colors.magenta2};
  }
`;

export const ButtonTertiary = styled(ButtonDefault)`
  background-color: transparent;
  border: 2px solid ${colors.grey1};
  color: ${colors.grey1};

  &:hover {
    background-color: ${colors.grey5};
  }
`;

export const ButtonIcon = styled(ButtonBase)`
  background-color: transparent;
  padding: 0;

  svg {
    fill: ${colors.grey1};
    vertical-align: middle;
    ${props =>
      props.size &&
      `
    width: ${props.size || 24}px;
    height: ${props.size || 24}px;
    `}
  }
`;

export const ButtonIconRound = styled.button`
  appearance: none;
  cursor: pointer;
  background-color: ${colors.white};
  border: 2px solid ${colors.grey4};
  color: ${colors.grey1};
  border-radius: 50%;
  height: ${props => props.size || 35}px;
  line-height: 0.9;
  padding: 0;
  width: ${props => props.size || 35}px;

  :hover,
  :focus {
    background-color: ${colors.grey5};
    border: 2px solid ${colors.grey1};
  }

  :disabled {
    border-color: ${colors.grey4};
    color: ${colors.grey4};
    background-color: ${colors.white};
  }

  :focus {
    outline: none;
  }
`;

export const ButtonReset = styled(ButtonBase)`
  background-color: transparent;
  color: ${colors.blue};
  display: flex;
  font-size: 14px;
  font-weight: 700;
  padding: 0;
  text-decoration: underline;
`;

/* -- */

export const primary = {
  color: colors.white,
  background: colors.magenta,
};

export const secondary = {
  color: colors.white,
  background: colors.secondary,
};

export const InputButton = styled(ButtonBase)`
  display: flex;
  align-items: center;
  background-color: ${colors.white};
  border-radius: 3px;
  margin-bottom: ${props => (props.isDefault ? '1em' : '')};
  padding: 17px;
  padding-left: 0;
  width: 100%;

  span {
    color: #484848;
    display: block;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.3;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100% - 52px);

    &.placeholder {
      color: ${colors.grey3};
    }

    ${media.desktop} {
      font-size: 1em;
    }
  }

  svg {
    width: 52px;
  }
`;

export const ButtonText = styled(ButtonBase)`
  background-color: transparent;
  color: ${colors.magenta};
  padding: 0;
`;

export const inlineStyles = {
  iconPerson: {
    fill: '#82888a',
    marginLeft: '0.55em',
    verticalAlign: 'middle',
  },
  iconSearch: {
    fill: 'white',
    verticalAlign: 'middle',
  },
  iconNegativePerson: {
    fill: colors.magenta,
    hover: { fill: 'white' },
    verticalAlign: 'middle',
  },
};

export const ButtonLink = styled.a`
  appearance: none;
  background-color: ${colors.magenta};
  border-radius: 8px;
  color: ${colors.white};
  cursor: pointer;
  display: block;
  font-family: ${fonts.default};
  font-size: 18px;
  font-weight: 700;
  line-height: 1.4;
  padding: 12px 20px;
  text-align: center;
  text-decoration: none;
  width: 100%;

  &:hover {
    background-color: ${colors.magenta3};
  }

  &:active {
    opacity: 0.8;
  }

  &:focus {
    outline: 0;
  }
`;
