import { call, put } from 'redux-saga/effects';

import { CONFLICT as RESET_LINK_USED } from 'src/constants/httpcodes';
import { displayAlertAction } from 'src/comps/AlertMessage/actions';

import { activateAccount } from './requests';
import { activateAccountFailureAction, activateAccountSuccessAction } from './actions';

export function* activateAccountSaga(action) {
  try {
    const response = yield call(activateAccount, action.payload);

    if (response.status === RESET_LINK_USED.status) {
      yield put(activateAccountFailureAction({ error: response.data }));
      yield put(displayAlertAction({ messageId: 'resetLinkUsed' }));
      return;
    }

    if (response.status >= 400) {
      yield put(activateAccountFailureAction({ error: response.data }));
      yield put(displayAlertAction({ messageId: 'errorDefault' }));
      return;
    }

    yield put(activateAccountSuccessAction());
    yield put(displayAlertAction({ messageId: 'accountCreated', severity: 'success' }));
  } catch (error) {
    yield put(activateAccountFailureAction({ error: error.message }));
    yield put(displayAlertAction({ messageId: 'errorDefault' }));
  }
}
