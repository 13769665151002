export function mapDataToUserWallet(data) {
  if (!data.available_credit) {
    return null;
  }

  return {
    currencies: Object.keys(data.available_credit).map(key => ({
      value: data.available_credit[key],
      currency: key,
    })),
  };
}
