import { ALERTS_STATE } from './constants';

const state = {
  [ALERTS_STATE]: {
    messageId: '',
    severity: 'error',
    values: {},
  },
};

export default state;
