import styled from 'styled-components';
import { colors, updatedMedia } from 'client/styles';

import { ButtonPrimary } from 'src/comps/Button/styles';
import { DatePickerBase } from 'client/components/DatePicker/styles';
import { DateSelectorBase } from 'client/components/DateSelector/styles';
import { PaxSelectorBase } from 'client/components/PaxSelector/styles';
import { Label } from 'client/components/InputReadOnly/styles';
import { LoaderBase } from 'src/comps/Atom/Loader/styles';

export const Paxes = styled.div`
  background-color: ${colors.white};
  border-radius: 8px;
  border: 2px solid;
  border-color: ${props => (props.isFocus ? colors.grey1 : colors.grey4)};

  ${updatedMedia.tablet} {
    position: relative;

    & ${PaxSelectorBase} {
      position: absolute;
      top: calc(100% + 10px);
      left: 0;
      width: 375px;
      background-color: ${colors.white};
      padding-bottom: 0;
    }
  }

  ${updatedMedia.desktop} {
    &:hover {
      border-color: ${colors.grey1};
    }
  }

  ${Label} {
    padding: 6px 12px;
  }

  ${LoaderBase}{
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const Form = styled.form`
  display: grid;
  gap: 20px;
  width: 100%;

  ${updatedMedia.tablet} {
    align-items: stretch;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr auto;
  }

  ${updatedMedia.desktop} {
    ${DateSelectorBase} {
      flex: 0 0 308px;
    }
  }
`;

export const ButtonSearch = styled(ButtonPrimary)`
  display: grid;
  grid-auto-flow: column;
  place-content: center;
  place-items: center;
  padding: 18px 20px;
  gap: 12px;
  grid-gap: 12px;

  svg, span {
    pointer-events: none;
  }

  svg path {
    stroke-width: 2px;
    fill: currentColor;
  }

  ${updatedMedia.tablet} {
    font-size: 16px;
    width: 60px;

    span {
      display: none;
    }
  }

  ${updatedMedia.desktop} {
    min-width: 150px;
    width: 100%;
    span {
      display: inline;
    }
  }
`;

export const RoomsUpdaterBase = styled.div`
  padding: 20px;

  h2 {
    color: ${colors.grey1};
    font-size: 21px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 20px;
    ${updatedMedia.tablet} {
      font-size: 28px;
    }
  }

  ${updatedMedia.tablet} {
    padding: 80px 40px 0;

    ${Form} {
      display: inline-flex;
      justify-content: space-between;
    }

    ${DateSelectorBase} {
      flex: 0 0 49%;
      margin-right: 0;

      ${DatePickerBase} {
        left: 0;
      }
    }

    ${Paxes} {
      flex: 0 0 49%;
      margin-right: 0;
    }

    ${ButtonSearch} {
      width: 175px;
    }
  }
`;
