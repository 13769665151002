import {
  REFUND_CREDIT_CARD_STATE,
  REFUND_CREDIT_CARD_FAILURE,
  REFUND_CREDIT_CARD_SUCCESS,
  REFUND_CREDIT_CARD_REQUEST,
  STATUS_REFUND_CREDIT_CARD_OK,
  STATUS_REFUND_CREDIT_CARD_NOT_OK,
} from './constants';
import stateBase from './state';

const initialState = stateBase[REFUND_CREDIT_CARD_STATE];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REFUND_CREDIT_CARD_REQUEST:
      return {
        ...state,
        error: null,
        isFetching: true,
        status: null,
      };

    case REFUND_CREDIT_CARD_SUCCESS: {
      return {
        ...state,
        error: null,
        isFetching: false,
        status: STATUS_REFUND_CREDIT_CARD_OK,
      };
    }

    case REFUND_CREDIT_CARD_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isFetching: false,
        status: STATUS_REFUND_CREDIT_CARD_NOT_OK,
      };

    default:
      return state;
  }
};

export default reducer;
