import CookieClient from 'js-cookie';
import { call, put } from 'redux-saga/effects';

import { COOKIE_AUTHENTICATION } from 'src/constants/cookies';
import { displayAlertAction } from 'src/comps/AlertMessage/actions';
import { loadEmailLogin } from 'client/contexts/Tracking/events';
import { saveCustomerDataAction } from 'client/contexts/Purchase/actions';
import { sendTrackingRequestAction } from 'client/contexts/Tracking/actions';
import { UNAUTHORIZED } from 'src/constants/httpcodes';
import { resetUserAction } from 'src/effects/User/actions';

import {
  fetchCustomerDataFailureAction,
  fetchCustomerDataRequestAction,
  fetchCustomerDataSuccessAction,
  loginFailureAction,
  loginSuccessAction,
  logoutFailureAction,
  logoutSuccessAction,
} from './actions';
import { fetchCustomerData, login, logout, loginGoogle } from './requests';
import { sendLoginEvent } from './helpers';

export function* loginSagaActionsSaga(action, loginFn) {
  try {
    const response = yield call(loginFn, action.payload);
    if (response.status === UNAUTHORIZED.status) {
      yield put(loginFailureAction({ error: UNAUTHORIZED.message, status: response.status }));
      yield put(displayAlertAction({ messageId: 'errorInvalidCredentials' }));
      return;
    }

    if (response.status >= 400) {
      throw new Error(response.data || response.status);
    }

    yield put(loginSuccessAction({ email: action.payload.data.email }));
    yield put(sendLoginEvent());
    yield put(fetchCustomerDataRequestAction({ hostname: action.payload.hostname }));
  } catch (error) {
    yield put(loginFailureAction({ error: error.message }));
  }
}

export function* loginGoogleSaga(action) {
  yield call(loginSagaActionsSaga, action, loginGoogle)
}

export function* loginSaga(action) {
  yield call(loginSagaActionsSaga, action, login)
}

export function* fetchCustomerDataSaga(action) {
  try {
    const response = yield call(fetchCustomerData, action.payload);

    if (response.status >= 400) {
      throw new Error(response.data);
    }

    const user = response.data;
    yield put(fetchCustomerDataSuccessAction(user));
    yield put(saveCustomerDataAction(user));
    yield put(sendTrackingRequestAction({ event: loadEmailLogin() }));
  } catch (error) {
    CookieClient.remove(COOKIE_AUTHENTICATION);
    yield put(fetchCustomerDataFailureAction({ error: error.message }));
  }
}

export function* logoutSaga(action) {
  try {
    const response = yield call(logout, action.payload);

    if (response.status >= 400) {
      throw new Error(response.data);
    }

    yield put(logoutSuccessAction());
    yield put(resetUserAction());
  } catch (error) {
    yield put(logoutFailureAction({ error: error.message }));
  }
}
