export const SECTIONS = {
  DESTINATION_BOX: 'destination_box',
  CHECKIN_BOX: 'checkin_box',
  CHECKOUT_BOX: 'checkout_box',
  ROOM_IMAGES: 'room_images',
  HOTEL_IMAGES: 'hotel_images',
  PAXES_BOX: 'paxes_box',
  SEARCH_FILTERS: 'search_filters',
  ROOM_OFFERS: 'room_offers',
  SHOW_MORE_ROOMS: 'show_more_rooms',
  BANNER: 'banner',
  CHANGE_PAXES_DATES: 'change_paxes_dates',
} as const;

export const SECTION_KEYS = Object.values(SECTIONS);

export type SectionType = (typeof SECTION_KEYS)[number];
