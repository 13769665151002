import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate } from 'react-intl';
import { toDate } from 'date-fns-tz';

import { VALID_DATE_INTL_FORMATS, DATE_INTL_FORMAT_DEFAULT, formatMappings } from './constants';

const DateIntl = ({ value, format }) => {
  const props = formatMappings[format] || formatMappings[DATE_INTL_FORMAT_DEFAULT];
  return <FormattedDate value={toDate(value)} {...props} />;
};

DateIntl.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)])
    .isRequired,
  format: PropTypes.oneOf(VALID_DATE_INTL_FORMATS),
};

export default DateIntl;
