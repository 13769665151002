import { call, put, select } from 'redux-saga/effects';

import { displayAlertAction } from 'src/comps/AlertMessage/actions';
import {
  fetchSubscriptionStatus,
  subscribe,
  unsubscribe,
  confirm,
  activateSubscriptionTopic,
  deactivateSubscriptionTopic,
} from './requests';

import {
  confirmFailureAction,
  confirmSuccessAction,
  fetchActivateTopicFailureAction,
  fetchActivateTopicSuccessAction,
  fetchDeactivateTopicFailureAction,
  fetchDeactivateTopicSuccessAction,
  fetchSubscriptionStatusFailureAction,
  fetchSubscriptionStatusSuccessAction,
  subscribeFailureAction,
  subscribeSuccessAction,
  unsubscribeFailureAction,
  unsubscribeSuccessAction,
} from './actions';
import { ACTIVE, DIRECT_OPT_IN_ALLOWED_MARKETS, SUBSCRIPTION } from './constants';
import { getSubscriptions } from './selectors';
import { SUBSCRIPTION_CHECKOUT_PAGE } from 'src/constants/subscriptionSources';

export function* subscriptionStatusSaga(action) {
  try {
    if (!action.payload?.hash) {
      throw new Error('Hash not defined...');
    }

    const { data, status } = yield call(fetchSubscriptionStatus, action.payload);

    if (status >= 400) {
      yield put(fetchSubscriptionStatusFailureAction({ error: data }));
      yield put(displayAlertAction({ messageId: 'errorDefault' }));
      return;
    }

    yield put(fetchSubscriptionStatusSuccessAction(data));
  } catch (error) {
    yield put(fetchSubscriptionStatusFailureAction({ error: error.message }));
    yield put(displayAlertAction({ messageId: 'errorDefault' }));
  }
}

export function* subscribeSaga(action) {
  try {
    const { status } = yield call(subscribe, action.payload);
    const { market, source } = action.payload;

    if (status >= 400) {
      yield put(subscribeFailureAction({ error: SUBSCRIPTION }));
      yield put(displayAlertAction({ messageId: 'errorDefault' }));
      return;
    }

    if (status === 202 && source !== SUBSCRIPTION_CHECKOUT_PAGE) {
      yield put(subscribeSuccessAction({ status }));

      if (market && DIRECT_OPT_IN_ALLOWED_MARKETS.includes(market)) {
        // call for latest status after this is completed
        yield call(subscriptionStatusSaga, action);
        yield put(displayAlertAction({ messageId: 'thankYouSubscription', severity: 'success' }));
      } else {
        yield put(displayAlertAction({ messageId: 'willReceiveEmail', severity: 'success' }));
      }
      return;
    }

    yield put(subscribeSuccessAction({ status }));
    if (source !== SUBSCRIPTION_CHECKOUT_PAGE) {
      yield put(displayAlertAction({ messageId: 'thankYouSubscription', severity: 'success' }));
    }
  } catch (error) {
    yield put(subscribeFailureAction({ error: SUBSCRIPTION }));
    yield put(displayAlertAction({ messageId: 'errorDefault' }));
  }
}

export function* unsubscribeSaga(action) {
  try {
    const { status } = yield call(unsubscribe, action.payload);

    if (status >= 400) {
      yield put(unsubscribeFailureAction({ error: SUBSCRIPTION }));
      yield put(displayAlertAction({ messageId: 'errorDefault' }));
      return;
    }

    yield put(unsubscribeSuccessAction());
    yield put(displayAlertAction({ messageId: 'successUnsubscribed', severity: 'success' }));
  } catch (error) {
    yield put(unsubscribeFailureAction({ error: SUBSCRIPTION }));
    yield put(displayAlertAction({ messageId: 'errorDefault' }));
  }
}

export function* confirmSaga(action) {
  try {
    const { data, status } = yield call(confirm, action.payload);

    if (status >= 400) {
      yield put(confirmFailureAction({ error: data }));
      yield put(displayAlertAction({ messageId: 'errorDefault' }));
      return;
    }

    yield put(confirmSuccessAction());
    yield put(displayAlertAction({ messageId: 'successSubscribed', severity: 'success' }));
  } catch (error) {
    yield put(confirmFailureAction({ error: error.message }));
    yield put(displayAlertAction({ messageId: 'errorDefault' }));
  }
}

export function* activateSubscriptionTopicSaga(action) {
  try {
    const { status } = yield call(activateSubscriptionTopic, action.payload);
    const { uuid } = action.payload;

    if (status >= 400) {
      yield put(fetchActivateTopicFailureAction({ error: uuid }));
      yield put(displayAlertAction({ messageId: 'errorDefault' }));
      return;
    }

    yield put(fetchActivateTopicSuccessAction({ uuid }));
    yield put(displayAlertAction({ messageId: 'thankYouSubscription', severity: 'success' }));
  } catch (error) {
    yield put(fetchActivateTopicFailureAction({ error: action.payload.uuid }));
    yield put(displayAlertAction({ messageId: 'errorDefault' }));
  }
}

export function* deactivateSubscriptionTopicSaga(action) {
  try {
    const { status } = yield call(deactivateSubscriptionTopic, action.payload);
    const { uuid } = action.payload;

    if (status >= 400) {
      yield put(fetchDeactivateTopicFailureAction({ error: uuid }));
      yield put(displayAlertAction({ messageId: 'errorDefault' }));
      return;
    }

    yield put(fetchDeactivateTopicSuccessAction({ uuid }));

    const subscriptions = yield select(getSubscriptions);
    if (
      subscriptions &&
      Object.values(subscriptions).every(({ status: stat }) => stat !== ACTIVE)
    ) {
      yield put(unsubscribeSuccessAction());
    }

    yield put(displayAlertAction({ messageId: 'successUnsubscribed', severity: 'success' }));
  } catch (error) {
    yield put(fetchDeactivateTopicFailureAction({ error: action.payload.uuid }));
    yield put(displayAlertAction({ messageId: 'errorDefault' }));
  }
}
