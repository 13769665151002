import styled, { css, keyframes } from 'styled-components';

const ellipsis1 = keyframes`
  0%   { transform: scale(0) }
  100% { transform: scale(1) }
`;

const ellipsis2 = keyframes`
  0%   { transform: translate(0, 0) }
  100% { transform: translate(19px, 0) }
`;

const ellipsis2Inline = keyframes`
  0%   { transform: translate(0, 0) }
  100% { transform: translate(10px, 0) }
`;

const ellipsis3 = keyframes`
  0%   { transform: scale(1) }
  100% { transform: scale(0) }
`;

export const Dot = styled.div`
  border-radius: 50%;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
`;

const baseLoaderStyles = css`
  background-color: transparent;
  display: flex;
  position: relative;
`;

export const LoaderBase = styled.div<{ top: string; margin?: string }>`
  ${baseLoaderStyles}
  width: 64px;
  height: 30px;
  top: ${props => `${props.top}px`};

  margin: ${props => (props.margin ? `${props.margin}px auto;` : `20px auto;`)};

  ${Dot} {
    position: absolute;
    top: 10px;
    width: 11px;
    height: 11px;
    background: ${({ color, theme }) => color || theme.colors.brand1};

    &:nth-child(1) {
      left: 6px;
      animation: ${ellipsis1} 0.6s infinite;
    }

    &:nth-child(2) {
      left: 6px;
      animation: ${ellipsis2} 0.6s infinite;
    }

    &:nth-child(3) {
      left: 26px;
      animation: ${ellipsis2} 0.6s infinite;
    }

    &:nth-child(4) {
      left: 45px;
      animation: ${ellipsis3} 0.6s infinite;
    }
  }
`;

export const LoaderInline = styled.div<{ top: string }>`
  ${baseLoaderStyles}
  width: 30px;
  height: 10px;
  margin: 0;
  top: ${props => `${props.top}px`};

  ${Dot} {
    position: absolute;
    top: 2px;
    width: 7px;
    height: 7px;
    background: ${({ color, theme }) => color || theme.colors.brand1};

    &:nth-child(1) {
      left: 3px;
      animation: ${ellipsis1} 0.6s infinite;
    }

    &:nth-child(2) {
      left: 3px;
      animation: ${ellipsis2Inline} 0.6s infinite;
    }

    &:nth-child(3) {
      left: 13px;
      animation: ${ellipsis2Inline} 0.6s infinite;
    }

    &:nth-child(4) {
      left: 22px;
      animation: ${ellipsis3} 0.6s infinite;
    }
  }
`;
