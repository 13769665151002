import styled from 'styled-components';
import { updatedMedia, colors } from 'client/styles';

export const KeyFeaturesBase = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
  gap: 12px;

  > h2 {
    font-weight: 700;
    font-size: 21px;
    line-height: 130%;
    margin-bottom: 4px;
    color: ${colors.grey1};
  }

  ${updatedMedia.tablet} {
    flex-direction: row;
    padding: 40px;
    gap: 20px;
  }

  ${updatedMedia.desktop} {
    margin: 0 auto;
    max-width: 1280px;
  }
`;

export const KeyFeatureBase = styled.div`
  display: flex;
  width: 100%;

  h6 {
    font-size: var(--font-size-3);
    font-weight: var(--font-weight-3);
    letter-spacing: var(--font-letterspacing-0);
    line-height: var(--font-lineheight-2);
    color: var(--color--neutral--800);
    margin-bottom: 8px;
  }

  p {
    font-size: var(--font-size-1);
    font-weight: var(--font-weight-2);
    letter-spacing: var(--font-letterspacing-0);
    line-height: var(--font-lineheight-3);
    color: var(--color--neutral--800);
  }
`;

export const IconWrapper = styled.div`
  > svg {
    fill: ${colors.grey1};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;
