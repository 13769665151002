export const VOUCHER_STATE = 'voucher';

export const FETCH_VOUCHER_REQUEST = 'FETCH_VOUCHER_REQUEST';
export const FETCH_VOUCHER_SUCCESS = 'FETCH_VOUCHER_SUCCESS';
export const FETCH_VOUCHER_FAILURE = 'FETCH_VOUCHER_FAILURE';

export const UPDATE_VOUCHER = 'UPDATE_VOUCHER';
export const REFETCH_VOUCHER = 'REFETCH_VOUCHER';

export const CLEAN_EMAIL_VOUCHER = 'CLEAN_EMAIL_VOUCHER';
export const SAVE_EMAIL_VOUCHER = 'SAVE_EMAIL_VOUCHER';
