import React, { createContext, ReactElement, useMemo } from 'react';

import { MarketType } from 'src/types/market';

import { addDays } from 'date-fns';
import { getMinBookingDate } from 'src/context/search/utils';

const MarketContext = createContext<MarketType | null>(null);

const MarketProvider = ({
  children,
  value,
}: {
  children: ReactElement;
  value: MarketType | null;
}) => {
  if (value === null) {
    throw new Error('MarketContext provider can not have a null value');
  }

  const {
    countryCode,
    countryLanguage,
    countryName,
    contactEmail,
    currency,
    language,
    locale,
    market,
    phone,
    measurementSystem,
    subdomain,
    topdomain,
    helpCenterUrl,
    faqsUrl,
    daysOfBooking,
    defaultBookingDays,
    inanitionDays,
    chatbot,
    overrideCreditCardLogos,
  } = value;

  const currentDate = new Date();

  const minBookingDateObject = getMinBookingDate(
    {
      year: currentDate.getFullYear(),
      month: currentDate.getMonth(),
      date: currentDate.getDate(),
    },
    {
      year: currentDate.getUTCFullYear(),
      month: currentDate.getUTCMonth(),
      date: currentDate.getUTCDate(),
    }
  );

  const {
    year: minBookingYear,
    month: minBookingMonth,
    date: minBookingDay,
  } = minBookingDateObject;

  const minBookingDate = useMemo(
    () => addDays(new Date(minBookingYear, minBookingMonth, minBookingDay), inanitionDays),
    [minBookingDay, minBookingMonth, minBookingYear, inanitionDays]
  );

  const marketValue = useMemo(
    () => ({
      countryCode,
      countryLanguage,
      countryName,
      contactEmail,
      currency,
      language,
      locale,
      market,
      phone,
      measurementSystem,
      subdomain,
      topdomain,
      helpCenterUrl,
      faqsUrl,
      daysOfBooking,
      inanitionDays,
      minBookingDate,
      defaultBookingDays,
      chatbot,
      overrideCreditCardLogos,
    }),
    [
      countryCode,
      countryLanguage,
      countryName,
      contactEmail,
      currency,
      language,
      locale,
      market,
      phone,
      measurementSystem,
      subdomain,
      topdomain,
      helpCenterUrl,
      faqsUrl,
      daysOfBooking,
      inanitionDays,
      minBookingDate,
      defaultBookingDays,
      chatbot,
      overrideCreditCardLogos,
    ]
  );

  return <MarketContext.Provider value={marketValue}>{children}</MarketContext.Provider>;
};

export { MarketContext, MarketProvider };
