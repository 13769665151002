import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import noop from 'lodash/noop';

import IconArrowBack from 'client/icons/ArrowBack';
import IconLocation from 'client/icons/Location';
import Suggester from 'src/effects/Suggester';
import { useDevice } from 'src/hooks/useDevice';
import { useMarket } from 'src/hooks/useMarket';
import { BreakpointSizes } from 'src/types/device';
import { documentBodyFreeze, documentBodyUnfreeze } from 'client/utils';

import {
  ButtonClose,
  LocationSelectorBase,
  LocationField,
  Wrapper,
  Label,
  WrapperContentMobile,
} from './styles';

import messages from './messages';

const LocationSelector = ({ handleSelectLocation, search = '', setIsSuggesterOpen = noop }) => {
  const intl = useIntl();
  const { activeBreakpoint } = useDevice();
  const { market, language } = useMarket();

  const isWide = activeBreakpoint > BreakpointSizes.M;

  const [isOpen, setIsOpen] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [isResetSuggester, setResetSuggester] = useState(false);

  const placeholder = intl.formatMessage(messages.placeholder);

  function resetSearch() {
    handleSelectLocation({});
    setResetSuggester(true);
  }

  function handleSelect(location) {
    handleSelectLocation(location);
    setResetSuggester(false);

    setTimeout(() => {
      setIsOpen(false);
      setIsSuggesterOpen(false);
    }, 0);
  }

  function handleButtonBack(e) {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(false);
    setIsSuggesterOpen(false);
  }

  function openSuggester() {
    setIsOpen(true);
    setIsSuggesterOpen(true);
  }

  useEffect(() => {
    if (isOpen && !isWide) {
      // Scroll to top when opening the suggester on mobile.
      // We need to do this because the suggester is fullscreen
      // and the user might be scrolled down. If we don't scroll
      // to the top, the user will see a blank page.
      window.scrollTo(0, 0);
      documentBodyFreeze();
      return;
    }

    documentBodyUnfreeze();
  }, [isOpen, isWide]);

  return (
    <LocationSelectorBase>
      <LocationField isFocus={isFocus}>
        <Label>
          <IconLocation />
          {isWide ? (
            <Suggester
              handleFocus={setIsFocus}
              handleReset={resetSearch}
              handleSelect={handleSelect}
              language={language}
              market={market}
              placeholder={placeholder}
              search={search}
            />
          ) : (
            <Wrapper data-testid="mobile-overlay" isOpen={isOpen} onClick={openSuggester}>
              <WrapperContentMobile>
                {isOpen && (
                  <ButtonClose as="a" onClick={handleButtonBack}>
                    <IconArrowBack />
                  </ButtonClose>
                )}
                <Suggester
                  fullscreen
                  handleReset={resetSearch}
                  handleSelect={handleSelect}
                  language={language}
                  market={market}
                  placeholder={placeholder}
                  reset={isResetSuggester}
                  search={search}
                  isOpen={isOpen}
                />
              </WrapperContentMobile>
            </Wrapper>
          )}
        </Label>
      </LocationField>
    </LocationSelectorBase>
  );
};

LocationSelector.propTypes = {
  handleSelectLocation: PropTypes.func.isRequired,
  search: PropTypes.string,
  setIsSuggesterOpen: PropTypes.func,
};

export default LocationSelector;
