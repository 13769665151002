import { SEARCH_STATE } from './constants';
import { DEFAULT_SEARCH } from 'src/constants/search';
import { getSearchParamsLocalStorage } from 'lib/storage';
import { sanitizeSearchParams } from 'client/utils';

const storage = getSearchParamsLocalStorage();

const state = {
  [SEARCH_STATE]: sanitizeSearchParams({
    adults: storage.adults || DEFAULT_SEARCH.adults,
    checkIn: storage.checkIn,
    checkOut: storage.checkOut,
    children: storage.children || DEFAULT_SEARCH.children,
    city: storage.city || '',
    country: storage.country || '',
    latitude: storage.latitude || '',
    longitude: storage.longitude || '',
    market: storage.market || '',
    slug: storage.slug || '',
    term: storage.term || '',
    type: storage.type || '',
  }),
};

export default state;
