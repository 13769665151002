import styled, { css } from 'styled-components';

export const LoginButtonBase = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  margin-right: 16px;
`;

export const LoginLink = styled.a`
  display: flex;
  align-items: center;
  padding: 3px 0;
  cursor: pointer;
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.grey6};
  border-radius: 50%;
  width: 24px;
  height: 24px;

  svg {
    color: ${props => props.theme.colors.grey1};
    margin-bottom: 2px;
  }
`;

const isLogged = css`
  color: ${props => props.theme.colors.grey1};
  font-weight: 700;
`;

const isNotLogged = css`
  color: ${props => props.theme.colors.grey3};
`;

export const LoginLabel = styled.p<{ isLogged: boolean; isEbooking: boolean }>`
  padding-left: 8px;
  ${props => (props.isLogged ? isLogged : isNotLogged)}
  ${props =>
    props.isEbooking &&
    css`
      color: var(--color--base--white);
    `}
`;
