import { requestByPOST, requestByGET } from 'lib/requests';
import { mapUserData } from './mappers';

const OPTIONS = {
  headers: { 'Content-Type': 'application/json' },
  timeout: 30000,
  withCredentials: true,
};

const END_POINTS = {
  CUSTOMER_DATA: '/user/details',
  LOGIN_GOOGLE: '/login/google',
  LOGIN: '/login',
  LOGOUT: '/logout',
};

export function loginGoogle({ hostname, data, market, language }) {
  return requestByPOST({
    data: { market, language },
    endpoint: END_POINTS.LOGIN_GOOGLE,
    hostname,
    options: {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${data.token}`,
      },
      timeout: 30000,
      withCredentials: true,
    },
  });
}

export function login(params) {
  return requestByPOST({
    ...params,
    endpoint: END_POINTS.LOGIN,
    options: OPTIONS,
  });
}

export function fetchCustomerData(params) {
  return requestByGET({
    ...params,
    endpoint: END_POINTS.CUSTOMER_DATA,
    mapper: mapUserData,
    options: { ...OPTIONS, headers: { ...OPTIONS.headers, Cookie: params.cookies } },
  });
}

export function logout(params) {
  return requestByPOST({
    ...params,
    endpoint: END_POINTS.LOGOUT,
    options: OPTIONS,
  });
}
