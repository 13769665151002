import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { ErrorSectionBase, ErrorMessage } from './styles';
import messages from './messages';

const ErrorSection = ({ notFound }) => {
  return (
    <ErrorSectionBase>
      <FormattedMessage {...messages.preTitle} tagName="p" />

      <ErrorMessage>
        <FormattedMessage {...(notFound ? messages.notFound : messages.error)} />
      </ErrorMessage>

      <FormattedMessage {...messages.newSearch} tagName="span" />
    </ErrorSectionBase>
  );
};

ErrorSection.propTypes = {
  notFound: PropTypes.bool,
};

export default ErrorSection;
