import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import { BusinessType } from 'src/types/business';

export const getBusinessContextValue = (config: unknown) => {
  const contextValue = pick(config, [
    'mobileRate',
    'affiliateId',
    'utmMedium',
    'promo',
    'rateModel',
  ]) as {
    mobileRate: boolean;
    affiliateId: string;
    utmMedium: string;
    promo: object;
    rateModel?: string;
  };

  if (isEmpty(contextValue)) {
    return null;
  }

  const { mobileRate, affiliateId, utmMedium, promo, rateModel } = contextValue;

  return { mobileRate, affiliate: affiliateId, utmMedium, promo, rateModel } as BusinessType;
};
