import styled from 'styled-components';
import { colors, media } from 'client/styles';

export const ErrorSectionBase = styled.section`
  background-color: ${colors.grey0};
  color: ${colors.white};
  display: flex;
  flex-direction: column;
  height: 250px;
  line-height: 1.4;
  padding: 20px;
  padding-bottom: 30px;
  position: relative;
  text-align: center;
  justify-content: center;

  p {
    font-size: 28px;
    font-weight: 700;
  }

  span {
    font-size: 16px;
    font-weight: 500;
  }

  ${media.tablet} {
    height: 350px;

    p {
      font-size: 51px;
    }
  }

  ${media.desktop} {
    height: 500px;

    p {
      font-size: 51px;
    }

    span {
      margin-bottom: 70px;
    }
  }
`;

export const ErrorMessage = styled.h1`
  font-size: 21px;
  font-weight: 700;
  margin-bottom: 20px;

  ${media.tablet} {
    font-size: 38px;
  }

  ${media.desktop} {
    font-size: 38px;
  }
`;
