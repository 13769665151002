import { REFUND_WALLET_REQUEST, REFUND_WALLET_FAILURE, REFUND_WALLET_SUCCESS } from './constants';

export const fetchRefundWalletRequestAction = payload => ({
  type: REFUND_WALLET_REQUEST,
  payload,
});

export const fetchRefundWalletSuccessAction = payload => ({
  type: REFUND_WALLET_SUCCESS,
  payload,
});

export const fetchRefundWalletFailureAction = payload => ({
  type: REFUND_WALLET_FAILURE,
  payload,
});
