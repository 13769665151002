import {
  PRIME_CANCEL_REQUEST,
  PRIME_CANCEL_SUCCESS,
  PRIME_CANCEL_FAILURE,
  PRIME_REACTIVATE_FAILURE,
  PRIME_REACTIVATE_SUCCESS,
  PRIME_REACTIVATE_REQUEST,
} from './constants';

export const primeCancelRequestAction = payload => ({
  type: PRIME_CANCEL_REQUEST,
  payload,
});

export const primeCancelSuccessAction = payload => ({
  type: PRIME_CANCEL_SUCCESS,
  payload,
});

export const primeCancelFailureAction = payload => ({
  type: PRIME_CANCEL_FAILURE,
  payload,
});

export const primeReactivateRequestAction = payload => ({
  type: PRIME_REACTIVATE_REQUEST,
  payload,
});

export const primeReactivateSuccessAction = payload => ({
  type: PRIME_REACTIVATE_SUCCESS,
  payload,
});

export const primeReactivateFailureAction = payload => ({
  type: PRIME_REACTIVATE_FAILURE,
  payload,
});
