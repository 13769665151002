import { defineMessages } from 'react-intl';

const messages = defineMessages({
  finishBooking: {
    id: 'global.finish-your-booking',
  },
  roomFor: {
    id: 'global.room-for',
  },
  roomsFor: {
    id: 'global.rooms-for',
  },
});

export default messages;
