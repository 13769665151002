import config from 'src/comps/Page/state';
import filters from 'client/components/FiltersContainer/state';
import property from 'client/contexts/Property/state';
import suggester from 'src/effects/Suggester/state';

const state = {
  ...config,
  ...filters,
  ...property,
  ...suggester,
};

export default state;
