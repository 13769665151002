import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useClickAway } from 'react-use';
import { differenceInCalendarDays, differenceInCalendarMonths } from 'date-fns';
import { useDevice } from 'src/hooks/useDevice';
import { useMarket } from 'src/hooks/useMarket';

import IconClose from 'client/icons/Close';
import { documentBodyFreeze, documentBodyUnfreeze, getWeekDays, getWeekStartsOn } from 'client/utils';
import DateIntl from 'src/comps/DateIntl';
import { DATE_INTL_FORMAT_WEEKDAY_MONTH_SHORT_TEXT, formatMappings } from 'src/comps/DateIntl/constants';

import Calendar from './Calendar';
import messages from './messages';
import MobileDates from './MobileDates';

import { ButtonPrimary } from 'src/comps/Button/styles';

import {
  ButtonClose,
  CalendarWrapper,
  DateLine,
  DatePickerBase,
  DatePickerDesktopContainer,
  DatePickerMobileContainer,
  DatesResume,
  Header,
  Ready,
  TopDate,
  TopDates,
  WeekDays
} from './styles';

const DatePicker = ({
  checkIn,
  checkOut,
  initialFocusedInput = 'checkin',
  onChangeDates,
  onClose,
  dataTestId,
  market,
  type,
  currentDate = new Date(),
  unFreezeScroll = false
}) => {
  const intl = useIntl();
  const { device } = useDevice();
  const { minBookingDate } = useMarket();
  const containerElement = useRef();

  const initialSelectedDates = useRef({
    startDate: checkIn,
    endDate: checkOut,
  });

  const initialMonthIndex = differenceInCalendarMonths(checkIn, currentDate);

  const [visibleMonths, setVisibleMonths] = useState([initialMonthIndex, initialMonthIndex + 1]);
  const [selectedDates, setSelectedDates] = useState(initialSelectedDates.current);
  const [dateFocused, setDateFocused] = useState(initialFocusedInput);
  const prevNights = differenceInCalendarDays(checkOut, checkIn);
  const nights = differenceInCalendarDays(selectedDates.endDate, selectedDates.startDate);
  const nightsTranslation = checkOut ? { days: nights || prevNights } : { nights: nights || prevNights || 0 };

  const weekStartsOn = getWeekStartsOn(market);
  const weekDays = getWeekDays(weekStartsOn);

  useEffect(() => {
    if (device !== 'desktop') {
      documentBodyFreeze();
    }
    return () => !unFreezeScroll && documentBodyUnfreeze();
  }, [unFreezeScroll, device]);

  function handleDatesChange({ startDate: start, endDate: end }) {
    setSelectedDates({ startDate: start, endDate: end });
    onChangeDates({ checkIn: start, checkOut: end });
  }

  function handleDatesChangeAndClose({ startDate: start, endDate: end, isCheckIn }) {
    const targetFocus = isCheckIn ? 'checkin' : 'checkout';

    setSelectedDates({ startDate: start, endDate: end });
    setDateFocused(targetFocus);
    onChangeDates({ checkIn: start, checkOut: end });

    if (isCheckIn) {
      if (device === 'desktop') {
        documentBodyFreeze();
      }      
      onClose();
    }
  }

  function resetCalendar() {
    if (device === 'desktop') {
      documentBodyFreeze();
    }
    handleDatesChange(initialSelectedDates.current);
    onClose();
  }

  function navigateMonths(direction) {
    const updatedMonths = visibleMonths.map(month =>
      direction === 'prev' ? month - 1 : month + 1
    );

    setVisibleMonths(updatedMonths);
  }

  function handleFocusInput(input) {
    if (!input) return;
    setDateFocused(input);
  }

  function closeCalendar() {
    if (device === 'desktop') {
      documentBodyFreeze();
    }
    if (selectedDates.startDate && selectedDates.endDate) {
      onChangeDates({ checkIn: selectedDates.startDate, checkOut: selectedDates.endDate });
    }

    onClose();
  }

  useClickAway(containerElement, resetCalendar);

  const checkInFormatted = intl.formatDate(checkIn, formatMappings.DATE_INTL_FORMAT_TWO_DIGITS);
  const checkOutFormatted = intl.formatDate(checkOut, formatMappings.DATE_INTL_FORMAT_TWO_DIGITS);
  const nightsMessage = intl.formatMessage(messages.nights, { nights: nights || prevNights || 0 });

  return (
    <DatePickerBase ref={containerElement} data-testid={dataTestId} type={type}>
      <Header>
        <ButtonClose
          id="button-close-date-picker"
          data-testid="button-close-date-picker"
          onClick={closeCalendar}
        >
          <IconClose />
        </ButtonClose>

        <TopDates>
          <TopDate
            active={dateFocused === 'checkin'}
            onClick={() => handleFocusInput('checkin')}
            data-testid="top-dates-check-in"
            className={dateFocused === 'checkin' ? 'active' : ''}
          >
            <FormattedMessage {...messages.checkIn} />
            <DateLine>
              <DateIntl value={checkIn} format={DATE_INTL_FORMAT_WEEKDAY_MONTH_SHORT_TEXT} />
            </DateLine>
          </TopDate>

          <TopDate
            active={dateFocused === 'checkout'}
            onClick={() => handleFocusInput('checkout')}
            data-testid="top-dates-check-out"
            className={dateFocused === 'checkout' ? 'active' : ''}
          >
            <FormattedMessage {...messages.checkOut} />
            <DateLine>
              {checkOut ? (
                <DateIntl value={checkOut} format={DATE_INTL_FORMAT_WEEKDAY_MONTH_SHORT_TEXT} />
              ) : (
                '-'
              )}
            </DateLine>
          </TopDate>
        </TopDates>
      </Header>
      <WeekDays id="small" data-testid="week-days-small">
        {weekDays.map(day => (
          <li key={day}>{intl.formatDate(day, { weekday: 'narrow' })}</li>
        ))}
      </WeekDays>
      <CalendarWrapper>
        <DatePickerDesktopContainer data-testid="voucher-date-picker">
          {visibleMonths.map((month, index) => (
            <Calendar
              index={month}
              isFocusedCheckIn={dateFocused === 'checkin'}
              key={month}
              onNavigate={navigateMonths}
              onSelect={handleDatesChangeAndClose}
              position={index}
              selectedDates={selectedDates}
              variant="horizontal"
              market={market}
              currentDate={currentDate}
              minBookingDate={minBookingDate}
            />
          ))}
        </DatePickerDesktopContainer>
        <DatePickerMobileContainer data-testid="voucher-date-picker-mobile">
          <MobileDates
            endDate={checkOut}
            focusedInput={dateFocused === 'checkin'}
            setDateFocused={setDateFocused}
            dateFocused={dateFocused}
            startDate={checkIn}
            onDatesSelected={handleDatesChange}
            market={market}
            currentDate={currentDate}
            minBookingDate={minBookingDate}
          />
        </DatePickerMobileContainer>
      </CalendarWrapper>
      <DatesResume>
        {`${checkInFormatted} · ${checkOutFormatted} `}
        <strong>{`(${nightsMessage})`}</strong>
      </DatesResume>
      <Ready>
        <ButtonPrimary
          type="button"
          onClick={closeCalendar}
          disabled={!checkOut}
          data-testid="btn-ready"
        >
          <FormattedMessage
            {...messages[checkOut ? 'ready' : 'nights']}
            values={nightsTranslation}
          />
        </ButtonPrimary>
      </Ready>
    </DatePickerBase>
  );
};

DatePicker.propTypes = {
  checkIn: PropTypes.instanceOf(Date).isRequired,
  checkOut: PropTypes.instanceOf(Date).isRequired,
  dataTestId: PropTypes.string,
  initialFocusedInput: PropTypes.string,
  onChangeDates: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  market: PropTypes.string,
  type: PropTypes.oneOf(['default', 'availability']),
  currentDate: PropTypes.instanceOf(Date),
  unFreezeScroll: PropTypes.bool,
};

export default DatePicker;
