import PropTypes from "prop-types";
import { Component } from "react"
import { report } from "src/utils/clientErrorReporter";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: '' };
  }

  componentDidCatch(error) {
    this.setState({ error: `${error.name}: ${error.message}` });
  }

  render() {
    const { error } = this.state;
    const { fallback } = this.props;

    if (error) {
      report(error)
      return fallback;
    }

    const { children } = this.props;

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element,
  fallback: PropTypes.element,
};

ErrorBoundary.defaultProps = {
  fallback: null,
};

export default ErrorBoundary;
