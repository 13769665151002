import { call, put } from 'redux-saga/effects';

import { displayAlertAction } from 'src/comps/AlertMessage/actions';
import { sendVoucherSuccessAction, sendVoucherFailureAction } from './actions';
import { sendVoucher } from './requests';

export function* sendVoucherSaga(action) {
  try {
    const response = yield call(sendVoucher, action.payload);

    if (response.status >= 400) {
      yield put(displayAlertAction({ messageId: 'errorDefault' }));
      yield put(sendVoucherFailureAction({ error: response.data }));
      return;
    }

    yield put(sendVoucherSuccessAction());
    yield put(displayAlertAction({ messageId: 'successVoucherSent', severity: 'success' }));
  } catch (error) {
    yield put(displayAlertAction({ messageId: 'errorDefault' }));
    yield put(sendVoucherFailureAction({ error: error.message }));
  }
}
