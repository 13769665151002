import {
  FETCH_SUGGESTIONS_REQUEST,
  FETCH_SUGGESTIONS_SUCCESS,
  FETCH_SUGGESTIONS_FAILURE,
  RESET_SUGGESTIONS,
} from './constants';

export const fetchSuggestionsRequestAction = payload => ({
  type: FETCH_SUGGESTIONS_REQUEST,
  payload,
});

export const fetchSuggestionsSuccessAction = payload => ({
  type: FETCH_SUGGESTIONS_SUCCESS,
  payload,
});

export const fetchSuggestionsFailureAction = payload => ({
  type: FETCH_SUGGESTIONS_FAILURE,
  payload,
});

export const resetSuggestionsAction = () => ({
  type: RESET_SUGGESTIONS,
});
