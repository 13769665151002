import { requestByGET, requestByPOST } from 'lib/requests';
import { buildConfirmationPayload, mapConfirmation } from './mappers';
import { mapPaymentMethods } from 'client/contexts/Purchase/mappers';

const OPTIONS = {
  headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
  timeout: 30000,
  withCredentials: true,
};

const END_POINTS = {
  PRIME_CONFIRMATION: '/v1/prime/subscription/confirmation',
  PRIME_PAYMENT_METHODS: '/v1/prime/payment-methods',
};

export function fetchPrimePaymentMethods(params) {
  return requestByGET({
    data: params,
    endpoint: END_POINTS.PRIME_PAYMENT_METHODS,
    mapper: mapPaymentMethods,
    options: { ...OPTIONS, headers: { ...OPTIONS.headers, Cookie: params.cookies } },
  });
}

export function fetchPrimeConfirmation(params) {
  return requestByPOST({
    data: buildConfirmationPayload(params),
    endpoint: END_POINTS.PRIME_CONFIRMATION,
    mapper: mapConfirmation,
    options: OPTIONS,
  });
}
