import { requestByGET, requestByPOST } from 'lib/requests';
import { buildPathParams } from 'lib/utils/url';
import { mapWalletCredit } from './mappers';

const OPTIONS = {
  headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
  timeout: 30000,
  withCredentials: true,
};

export const END_POINTS = {
  APPLIED_WALLET_BY_PURCHASE: '/applied/wallet/{purchaseId}',
  GENERATED_WALLET_BY_PURCHASE: '/generated/wallet/{purchaseId}',
  WALLET_BY_PURCHASE: '/applicable/wallet/{purchaseId}',
  WALLET_PURCHASE_CHECK: '/check/wallet/{purchaseId}',
  WALLET_PURCHASE_UNCHECK: '/uncheck/wallet/{purchaseId}',
};

export function fetchAppliedWalletByPurchaseId(params) {
  return requestByGET({
    hostname: params.hostname,
    options: OPTIONS,
    mapper: mapWalletCredit,
    endpoint: buildPathParams(END_POINTS.APPLIED_WALLET_BY_PURCHASE, {
      purchaseId: params.data.purchaseId,
    }),
  });
}

export function fetchWalletByPurchaseId(params) {
  return requestByGET({
    hostname: params.hostname,
    options: OPTIONS,
    mapper: mapWalletCredit,
    endpoint: buildPathParams(END_POINTS.WALLET_BY_PURCHASE, {
      purchaseId: params.data.purchaseId,
    }),
  });
}

export function checkPurchaseWallet(params) {
  return requestByPOST({
    hostname: params.hostname,
    options: OPTIONS,
    endpoint: buildPathParams(END_POINTS.WALLET_PURCHASE_CHECK, {
      purchaseId: params.data.purchaseId,
    }),
  });
}

export function uncheckPurchaseWallet(params) {
  return requestByPOST({
    hostname: params.hostname,
    options: OPTIONS,
    endpoint: buildPathParams(END_POINTS.WALLET_PURCHASE_UNCHECK, {
      purchaseId: params.data.purchaseId,
    }),
  });
}
