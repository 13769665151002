import {
  CANCEL_BOOKING_REQUEST,
  CANCEL_BOOKING_SUCCESS,
  CANCEL_BOOKING_FAILURE,
  CANCEL_BOOKING_REJECTED,
  STATUS_OK,
  STATUS_REJECTED,
  STATUS_NOT_OK,
} from './constants';
import defaultState from './state';

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case CANCEL_BOOKING_REQUEST: {
      return {
        ...state,
        bookings: {
          ...state.bookings,
          [action.payload.hash]: {
            isFetching: true,
            status: null,
            error: null,
            errorCode: null,
          },
        },
      };
    }

    case CANCEL_BOOKING_SUCCESS: {
      return {
        ...state,
        bookings: {
          ...state.bookings,
          [action.payload.hash]: {
            status: STATUS_OK,
            isFetching: false,
            error: null,
            errorCode: null,
          },
        },
      };
    }

    case CANCEL_BOOKING_REJECTED: {
      return {
        ...state,
        bookings: {
          ...state.bookings,
          [action.payload.hash]: {
            status: STATUS_REJECTED,
            isFetching: false,
            error: action.payload.error,
            errorCode: action.payload.errorCode,
          },
        },
      };
    }

    case CANCEL_BOOKING_FAILURE: {
      return {
        ...state,
        bookings: {
          ...state.bookings,
          [action.payload.hash]: {
            status: STATUS_NOT_OK,
            error: action.payload.error,
            errorCode: action.payload.errorCode,
            isFetching: false,
          },
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
