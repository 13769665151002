import { defineMessages } from 'react-intl';

const messages = defineMessages({
  login: {
    id: 'global.login',
  },
  myAccount: {
    id: 'user.account-section',
  },
});

export default messages;
