import styled from 'styled-components';
import { Paxes, CapacityBase } from 'src/comps/Capacity/styles';
import { colors } from 'client/styles';

export const PurchaseReminderBase = styled.div`
  width: 100%;
`;
export const BookingDetailsSection = styled.div`
  margin-bottom: 16px;
  display: flex;
  img {
    border-radius: 8px;
  }
`;

export const BookinDetailsInformation = styled.div`
  margin-left: 10px;
  font-size: 12px;
  line-height: 17px;

  ${CapacityBase} {
    color: inherit;
    ${Paxes} {
      font-size: 12px;
      line-height: 17px;
    }
  }
`;

export const FinishBookingSection = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 20px;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 8px;
  &:hover {
    background-color: ${colors.grey2};
    color: ${colors.white};
    svg {
      fill: ${colors.white};
    }
  }
`;

export const FinishBookingContent = styled.div`
  margin-right: 10px;
`;
export const FinishBookingCta = styled.div`
  font-weight: 700;
`;
export const FinishBookingPrice = styled.div`
  text-decoration-line: underline;
`;
