import { CONFIG_STATE } from './constants';

const state = {
  [CONFIG_STATE]: {
    affiliateId: 'stayforlong',
    currency: 'EUR',
    device: 'mobile',
    ip: '',
    language: 'es',
    locale: 'es-ES',
    market: 'es',
    pageId: '',
    phone: '',
    mobileRate: false,
    featureFlag: '',
  },
};

export default state;
