import { requestByGET } from 'lib/requests';

import { mapDataToUserWallet } from './mappers';

const OPTIONS = {
  headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
  timeout: 30000,
  withCredentials: true,
};

const END_POINTS = {
  USER_WALLET_AVAILABLE: '/user/wallet/available',
};

export function fetchUserWalletAvailable(params) {
  return requestByGET({
    hostname: params.hostname,
    options: OPTIONS,
    mapper: mapDataToUserWallet,
    endpoint: END_POINTS.USER_WALLET_AVAILABLE,
  });
}
