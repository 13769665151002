import { PROPERTY_STATE } from './constants';

const state = {
  [PROPERTY_STATE]: {
    error: null,
    isFetching: false,
    property: {},
  },
};

export default state;
