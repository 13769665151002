import React, { createContext, ReactElement, useMemo, useEffect, useState } from 'react';

import { DeviceType } from 'src/types/device';
import { getTimezoneOffset, widthToBreakpoint, deviceToBreakpoint } from './helpers';

const DeviceContext = createContext<DeviceType | null>(null);

const DeviceProvider = ({
  children,
  value,
}: {
  children: ReactElement;
  value: DeviceType | null;
}) => {
  if (value === null) {
    throw new Error('DeviceContext provider can not have a null value');
  }

  const { device, ip, metadevice, isBot, activeBreakpoint: initialBreakpoint } = value;

  const [activeBreakpoint, setActiveBreakpoint] = useState(initialBreakpoint ?? deviceToBreakpoint(device));
  const [colorDepth, setColorDepth] = useState<string | null>(null);
  const [javaEnabled] = useState<boolean>(false);
  const [language, setLanguage] = useState<string | null>(null);
  const [screenHeight, setScreenHeight] = useState<number>(0);
  const [screenWidth, setScreenWidth] = useState<number>(0);
  const [timeZone, setTimeZone] = useState<number>(0);

  useEffect(() => {
    const handleWindowResize = () => {
      if (window?.innerWidth) {
        const breakpoint = widthToBreakpoint(window?.innerWidth);
        setActiveBreakpoint(breakpoint);
      }
    };
    window?.addEventListener('resize', handleWindowResize);

    setColorDepth(`${window.screen.colorDepth}`);
    setLanguage(window.navigator.language);
    setScreenHeight(window.screen.height);
    setScreenWidth(window.screen.width);
    setTimeZone(getTimezoneOffset());

    return () => {
      window?.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const deviceContextValue = useMemo(
    () => ({
      device,
      ip,
      metadevice,
      isBot,
      activeBreakpoint,
      colorDepth,
      javaEnabled,
      language,
      screenHeight,
      screenWidth,
      timeZone
    }),
    [device,
      ip,
      metadevice,
      isBot,
      activeBreakpoint,
      colorDepth,
      javaEnabled,
      language,
      screenHeight,
      screenWidth,
      timeZone
    ]
  );

  return <DeviceContext.Provider value={deviceContextValue}>{children}</DeviceContext.Provider>;
};

export { DeviceContext, DeviceProvider };
