import {
  REFUND_CREDIT_CARD_REQUEST,
  REFUND_CREDIT_CARD_FAILURE,
  REFUND_CREDIT_CARD_SUCCESS,
} from './constants';

export const fetchRefundCreditCardRequestAction = payload => ({
  type: REFUND_CREDIT_CARD_REQUEST,
  payload,
});

export const fetchRefundCreditCardSuccessAction = payload => ({
  type: REFUND_CREDIT_CARD_SUCCESS,
  payload,
});

export const fetchRefundCreditCardFailureAction = payload => ({
  type: REFUND_CREDIT_CARD_FAILURE,
  payload,
});
