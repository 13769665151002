import { mapDataSearchToProperty } from 'client/contexts/Property/mappers';
import {
  mapAffiliateParams,
  mapDatesParams,
  mapFilterParams,
  mapMarketParams,
  mapPaginationParams,
  mapPaxesParams,
} from 'src/effects/Search/mappers';

export function buildSearchMapPropertiesPayload(params) {
  const { latitude, longitude, radius, filters, highlightedProperty, currency, minSellingRate } =
    params;

  return {
    latitude,
    longitude,
    distance: radius,
    highlighted: highlightedProperty,
    sortBy: 'price',
    currency,
    minSellingRate,
    ...(params.device && { device: params.device }),
    ...mapDatesParams(params),
    ...mapMarketParams(params),
    ...mapPaxesParams(params),
    ...mapFilterParams(filters),
    ...mapPaginationParams(params),
    ...mapAffiliateParams(params),
  };
}

export function mapDataSearchMapToProperty(data) {
  return mapDataSearchToProperty({ ...data, distance: data.distance_to_center });
}

export function mapDataSearchMapToPropertiesCollection(data = []) {
  return data.map(p => mapDataSearchMapToProperty(p));
}
