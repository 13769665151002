import isArray from './isArray';
import isObject from './isObject';

export default function isEmpty(value) {
  if (isArray(value) && value.length > 0) {
    return false;
  }

  if (isObject(value) && Object.keys(value).length > 0) {
    return false;
  }

  if (typeof value === 'string' && value.length > 0) {
    return false;
  }

  return true;
}
