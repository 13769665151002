import { requestByPOST } from 'lib/requests';

const OPTIONS = {
  headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
  timeout: 30000,
  withCredentials: true,
};

const END_POINTS = {
  PRIME_CANCEL: '/v1/prime/subscription/cancel',
  PRIME_REACTIVATE: '/v1/prime/subscription/restore',
};

export function primeCancel() {
  return requestByPOST({
    endpoint: END_POINTS.PRIME_CANCEL,
    options: OPTIONS,
  });
}

export function primeReactivation() {
  return requestByPOST({
    endpoint: END_POINTS.PRIME_REACTIVATE,
    options: OPTIONS,
  });
}
