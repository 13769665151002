import { FORGOT_PASSWORD_STATE } from './constants';

const state = {
  [FORGOT_PASSWORD_STATE]: {
    error: null,
    isFetching: false,
    accountExist: null,
  },
};

export default state;
