import styled, { keyframes, css } from 'styled-components';
import { colors, media } from 'client/styles';

const fadeInEffect = keyframes`
  0% { opacity: 0  }
  100% { opacity: 1 }
`;

export const NotificationBase = styled.div`
  display: flex;
  animation: ${fadeInEffect} 0.3s linear;
  background-color: ${colors.grey1};
  border-radius: 8px;
  margin-bottom: 20px;
  opacity: 1;
  padding: 12px;
  width: 300px;
  color: ${colors.white};
  svg {
    fill: ${colors.white};
  }
`;

export const NotificationClose = styled.div`
  margin-right: 10px;
  svg {
    cursor: pointer;
  }
`;

export const NotificationContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const reverse = css`
  ${NotificationBase} {
    flex-direction: row-reverse;
    width: 380px;
    ${NotificationClose} {
      margin-right: 0px;
      margin-left: 10px;
    }
  }
`;

export const NotificationsBase = styled.div`
  ${props => props.reverse && reverse}
  display: none;

  ${media.tablet} {
    display: block;
  }
`;
