import { PAGES_LIST_REDIRECT } from 'src/comps/LoginButton/constants';
import { routes } from 'src/constants/routes';
import { NextRouter } from 'next/router';

export const addRedirect = (router: NextRouter, pageId: string): void => {
  if (PAGES_LIST_REDIRECT.includes(pageId)) {
    if (pageId === 'primeCheckout') {
      router.push({ pathname: routes.login.pathname, query: { referrer: 'prime/checkout' } });
    } else {
      router.push({ pathname: routes.home.pathname });
    }
  }
};
