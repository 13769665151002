import { UPDATE_FILTERS, RESET_FILTERS, DEFAULT_FILTERS, FILTERS_STATE } from './constants';
import defaultState from 'client/state';

const filtersState = defaultState[FILTERS_STATE];

const reducer = (state = filtersState, action) => {
  switch (action.type) {
    case UPDATE_FILTERS: {
      const {
        family,
        facilities,
        orderBy,
        stars,
        ratings,
        accommodationTypes,
        minPrice,
        maxPrice,
      } = action.payload;

      return {
        ...state,
        hasFiltered: true,
        family,
        facilities,
        orderBy,
        stars,
        ratings,
        accommodationTypes,
        minPrice,
        maxPrice,
      };
    }

    case RESET_FILTERS: {
      return {
        ...DEFAULT_FILTERS,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
