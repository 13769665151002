import React from 'react';
import createSvgIcon from './createSvgIcon';

export default createSvgIcon(
  <>
    <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm0 18c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8z" />
    <path d="M12 6c-2.2 0-4 1.8-4 4 0 .6.4 1 1 1s1-.4 1-1c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2c-.6 0-1 .4-1 1v1c0 .6.4 1 1 1s1-.4 1-1v-.1c1.7-.4 3-2 3-3.9 0-2.2-1.8-4-4-4zM12 18a1 1 0 100-2 1 1 0 000 2z" />
  </>,
  'Help'
);
