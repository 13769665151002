import { USER_WALLET_REQUEST, USER_WALLET_SUCCESS, USER_WALLET_FAILED } from './constants';

export const fetchUserWalletRequestAction = payload => ({
  type: USER_WALLET_REQUEST,
  payload,
});

export const fetchUserWalletSuccessAction = payload => ({
  type: USER_WALLET_SUCCESS,
  payload,
});

export const fetchUserWalletFailedAction = payload => ({
  type: USER_WALLET_FAILED,
  payload,
});
