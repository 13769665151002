import * as sprite from 'client/icons/LocationTypes';
import iconLocation from 'client/icons/Location';

const LOCATION_TYPES = [
  { name: 'apartment', icon: sprite.iconApartment },
  { name: 'bnb', icon: sprite.iconBnb },
  { name: 'camping', icon: sprite.iconCamping },
  { name: 'city', icon: sprite.iconCity },
  { name: 'country', icon: sprite.iconCountry },
  { name: 'cruise', icon: sprite.iconCruise },
  { name: 'homes', icon: sprite.iconHomes },
  { name: 'hostel', icon: sprite.iconHostel },
  { name: 'hotel', icon: sprite.iconHotel },
  { name: 'houseboat', icon: sprite.iconHouseBoat },
  { name: 'inn', icon: sprite.iconInn },
  { name: 'motel', icon: sprite.iconMotel },
  { name: 'pending' },
  { name: 'point-of-interest', icon: sprite.iconPOI },
  { name: 'residence', icon: sprite.iconResidence },
  { name: 'riad', icon: sprite.iconRiad },
  { name: 'rural', icon: sprite.iconRural },
  { name: 'safari', icon: sprite.iconSafari },
  { name: 'treehouse', icon: sprite.iconTreeHouse },
  { name: 'villa', icon: sprite.iconVilla },
];

export function getSuggestionIcon(type) {
  return LOCATION_TYPES.find(locationType => locationType.name === type)?.icon || iconLocation;
}
