import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { isSameDay, isWithinInterval, differenceInCalendarDays, isBefore } from 'date-fns';

import { getDaysOfBookings } from 'src/constants/business';
import { DefaultDay, EndDate, SelectedDate, StartDate, UnavailableDate } from './styles';

const Day = ({
  day,
  isFocusedCheckIn,
  isVisible = true,
  onSelect,
  selected,
  market,
  minBookingDate,
}) => {
  const { startDate, endDate } = selected;

  const selectDate = date => {
    if (isFocusedCheckIn) {
      onSelect({ startDate: date, endDate: null, isCheckIn: !isFocusedCheckIn });
    } else {
      if (date.getTime() === startDate.getTime()) return;
      onSelect({ startDate, endDate: date, isCheckIn: !isFocusedCheckIn });
    }
  };

  const handleClick = event => {
    event.preventDefault();
    selectDate(day);
  };

  const dayKind = useMemo(() => {
    const isEndDate = isSameDay(day, endDate);
    const isStartDate = isSameDay(day, startDate);
    const isSearchAllowed = differenceInCalendarDays(day, startDate) < getDaysOfBookings(market);

    const isWithinStay =
      endDate &&
      isWithinInterval(day, {
        start: startDate,
        end: endDate,
      });

    if (isStartDate) {
      return 'start';
    }

    if (isEndDate) {
      return 'end';
    }

    if (isWithinStay) {
      return 'selected';
    }

    if (
      isBefore(day, minBookingDate) ||
      (!endDate && isSearchAllowed) ||
      (!isFocusedCheckIn && isSearchAllowed)
    ) {
      return 'unavailable';
    }

    return 'default';
  }, [day, endDate, startDate, market, isFocusedCheckIn, minBookingDate]);

  const props = {
    'aria-hidden': !isVisible,
    children: <span>{day.getDate()}</span>,
    isVisible,
    onClick: handleClick,
    role: 'button',
  };

  if (dayKind === 'start') {
    return (
      <StartDate
        {...props}
        style={{ pointerEvents: `${!isFocusedCheckIn ? 'none' : 'initial'}` }}
        data-testid="checkin"
      />
    );
  }

  if (dayKind === 'end') {
    return <EndDate {...props} data-testid="checkout" />;
  }

  if (dayKind === 'selected') {
    return <SelectedDate {...props} data-testid="inRange" />;
  }

  if (dayKind === 'unavailable') {
    return <UnavailableDate {...props} data-testid="disabled" />;
  }

  return <DefaultDay {...props} data-testid="default-day" />;
};

Day.propTypes = {
  day: PropTypes.instanceOf(Object).isRequired,
  isFocusedCheckIn: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }).isRequired,
  market: PropTypes.string,
  minBookingDate: PropTypes.instanceOf(Date),
};

export default Day;
