import React from 'react';
import PropTypes from 'prop-types';
import { typeAny } from 'client/types';
import { typeConfig } from 'src/comps/Page/types';

import ErrorSection from 'client/components/ErrorSection/ErrorSection';
import Footer from 'client/components/Footer';
import Header from 'client/components/Header';
import KeyFeatures from 'src/comps/KeyFeatures';
import Page from 'src/comps/Page';
import SearchForm from 'client/components/SearchForm';

import { Layout } from 'client/pages/styles';
import { getInitialProps } from 'src/pages/_error/server';

const pageId = 'error';

const ErrorPage = ({ statusCode, config, translations }) => {

  return (
    <Page config={config} translations={translations} pageId={pageId} disableOAuth>
      <Layout>
        <Header pageId={statusCode} />
        <ErrorSection notFound={statusCode === 404} />
        <SearchForm />
        <KeyFeatures />
        <Footer />
      </Layout>
    </Page>
  );
};

ErrorPage.propTypes = {
  config: typeConfig,
  statusCode: PropTypes.number,
  translations: typeAny,
};

ErrorPage.getInitialProps = getInitialProps;

ErrorPage.pageId = pageId;
ErrorPage.pagePathView = '/_error';

export default ErrorPage;
