import { SUGGESTED_PROPERTIES_STATE } from './constants';

const state = {
  [SUGGESTED_PROPERTIES_STATE]: {
    count: 0,
    error: null,
    isFetching: true,
    properties: [],
  },
};

export default state;
