import { defineMessages } from 'react-intl';

const messages = defineMessages({
  login: {
    id: 'global.login',
  },
  myAccount: {
    id: 'user.account-section',
  },
  email: {
    id: 'global.email',
  },
  password: {
    id: 'global.password',
  },
  forgotPassword: {
    id: 'login.forgot-password',
  },
  rememberMe: {
    id: 'login.remember-me',
  },
  alreadyAccount: {
    id: 'login.already-account',
  },
  createAccount: {
    id: 'global.create-account',
  },
});

export default messages;
