import { defineMessages } from 'react-intl';

const messages = defineMessages({
  placeholder: {
    id: 'search.placeholder',
  },
  destination: {
    id: 'global.destination',
  },
});

export default messages;
