import { call, put } from 'redux-saga/effects';

import { fetchUserBookingsFailedAction, fetchUserBookingsSuccessAction } from './actions';
import { getUserBookings, fetchPropertiesFromBookings } from './requests';
import { mapFromDataToUserBookingList } from './mappers';

export function* getUserBookingsSaga(action) {
  try {
    const bookingResponse = yield call(getUserBookings, action.payload);
    const allPropertiesIds = bookingResponse.data.map(i => i.idProperty);
    const propertiesIds = [...new Set(allPropertiesIds)];
    const propertiesResponse = yield call(fetchPropertiesFromBookings, {
      propertiesIds,
      language: action.payload.language,
      hostname: action.payload.hostname,
    });
    const bookingList = mapFromDataToUserBookingList(bookingResponse.data, propertiesResponse.data);

    yield put(
      fetchUserBookingsSuccessAction({
        userBookings: bookingList,
      })
    );
  } catch (error) {
    yield put(
      fetchUserBookingsFailedAction({
        error: error.message,
      })
    );
  }
}
