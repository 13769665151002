import styled from 'styled-components';
import { colors } from 'client/styles';

export const LogoLink = styled.a`
  display: flex;
  align-items: center;

  svg#arrow-back {
    fill: ${props => (props.isEbooking ? colors.white : colors.magenta)};
    margin-right: 0.5em;
  }

  svg#brand {
    height: 37px;
    width: 138px;
    vertical-align: middle;
  }
`;
