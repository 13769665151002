import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { LAYOUT_COVER } from 'src/comps/Image/constants';
import { DATE_INTL_FORMAT_DAY_MONTH_SHORT_TEXT } from 'src/comps/DateIntl/constants';
import { renderSrc } from 'src/helpers/imageHandler';

import DateIntl from 'src/comps/DateIntl';
import IconArrowRight from 'client/icons/ChevronRight';
import Image from 'src/comps/Image';

import {
  BookinDetailsInformation,
  BookingDetailsSection,
  FinishBookingContent,
  FinishBookingCta,
  FinishBookingPrice,
  FinishBookingSection,
  PurchaseReminderBase,
} from './styles';
import messages from './messages';

const PurchaseReminder = ({ name, photo, checkIn, checkOut, onClick }) => {
  const photoURL = photo ? renderSrc({ imgObj: photo, width: 580 }) : '';

  return (
    <PurchaseReminderBase data-testid="purchase-reminder">
      <BookingDetailsSection>
        <Image alt={name} src={photoURL} width={32} height={32} layout={LAYOUT_COVER} />
        <BookinDetailsInformation>
          <p>{name}</p>
          <p>
            <DateIntl value={checkIn} format={DATE_INTL_FORMAT_DAY_MONTH_SHORT_TEXT} />
            {' - '}
            <DateIntl value={checkOut} format={DATE_INTL_FORMAT_DAY_MONTH_SHORT_TEXT} />
          </p>
        </BookinDetailsInformation>
      </BookingDetailsSection>

      <FinishBookingSection data-testid="purchase-reminder-link" onClick={onClick}>
        <FinishBookingContent>
          <FinishBookingCta>
            <FormattedMessage {...messages.finishBooking} />
          </FinishBookingCta>
          <FinishBookingPrice>
            <FormattedMessage id="reserve.now-cta" />
          </FinishBookingPrice>
        </FinishBookingContent>

        <IconArrowRight />
      </FinishBookingSection>
    </PurchaseReminderBase>
  );
};

PurchaseReminder.propTypes = {
  name: PropTypes.string,
  photo: PropTypes.object,
  checkIn: PropTypes.string,
  checkOut: PropTypes.string,
  onClick: PropTypes.func,
};

export default PurchaseReminder;
