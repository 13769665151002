import { requestByGET, requestByPOST, requestByPATCH } from 'lib/requests';
import { buildPathParams } from 'lib/utils/url';
import {
  buildCreateUserRequest,
  buildUpdateUserRequest,
  buildChangePasswordRequest,
  mapCreditCard,
} from './mappers';

const OPTIONS = {
  headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
  timeout: 30000,
  withCredentials: true,
};

const END_POINTS = {
  USERS: '/users',
  CREDIT_CARD: '/v2/user/credit-card/{market}',
};

export function createUser(params) {
  return requestByPOST({
    data: buildCreateUserRequest(params.data),
    endpoint: END_POINTS.USERS,
    hostname: params.hostname,
    options: OPTIONS,
  });
}

export function updateUser(params) {
  return requestByPATCH({
    data: buildUpdateUserRequest(params.data),
    endpoint: END_POINTS.USERS,
    hostname: params.hostname,
    options: OPTIONS,
  });
}

export function changePassword(params) {
  return requestByPATCH({
    data: buildChangePasswordRequest(params.data),
    endpoint: END_POINTS.USERS,
    hostname: params.hostname,
    options: OPTIONS,
  });
}

export function fetchCustomerCreditCard(params) {
  return requestByGET({
    endpoint: buildPathParams(END_POINTS.CREDIT_CARD, params),
    hostname: params.hostname,
    mapper: mapCreditCard,
    options: OPTIONS,
  });
}
