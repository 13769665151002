import {
  PAYMENT_REQUEST,
  PAYMENT_SUCCESS,
  PAYMENT_FAILURE,
  CONFIRMATION_REQUEST,
  CONFIRMATION_SUCCESS,
  CONFIRMATION_SUCCESS_3DS,
  CONFIRMATION_FAILURE,
  RESET_PAYMENT,
} from './constants';

export const paymentRequestAction = payload => ({
  type: PAYMENT_REQUEST,
  payload,
});

export const paymentSuccessAction = payload => ({
  type: PAYMENT_SUCCESS,
  payload,
});

export const paymentFailureAction = payload => ({
  type: PAYMENT_FAILURE,
  payload,
});

export const confirmationRequestAction = payload => ({
  type: CONFIRMATION_REQUEST,
  payload,
});

export const confirmationSuccessAction = payload => ({
  type: CONFIRMATION_SUCCESS,
  payload,
});

export const confirmationSuccess3DSAction = payload => ({
  type: CONFIRMATION_SUCCESS_3DS,
  payload,
});

export const confirmationFailureAction = payload => ({
  type: CONFIRMATION_FAILURE,
  payload,
});

export const resetPaymentAction = payload => ({
  type: RESET_PAYMENT,
  payload,
});
