import React, { ReactNode } from 'react';

export const EBOOKING_URL = 'https://www.ebooking.com';

type EbookingLinkProps = {
  readonly children: ReactNode;
};

export function EbookingLink({ children }: EbookingLinkProps) {
  return (
    <a data-testid="ebooking-link" href={EBOOKING_URL}>
      {children}
    </a>
  );
}
