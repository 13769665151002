import React from 'react';
import PropTypes from 'prop-types';

import IconStar from 'client/icons/Star';
import { MIN_RATING, MAX_RATING } from './constants';
import { StarsBase, Item } from './styles';

const Stars = ({ color = '', stars, size = 10 }) => {
  if (!stars || stars < MIN_RATING || stars > MAX_RATING) {
    return null;
  }

  const starsList = [...Array(stars).keys()];
  return (
    <StarsBase data-testid="stars">
      {starsList.map(star => (
        <Item key={star} data-testid="star" color={color} size={size}>
          <IconStar />
        </Item>
      ))}
    </StarsBase>
  );
};

Stars.propTypes = {
  color: PropTypes.string,
  stars: PropTypes.number.isRequired,
  size: PropTypes.number,
};

export default Stars;
