import { PURCHASE_STATE } from './constants';
import { getLocalStorage } from 'lib/storage';

const defaultState = {
  name: '',
  last: '',
  email: '',
  phone: '',
  holders: [],
  errors: [],
  insuranceIds: [],
  subscribeNewsletter: false,
  isMandatory: null,
};

const storedState = getLocalStorage(PURCHASE_STATE);

const state = {
  [PURCHASE_STATE]: {
    ...defaultState,
    ...storedState,
    errors: [],
    subscribeNewsletter: false,
  },
};

export default state;
