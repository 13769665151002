import qs from 'query-string';
import { DOMAIN } from 'src/constants/environment';
import { routes } from 'src/constants/routes';
import isValidArray from 'lib/functions/isValidArray';

export function queryStringify(query) {
  const { adults, children, ...rest } = query;

  if (!isValidArray(adults)) {
    return rest;
  }

  if (!isValidArray(children) || children?.flat()?.length === 0) {
    return { adults, ...rest };
  }

  return {
    adults: adults.join(),
    children: children.map(item => item.join()).join('!'),
    ...rest,
  };
}

export function buildMarketLanguageQueryString({ market, language } = {}) {
  if (!market || !language) {
    return '';
  }

  return `?market=${market}&lang=${language}`;
}

export function buildCanonicalURL({ pathname = '', market, language, subdomain, topdomain }) {
  const subDomain = subdomain ?? 'www';
  const topDomain = topdomain ?? 'com';

  const queryParams = subdomain || topdomain ? '' : `?market=${market}&lang=${language}`;

  return `https://${subDomain}.${DOMAIN}.${topDomain}${pathname}${queryParams}`;
}

export function buildBaseURL({ subdomain, topdomain }) {
  const subDomain = subdomain ?? 'www';
  const topDomain = topdomain ?? 'com';

  return `https://${subDomain}.${DOMAIN}.${topDomain}`;
}

export function buildLocation({
  page = 'home',
  path: { city, country, slug, type, purchaseId } = {},
  query = '',
  isDetail = false,
  isSameLocation = false,
} = {}) {
  const queryParams = queryStringify(query);
  const queryString = Object.keys(queryParams).length > 0 ? queryParams : '';
  const queryMarketLanguage = buildMarketLanguageQueryString(query);

  if (isDetail && isSameLocation && country && city) {
    return {
      as: `/${country}/city/${city}`,
      href: { pathname: routes.list.pathname, query: queryString },
      passHref: true,
    };
  }

  if (page === routes.detail.page && country && slug && city) {
    return {
      as: `/hotel/${country}/${slug}_${city}${queryMarketLanguage}`,
      href: { pathname: routes.detail.pathname, query: queryString },
      passHref: true,
    };
  }

  if (page === routes.propertyMap.page && country && slug && city) {
    return {
      as: `/map/hotel/${country}/${slug}_${city}`,
      href: { pathname: routes.propertyMap.pathname, query: queryString },
      passHref: true,
    };
  }

  if (page === routes.map.page && country && type && slug) {
    const newQueryString =
      Object.keys(queryParams).length > 0 ? `?${qs.stringify(queryParams)}` : '';
    return {
      as: `/map/${country}/${type}/${slug}${newQueryString}`,
      href: { pathname: routes.map.pathname, query: queryString },
      passHref: true,
    };
  }

  if (page === routes.list.page && country && type && slug) {
    return {
      as: `/${country}/${type}/${slug}`,
      href: { pathname: routes.list.pathname, query: queryString },
      passHref: true,
    };
  }

  if (page === routes.search.page) {
    return {
      as: `/search`,
      href: { pathname: routes.search.pathname, query: queryString },
      passHref: true,
    };
  }

  if (page === routes.myBookings.page) {
    return {
      as: `/user-profile/my-bookings`,
      href: { pathname: routes.myBookings.pathname },
      passHref: true,
    };
  }

  if (page === routes.primeLanding.page) {
    return {
      as: `/prime/landing`,
      href: { pathname: routes.primeLanding.pathname },
      passHref: true,
    };
  }

  if (page === routes.prime.page) {
    return {
      as: `/user-profile/prime`,
      href: { pathname: routes.prime.pathname },
      passHref: true,
    };
  }

  if (page === routes.primeReceipts.page) {
    return {
      as: `/user-profile/prime/receipts`,
      href: { pathname: routes.primeReceipts.pathname },
      passHref: true,
    };
  }

  if (page === routes.primeCancel.page) {
    return {
      as: `/user-profile/prime/cancel`,
      href: { pathname: routes.primeCancel.pathname },
      passHref: true,
    };
  }

  if (page === routes.primeCancelled.page) {
    return {
      as: `/user-profile/prime/cancelled`,
      href: { pathname: routes.primeCancel.pathname },
      passHref: true,
    };
  }

  if (page === routes.hotelsSeen.page) {
    return {
      as: `/user-profile/hotels-seen`,
      href: { pathname: routes.hotelsSeen.pathname },
      passHref: true,
    };
  }

  if (page === routes.myDetails.page) {
    return {
      as: `/user-profile/my-details`,
      href: { pathname: routes.myDetails.pathname },
      passHref: true,
    };
  }

  if (page === routes.myStaywallet.page) {
    return {
      as: `/user-profile/staywallet`,
      href: { pathname: routes.myStaywallet.pathname },
      passHref: true,
    };
  }

  if (page === routes.myCommunicationPreferences.page) {
    return {
      as: `/user-profile/communication-preferences`,
      href: { pathname: routes.myCommunicationPreferences.pathname },
      passHref: true,
    };
  }

  if (page === routes.voucher.page && purchaseId) {
    return {
      as: `/booking/voucher/${purchaseId}`,
      href: { pathname: routes.voucher.pathname },
      passHref: true,
    };
  }

  return {
    as: '/',
    href: { pathname: routes.home.pathname, query: queryString },
    passHref: true,
  };
}

export function getBodyId(pathname) {
  const route = Object.values(routes).find(r => r.pathname === pathname);

  if (pathname === routes.checkout.pathname) {
    return 'checkout_step_one';
  }

  if (pathname === routes.payment.pathname) {
    return 'checkout_step_two';
  }

  if (pathname === routes.thanks.pathname) {
    return 'booking_thanks';
  }

  return route?.page || '';
}

export function updateBodyId(pageId) {
  if (document.body.id !== pageId) {
    document.body.id = getBodyId(routes[pageId].pathname);
  }
}

export function getLinkPropsFromUrl(url) {
  const { pathname, search } = new URL(url);
  const urlParts = pathname.split('/');
  const propertySlug = urlParts[urlParts.length - 1];

  const [slug, city] = propertySlug.split('_');
  const country = urlParts[urlParts.length - 2];

  return { country, slug, city, ...(search && { search: qs.parse(search) }) };
}
