import { requestByPOST } from 'lib/requests';
import { buildCancelBookingRequest } from './mappers';

const END_POINTS = {
  CANCEL_BOOKING: '/booking/v3/cancellation',
};

export function cancelBooking(params) {
  return requestByPOST({
    data: buildCancelBookingRequest(params.data),
    endpoint: END_POINTS.CANCEL_BOOKING,
  });
}
