export function mapFromChildrenAgesToData(childrenAges) {
  return childrenAges.map(item => item.value).join();
}

export const buildModifyDatesRequest = ({
  adults,
  checkin,
  checkout,
  children,
  childrenAges = [],
  rooms = 1,
}) => {
  const paxesObject = {};
  for (let i = 0; i < rooms; i += 1) {
    paxesObject[`adults[${i}]`] = adults;
    paxesObject[`children_ages[${i}]`] = childrenAges.length > 0 ? mapFromChildrenAgesToData(childrenAges) : null;
    paxesObject[`children[${i}]`] = children;
  }

  return {
    ...paxesObject,
    checkin,
    checkout,
  };
};

export function mapConfirmation(data) {
  return {
    error: data.error || null,
    errorCode: data.error_code || null,
  };
}

export function mapDataToBookingRules(data) {
  return Object.keys(data).map(key => ({
    adults: data[key].adults,
    allotment: data[key].allotment,
    availabilityId: key,
    avgRate: data[key].avg_rate,
    bigHeadRate: data[key].bighead_rate,
    boardName: data[key].board_name,
    cancellation: data[key].cancellation_policies,
    children: data[key].children,
    childrenAges: data[key].children_ages || '',
    code: data[key].board_code,
    currency: data[key].currency,
    paymentPolicy: data[key].payment_policy,
    payRate: data[key].pay_rate,
    roomName: data[key].room_name,
    roomsNumber: data[key].rooms,
    sellingRate: data[key].selling_rate,
  }));
}

export function mapDataToAvailabilities(data) {
  if (!data) {
    return {};
  }

  return {
    bookingRules: mapDataToBookingRules(data.booking_rules) || [],
  };
}
