/* eslint-disable camelcase */
interface IPaymentAPMRaw {
  paycomet_klarna?: string; // Paycomet klarna
  paycomet_giropay?: string; // Paycomet giropay
  paycomet_ideal?: string; // Paycomet ideal
  paycomet_multibanco?: string; // Paycomet multibanco
  paycomet_bancontact?: string; // Paycomet bancontact
}

interface IPaymentMethodOgoneRaw extends IPaymentAPMRaw {
  // Ogone
  flexcheckout: {
    payment_url: string;
  };
}

interface IPaymentMethodWordlineRaw extends IPaymentAPMRaw {
  worldline_direct: {
    payment_url: string;
  };
}
interface IPaymentMethodPaycometRaw extends IPaymentAPMRaw {
  paycomet: {
    jetID: string;
  };
}

export type PaymentMethodRaw = IPaymentMethodOgoneRaw &
  IPaymentMethodWordlineRaw &
  IPaymentMethodPaycometRaw;

export enum PaymentMethodEnum {
  Ogone = 'ogone',
  Worldline = 'worldline_direct',
  Paycomet = 'paycomet',
}

enum PaycometKindEnum {
  Klarna = 'klarna',
  Giropay = 'giropay',
  Ideal = 'ideal',
  Multibanco = 'multibanco',
  Bancontact = 'bancontact',
}

enum PaycometValuesEnum {
  Klarna = 'paycomet_klarna',
  Giropay = 'paycomet_giropay',
  Ideal = 'paycomet_ideal',
  Multibanco = 'paycomet_multibanco',
  Bancontact = 'paycomet_bancontact',
}

interface IAPM {
  kind: PaycometKindEnum;
  value: PaycometValuesEnum;
}

interface IPaymentMethodOgone {
  kind: PaymentMethodEnum.Ogone;
  creditCard: {
    url: string;
  };
  apm?: IAPM[];
}

interface IPaymentMethodWorldline {
  kind: PaymentMethodEnum.Worldline;
  creditCard: {
    url: string;
  };
  apm?: IAPM[];
}

interface IPaymentMethodPaycomet {
  kind: PaymentMethodEnum.Paycomet;
  creditCard: {
    clientId: string;
  };
  apm?: IAPM[];
}

export function mapAPMs(data: IPaymentAPMRaw): IAPM[] {
  const apm = [];
  if (data.paycomet_klarna) {
    apm.push({ kind: PaycometKindEnum.Klarna, value: PaycometValuesEnum.Klarna });
  }
  if (data.paycomet_giropay) {
    apm.push({ kind: PaycometKindEnum.Giropay, value: PaycometValuesEnum.Giropay });
  }
  if (data.paycomet_ideal) {
    apm.push({ kind: PaycometKindEnum.Ideal, value: PaycometValuesEnum.Ideal });
  }
  if (data.paycomet_multibanco) {
    apm.push({ kind: PaycometKindEnum.Multibanco, value: PaycometValuesEnum.Multibanco });
  }
  if (data.paycomet_bancontact) {
    apm.push({ kind: PaycometKindEnum.Bancontact, value: PaycometValuesEnum.Bancontact });
  }
  return apm;
}

function mapOgone(data: IPaymentMethodOgoneRaw): IPaymentMethodOgone {
  return {
    kind: PaymentMethodEnum.Ogone,
    creditCard: {
      url: data.flexcheckout.payment_url,
    },
    apm: mapAPMs(data),
  };
}

function mapWorldline(data: IPaymentMethodWordlineRaw): IPaymentMethodWorldline {
  return {
    kind: PaymentMethodEnum.Worldline,
    creditCard: {
      url: data.worldline_direct.payment_url,
    },
    apm: mapAPMs(data),
  };
}

function mapPaycomet(data: IPaymentMethodPaycometRaw): IPaymentMethodPaycomet {
  return {
    kind: PaymentMethodEnum.Paycomet,
    creditCard: {
      clientId: data.paycomet.jetID,
    },
    apm: mapAPMs(data),
  };
}

export type PaymentMethods = IPaymentMethodOgone | IPaymentMethodWorldline | IPaymentMethodPaycomet;

export function mapPaymentMethods(data: PaymentMethodRaw): PaymentMethods {
  if (data.flexcheckout?.payment_url) {
    return mapOgone(data);
  }
  if (data.worldline_direct?.payment_url) {
    return mapWorldline(data);
  }
  if (data.paycomet?.jetID) {
    return mapPaycomet(data);
  }

  throw new Error('Invalid payment method');
}
