import { requestByPOST } from 'lib/requests';
import { buildPathParams } from 'lib/utils/url';

const END_POINTS = {
  PAY_NOW_VOUCHER: '/voucher/pay/{purchaseId}',
};

export function payNowVoucher(params) {
  const {
    browserColorDepth,
    browserJavaEnabled,
    browserLanguage,
    browserScreenHeight,
    browserScreenWidth,
    browserTimeZone,
  } = params;
  return requestByPOST({
    data: {
      amount: params.rawAmount,
      hash: params.bookingHash,
      feature: true,
      browserColorDepth,
      browserJavaEnabled,
      browserLanguage,
      browserScreenHeight,
      browserScreenWidth,
      browserTimeZone,
    },
    endpoint: buildPathParams(END_POINTS.PAY_NOW_VOUCHER, params),
  });
}
