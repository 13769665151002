import * as CookieServer from 'lib/cookies';
import cloneDeep from 'lodash/cloneDeep';

import isArray from 'lib/functions/isArray';
import isEmpty from 'lib/functions/isEmpty';

import { addDays, addMonths, differenceInCalendarMonths, isSameMonth, startOfWeek } from 'date-fns';

import { COOKIE_LANGUAGE, COOKIE_EXP_30D } from 'src/constants/cookies';

import { DEFAULT_SEARCH } from 'src/constants/search';

import { getCookieDomain } from 'lib/utils/url';
import { getValidDates, checkDates, formatISO } from 'lib/dates';

export function saveCookies({ req, res, language }) {
  const cookies = [];
  const options = { path: '/', maxAge: COOKIE_EXP_30D }; // 30 days in seconds

  const { host } = req.headers;
  const domain = getCookieDomain(host);

  /* Save cookie language */
  cookies.push(CookieServer.serialize(COOKIE_LANGUAGE, language, { ...options, domain }));

  return res.setHeader('Set-Cookie', cookies);
}

export const ANONYMOUS_USER = {
  data: null,
  error: null,
  isFetching: false,
  isLoggedIn: false,
  status: 'resolved',
};

export const checkArrayOfNumbers = array =>
  !array.some(i => isArray(i) || !Number.isInteger(Number(i)));

const checkAdults = adults => isArray(adults) && checkArrayOfNumbers(adults);

const checkChildren = children =>
  isArray(children) &&
  !isEmpty(children) &&
  !children.some(c => !isArray(c) && !checkArrayOfNumbers(c));

export const sanitizeSearchParams = ({ checkIn, checkOut, adults, children, market, ...rest }) => {
  const sanitizedSearchParams = cloneDeep(DEFAULT_SEARCH);

  if (checkDates(checkIn, checkOut, market)) {
    sanitizedSearchParams.checkIn = checkIn;
    sanitizedSearchParams.checkOut = checkOut;
  }

  if (checkAdults(adults) && checkChildren(children)) {
    if (adults.length > children.length) {
      const emptyChildrenRooms = new Array(adults.length - children.length).fill([]);
      children.push(...emptyChildrenRooms);
    }

    sanitizedSearchParams.adults = adults;
    sanitizedSearchParams.children = children;
  }

  return { ...rest, ...sanitizedSearchParams };
};

export function getWeekDays(weekStartsOn) {
  const startDate = startOfWeek(new Date(), { weekStartsOn });
  return Array.from({ length: 7 }).map((_, index) => addDays(startDate, index));
}

export function getMonthMatrix(year, month, weekStartsOn = 1) {
  const date = new Date(year, month, 1);
  const startDate = startOfWeek(date, { weekStartsOn });
  const rows = 6;
  const cols = 7;
  const length = rows * cols;

  return Array.from({ length })
    .map((_, index) => addDays(startDate, index))
    .reduce(
      (matrix, current, index, days) =>
        !(index % cols !== 0) ? [...matrix, days.slice(index, index + cols)] : matrix,
      []
    );
}

export function getMonthsToRender(checkin, today = new Date()) {
  const totalMonths = differenceInCalendarMonths(checkin, today) + 3;

  return Array.from({ length: totalMonths }, (_, index) => {
    const currentMonth = addMonths(today, index);
    return {
      date: currentMonth,
      isCheckinMonth: isSameMonth(currentMonth, checkin),
    };
  });
}

export function scrollToTop() {
  if (typeof window === 'undefined' || typeof document === 'undefined') {
    return;
  }

  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}

export function documentBodyFreeze() {
  if (typeof window === 'undefined' || typeof document === 'undefined') {
    return;
  }

  document.body.style.top = `-${window.scrollY}px`;
  document.body.style.position = 'fixed';
}

export function documentBodyUnfreeze() {
  if (typeof window === 'undefined' || typeof document === 'undefined') {
    return;
  }

  const scrollY = document.body.style.top;

  document.body.style.position = '';
  document.body.style.top = '';
  window.scrollTo({ top: parseInt(scrollY || '0') * -1, behavior: 'instant' });
}

export function getPaxesfromUrlParams({ adults, children = '' }) {
  const parsedAdults = (isArray(adults) ? adults : adults.split(',')).map(a => parseInt(a));

  if (children === '')
    return {
      adults: parsedAdults,
      children: Array.isArray(parsedAdults) ? parsedAdults.map(() => []) : [],
    };

  return {
    adults: parsedAdults,
    children: isArray(children)
      ? children
      : children
          .split('!')
          .map(i => i.split(',').map(j => (j ? parseInt(j) : null)))
          .map(k => (k[0] === null || Number.isNaN(k[0]) ? [] : k)),
  };
}

export function getSearchParamsFromQuery({
  adults,
  checkin,
  checkout,
  children,
  hotelId,
  market,
  page,
  relativeInDate,
  relativeOutDate,
}) {
  const paxes = {};
  const dates = getValidDates({
    checkIn: checkin,
    checkOut: checkout,
    daysForCheckIn: relativeInDate,
    daysForCheckOut: relativeOutDate,
    market,
  });

  if (adults) {
    const { adults: adultsParam, children: childrenParam } = getPaxesfromUrlParams({
      adults,
      children,
    });

    paxes.adults = adultsParam;
    paxes.children = childrenParam;
  }

  let propertyId = null;
  if (hotelId) {
    const id = parseInt(hotelId, 10);
    propertyId = Number.isNaN(id) ? null : { hotelId: id };
  }

  return {
    ...dates,
    ...paxes,
    ...propertyId,
    ...(page && { page }),
  };
}

export function isCancellable(cancellations) {
  const today = new Date();
  const todayFormatted = formatISO(today);

  const isNRF = cancellations.length === 0;
  const isExpired = cancellations[0]?.cancellationDate < todayFormatted;

  return !isNRF && !isExpired;
}

export function getWeekStartsOn(market) {
  const startsOnSundayMarkets = ['us', 'ca'];
  return startsOnSundayMarkets.includes(market) ? 0 : 1;
}
