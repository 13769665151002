import {
  PRIME_RECEIPTS_FAILURE,
  PRIME_RECEIPTS_REQUEST,
  PRIME_RECEIPTS_SUCCESS,
  PRIME_RECEIPTS_STATE,
} from './constants';
import stateBase from './state';

const initialState = stateBase[PRIME_RECEIPTS_STATE];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PRIME_RECEIPTS_REQUEST: {
      return {
        ...state,
        error: null,
        isFetching: true,
        data: [],
      };
    }

    case PRIME_RECEIPTS_SUCCESS: {
      return {
        ...state,
        error: null,
        isFetching: false,
        data: action.payload || [],
      };
    }

    case PRIME_RECEIPTS_FAILURE: {
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
