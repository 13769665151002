// OPTIN-1
export const SUBSCRIPTION_CHECKOUT_PAGE = 'check-out1_page'; // Subscription from Checkout page
export const SUBSCRIPTION_STAYWALLET_PAGE = 'staywallet'; // Subscription from staywallet landing
export const SUBSCRIPTION_BOX_HOME_PAGE = 'subscription_box-home'; // Subscription from footer box at home
export const SUBSCRIPTION_BOX_HOTEL_PAGE = 'subscription_box-hotel_page'; // Subscription from footer box at hotel detail
export const SUBSCRIPTION_BOX_RESULT_LIST_PAGE = 'subscription_box-result_list'; // Subscription from footer box at list
export const SUBSCRIPTION_BOX_BLACK_FRIDAY_PAGE = 'subscription_box-black-friday'; // Subscription from footer box at BF page
export const SUBSCRIPTION_POP_UP_PRODUCT_PAGE = 'subscription_pop_up-product_page_1'; // Subscription modal on product page
export const SUBSCRIPTION_BOX_REVIEWS_PAGE = 'subscription_box-reviews'; // Subscription from footer box at reviews
export const SUBSCRIPTION_BOX_LANDINGS_PAGE = 'subscription_box-landings'; // Subscription from footer box at landings

// OPTIN-2
export const SUBSCRIPTION_OPTIN_2_EMAIL = 'optin2_email'; // Subscription confirmed on optin2 email

// UNSUBCRIBE
export const SUBSCRIPTION_EMAIL = 'email'; // User clics on unsubscribe button of any newsletter

// OPTIN-1 && UNSUBCRIBE
export const SUBSCRIPTION_PROFILE_PAGE = 'profile'; // Subscription from user profile && User unsubscribes on the private area from marketing communications
