import {
  FETCH_PROPERTY_REQUEST,
  FETCH_PROPERTY_SUCCESS,
  FETCH_PROPERTY_FAILURE,
  CLEAR_PROPERTY,
} from './constants';

export const fetchPropertyRequestAction = payload => ({
  type: FETCH_PROPERTY_REQUEST,
  payload,
});

export const fetchPropertySuccessAction = payload => ({
  type: FETCH_PROPERTY_SUCCESS,
  payload,
});

export const fetchPropertyFailureAction = payload => ({
  type: FETCH_PROPERTY_FAILURE,
  payload,
});

export const clearPropertyAction = (payload) => ({
  type: CLEAR_PROPERTY,
  payload,
})
