/* eslint-disable import/no-extraneous-dependencies */

import { useState, useEffect } from 'react';

export const createMockServer = async () => {
  if (process.env.ENABLE_MOCK_SERVER) {
    const { setupWorker } = await import('msw');
    const { handlers } = await import('mocks/handlers');
    
    const worker = setupWorker(...handlers);
    await  worker.start();
  }
}

export const getIsServer = () => typeof window === 'undefined';

// The options argument is only there for the sole purpose of testing the server
// branching logic
export default function useMockServer({ isServer = getIsServer() } = {}) {
  const enable = process.env.ENABLE_MOCK_SERVER
  // While in the server we always want to say is ready that way SSR can happen
  // so we don't block the rendering process.
  // In the other hand if we enable the mock server we want to wait to be ready 
  // so we don't run into any race conditions.
  const initialMocksServerReadyState = isServer ? true : !enable

  const [ready, setReady] = useState(initialMocksServerReadyState)

  useEffect(() => {
    if (isServer) return;
    if (!enable) return;

    createMockServer()
      .then(() => setReady(true));
  }, [isServer, enable])

  return {
    ready
  }
}
