import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useMedia } from 'react-use';

import { getSearch, getDates, getSearchPaxes } from 'src/effects/Search/selectors';
import { PageContext } from 'src/comps/Page/context';

import DateSelector from 'client/components/DateSelector';
import InputReadOnly from 'client/components/InputReadOnly';
import Loader from 'src/comps/Atom/Loader';
import Modal from 'client/components/Modal';

import { RoomsUpdaterBase, Form, Paxes } from './styles';
import { updatedBreakpoint } from 'client/styles';
import messages from './messages';
import { useKafkaEvent } from 'src/hooks/useKafkaEvent';
import { SECTIONS } from 'src/hooks/useKafkaEvent/constants';
import { getLocation } from 'client/contexts/Location/selectors';

const PaxSelector = dynamic(
  () => import(/* webpackChunkName: "pax-selector" */ 'client/components/PaxSelector'),
  { loading: Loader }
);

const RoomsUpdater = ({ handleUpdate }) => {
  const intl = useIntl();

  const { device } = useContext(PageContext);
  const isWide = useMedia(updatedBreakpoint.desktop, device === 'desktop');

  const { adults, children, } = useSelector(getSearch);
  const { checkIn, checkOut } = useSelector(getDates);
  const paxes = { adults, children };
  const occupancies = useSelector(getSearchPaxes);
  const locationState = useSelector(getLocation);

  const [isPaxesOpen, setPaxesOpen] = useState(false);
  const [isDatePickerOpen, setDatePickerOpen] = useState(false);

  const { sendEvent } = useKafkaEvent({ page: 'hotel_details' })

  function handleKeyDown(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      setPaxesOpen(true);
    }
  }

  function handleUpdateRooms() {
    setPaxesOpen(false);

    if (handleUpdate) {
      handleUpdate();
    }
  }

  /* @todo: this is the same that the component <Paxes /> */
  function translatePaxes(paxesNumber, singular, plural) {
    return `${paxesNumber} ${intl
      .formatMessage(paxesNumber > 1 ? plural : singular)
      .toLowerCase()}`;
  }

  const textRooms = translatePaxes(adults.length, messages.room, messages.rooms);
  const textAdults = translatePaxes(
    adults.reduce((a, b) => parseInt(a) + parseInt(b)),
    messages.adult,
    messages.adults
  );
  const textChildrenTranlated = translatePaxes(children.flat().length, messages.child, messages.children);
  const textChildren = children.flat().length ? `, ${textChildrenTranlated}` : ''
  const textPaxes = `${textRooms}, ${textAdults}${textChildren}`
  /* @todo end */

  const eventPayload = {
    occupancies,
    search: {
      country: locationState?.country,
      type: locationState?.citySlug ? 'city' : 'zone',
      slug: locationState?.slug
    }
  }

  return (
    <RoomsUpdaterBase>
      <FormattedMessage {...messages.roomsAvailable} tagName="h2" />

      <Form data-testid="rooms-updater">
        <DateSelector
          name="rooms"
          checkIn={checkIn}
          checkOut={checkOut}
          isDatePickerOpen={isDatePickerOpen}
          setDatePickerOpen={setDatePickerOpen}
          handleUpdate={handleUpdateRooms}
          type="availability"
          onCheckInClick={() => sendEvent({ section: SECTIONS.CHECKIN_BOX }, eventPayload)}
          onCheckOutClick={() => sendEvent({ section: SECTIONS.CHECKOUT_BOX }, eventPayload)}
        />

        <Paxes>
          <InputReadOnly
            dataTestId="input-paxes"
            inputName="pax-rooms"
            labelMessage={messages.guests}
            onClick={() => {
              setPaxesOpen(true)
              sendEvent({ section: SECTIONS.PAXES_BOX }, eventPayload)
            }}
            onKeyDown={handleKeyDown}
            value={textPaxes}
          />

          {isWide && device !== 'tablet' && isPaxesOpen && (
            <PaxSelector onSave={handleUpdateRooms} initialPaxes={paxes} />
          )}
        </Paxes>

        {(!isWide || device === 'tablet') && (
          <Modal
            handleClose={handleUpdateRooms}
            show={isPaxesOpen}
            title={intl.formatMessage(messages.guests)}
            variant="fullscreen"
          >
            <PaxSelector onSave={handleUpdateRooms} initialPaxes={paxes} inModal />
          </Modal>
        )}
      </Form>
    </RoomsUpdaterBase>
  );
};

RoomsUpdater.propTypes = {
  handleUpdate: PropTypes.func,
};

export default RoomsUpdater;
