import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import { documentBodyFreeze, documentBodyUnfreeze } from 'client/utils';

import IconArrowBack from 'client/icons/ArrowBack';
import IconClose from 'client/icons/Close';
import Loader from 'src/comps/Atom/Loader';

import {
  VARIANT_CUSTOM,
  VARIANT_DEFAULT,
  VARIANT_EXTENDED,
  VARIANT_FULLSCREEN,
  VARIANTS,
} from './constants';

import {
  ButtonClose,
  CloseButtonWrapper,
  Content,
  ContentFallback,
  Header,
  ModalBase,
  Title,
  ExternalContent,
} from './styles';

import { ButtonIconRound } from 'src/comps/Button/styles';

const Modal = ({
  animate,
  backButton = false,
  children,
  displayHeader = true,
  displayHeaderPage = false,
  displayCloseButton = true,
  fixedTitle = false,
  handleClose,
  position = 'center',
  show = false,
  testId = 'modal',
  timeOut,
  title,
  variant = VARIANT_DEFAULT,
  width,
  unfreezeScroll = true,
}) => {
  const modalNodeRef = useRef();
  const onOutsideClickRef = useRef();
  const isModalOpen = useRef(false);

  const [isOpen, setIsOpen] = useState(show);

  useEffect(() => {
    if (show) {
      documentBodyFreeze();
      isModalOpen.current = true;
    }

    setIsOpen(show);
    return () => {
      if (isModalOpen.current && unfreezeScroll) {
        documentBodyUnfreeze();
      }

      isModalOpen.current = false;
    };
  }, [show]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = useCallback(
    event => {
      if (event) {
        event.stopPropagation();
      }

      setIsOpen(false);

      if (handleClose) {
        handleClose(event);
      }
    },
    [handleClose]
  );

  useEffect(() => {
    if (timeOut) {
      setTimeout(() => handleClick(), timeOut);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function handleOnKeyDown(event) {
    if (event.key === 'Escape') {
      event.stopPropagation();
      handleClick();
    }
  }

  const handleClickOnDock = useCallback(
    (event, node) => {
      if (!node.contains(event.target)) {
        handleClick();
      }
    },
    [handleClick]
  );

  const getModalContent = useCallback(
    node => {
      if (modalNodeRef.current && onOutsideClickRef.current) {
        modalNodeRef.current.parentElement.removeEventListener(
          'mousedown',
          onOutsideClickRef.current
        );
        onOutsideClickRef.current = null;
      }

      modalNodeRef.current = node;

      if (modalNodeRef.current && variant !== VARIANT_FULLSCREEN && variant !== VARIANT_EXTENDED) {
        onOutsideClickRef.current = event => handleClickOnDock(event, modalNodeRef.current);
        modalNodeRef.current.parentElement.addEventListener('mousedown', onOutsideClickRef.current);
      }
    },
    [handleClickOnDock, variant]
  );

  if (!isOpen) {
    return null;
  }

  const HeaderWrapper = () =>
    displayHeader && (
      <Header variant={variant} fixedTitle={fixedTitle}>
        {variant === VARIANT_CUSTOM ? (
          <ButtonClose onClick={handleClick}>
            <IconClose />
          </ButtonClose>
        ) : (
          <>
            {backButton && (
              <ButtonClose onClick={handleClick}>
                <IconArrowBack />
              </ButtonClose>
            )}

            <Title fixedTitle={fixedTitle}>{title}</Title>

            {!backButton && (
              <ButtonClose data-testid="close-button" onClick={handleClick}>
                <IconClose />
              </ButtonClose>
            )}
          </>
        )}
      </Header>
    );

  return (
    <ModalBase
      displayHeaderPage={displayHeaderPage}
      onKeyDown={handleOnKeyDown}
      data-testid={testId}
      variant={variant}
    >
      <Content
        animate={animate}
        position={position}
        ref={getModalContent}
        variant={variant}
        width={width}
      >
        <HeaderWrapper />
        {!displayHeader && displayCloseButton && (
          <CloseButtonWrapper>
            <ButtonIconRound
              type="button"
              onClick={handleClick}
              size={40}
              data-testid="close-button"
            >
              <IconClose size={19} />
            </ButtonIconRound>
          </CloseButtonWrapper>
        )}
        <ExternalContent displayHeader={displayHeader || displayCloseButton}>
          {children}
        </ExternalContent>
      </Content>
    </ModalBase>
  );
};

Modal.propTypes = {
  animate: PropTypes.string,
  children: PropTypes.node.isRequired,
  backButton: PropTypes.bool,
  displayHeader: PropTypes.bool,
  displayHeaderPage: PropTypes.bool,
  displayCloseButton: PropTypes.bool,
  fixedTitle: PropTypes.bool,
  handleClose: PropTypes.func,
  position: PropTypes.oneOf(['top', 'bottom', 'center']),
  show: PropTypes.bool,
  testId: PropTypes.string,
  timeOut: PropTypes.number,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  variant: PropTypes.oneOf(VARIANTS),
  width: PropTypes.string,
  unfreezeScroll: PropTypes.bool,
};

const Fallback = () => (
  <ContentFallback>
    <Loader />
  </ContentFallback>
);

export { Modal as default, Fallback };
