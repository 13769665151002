import queryString from 'query-string';
import { PAGES_STATE } from './constants';

const location = typeof window !== 'undefined' && window.location;
const { page } = queryString.parse(location.search);

const state = {
  [PAGES_STATE]: {
    pageId: '',
    pageNumber: parseInt(page, 10) || 1,
  },
};

export default state;
