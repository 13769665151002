import React, { useContext, ReactElement } from 'react';
import { useMedia } from 'react-use';
import { useIntl } from 'react-intl';

import { PageContext } from 'src/comps/Page/context';

import { updatedBreakpoint } from 'client/styles';
import { KeyFeaturesBase, KeyFeatureBase, Content, IconWrapper } from './styles';
import IconTimer from 'client/icons/Timer';
import IconCreditCard from 'client/icons/CreditCard';
import IconShield from 'client/icons/Shield';

export const keyFeatures = [
  {
    icon: <IconTimer />,
    title: 'benefits.the-more-nights',
    subtitle: 'benefits.stay-longer-better-discounts',
  },
  {
    icon: <IconCreditCard />,
    title: 'benefits.you-can-pay-later',
    subtitle: 'benefits.we-offer-different-payment-options',
  },
  {
    icon: <IconShield />,
    title: 'benefits.free-cancellation-available',
    subtitle: 'benefits.you-can-choose-rates-with-free-cancellation',
  },
];

interface IKeyFeatureProps {
  className?: string;
  title: string;
  subtitle: string;
  icon: ReactElement;
  iconSize: number;
}

export const KeyFeature = ({ className, title, subtitle, icon, iconSize }: IKeyFeatureProps) => {
  const intl = useIntl();

  return (
    <KeyFeatureBase className={className}>
      <IconWrapper>{React.cloneElement(icon, { size: iconSize })}</IconWrapper>
      <Content>
        <h6>{intl.formatMessage({ id: title })}</h6>
        <p>{intl.formatMessage({ id: subtitle })}</p>
      </Content>
    </KeyFeatureBase>
  );
};

const KeyFeatures = () => {
  const intl = useIntl();
  const { device } = useContext(PageContext);
  const isTablet = useMedia(updatedBreakpoint.tablet, device !== 'mobile');
  const iconSize = isTablet ? 36 : 24;

  return (
    <KeyFeaturesBase>
      {!isTablet && <h2>{intl.formatMessage({ id: 'advantages.title' })}</h2>}
      {keyFeatures.map(keyFeatureProps => (
        <KeyFeature key={keyFeatureProps.title} {...keyFeatureProps} iconSize={iconSize} />
      ))}
    </KeyFeaturesBase>
  );
};

export default KeyFeatures;
