export const PRIME_SUBSCRIPTION = 'prime';

export const PRIME_CANCEL_REQUEST = 'PRIME_CANCEL_REQUEST';
export const PRIME_CANCEL_SUCCESS = 'PRIME_CANCEL_SUCCESS';
export const PRIME_CANCEL_FAILURE = 'PRIME_CANCEL_FAILURE';

export const PRIME_REACTIVATE_REQUEST = 'PRIME_REACTIVATE_REQUEST';
export const PRIME_REACTIVATE_SUCCESS = 'PRIME_REACTIVATE_SUCCESS';
export const PRIME_REACTIVATE_FAILURE = 'PRIME_REACTIVATE_FAILURE';

export const PRIME_SUBSCRIPTION_STATUS_CANCELLED = 'cancelled';
export const PRIME_SUBSCRIPTION_STATUS_ACTIVE = 'active';
