export const WALLET_STATE = 'wallet';

export const FETCH_WALLET_REQUEST = 'FETCH_WALLET_REQUEST';
export const FETCH_WALLET_SUCCESS = 'FETCH_WALLET_SUCCESS';
export const FETCH_WALLET_FAILURE = 'FETCH_WALLET_FAILURE';

export const FETCH_APPLIED_WALLET_REQUEST = 'FETCH_APPLIED_WALLET_REQUEST';
export const FETCH_APPLIED_WALLET_SUCCESS = 'FETCH_APPLIED_WALLET_SUCCESS';
export const FETCH_APPLIED_WALLET_FAILURE = 'FETCH_APPLIED_WALLET_FAILURE';

export const CHECK_PURCHASE_WALLET_REQUEST = 'CHECK_PURCHASE_WALLET_REQUEST';
export const CHECK_PURCHASE_WALLET_SUCCESS = 'CHECK_PURCHASE_WALLET_SUCCESS';
export const CHECK_PURCHASE_WALLET_FAILURE = 'CHECK_PURCHASE_WALLET_FAILURE';

export const UNCHECK_PURCHASE_WALLET_REQUEST = 'UNCHECK_PURCHASE_WALLET_REQUEST';
export const UNCHECK_PURCHASE_WALLET_SUCCESS = 'UNCHECK_PURCHASE_WALLET_SUCCESS';
export const UNCHECK_PURCHASE_WALLET_FAILURE = 'UNCHECK_PURCHASE_WALLET_FAILURE';
