import React from 'react';
import Script from 'next/script';
import { useDevice } from 'src/hooks/useDevice';

const Datadog = () => {
  const { isBot } = useDevice();

  return (
    <Script id="datadog" strategy="afterInteractive">
      {`
      (function(h,o,u,n,d) {
        h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}
        d=o.createElement(u);d.async=1;d.src=n
        n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)
      })(window,document,'script','https://www.datadoghq-browser-agent.com/datadog-rum-v4.js','DD_RUM')
      DD_RUM.onReady(function() {
        ${!isBot ? (`
          DD_RUM.init({
            clientToken: '${process.env.NEXT_PUBLIC_DD_TOKEN}',
            applicationId: '${process.env.NEXT_PUBLIC_DD_APP_ID}',
            service: '${process.env.NEXT_PUBLIC_DD_SERVICE}',
            env: '${process.env.NEXT_PUBLIC_ENV}',
            version: '${process.env.NEXT_PUBLIC_DD_VERSION}',
            sessionSampleRate: ${process.env.NEXT_PUBLIC_DD_SAMPLE_RATE && +process.env.NEXT_PUBLIC_DD_SAMPLE_RATE},
            sessionReplaySampleRate: ${process.env.NEXT_PUBLIC_DD_REPLAY_SAMPLE_RATE && +process.env.NEXT_PUBLIC_DD_REPLAY_SAMPLE_RATE},
            trackUserInteractions: ${!!process.env.NEXT_PUBLIC_DD_TRACK_USER_INTERACTIONS},
            trackResources: ${!!process.env.NEXT_PUBLIC_DD_TRACK_RESOURCES},
            trackLongTasks: ${!!process.env.NEXT_PUBLIC_DD_TRACK_LONG_TASKS},
            defaultPrivacyLevel: '${process.env.NEXT_PUBLIC_DD_PRIVACY_LEVEL}',
            trackViewsManually: true,
            beforeSend: (event) => {
              if (event.type === 'error' && event.error.message.includes('GSI_LOGGER')) {
                return false;
              }
            },
          });
          //DD_RUM.startSessionReplayRecording();`
        ): ''}

      })
      `}
    </Script>
  )
};
export default Datadog;
