import {
  ACTIVATE_ACCOUNT_REQUEST,
  ACTIVATE_ACCOUNT_SUCCESS,
  ACTIVATE_ACCOUNT_FAILURE,
} from './constants';

export const activateAccountRequestAction = payload => ({
  type: ACTIVATE_ACCOUNT_REQUEST,
  payload,
});

export const activateAccountSuccessAction = payload => ({
  type: ACTIVATE_ACCOUNT_SUCCESS,
  payload,
});

export const activateAccountFailureAction = payload => ({
  type: ACTIVATE_ACCOUNT_FAILURE,
  payload,
});
