import { ALERTS_STATE, ALERT_DISPLAY, ALERT_REMOVE } from './constants';
import stateBase from './state';

const initialState = stateBase[ALERTS_STATE];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ALERT_DISPLAY:
      return {
        ...state,
        messageId: action.payload.messageId,
        severity: action.payload.severity,
        values: action.payload.values,
      };

    case ALERT_REMOVE:
      return {
        ...state,
        messageId: '',
        severity: '',
        values: {},
      };

    default:
      return state;
  }
};

export default reducer;
