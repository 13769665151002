import styled from 'styled-components';
import { media, colors } from 'client/styles';
import { Form as SearchForm } from 'client/components/SearchForm/styles';
import { LocationSelectorBase } from 'client/components/LocationSelector/styles';

export const Layout = styled.div`
  ${props => props.background && `background-color: ${colors[props.background]};`}

  ${SearchForm} {
    padding: 20px 20px 0;
  }

  ${media.tablet} {
    ${SearchForm} {
      padding-top: 40px;
    }
  }

  ${media.desktop} {
    ${SearchForm} {
      top: 400px;
      left: calc(50% - 555px);
      position: absolute;
      width: 1130px;
      background-color: transparent;
      padding: 0;
    }

    ${LocationSelectorBase} {
      background-color: transparent;
    }
  }

  @media print {
    header {
      display: none !important;
    }

    .noPrint {
      display: none !important;
    }
  }
`;
