import styled from 'styled-components';
import { colors } from 'client/styles';

export const StarsBase = styled.ul`
  align-items: center;
  display: flex;
  padding: 0.1em 0;
`;

export const Item = styled.li`
  line-height: 0;
  margin-right: 3px;

  svg {
    flex: 1 0 auto;
    vertical-align: middle;
    fill: ${props => (props.color ? colors[props.color] : colors.yellow)};
    width: ${props => props.size}px;
    height: ${props => props.size}px;
  }
`;
