import murmur from 'murmurhash-js';
import getTime from 'date-fns/getTime';
import toDate from 'date-fns-tz/toDate';

import {
  buildEmailHash,
  getChildren,
  getChildrenAge,
  getIsMandatory,
  getNights,
  getPropertiesInfo,
  getPropertyId,
  getSessionId,
  removeEmptyKeys,
  sumAdults,
} from './utils';

import { affiliatesDataFilter, ORIGIN_DIRECT } from './constants';

export function mapKafkaData({
  action,
  adults,
  affiliateData,
  affiliateRate,
  affiliateId = 'stayforlong',
  bestPrice,
  board,
  bookingId,
  checkIn,
  checkOut,
  children,
  clickDetails,
  clickIn,
  device,
  email,
  eventName,
  eventType,
  filters,
  ip,
  isBot,
  isLoggedIn,
  isMandatory,
  language,
  mapSearch,
  market,
  page,
  paymentMethod,
  position,
  price,
  property,
  propertyId,
  purchaseId,
  purchaseEmail,
  purchaseEmailHash,
  queryParams,
  rateClass,
  roomImages,
  rooms,
  roomsAvailability,
  search,
  searchResults,
  section,
  source,
  title,
  totalRates,
  url,
  userId,
  visited,
  ...rest
} = {}) {
  if (!eventName) {
    console.error('Missing required tracking params'); // eslint-disable-line no-console
  }

  const timestamp = Date.now();
  const timestampCheckIn = getTime(toDate(checkIn));
  const timestampCheckOut = getTime(toDate(checkOut));

  return {
    created_at: timestamp,
    device: device || null,
    event_name: eventName,
    event_sub_type: null,
    event_type: eventType || null,
    hsl: murmur(`${eventName}${timestamp}${eventType}`, 87) || null,
    ip: ip || null,
    is_bot: isBot,
    language: language || null,
    level: 'info',
    market: market?.toUpperCase() || null,
    scope: 'tracking',
    screen: `${window.screen.width}x${window.screen.height}`,
    session_id: getSessionId(),
    source: 'frontend',
    user_agent: navigator.userAgent,
    user_id: userId || null,
    payload: removeEmptyKeys({
      ...rest,
      action,
      affiliate_data: affiliateData,
      affiliate: affiliateId,
      affiliate_rate: affiliateRate,
      availability: roomsAvailability,
      best_price: bestPrice,
      board_code: board?.code,
      checkin: timestampCheckIn,
      checkout: timestampCheckOut,
      click_details: clickDetails,
      clickin: clickIn,
      currency: board?.currency,
      email,
      filters,
      id_availability: board?.availabilityId,
      id_booking: bookingId ? [...bookingId] : null,
      id_property: getPropertyId({ property, propertyId }),
      id_purchase: purchaseId,
      purchase_id: purchaseId,
      is_logged: isLoggedIn,
      is_mandatory: getIsMandatory(isMandatory),
      nights: getNights(checkIn, checkOut),
      occupancies: removeEmptyKeys({
        adults,
        children: children?.map(item => item.length),
        childrenAges: children,
      }),
      origin: document.referrer || ORIGIN_DIRECT,
      page,
      payment_method: paymentMethod,
      position,
      price: price || bestPrice?.totalDiscounted || board?.finalPrice,
      purchase_email_hash: purchaseEmailHash,
      rate_class: rateClass || board?.rateClass || board?.paymentPolicy || '',
      room_code: null,
      room_images: roomImages,
      rooms,
      search_results: searchResults,
      search: mapSearch || search,
      section,
      source,
      title,
      total_rates: totalRates,
      url: url || window.location.href,
      utm: queryParams,
      visited,
    }),
  };
}

export function mapSFLData({
  adults,
  affiliateId,
  bestPriceData,
  board,
  bookingId,
  checkIn,
  checkOut,
  children,
  clickIn,
  country,
  currency,
  device,
  emailLogin,
  generatedWallet,
  ip,
  isBot,
  isMandatory,
  language,
  market,
  pageId,
  paymentMethod,
  price,
  properties,
  property,
  purchaseEmail,
  region,
  searchTerm,
  slug,
}) {
  return {
    ...getPropertiesInfo({
      pageId,
      properties,
      property,
      price: price || bestPriceData?.totalDiscounted || board?.finalPrice,
    }),
    adults: `${sumAdults(adults)}`,
    affiliate_id: affiliateId,
    board_name: board?.boardName || '',
    booking_id: Array.isArray(bookingId) ? bookingId.toString() : bookingId || '',
    checkin: checkIn || '',
    checkout: checkOut || '',
    children: getChildren(children) || '',
    children_age: getChildrenAge(children),
    city: slug || property?.citySlug || '',
    clickin: clickIn || '',
    country: country || property?.country || '',
    currency: currency || '',
    device: device || '',
    eml_hash: buildEmailHash(emailLogin || purchaseEmail),
    eml_hash_sha256: buildEmailHash(emailLogin || purchaseEmail, 'sha-256'),
    generated_wallet: generatedWallet ? `${generatedWallet}` : '',
    ip: ip || '',
    is_bot: isBot,
    is_mandatory: isMandatory ? 1 : 0,
    language: language || '',
    market: market || '',
    nights: getNights(checkIn, checkOut),
    payment_method: paymentMethod || null,
    rate_class: board?.rateClass || board?.paymentPolicy || '',
    region: region || property?.region || '',
    rooms: `${adults?.length}` || '',
    search_string: searchTerm || property?.searchTerm || '',
    session_id: getSessionId(),
    subtotal: `${board?.subtotal ?? ''}`,
    user_agent: navigator.userAgent,
  };
}

export function mapConversionGA({ purchaseId, affiliateId, price, property }) {
  return {
    transactionId: purchaseId,
    transactionAffiliation: affiliateId,
    transactionTotal: price,
    transactionProducts: [
      {
        sku: property.id,
        name: property.name,
        price,
        quantity: 1,
      },
    ],
  };
}

export function mapEcommerceData({ adults, board, price, bestPriceData, currency, property }) {
  return {
    products: [
      {
        item_name: property.name,
        item_id: property.id,
        price: `${price || bestPriceData?.totalDiscounted || board?.finalPrice}`,
        currency,
        quantity: `${adults?.length}` || '',
      },
    ],
  };
}

export function mapFiltersToTracking(filters) {
  if (!filters || Object.keys(filters).length === 0) {
    return null;
  }

  const { accommodationTypes, facilities, family, maxPrice, minPrice, orderBy, ratings, stars } =
    filters;

  return removeEmptyKeys({
    accommodationTypes,
    facilities,
    family: family === 'true',
    maxPrice: parseInt(maxPrice, 10),
    minPrice: parseInt(minPrice, 10),
    orderBy,
    ratings,
    stars,
  });
}

export function mapIterableData(data) {
  return {
    accommodation_type: data.property?.accommodationType,
    adults: sumAdults(data.adults),
    affiliate: data.affiliateId || 'stayforlong',
    checkin: data.checkIn,
    checkout: data.checkOut,
    children_age: getChildrenAge(data.children),
    children: getChildren(data.children),
    currency: data.currency,
    destination_country_slug: undefined,
    destination_country: data.country,
    facilities: data.property?.facilities?.map(facility => facility.icon),
    from_booking_error: data.alertMessage && !!data.alertMessage,
    is_logged: data.isLoggedIn,
    language: data.language,
    location_name: data.property?.city || data.city,
    location_slug: data.property?.citySlug || data.citySlug,
    location_type: data.locationType || 'city',
    market: data.market,
    price: data.bestPriceData?.totalDiscounted || 0,
    property_id: data.property?.id,
    property_name: data.property?.name,
    rooms: data.adults?.length,
    session: getSessionId(),
  };
}

export function mapTrackingAffiliateData(affiliateId, trackData) {
  const keysFilter = affiliatesDataFilter[affiliateId];
  if (trackData?.aid !== affiliateId || !keysFilter) {
    return {};
  }
  const affiliateData = Object.fromEntries(
    Object.entries(trackData).filter(([key]) => {
      return keysFilter.includes(key);
    })
  );
  return affiliateData;
}
