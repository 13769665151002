import React, { createContext, ReactElement, useMemo } from "react";

import { BusinessType } from "src/types/business";

const BusinessContext = createContext<BusinessType | null>(null)

const BusinessProvider = ({ children, value }: { children: ReactElement, value: BusinessType | null }) => {

    if (value === null) {
        throw new Error("BusinessContext provider can not have a null value")
    }

    const { mobileRate, affiliate, utmMedium, promo, rateModel } = value;

  const businessContextValue = useMemo(
    () => ({ mobileRate, affiliate, utmMedium, promo, rateModel }),
    [mobileRate, affiliate, utmMedium, promo, rateModel]
  );

    return (
        <BusinessContext.Provider value={businessContextValue}>
            {children}
        </BusinessContext.Provider>
    );
};

export { BusinessContext, BusinessProvider }
