import { defineMessages } from 'react-intl';

const messages = defineMessages({
  newSearch: {
    id: 'global.new-search',
  },
  preTitle: {
    id: 'error.pre-title',
  },
  notFound: {
    id: 'error.not-found',
  },
  error: {
    id: 'error.default',
  },
});

export default messages;
