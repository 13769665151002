import { SUBSCRIPTION_STATUS_STATE, UNSUBSCRIBED } from './constants';

const state = {
  [SUBSCRIPTION_STATUS_STATE]: {
    error: null,
    isFetching: false,
    status: UNSUBSCRIBED,
    subscriptions: {
      specialOffers: {
        uuid: null,
        status: null,
        type: null,
      },
      specialDayOffers: {
        uuid: null,
        status: null,
        type: null,
      },
      stayforlongNews: {
        uuid: null,
        status: null,
        type: null,
      },
      destinations: {
        uuid: null,
        status: null,
        type: null,
      },
      contests: {
        uuid: null,
        status: null,
        type: null,
      },
      primeOffers: {
        uuid: null,
        status: null,
        type: null,
      },
      primeGuides: {
        uuid: null,
        status: null,
        type: null,
      },
    },
  },
};

export default state;
