import {
  MODIFY_DATES_CANCELLATION_FAILURE,
  MODIFY_DATES_CONFIRMATION_FAILURE,
  MODIFY_DATES_CONFIRMATION_REQUEST,
  MODIFY_DATES_CONFIRMATION_SUCCESS,
  MODIFY_DATES_FAILURE,
  MODIFY_DATES_REQUEST,
  MODIFY_DATES_RESET,
  MODIFY_DATES_SAVE_OFFER,
  MODIFY_DATES_SAVE_PAXES,
  MODIFY_DATES_STATE,
  MODIFY_DATES_SUCCESS,
  STATUS_NOT_OK,
  STATUS_OK,
} from './constants';
import stateBase from './state';

const initialState = stateBase[MODIFY_DATES_STATE];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MODIFY_DATES_REQUEST:
      return {
        ...state,
        bookingRules: null,
        error: null,
        isFetching: true,
        status: null,
      };

    case MODIFY_DATES_SUCCESS:
      return {
        ...state,
        bookingRules: action.payload.data.bookingRules,
        error: null,
        isFetching: false,
        status: STATUS_OK,
        step: 'SELECT_OFFER',
      };

    case MODIFY_DATES_FAILURE:
      return {
        ...state,
        bookingRules: null,
        error: action.payload.error,
        isFetching: false,
        status: STATUS_NOT_OK,
        step: 'SELECT_OFFER',
      };

    case MODIFY_DATES_RESET:
      return {
        ...state,
        boardSelected: null,
        bookingRules: null,
        isFetching: false,
        status: null,
        step: 'CHECK_AVAILABILITY',
      };

    case MODIFY_DATES_SAVE_PAXES:
      return {
        ...state,
        datesAndPaxes: {
          checkIn: action.payload?.checkin,
          checkOut: action.payload?.checkout,
          children: action.payload?.children,
          adults: action.payload?.adults,
          childrenAges: action.payload?.childrenAges,
        },
      };

    case MODIFY_DATES_SAVE_OFFER:
      return {
        ...state,
        boardSelected: action.payload.board,
        step: 'CONFIRM_OFFER',
      };

    case MODIFY_DATES_CONFIRMATION_REQUEST:
      return {
        ...state,
        error: null,
        isFetchingConfirmation: true,
        results: null,
      };

    case MODIFY_DATES_CONFIRMATION_SUCCESS:
      return {
        ...state,
        error: null,
        isFetchingConfirmation: false,
        step: null,
      };

    case MODIFY_DATES_CONFIRMATION_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isFetchingConfirmation: false,
        results: null,
        step: 'ERROR_CONFIRMATION',
      };

    case MODIFY_DATES_CANCELLATION_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isFetchingConfirmation: false,
        results: null,
        step: 'ERROR_CANCELLATION',
      };

    default:
      return state;
  }
};

export default reducer;
