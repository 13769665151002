import { call, put, select } from 'redux-saga/effects';

import { fetchProperties, fetchPropertiesAuthorized } from './requests';
import {
  fetchPropertiesSuccessAction,
  fetchPropertiesFailureAction,
} from 'src/effects/Properties/actions';

import { loadProperties } from 'client/contexts/Tracking/events';
import { sendTrackingRequestAction } from 'client/contexts/Tracking/actions';
import { mapSearchDataToPropertiesCollection } from 'client/contexts/Property/mappers';
import { getIsFetching } from 'client/contexts/Login/selectors';
import { SortOptions } from 'src/pages/api/trpc/search/schema';

export function* fetchPropertiesSaga(action) {
  try {
    const isFetchingUser = yield select(getIsFetching);
    if (isFetchingUser) {
      return;
    }

    const { payload } = action;

    const request = action.payload?.isLogged ? fetchPropertiesAuthorized : fetchProperties;

    const newOrderBy = { orderBy: 'relevance' };

    if (payload?.filtersParams?.orderBy === 'relevance') {
      newOrderBy.orderBy = SortOptions.Recommended;
    }

    const newPayload = {
      ...payload,
      filtersParams: {
        ...payload.filtersParams,
        ...(newOrderBy.orderBy !== 'relevance' && newOrderBy),
      },
    };

    const { data, status } = yield call(request, newPayload);

    if (status >= 400) {
      yield put(fetchPropertiesFailureAction({ error: data }));
      return;
    }

    const { properties, metadata } = data;
    const propertiesMapped = mapSearchDataToPropertiesCollection(properties);

    const { count = 0, price } = metadata;
    const distribution = price?.distribution;

    yield put(fetchPropertiesSuccessAction({ properties: propertiesMapped, count, distribution }));
    yield put(sendTrackingRequestAction({ event: loadProperties() }));
  } catch (error) {
    yield put(fetchPropertiesFailureAction({ error: error.message }));
  }
}
