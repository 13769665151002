const companies = {
  facebook: '#3B5998',
  twitter: '#1DA1F2',
  linkedin: '#017BBE',
  googlePlus: '#DD4B39',
  whatsapp: '#25D366',
  trustpilot: '#00B67A',
  tripadvisor: '#00AA6C',
  paypal: '#009CDE',
};

const s4lColors = {
  brand1: 'var(--color--primary--500)',
  brand2: 'var(--color--primary--700)',
  prime1: '#7802DE',
  prime2: '#6002b2',
  prime3: '#F2E6FC',
  prime4: '#a14ee8',
  grey0: '#000000',
  grey1: '#333333',
  grey2: '#444444',
  grey3: '#666666',
  grey4: '#A8A8A8',
  grey5: '#E0E0E0',
  grey6: '#F2F2F2',
  grey7: '#FAFAFA',
  grey8: '#FFFFFF',
  failure: '#C92A2A',
  success: '#1D8549',
  pending: '#F2994A',
  warning: '#F2C94C',
  promo: '#0078CC',
  get specials() {
    return {
      blackFriday: this.grey0,
      winterDeal: this.promo,
    };
  },
  get srgb() {
    return {
      brand1: {
        r: 'var(--color--primary--500-r)',
        g: 'var(--color--primary--500-g)',
        b: 'var(--color--primary--500-b)',
      },
      grey0: {
        r: 0,
        g: 0,
        b: 0,
      },
      grey8: {
        r: 255,
        g: 255,
        b: 255,
      },
    };
  },
  berlin: '#0F0F0F',
  marquee: 'var(--color-marquee)',
  palermo: '#DDE780',
  tokio: '#B593FF',
  companies,
};

export default s4lColors;
