import isEmpty from "lodash/isEmpty";
import pick from "lodash/pick"
import { SystemType } from "src/types/system";


export const getSystemContextValue = (config: unknown) => {
  const contextValue = pick(config, ['apiHostName', 'cookieReturnUrl', 'host'])

  if (isEmpty(contextValue)) {
    return null;
  }

  return contextValue as SystemType
}