import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { routes } from 'src/constants/routes';

import { PageProvider } from './context';
import { typeConfig } from './types';

import { getIsUserDataReady, getIsFetching } from 'client/contexts/Login/selectors';
import { useAlertMessage } from 'src/hooks/useAlertMessage';
import { usePageview } from 'src/hooks/usePageview';
import ErrorBoundary from 'src/comps/ErrorBoundary';

const Page = ({
  children,
  config,
  pageId,
  trackingData,
  disableOAuth = false,
  loginRequired = false,
}) => {
  const GoogleOneTapLogin = dynamic(() => import('src/comps/GoogleOneTapLogin'));
  const isUserDataReady = useSelector(getIsUserDataReady);
  const isFetching = useSelector(getIsFetching);
  const userData = config.customer?.data;

  const router = useRouter();

  useEffect(() => {
    if (loginRequired && !config.isLogged) {
      router.push(routes.login.pathname);
    }
    return null;
  }, [loginRequired, config.isLogged, router]);

  useAlertMessage();

  usePageview(pageId, trackingData, config.isLogged, userData);

  const pageConfig = { ...config, pageId };

  return (
    <PageProvider value={pageConfig}>
      {!isUserDataReady && !isFetching && !disableOAuth && (
        <ErrorBoundary>
          <GoogleOneTapLogin />
        </ErrorBoundary>
      )}
      {children}
    </PageProvider>
  );
};

Page.propTypes = {
  children: PropTypes.node.isRequired,
  config: typeConfig,
  disableOAuth: PropTypes.bool,
  pageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  trackingData: PropTypes.instanceOf(Object),
  loginRequired: PropTypes.bool,
};

export default Page;
