import { TA_REVIEWS_STATE } from './constants';

const state = {
  [TA_REVIEWS_STATE]: {
    error: null,
    isFetching: false,
    data: [],
  },
};

export default state;
