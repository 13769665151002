import { requestByGET } from 'lib/requests';
import { mapPrimeReceipts } from './mappers';

const OPTIONS = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  timeout: 30000,
  withCredentials: true,
};

const ENDPOINTS = {
  PRIME_RECEIPTS: '/v1/prime/tickets',
};

export function fetchPrimeTickets() {
  return requestByGET({
    endpoint: ENDPOINTS.PRIME_RECEIPTS,
    options: OPTIONS,
    mapper: mapPrimeReceipts,
  });
}
