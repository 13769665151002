import { buildPathParams } from 'lib/utils/url';
import { requestByGET, requestByPOST } from 'lib/requests';

const OPTIONS = {
  headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
  timeout: 30000,
  withCredentials: true,
};

const END_POINTS = {
  SUBSCRIPTION: '/subscription/user/{hash}',
  SUBSCRIBE: '/subscription',
  SUBSCRIPTIONS_CONFIRM: '/subscription/{hash}/confirm',
  SUBSCRIPTION_UNSUBSCRIBE: '/subscription/{hash}/unsubscribe',
  ACTIVATE_TOPIC: '/subscription/activate/{uuid}',
  DEACTIVATE_TOPIC: '/subscription/deactivate/{uuid}',
};

export function fetchSubscriptionStatus(params) {
  return requestByGET({
    endpoint: buildPathParams(END_POINTS.SUBSCRIPTION, { hash: params.hash }),
    options: OPTIONS,
    hostname: params.hostname,
  });
}

export function subscribe(params) {
  return requestByPOST({
    data: {
      email: params.hash || params.email,
      language: params.language,
      market: params.market,
      source: params.source,
      subscription_type: params.subscriptionType,
    },
    endpoint: END_POINTS.SUBSCRIBE,
    options: OPTIONS,
    hostname: params.hostname,
  });
}

export function unsubscribe(params) {
  return requestByPOST({
    data: {
      subscription_type: params.subscriptionType,
      source: params.source,
    },
    endpoint: buildPathParams(END_POINTS.SUBSCRIPTION_UNSUBSCRIBE, { hash: params.hash }),
    options: OPTIONS,
    hostname: params.hostname,
  });
}

export function confirm(params) {
  return requestByGET({
    data: {
      subscription_type: params.subscriptionType,
      source: params.source,
    },
    endpoint: buildPathParams(END_POINTS.SUBSCRIPTIONS_CONFIRM, { hash: params.hash }),
    options: OPTIONS,
    hostname: params.hostname,
  });
}

export function activateSubscriptionTopic(params) {
  return requestByPOST({
    data: {
      hash: params.hash,
      source: params.source,
    },
    endpoint: buildPathParams(END_POINTS.ACTIVATE_TOPIC, { uuid: params.uuid }),
    options: OPTIONS,
    hostname: params.hostname,
  });
}

export function deactivateSubscriptionTopic(params) {
  return requestByPOST({
    data: {
      hash: params.hash,
      source: params.source,
    },
    endpoint: buildPathParams(END_POINTS.DEACTIVATE_TOPIC, { uuid: params.uuid }),
    options: OPTIONS,
    hostname: params.hostname,
  });
}
