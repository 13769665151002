import {
  LOGIN_GOOGLE_REQUEST,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  FETCH_CUSTOMER_DATA_REQUEST,
  FETCH_CUSTOMER_DATA_SUCCESS,
  FETCH_CUSTOMER_DATA_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  SET_ANONYMOUS_USER
} from './constants';

export const loginGoogleRequestAction = payload => ({
  type: LOGIN_GOOGLE_REQUEST,
  payload,
});

export const loginRequestAction = payload => ({
  type: LOGIN_REQUEST,
  payload,
});

export const loginSuccessAction = payload => ({
  type: LOGIN_SUCCESS,
  payload,
});

export const loginFailureAction = payload => ({
  type: LOGIN_FAILURE,
  payload,
});

export const fetchCustomerDataRequestAction = payload => ({
  type: FETCH_CUSTOMER_DATA_REQUEST,
  payload,
});

export const fetchCustomerDataSuccessAction = payload => ({
  type: FETCH_CUSTOMER_DATA_SUCCESS,
  payload,
});

export const fetchCustomerDataFailureAction = payload => ({
  type: FETCH_CUSTOMER_DATA_FAILURE,
  payload,
});

export const logoutRequestAction = payload => ({
  type: LOGOUT_REQUEST,
  payload,
});

export const logoutSuccessAction = payload => ({
  type: LOGOUT_SUCCESS,
  payload,
});

export const logoutFailureAction = payload => ({
  type: LOGOUT_FAILURE,
  payload,
});

export const setAnonymousUserAction = () => ({
  type: SET_ANONYMOUS_USER
});
