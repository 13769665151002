import {
  PURCHASE_STATE,
  SAVE_CUSTOMER_DATA,
  SAVE_HOLDERS,
  SAVE_INSURANCE_IDS,
  SAVE_ADDITIONAL_DATA,
  CLEAN_CUSTOMER_DATA,
} from './constants';
import stateBase from './state';

const initialState = stateBase[PURCHASE_STATE];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_CUSTOMER_DATA:
      return {
        ...state,
        ...action.payload,
        errors: [],
      };

    case SAVE_HOLDERS:
      return {
        ...state,
        holders: action.payload,
      };

    case SAVE_INSURANCE_IDS:
      return {
        ...state,
        insuranceIds: action.payload,
      };

    case SAVE_ADDITIONAL_DATA:
      return {
        ...state,
        isMandatory: action.payload.isMandatory,
      };

    case CLEAN_CUSTOMER_DATA:
      return {
        ...action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
