import {
  WALLET_STATE,
  FETCH_APPLIED_WALLET_REQUEST,
  FETCH_APPLIED_WALLET_SUCCESS,
  FETCH_APPLIED_WALLET_FAILURE,
  FETCH_WALLET_REQUEST,
  FETCH_WALLET_SUCCESS,
  FETCH_WALLET_FAILURE,
  CHECK_PURCHASE_WALLET_REQUEST,
  CHECK_PURCHASE_WALLET_SUCCESS,
  CHECK_PURCHASE_WALLET_FAILURE,
  UNCHECK_PURCHASE_WALLET_REQUEST,
  UNCHECK_PURCHASE_WALLET_SUCCESS,
  UNCHECK_PURCHASE_WALLET_FAILURE,
} from './constants';
import stateBase from './state';

const initialState = stateBase[WALLET_STATE];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WALLET_REQUEST:
    case FETCH_APPLIED_WALLET_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };

    case FETCH_WALLET_SUCCESS:
      return {
        ...state,
        amount: action.payload.amount,
        currency: action.payload.currency,
        isFetching: false,
        error: null,
      };

    case FETCH_APPLIED_WALLET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isCheck: action.payload.amount > 0,
      };

    case FETCH_APPLIED_WALLET_FAILURE:
    case FETCH_WALLET_FAILURE:
    case CHECK_PURCHASE_WALLET_FAILURE:
    case UNCHECK_PURCHASE_WALLET_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };

    case CHECK_PURCHASE_WALLET_REQUEST:
    case UNCHECK_PURCHASE_WALLET_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };

    case CHECK_PURCHASE_WALLET_SUCCESS:
      return {
        ...state,
        isCheck: true,
        isFetching: false,
        error: null,
      };

    case UNCHECK_PURCHASE_WALLET_SUCCESS:
      return {
        ...state,
        isCheck: false,
        isFetching: false,
        error: null,
      };

    default:
      return state;
  }
};

export default reducer;
