import { call, put } from 'redux-saga/effects';
import { TOO_MANY_REQUESTS } from 'src/constants/httpcodes';
import { fetchTAReviewsFailureAction, fetchTAReviewsSuccessAction } from './actions';
import { fetchTAReviews } from './requests';

export function* fetchTAReviewsSaga(action) {
  try {
    const response = yield call(fetchTAReviews, { data: action.payload });

    if (response.status === TOO_MANY_REQUESTS.status) {
      yield put(fetchTAReviewsFailureAction({ error: response.data.error.message })); // tripadvisor send a json with the error info
      return;
    }

    if (response.status >= 400) {
      throw new Error(response.data);
    }

    yield put(fetchTAReviewsSuccessAction(response.data));
  } catch (error) {
    yield put(fetchTAReviewsFailureAction({ error: error.message }));
  }
}
