export const sizes = {
  mobile: 375,
  tablet: 860,
  desktop: 1280,
};

export const breakpoint = {
  mobile: `(min-width: ${sizes.mobile}px)`,
  tablet: `(min-width: ${sizes.tablet}px)`,
  desktop: `(min-width: ${sizes.desktop}px)`,
};

export const media = {
  mobile: `@media ${breakpoint.mobile}`,
  tablet: `@media ${breakpoint.tablet}`,
  desktop: `@media ${breakpoint.desktop}`,
};

export const updatedSizes = {
  tablet: 768,
  desktop: 1024,
};

export const maxWidthContent = '1280px'

export const updatedBreakpoint = {
  onlyMobile: `(max-width: ${updatedSizes.tablet - 1}px)`,
  tablet: `(min-width: ${updatedSizes.tablet}px)`,
  onlyTablet: `(min-width: ${updatedSizes.tablet}px) and (max-width: ${updatedSizes.desktop - 1}px)`,
  desktop: `(min-width: ${updatedSizes.desktop}px)`,
};

export const updatedMedia = {
  onlyMobile: `@media ${updatedBreakpoint.onlyMobile}`,
  tablet: `@media ${updatedBreakpoint.tablet}`,
  onlyTablet: `@media ${updatedBreakpoint.onlyTablet}`,
  desktop: `@media ${updatedBreakpoint.desktop}`,
};
