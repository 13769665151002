import {
  FETCH_PROPERTIES_RECOMMENDED_REQUEST,
  FETCH_PROPERTIES_RECOMMENDED_SUCCESS,
  FETCH_PROPERTIES_RECOMMENDED_FAILURE,
} from './constants';

export const fetchPropertiesRecommendedRequestAction = payload => ({
  type: FETCH_PROPERTIES_RECOMMENDED_REQUEST,
  payload,
});

export const fetchPropertiesRecommendedSuccessAction = payload => ({
  type: FETCH_PROPERTIES_RECOMMENDED_SUCCESS,
  payload,
});

export const fetchPropertiesRecommendedFailureAction = payload => ({
  type: FETCH_PROPERTIES_RECOMMENDED_FAILURE,
  payload,
});
