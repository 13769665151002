import { defineMessages } from 'react-intl';

const messages = defineMessages({
  checkIn: {
    id: 'search.checkin',
  },
  checkOut: {
    id: 'search.checkout',
  },
  search: {
    id: 'global.search',
  },
  onlyFor: {
    id: 'global.only-three-nights',
  },
});

export default messages;
