import React from 'react';
import { LoaderBase, LoaderInline, Dot } from './styles';

export interface LoaderProps {
  className?: string;
  color?: string;
  isInline?: boolean;
  margin?: string;
  top?: string;
}

const Loader: React.FC<LoaderProps> = ({
  className = '',
  isInline = false,
  color = '',
  margin,
  top = '0',
}) => {
  if (isInline) {
    return (
      <LoaderInline className={className} color={color} top={top} data-testid="loader">
        <Dot />
        <Dot />
        <Dot />
        <Dot />
      </LoaderInline>
    );
  }

  return (
    <LoaderBase className={className} color={color} data-testid="loader" margin={margin} top={top}>
      <Dot />
      <Dot />
      <Dot />
      <Dot />
    </LoaderBase>
  );
};

export default Loader;
