export function mapSuggestion(data) {
  return {
    citySlug: data.CitySlug,
    coordinates: data.Coordinate,
    country: data.Country,
    description: data.Description,
    id: data.ID,
    slug: data.Slug,
    stars: data.Stars,
    term: `${data.Text}, ${data.Description}`,
    text: data.Text,
    type: data.Type,
  };
}

export function mapSuggestions(data) {
  return data.map(suggestion => mapSuggestion(suggestion));
}

export function extractLocationParts(rawLocation) {
  const parts = rawLocation.split(',');
  const place = parts[0];
  const location = parts.splice(1).join();

  return { location, place };
}
