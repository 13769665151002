export const PAGES_LIST_REDIRECT = [
  'changePassword',
  'myCommunicationPreferences',
  'hotelsSeen',
  'myBookings',
  'myDetails',
  'myStaywallet',
  'prime',
  'primeReceipts',
  'primeCancel',
  'primeCancelled',
  'primeCheckout',
];
