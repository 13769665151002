import {
  FETCH_TA_REVIEWS_REQUEST,
  FETCH_TA_REVIEWS_SUCCESS,
  FETCH_TA_REVIEWS_FAILURE,
} from './constants';

export const fetchTAReviewsRequestAction = payload => ({
  type: FETCH_TA_REVIEWS_REQUEST,
  payload,
});

export const fetchTAReviewsSuccessAction = payload => ({
  type: FETCH_TA_REVIEWS_SUCCESS,
  payload,
});

export const fetchTAReviewsFailureAction = payload => ({
  type: FETCH_TA_REVIEWS_FAILURE,
  payload,
});
