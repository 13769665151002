import styled, { css } from 'styled-components';
import { colors, fonts, updatedMedia } from 'client/styles';
import { ButtonIcon } from 'src/comps/Button/styles';

export const iconStyles = { fill: colors.grey1 };

export const DatePickerDesktopContainer = styled.div`
  display: none;
  flex-direction-row;

  ${updatedMedia.desktop} {
    display: flex;
    position: relative;
  }  
`

export const DatePickerMobileContainer = styled.div`
  width: 100%;
  ${updatedMedia.desktop} {
    display: none;
  } 
`

export const DatePickerMobileBase = styled.div`
  display: flex;
  flex-direction: column;
  height: ${props => `${props.height}px`};
`;

export const Header = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid ${colors.grey4};
  background-color: ${colors.white};
  z-index: 20;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  ${updatedMedia.desktop} {
    display: none;
  }
`;

export const TopDates = styled.div`
  background-color: ${colors.white};
  display: flex;
  padding: 53px 20px 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const TopDate = styled.div`
  width: calc(50% - 0.5rem);
  background-color: ${colors.white};
  color: ${colors.grey1};
  border-radius: 3px;
  border: 2px solid ${props => (props.active ? colors.magenta : colors.grey4)};
  font-family: ${fonts.default};
  padding: 10px;
  line-height: 1.5;

  &:first-child {
    margin-right: 1rem;
  }
`;

export const DateLine = styled.span`
  display: block;
  font-weight: 700;
`;

export const Ready = styled.div`
  background-color: ${colors.white};
  bottom: 0;
  box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.15);
  left: 0;
  padding: 0.75em;
  position: absolute;
  width: 100%;
  z-index: 20;
  ${updatedMedia.desktop} {
    display: none;
  }
`;

export const ButtonClose = styled(ButtonIcon)`
  position: absolute;
  right: 16px;
  top: 18px;
  z-index: 11;
`;

export const DatePickerBase = styled.div`
  padding: 30px;
  padding-bottom: 20px;
  position: absolute;
  top: calc(100% + 10px);
  left: ${props => props.type === "default" ? '-50%' : '0'};
  border: 2px solid ${colors.grey1};
  border-radius: 8px 8px;
  background-color: ${colors.white};
  z-index: 20;  

  ${updatedMedia.onlyMobile} {
    padding: 0px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border: none;
  }

  ${updatedMedia.onlyTablet} {
    padding: 0px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border: none;
  }
`;

const startDate = css`
  color: ${colors.white};
  background-color: ${colors.magenta2};
  position: relative;

  :before {
    content: '';
    background-color: ${colors.white};
    top: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    width: 50%;
    z-index: 0;
  }

  span {
    background-color: ${colors.magenta};
    border-radius: 50%;
    z-index: 1;
  }
`;

const endDate = css`
  background-color: ${colors.magenta2};
  position: relative;

  :before {
    content: '';
    background-color: ${colors.white};
    top: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    width: 50%;
    z-index: 0;
  }

  span {
    background-color: ${colors.white};
    border: 2px solid ${colors.magenta};
    border-radius: 50%;
    z-index: 1;
  }
`;

const disabled = css`
  pointer-events: none;

  span {
    color: ${colors.grey3};
  }
`;

const selected = css`
  background-color: ${colors.magenta2};
`;

export const DatePicker = styled.div`
  overflow-y: auto;
`;

export const Month = styled.div`
  align-items: center;
  display: flex;
  font-weight: 700;
  padding: 0 8px 20px 8px;
  text-align: center;

  svg {
    flex: 0 0 auto;
  }

  p {
    flex: 1 0 auto;
    line-height: 1.5;
  }
`;

export const Week = styled.ul`
  display: flex;
  justify-content: space-between;
`;

const baseDayStyles = css`
  flex: 1 0 auto;
  text-align: center;
  font-size: 14px;
  line-height: 1.4;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
  width: 44px;
  height: 44px;

  span {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
  }
`;

export const StartDate = styled.li`
  ${baseDayStyles}
  ${startDate}
`;

export const EndDate = styled.li`
  ${baseDayStyles}
  ${endDate}
`;

export const SelectedDate = styled.li`
  ${baseDayStyles}
  ${selected}
`;

export const UnavailableDate = styled.li`
  ${baseDayStyles}
  ${disabled}
`;

export const DefaultDay = styled.li`
  ${baseDayStyles}
`;

export const AddMonths = styled(ButtonIcon)`
  width: 100%;
  padding: 15px 0;
  box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.15);
`;

export const WeekDays = styled.ul`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;

  ${props => props.id === 'small' && css`
    ${updatedMedia.desktop} {
      display: none;
    };
    ${updatedMedia.onlyMobile} {
      display: flex;
    }
    ${updatedMedia.onlyTablet} {
      display: flex;
    }
  `}

  ${props => props.id === 'desktop' && css`
    ${updatedMedia.desktop} {
      display: flex;
    };
    ${updatedMedia.onlyMobile} {
      display: none;
    }
    ${updatedMedia.onlyTablet} {
      display: none;
    }
  `}

  li {
    flex: 1 0 auto;
    font-size: 14px;
    line-height: 1.4;
    text-align: center;
    text-transform: lowercase;
  }
`;

const horizontalVariantStyles = css`
  :first-child {
    margin-right: 20px;
  }

  ${Month} {
    background-color: ${colors.white};
    text-transform: capitalize;
  }
`;

const verticalVariantStyles = css`
  ${WeekDays} {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    min-height: 40px;
  }
`;

export const CalendarWrapper = styled.div`
  border-bottom: 1px solid ${colors.grey4};
  display: flex;
  padding-bottom: 25px;
  margin-bottom: 16px;

  width: 100%;
  max-height: calc(100% - 270px);
  overflow-y: auto;
`;

export const CalendarBase = styled.div`
  ${props => (props.variant === 'horizontal' ? horizontalVariantStyles : verticalVariantStyles)};
`;

export const DatesResume = styled.p`
  color: ${colors.grey1};
  text-align: center;
  text-transform: lowercase;
  width: 100%;
  display: none;

  ${updatedMedia.desktop} {
    display: block;
    position: relative;
  }
`;
