import { PRIME_SUBSCRIPTION } from './constants';

const state = {
  [PRIME_SUBSCRIPTION]: {
    error: null,
    isFetching: false,
    status: null,
  },
};

export default state;
