export enum Devices {
  Desktop = 'desktop',
  Mobile = 'mobile',
  Tablet = 'tablet',
}

export enum BreakpointSizes {
  XS = 0,
  S = 320,
  M = 768,
  L = 1024,
}

export interface DeviceType {
  activeBreakpoint: BreakpointSizes;
  colorDepth: string | null;
  device: Devices;
  ip: string;
  isBot: boolean;
  javaEnabled: boolean;
  language: string | null;
  metadevice?: Devices;
  screenHeight: number;
  screenWidth: number;
  timeZone: number;
  viewport?: {
    height: number;
    width: number;
  };
}
