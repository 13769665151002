const hostnameToApiGateway = new Map([
  ['www.stayforlong.com', 'https://gw.stayforlong.com'],
  ['www.stayforlong.fr', 'https://gw.stayforlong.fr'],
  ['www.stayforlong.co.uk', 'https://gw.stayforlong.co.uk'],
  ['www.stayforlong.de', 'https://gw.stayforlong.de'],
]);

export const buildApiGatewayDomainForClient = (hostname = '') => {
  if (hostnameToApiGateway.has(hostname)) {
    return hostnameToApiGateway.get(hostname);
  }

  return process.env.NEXT_PUBLIC_API_GATEWAY;
};
