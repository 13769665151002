import { PROPERTIES_STATE } from './constants';
import filters from 'client/components/FiltersContainer/state';

const state = {
  [PROPERTIES_STATE]: {
    count: 0,
    distribution: [],
    error: null,
    isFetching: true,
    properties: [],
    slug: '',
    ...filters,
  },
};

export default state;
