/* eslint-disable camelcase */
import CookieClient from 'js-cookie';

import { COOKIE_AFFILIATES } from 'src/constants/cookies';
import { parseCookieValue } from 'src/utils/parseCookieValue';

export function getAffiliateTrackingFromCookie() {
  const cookie = CookieClient.get(COOKIE_AFFILIATES) || '{}';
  const affiliateData = parseCookieValue(cookie);

  const { it_cmp, it_tpl, ...rest } = affiliateData;

  return {
    ...rest,
    itbl_cmp: affiliateData?.it_cmp || '',
    itbl_tpl: affiliateData?.it_tpl || '',
    refid: affiliateData?.refid || '',
  };
}
