import { requestByGET } from 'lib/requests';
import { buildPathParams } from 'lib/utils/url';
import {
  mapFromDataToUserBooking,
  buildPropertiesFromBookingsRequest,
  mapFromDataToPropertyInfo,
} from './mappers';

const OPTIONS = {
  headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
  timeout: 30000,
  withCredentials: true,
};

const END_POINTS = {
  USER_BOOKINGS: '/user/bookings/list',
  PROPERTIES_VISITED: '/properties/ids',
};

export function getUserBookings(params) {
  return requestByGET({
    hostname: params.hostname,
    options: OPTIONS,
    mapper: mapFromDataToUserBooking,
    endpoint: buildPathParams(END_POINTS.USER_BOOKINGS),
  });
}

export function fetchPropertiesFromBookings(params) {
  return requestByGET({
    hostname: params.hostname,
    data: buildPropertiesFromBookingsRequest(params),
    mapper: mapFromDataToPropertyInfo,
    endpoint: END_POINTS.PROPERTIES_VISITED,
  });
}
