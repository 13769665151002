import React, { useState, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useMedia } from 'react-use';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import { formatISO } from 'lib/dates';

import { PageContext } from 'src/comps/Page/context';
import { formatMappings } from 'src/comps/DateIntl/constants';
import { getLanguage } from 'src/comps/Page/selectors';
import { updateSearchAction } from 'src/effects/Search/actions';

import { typeRef } from 'client/types';
import DatePicker from 'client/components/DatePicker';
import InputReadOnly from 'client/components/InputReadOnly';
import { updatedBreakpoint } from 'client/styles';

import { Content, DateSelectorBase } from './styles';
import messages from './messages';
import { noop } from 'lodash';

const DateSelector = ({
  name = '',
  checkIn,
  checkOut,
  isDatePickerOpen,
  setDatePickerOpen,
  searchRef,
  handleUpdate,
  type = "default",
  unFreezeScroll = false,
  onCheckInClick = noop,
  onCheckOutClick = noop,
}) => {
  const intl = useIntl();
  const { market, device } = useContext(PageContext);
  const isOnlyTablet = useMedia(updatedBreakpoint.onlyTablet, device === 'tablet');

  const dispatch = useDispatch();
  const language = useSelector(getLanguage);

  const [focusedInput, setFocusedInput] = useState();

  const formatDateBasedOnViewport = useCallback(() => {
    if (isOnlyTablet) {
      return formatMappings.DATE_INTL_FORMAT_TWO_DIGITS_ONLY;
    }
    return formatMappings.DATE_INTL_FORMAT_TWO_DIGITS;
  }, [isOnlyTablet]);

  function updateSearch(payload) {
    if (payload.checkIn && payload.checkOut) {
      dispatch(
        updateSearchAction({
          ...payload,
          checkIn: formatISO(payload.checkIn),
          checkOut: formatISO(payload.checkOut),
        })
      );

      if (handleUpdate) {
        handleUpdate();
      }
    }
  }

  function onDateInputKeyDown(event, input) {
    if (event.key === 'Enter') {
      event.preventDefault();
      setFocusedInput(input);
      setDatePickerOpen(true);
    }
  }

  function handleOpen(input) {
    setFocusedInput(input);
    setDatePickerOpen(true);
  }

  return (
    <DateSelectorBase>
      <Content type="default" isFocus={isDatePickerOpen}>
        <InputReadOnly
          inputName={`checkin${name}`}
          labelMessage={messages.checkIn}
          onClick={() => {
            handleOpen('checkin');
            onCheckInClick();
          }}
          onKeyDown={event => onDateInputKeyDown(event, 'checkin')}
          reference={searchRef}
          type="default"
          value={intl.formatDate(checkIn, formatDateBasedOnViewport())}
        />
        <InputReadOnly
          inputName={`checkout${name}`}
          labelMessage={messages.checkOut}
          onClick={() => {
            handleOpen('checkout')
            onCheckOutClick();
          }}
          onKeyDown={event => onDateInputKeyDown(event, 'checkout')}
          type="default"
          value={intl.formatDate(checkOut, formatDateBasedOnViewport())}
        />
        {isDatePickerOpen && (
          <DatePicker
            checkIn={checkIn}
            checkOut={checkOut}
            initialFocusedInput={focusedInput}
            language={language}
            market={market}
            onChangeDates={updateSearch}
            onClose={() => setDatePickerOpen(false)}
            type={type}
            unFreezeScroll={unFreezeScroll}
          />
        )}
      </Content>
    </DateSelectorBase>
  );
};

DateSelector.propTypes = {
  checkIn: PropTypes.instanceOf(Date).isRequired,
  checkOut: PropTypes.instanceOf(Date),
  isDatePickerOpen: PropTypes.bool,
  setDatePickerOpen: PropTypes.func,
  name: PropTypes.string,
  searchRef: typeRef,
  handleUpdate: PropTypes.func,
  type: PropTypes.oneOf(['default', 'availability']),
  unFreezeScroll: PropTypes.bool,
  onCheckInClick: PropTypes.func,
  onCheckOutClick: PropTypes.func,
};

export default DateSelector;
