import {
  MODIFY_DATES_CANCELLATION_FAILURE,
  MODIFY_DATES_CONFIRMATION_FAILURE,
  MODIFY_DATES_CONFIRMATION_REQUEST,
  MODIFY_DATES_CONFIRMATION_SUCCESS,
  MODIFY_DATES_FAILURE,
  MODIFY_DATES_REQUEST,
  MODIFY_DATES_RESET,
  MODIFY_DATES_SAVE_OFFER,
  MODIFY_DATES_SAVE_PAXES,
  MODIFY_DATES_SUCCESS,
} from './constants';

export const modifyDatesRequestAction = payload => ({
  type: MODIFY_DATES_REQUEST,
  payload,
});

export const modifyDatesSuccessAction = payload => ({
  type: MODIFY_DATES_SUCCESS,
  payload,
});

export const modifyDatesFailureAction = payload => ({
  type: MODIFY_DATES_FAILURE,
  payload,
});

export const resetStatusAction = payload => ({
  type: MODIFY_DATES_RESET,
  payload,
});

export const saveOfferAction = payload => ({
  type: MODIFY_DATES_SAVE_OFFER,
  payload,
});

export const saveDatesAndPaxesAction = payload => ({
  type: MODIFY_DATES_SAVE_PAXES,
  payload,
});

export const modifyDatesConfirmationRequestAction = payload => ({
  type: MODIFY_DATES_CONFIRMATION_REQUEST,
  payload,
});

export const modifyDatesConfirmationSuccessAction = payload => ({
  type: MODIFY_DATES_CONFIRMATION_SUCCESS,
  payload,
});

export const modifyDatesConfirmationFailureAction = payload => ({
  type: MODIFY_DATES_CONFIRMATION_FAILURE,
  payload,
});

export const modifyDatesCancellationFailureAction = payload => ({
  type: MODIFY_DATES_CANCELLATION_FAILURE,
  payload,
});
