/* eslint-disable camelcase */
import split from 'lodash/split';
import { mapConditions } from 'client/contexts/Property/mappers';

export function mapBedType(beds) {
  if (!beds) {
    return [];
  }

  return beds.map(item => ({
    type: item.type,
    description: item.description,
    quantity: item.quantity,
  }));
}

function mapFromDataToSpecialRequests(data) {
  const requestsArray = data === '' ? [] : split(data, ';');

  return requestsArray.map(request => {
    const pair = request.split(':');

    return {
      requestKey: pair.length === 1 ? '' : pair[0],
      requestValue: pair.length === 1 ? pair[0] : pair[1],
    };
  });
}

function mapFromDataToTickets(data) {
  if (!data) {
    return null;
  }

  const tickets =
    data &&
    data.map(ticket => ({
      idTicket: ticket.id_ticket,
      total: ticket.total,
      currency: ticket.currency,
      paymentMethod: ticket.payment_method,
      paymentStatus: ticket.payment_status,
      paymentType: ticket.payment_type,
      paymentDate: ticket.payment_date,
    }));

  return {
    ticketsPaid: tickets?.filter(ticket => ticket.paymentStatus === 'success'),
  };
}

export function mapImages(images) {
  return images.map(item => ({
    path: item.path,
    source: item.source,
    code: item.code,
    bookingIdRoom: item.booking_id_room,
  }));
}

function mapFromDataToCancellation(data) {
  return data.length > 0
    ? data.map(item => ({
      cancellationDate: item.cancellation_date,
      amount: item.amount,
      currency: item.currency,
    }))
    : [];
}

export function mapInsurance(insurance) {
  if (!insurance) {
    return null;
  }

  return {
    currency: insurance.currency,
    final: insurance.final,
    insurance: insurance.insurance,
    terms: insurance.terms,
    total: insurance.total,
  };
}

export function mapCreditCardInfo(data) {
  if (!data) {
    return null;
  }

  return {
    number: data?.number,
    holder: data?.holder,
    expirationDate: data?.expiration_date,
    brand: data?.brand,
  };
}

export function mapPartialAmounts(booking) {
  if (!booking.partial_amounts?.minimum && !booking.partial_amounts?.maximum) {
    return {};
  }

  return {
    min: booking.partial_amounts.minimum,
    max: booking.partial_amounts.maximum,
  };
}

export function mapBookingInformation(bookings) {
  if (!bookings) {
    return null;
  }

  return bookings.map(booking => ({
    ...mapFromDataToTickets(booking.tickets),
    adults: booking.adults,
    beds: mapBedType(booking.beds),
    boardCode: booking.board_code,
    boardName: booking.board_name,
    bookingDate: booking.created_at,
    bookingStatus: booking.booking_status,
    cancellationPolicies: mapFromDataToCancellation(booking.cancellation_policies),
    checkIn: booking.checkin,
    checkOut: booking.checkout,
    children: booking.children,
    childrenAges: booking.children_ages,
    comments: booking.comments,
    creditCard: mapCreditCardInfo(booking.credit_card),
    currency: booking.currency,
    generatedWallet: booking.generated_wallet,
    hash: booking.hash,
    holders: booking.holders || [],
    idBooking: booking.id_booking,
    insurance: mapInsurance(booking?.additional_products?.insurance),
    internal: booking.internal,
    isCancellable: booking.is_cancellable,
    isMobile: booking.rate_discounts?.mobile || false,
    isPayable: booking.payable,
    partialAmounts: mapPartialAmounts(booking),
    issues: booking.issues,
    language: booking.language,
    market: booking.market?.toLowerCase(),
    nights: booking.nights,
    paymentStatus: booking.payment_status,
    pendingAmount: booking.pending_amount,
    rateClass: booking.rate_class,
    rateName: booking.rate_name,
    roomName: booking.room_name,
    rooms: booking.rooms || 1,
    sellingRate: booking.selling_rate,
    specialRequests: [...mapFromDataToSpecialRequests(booking.requests)] || null,
    totalPaid: booking.total_paid,
    userFullName: booking.user?.full_name,
    voucher: booking.voucher,
  }));
}

export function mapLocation(location) {
  return {
    cityName: location.city_name,
    latitude: location.latitude,
    longitude: location.longitude,
    postalCode: location.postal_code,
  };
}

export function mapHotelPreview(hotel) {
  return {
    addressUrl: hotel.address_url,
    fullAddress: hotel.full_address,
    propertyAddress: hotel.address,
    propertyCity: hotel.city,
    propertyCountry: hotel.country,
    propertySlug: hotel.seoname,
    propertyId: hotel.id_property,
    propertyImages: mapImages(hotel?.images),
    propertyLocation: mapLocation(hotel?.location),
    propertyName: hotel.name,
    propertyStars: hotel.stars,
    propertyType: hotel.accommodation_type,
    conditions: mapConditions(hotel.conditions),
  };
}

export function mapPriceSummary(summary) {
  return {
    additionalFees: summary.additional_fees,
    currency: summary.currency,
    insurance: summary.insurance,
    pendingAmount: summary.pending_amount,
    price: summary.price,
    subtotal: summary.subtotal,
    taxes: summary.taxes,
    total: summary.total,
    wallet: summary.wallet,
    bigHead: summary.big_head,
    isPrime: summary.big_head_concept === 'prime',
  };
}

export function mapFromDataToRoom(bookings) {
  return bookings.map(booking => ({
    roomName: booking?.room_name,
    rooms: booking?.rooms,
    nights: booking?.nights,
    bookingStatus: booking?.booking_status,
    isRefundable: booking?.is_refundable,
  }));
}

export function mapVoucherResponse(data) {
  return {
    purchaseId: data.purchase_id,
    rooms: data.rooms,
    nights: data.nights,
    roomsFullInformation: mapFromDataToRoom(data?.booking_information),
    currency: data.currency,
    bookingInformation: mapBookingInformation(data?.booking_information),
    hotelPreview: mapHotelPreview(data?.hotel_preview),
    priceSummary: mapPriceSummary(data?.price_summary),
    payNow: data.pay_now,
  };
}

export function mapVoucherToTracking(voucher) {
  const booking = voucher?.bookingInformation[0];
  const property = voucher?.hotelPreview;
  const bookingIds = voucher?.bookingInformation.map(({ hash }) => hash);

  return {
    bookingId: bookingIds.length ? bookingIds : null,
    children: booking?.children,
    childrenAges: booking?.childrenAges,
    generatedWallet: booking?.generatedWallet,
    paymentMethod: booking?.ticketsPaid[0]?.paymentMethod,
    price: voucher?.priceSummary?.total,
    purchaseId: voucher.purchaseId,
    property: {
      accommodationType: property?.propertyType,
      citySlug: property?.propertyCity,
      country: property?.propertyCountry,
      id: property?.propertyId,
      name: property?.propertyName,
    },
    board: {
      boardName: booking?.boardName,
      code: booking?.boardCode,
      currency: booking?.currency,
      rateClass: booking?.rateClass,
      subtotal: voucher?.priceSummary?.subtotal,
    },
  };
}
