import React, { createContext, ReactElement, useMemo, useRef } from 'react';

import { IBus, ProducerData, ConsumerCallback } from 'src/types/bus';

const BusContext = createContext<IBus | null>(null);

interface IBusProvider {
  children: ReactElement;
}

const BusProvider = ({ children }: IBusProvider) => {
  const bus = useRef<Map<string, Set<ConsumerCallback>>>(new Map());

  const api = useMemo(() => {
    return {
      send: (topic: string, payload?: ProducerData) => {
        if(!bus.current.has(topic)) return;
        const callbacks = bus.current.get(topic);
        callbacks?.forEach(callback => {
          callback(payload);
        })
      },
      subscribe: (topic: string, callback: ConsumerCallback) => {
        if(bus.current.has(topic)) {
          const callbacks = bus.current.get(topic);
          callbacks?.add(callback);
        } else {
          bus.current.set(topic, new Set([callback]));
        }

        return () => {
          if(bus.current.has(topic)) {
            const callbacks = bus.current.get(topic);
            callbacks?.delete(callback);
          }
        }
      }
    }
  }, []);

  return (
    <BusContext.Provider value={api}>
      {children}
    </BusContext.Provider>
  )
}

export { BusContext, BusProvider };
