/* eslint-disable no-underscore-dangle */
import React, { ReactElement } from 'react';
import { IntlProvider } from 'react-intl';
import { getTranslationMessages } from 'src/context/intl/helpers';

const IntlCustomProvider = ({
  children,
  locale,
  messages,
}: {
  children: ReactElement;
  locale: string;
  messages?: Record<string, string>;
}) => {
  if (typeof locale !== 'string' || (typeof locale === 'string' && !locale?.length)) {
    throw new Error('IntlCustomProvider provider requires locale definition');
  }

  const newMessages = getTranslationMessages(messages);

  return (
    <IntlProvider locale={locale} messages={newMessages}>
      {children}
    </IntlProvider>
  );
};

export { IntlCustomProvider };
