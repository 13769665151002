import styled, { css } from 'styled-components';
import { colors, fonts, updatedMedia } from 'client/styles';
import { ButtonIcon } from 'src/comps/Button/styles';

export const inputStyles = css`
  background-color: transparent;
  border: none;
  color: ${colors.grey1};
  font-family: ${fonts.default};
  font-size: 16px;
  font-weight: 700;
  padding: 17px 8px;
  text-overflow: ellipsis;
  width: 100%;

  :focus {
    outline: none;
  }

  ::placeholder {
    font-weight: 500;
  }
`;

export const LocationSelectorBase = styled.div`
  background-color: ${colors.white};

  input#searchform-search {
    ${inputStyles};
  }

  ${updatedMedia.tablet} {
    margin-bottom: 0;
  }
`;

export const Label = styled.label`
  display: flex;
  place-content: center;
  align-items: center;
  width: 100%;
  > svg {
    padding-left: 12px;
    width: 36px;
  }
`;

export const LocationField = styled.div`
  align-items: center;
  background-color: ${colors.white};
  border-radius: 8px;
  border: 2px solid;
  border-color: ${props => (props.isFocus ? colors.grey1 : colors.grey4)};
  display: flex;

  ${updatedMedia.desktop} {
    &:hover {
      border-color: ${colors.grey1};
    }
  }

  svg {
    flex: 0 0 auto;
    cursor: pointer;
  }

  input#searchform-search {
    flex: 1 0 auto;
  }
`;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  height: 60px;
  width: calc(100% - 36px);

  input {
    border: none;
  }

  ${props =>
    props.isOpen &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${colors.white};
      z-index: 1;
      align-items: flex-start;
    `}

  ${props =>
    props.isOpen &&
    `
      &>div {
        display: flex;
        border-bottom: 2px solid ${props.theme.colors.grey5};
      justify-content: space-between;
      width: 100%;
      align-items: center;
    }
  `}
`;

export const WrapperContentMobile = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  height: 60px;
`;

export const ButtonClose = styled(ButtonIcon)`
  svg {
    padding-left: 12px;
    width: 36px;
  }
`;
