import styled, { css } from 'styled-components';
import { colors, updatedMedia } from 'client/styles';

const baseText = css`
  color: ${props => props.theme.colors.grey8};
  font-size: 1rem;
  line-height: 24px;

  ::first-letter {
    text-transform: uppercase;
  }
`;

export const FooterBase = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${props => props.theme.colors.berlin};
  z-index: 1;
`;

export const Section = styled.div`
  width: 100%;
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  text-align: center;
  gap: 1rem;

  ${updatedMedia.tablet} {
    flex-wrap: nowrap;
    flex-direction: row;
    margin: 0 auto;
    justify-content: space-between;
  }

  > svg {
    fill: white;
    width: 40px;
    height: 40px;
  }

  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
  }

  li {
    ${baseText};

    ${updatedMedia.tablet} {
      white-space: nowrap;
    }
  }

  a {
    ${baseText};
    font-weight: 700;
    text-decoration: underline;
  }
`;

export const MainBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 48px 24px;

  ${updatedMedia.tablet} {
    padding: 48px;
  }

  ${updatedMedia.desktop} {
    padding: 40px 80px;
    max-width: 1280px;
  }
`;

export const LinkIcon = styled.a`
  align-items: center;
  cursor: pointer;
  display: flex;
  text-decoration: none;
  width: fit-content;

  :hover {
    text-decoration: underline;
    text-decoration-color: ${colors.white};
  }

  svg {
    fill: ${colors.white};
  }

  span {
    color: ${colors.white};
    margin-left: 10px;
    font-size: 14px;
  }
`;
