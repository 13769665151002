import {
  FETCH_APPLIED_WALLET_REQUEST,
  FETCH_APPLIED_WALLET_SUCCESS,
  FETCH_APPLIED_WALLET_FAILURE,
  FETCH_WALLET_REQUEST,
  FETCH_WALLET_SUCCESS,
  FETCH_WALLET_FAILURE,
  CHECK_PURCHASE_WALLET_REQUEST,
  CHECK_PURCHASE_WALLET_SUCCESS,
  CHECK_PURCHASE_WALLET_FAILURE,
  UNCHECK_PURCHASE_WALLET_REQUEST,
  UNCHECK_PURCHASE_WALLET_SUCCESS,
  UNCHECK_PURCHASE_WALLET_FAILURE,
} from './constants';

export const fetchAppliedWalletRequestAction = payload => ({
  type: FETCH_APPLIED_WALLET_REQUEST,
  payload,
});

export const fetchAppliedWalletSuccessAction = payload => ({
  type: FETCH_APPLIED_WALLET_SUCCESS,
  payload,
});

export const fetchAppliedWalletFailureAction = payload => ({
  type: FETCH_APPLIED_WALLET_FAILURE,
  payload,
});

export const fetchWalletRequestAction = payload => ({
  type: FETCH_WALLET_REQUEST,
  payload,
});

export const fetchWalletSuccessAction = payload => ({
  type: FETCH_WALLET_SUCCESS,
  payload,
});

export const fetchWalletFailureAction = payload => ({
  type: FETCH_WALLET_FAILURE,
  payload,
});

export const checkPurchaseWalletRequestAction = payload => ({
  type: CHECK_PURCHASE_WALLET_REQUEST,
  payload,
});

export const checkPurchaseWalletSuccessAction = payload => ({
  type: CHECK_PURCHASE_WALLET_SUCCESS,
  payload,
});

export const checkPurchaseWalletFailureAction = payload => ({
  type: CHECK_PURCHASE_WALLET_FAILURE,
  payload,
});

export const uncheckPurchaseWalletRequestAction = payload => ({
  type: UNCHECK_PURCHASE_WALLET_REQUEST,
  payload,
});

export const uncheckPurchaseWalletSuccessAction = payload => ({
  type: UNCHECK_PURCHASE_WALLET_SUCCESS,
  payload,
});

export const uncheckPurchaseWalletFailureAction = payload => ({
  type: UNCHECK_PURCHASE_WALLET_FAILURE,
  payload,
});
