export const MODIFY_DATES_STATE = 'modifyDates';

export const MODIFY_DATES_REQUEST = 'MODIFY_DATES_REQUEST';
export const MODIFY_DATES_SUCCESS = 'MODIFY_DATES_SUCCESS';
export const MODIFY_DATES_FAILURE = 'MODIFY_DATES_FAILURE';
export const MODIFY_DATES_RESET = 'MODIFY_DATES_RESET';
export const MODIFY_DATES_SAVE_OFFER = 'MODIFY_DATES_SAVE_OFFER';
export const MODIFY_DATES_SAVE_PAXES = 'MODIFY_DATES_SAVE_PAXES';

export const MODIFY_DATES_CONFIRMATION_REQUEST = 'MODIFY_DATES_CONFIRMATION_REQUEST';
export const MODIFY_DATES_CONFIRMATION_SUCCESS = 'MODIFY_DATES_CONFIRMATION_SUCCESS';
export const MODIFY_DATES_CONFIRMATION_FAILURE = 'MODIFY_DATES_CONFIRMATION_FAILURE';
export const MODIFY_DATES_CANCELLATION_FAILURE = 'MODIFY_DATES_CANCELLATION_FAILURE';

export const CHECK_AVAILABILITY = 'CHECK_AVAILABILITY';
export const SELECT_OFFER = 'SELECT_OFFER';
export const CONFIRM_OFFER = 'CONFIRM_OFFER';
export const ERROR_CANCELLATION = 'ERROR_CANCELLATION';
export const ERROR_CONFIRMATION = 'ERROR_CONFIRMATION';

export const STATUS_OK = 'STATUS_OK';
export const STATUS_NOT_OK = 'STATUS_NOT_OK';
