import {
  PRIME_CANCEL_REQUEST,
  PRIME_CANCEL_FAILURE,
  PRIME_CANCEL_SUCCESS,
  PRIME_REACTIVATE_FAILURE,
  PRIME_REACTIVATE_SUCCESS,
  PRIME_REACTIVATE_REQUEST,
  PRIME_SUBSCRIPTION,
  PRIME_SUBSCRIPTION_STATUS_CANCELLED,
  PRIME_SUBSCRIPTION_STATUS_ACTIVE,
} from './constants';
import stateBase from './state';

const initialState = stateBase[PRIME_SUBSCRIPTION];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PRIME_REACTIVATE_REQUEST:
    case PRIME_CANCEL_REQUEST: {
      return {
        ...state,
        error: null,
        status: null,
        isFetching: true,
      };
    }

    case PRIME_CANCEL_SUCCESS: {
      return {
        ...state,
        error: null,
        isFetching: false,
        status: PRIME_SUBSCRIPTION_STATUS_CANCELLED,
      };
    }

    case PRIME_REACTIVATE_SUCCESS: {
      return {
        ...state,
        error: null,
        isFetching: false,
        status: PRIME_SUBSCRIPTION_STATUS_ACTIVE,
      };
    }

    case PRIME_REACTIVATE_FAILURE:
    case PRIME_CANCEL_FAILURE: {
      return {
        ...state,
        error: action.payload.error,
        isFetching: false,
        status: null,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
