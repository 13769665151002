import { PAY_NOW_REQUEST, PAY_NOW_SUCCESS, PAY_NOW_FAILURE, PAY_NOW_SET_SECURECODE } from './constants';

export const payNowRequestAction = payload => ({
  type: PAY_NOW_REQUEST,
  payload,
});

export const payNowSuccessAction = payload => ({
  type: PAY_NOW_SUCCESS,
  payload,
});

export const payNowFailureAction = payload => ({
  type: PAY_NOW_FAILURE,
  payload,
});

export const payNowSetSecureCode = payload => ({
  type: PAY_NOW_SET_SECURECODE,
  payload,
})
