import s4lColors from 'src/styles/colors/colors';
import fontSizes from 'src/styles/typography/fontSizes';
import fontFamilies from 'src/styles/typography/fonts';

const lightTheme = {
  colors: s4lColors,
  fonts: {
    sizes: fontSizes,
    families: fontFamilies
  }
}

export default lightTheme;
