import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { clickFooterLink } from 'client/contexts/Tracking/events';
import { sendTrackingRequestAction } from 'client/contexts/Tracking/actions';

import Link from 'next/link';
import ExternalLink from 'src/comps/ExternalLink';

import IconInstagram from 'client/icons/social/Instagram';
import { useMarket } from 'src/hooks/useMarket';
import { LINK_ATTRIBUTES, SOCIAL_PAGES } from './constants';

import { FooterBase, LinkIcon, MainBlock, Section } from './styles';

const Footer = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { faqsUrl } = useMarket();
  const year = { year: new Date().getFullYear() };

  function handleTracking(event) {
    event.stopPropagation();
    dispatch(sendTrackingRequestAction({ event: clickFooterLink(event.target.id) }));
  }

  return (
    <FooterBase>
      <MainBlock>
        <Section>
          <ul>
            <li>
              <Link
                id="aboutUs"
                href="/the-brand/home"
                onClick={handleTracking}
                passHref
                legacyBehavior={false}
              >
                {intl.formatMessage({ id: 'global.about-stayforlong' })}
              </Link>
            </li>
            <li>
              <Link
                id="jobs"
                href="/the-brand/open-positions"
                onClick={handleTracking}
                passHref
                legacyBehavior={false}
              >
                {intl.formatMessage({ id: 'general.jobs' })}
              </Link>
            </li>
            <li>
              <Link
                id="reviews"
                href="/reviews"
                onClick={handleTracking}
                passHref
                legacyBehavior={false}
              >
                {intl.formatMessage({ id: 'global.reviews' })}
              </Link>
            </li>
            <li>
              <Link
                id="privacy"
                href="/privacy"
                onClick={handleTracking}
                passHref
                legacyBehavior={false}
              >
                {intl.formatMessage({ id: 'global.privacy' })}
              </Link>
            </li>
            <li>
              <Link
                id="generalConditions"
                href="/general-conditions"
                onClick={handleTracking}
                passHref
                legacyBehavior={false}
              >
                {intl.formatMessage({ id: 'legal.terms' })}
              </Link>
            </li>
            <li>
              <Link
                id="cookies"
                href="/cookies"
                onClick={handleTracking}
                passHref
                legacyBehavior={false}
              >
                {intl.formatMessage({ id: 'legal.cookies' })}
              </Link>
            </li>
            <li>
              <ExternalLink
                handleTracking={event => handleTracking(event)}
                id="faqs"
                url={faqsUrl}
              />
            </li>
          </ul>

          <ul>
            <li>
              <LinkIcon href={SOCIAL_PAGES.instagram} {...LINK_ATTRIBUTES}>
                <IconInstagram />
              </LinkIcon>
            </li>
          </ul>
        </Section>

        <Section>
          <ul>
            <li>{intl.formatMessage({ id: 'legal.copyright' }, year)}</li>
          </ul>
        </Section>
      </MainBlock>
    </FooterBase>
  );
};

Footer.propTypes = {
  id: PropTypes.string,
};

export default Footer;
