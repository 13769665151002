import { SPECIAL_REQUESTS_STATE } from './constants';

const state = {
  [SPECIAL_REQUESTS_STATE]: {
    bookings: {},
    error: null,
    isFetching: false,
    status: null,
  },
};

export default state;
