import styled from 'styled-components';
import { ButtonText } from 'src/comps/Button/styles';
import { colors, updatedMedia } from 'client/styles';

export const PaxSelectorBase = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;

  & > div:first-child {
    flex: 1 1 auto;
    overflow-y: auto;
  }

  ${updatedMedia.desktop} {
    display: block;
    height: auto;
    max-height: none;
    border: 2px solid ${colors.grey1};
    border-radius: 8px;
    z-index: 20;

    & > div:first-child {
      flex: auto;
      overflow-y: inherit;
    }
  }
`;

export const AddRoom = styled.div`
  flex: 0 0 auto;
  padding: 20px;
  border-top: 1px solid ${colors.grey4};
  display: grid;
  grid-auto-flow: column;
  gap: 10px;
  justify-content: space-between;
  margin-top: auto;

  button {
    width: auto;
  }
`;

export const AgeRange = styled.span`
  display: block;
  font-size: 12px;
  line-height: 1.4;
  color: ${colors.grey2};
`;

export const ListItems = styled.li`
  padding: 1rem;
  border-bottom: 1px solid ${colors.grey4};
  display: flex;
  flex-direction: column;
`;

export const TitleRoom = styled.span`
  color: ${colors.dark};
  font-weight: 700;
`;

export const ItemSelector = styled.li`
  margin-top: 20px;
`;

export const ListChildrenAges = styled.ul`
  padding-left: 10px;

  li {
    margin-top: 10px;
  }
`;

export const RemoveRoom = styled(ButtonText)`
  display: inline-block;
  margin-top: 15px;
  padding: 15px;
  align-self: flex-end;
  text-align: right;
  font-weight: 700;
`;

export const TitleAges = styled.span`
  display: block;
  margin: 1rem 0;
`;

export const Inputs = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
