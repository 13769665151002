import React, { createContext, ReactElement, useMemo, useState } from 'react';

import { SessionType } from 'src/types/session';

const SessionContext = createContext<SessionType | null>(null);

const SessionProvider = ({
  children,
  value,
}: {
  children: ReactElement;
  value: SessionType | null;
}) => {
  if (value === null) {
    throw new Error('SessionContext provider can not have a null value');
  }

  const [{ customer, isLogged, id }, setSession] = useState(value);

  const sessionContextValue = useMemo(
    () => ({
      customer,
      isLogged,
      id,
      setSession,
    }),
    [customer, isLogged, id]
  );

  return <SessionContext.Provider value={sessionContextValue}>{children}</SessionContext.Provider>;
};

export { SessionContext, SessionProvider };
