import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_STATE,
  FETCH_CUSTOMER_DATA_REQUEST,
  FETCH_CUSTOMER_DATA_SUCCESS,
  FETCH_CUSTOMER_DATA_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  LOGIN_GOOGLE_REQUEST,
  SET_ANONYMOUS_USER,
} from './constants';
import { PENDING, REJECTED, RESOLVED, IDLE } from 'src/effects/constants';
import stateBase from './state';
import { CREATE_USER_SUCCESS, UPDATE_USER_SUCCESS } from 'src/effects/User/constants';

const initialState = stateBase[LOGIN_STATE];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
    case LOGIN_GOOGLE_REQUEST:
    case FETCH_CUSTOMER_DATA_REQUEST:
    case LOGOUT_REQUEST: {
      return {
        ...state,
        error: null,
        isFetching: true,
        status: PENDING,
      };
    }

    case LOGIN_SUCCESS:
    case FETCH_CUSTOMER_DATA_SUCCESS: {
      return {
        data: action.payload,
        error: null,
        isFetching: false,
        isLoggedIn: true,
        status: RESOLVED,
      };
    }

    case CREATE_USER_SUCCESS: {
      return {
        data: action.payload.data,
        error: null,
        isFetching: false,
        isLoggedIn: true,
        status: RESOLVED,
      };
    }

    case UPDATE_USER_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          name: action.payload.data.firstName,
          last: action.payload.data.lastName,
          phone: action.payload.data.phone
        }
      };
    }

    case LOGIN_FAILURE:
    case FETCH_CUSTOMER_DATA_FAILURE:
    case LOGOUT_FAILURE: {
      return {
        data: null,
        error: action.payload.error,
        isFetching: false,
        isLoggedIn: false,
        status: REJECTED,
      };
    }

    case LOGOUT_SUCCESS: {
      return {
        data: null,
        error: false,
        isFetching: false,
        isLoggedIn: false,
        status: IDLE,
      };
    }

    case SET_ANONYMOUS_USER: {
      return {
        data: null,
        error: false,
        isFetching: false,
        isLoggedIn: false,
        status: RESOLVED,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
