import axios from 'axios';

import { buildApiGatewayDomain } from 'src/pages/withSession/config/system/server';

const OPTIONS = {
  headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
  timeout: 30000,
};

const defaultMapper = data => data;

export function requestByGET({
  hostname = buildApiGatewayDomain(),
  endpoint,
  data,
  options = OPTIONS,
  mapper = defaultMapper,
}) {
  return axios
    .get(`${hostname}${endpoint}`, { params: data, ...options })
    .then(res => ({ data: mapper(res.data), status: res.status }))
    .catch(error => error.response);
}

export function requestByPOST({
  hostname = buildApiGatewayDomain(),
  endpoint,
  data,
  options = OPTIONS,
  mapper = defaultMapper,
}) {
  return axios
    .post(`${hostname}${endpoint}`, data, options)
    .then(res => ({ data: mapper(res.data), status: res.status }))
    .catch(error => error.response);
}

export function requestByPATCH({
  hostname = buildApiGatewayDomain(),
  endpoint,
  data,
  options = OPTIONS,
  mapper = defaultMapper,
}) {
  return axios
    .patch(`${hostname}${endpoint}`, data, options)
    .then(res => ({ data: mapper(res.data), status: res.status }))
    .catch(error => error.response);
}
