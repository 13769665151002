import {
  CLEAN_EMAIL_VOUCHER,
  FETCH_VOUCHER_FAILURE,
  FETCH_VOUCHER_REQUEST,
  FETCH_VOUCHER_SUCCESS,
  REFETCH_VOUCHER,
  SAVE_EMAIL_VOUCHER,
  UPDATE_VOUCHER,
} from './constants';

export const fetchVoucherRequestAction = payload => ({
  type: FETCH_VOUCHER_REQUEST,
  payload,
});

export const fetchVoucherSuccessAction = payload => ({
  type: FETCH_VOUCHER_SUCCESS,
  payload,
});

export const fetchVoucherFailureAction = payload => ({
  type: FETCH_VOUCHER_FAILURE,
  payload,
});

export const refetchVoucherAction = payload => ({
  type: REFETCH_VOUCHER,
  payload,
});

export const updateVoucherAction = payload => ({
  type: UPDATE_VOUCHER,
  payload,
});

export const saveEmailAction = payload => ({
  type: SAVE_EMAIL_VOUCHER,
  payload,
});

export const cleanEmailAction = payload => ({
  type: CLEAN_EMAIL_VOUCHER,
  payload,
});
