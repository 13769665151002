import {
  ACTIVATE_ACCOUNT_STATE,
  ACTIVATE_ACCOUNT_REQUEST,
  ACTIVATE_ACCOUNT_SUCCESS,
  ACTIVATE_ACCOUNT_FAILURE,
} from './constants';
import stateBase from './state';

const initialState = stateBase[ACTIVATE_ACCOUNT_STATE];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIVATE_ACCOUNT_REQUEST:
      return {
        ...state,
        error: null,
        isFetching: true,
        activatedAccount: null,
      };

    case ACTIVATE_ACCOUNT_SUCCESS:
      return {
        error: null,
        isFetching: false,
        activatedAccount: true,
      };

    case ACTIVATE_ACCOUNT_FAILURE:
      return {
        error: action.payload.error,
        isFetching: false,
        activatedAccount: false,
      };

    default:
      return state;
  }
};

export default reducer;
