import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { InputGroup, Label, Input } from './styles';
import { typeRef } from 'client/types';

const InputReadOnly = ({
  dataTestId,
  inputName,
  labelMessage,
  onClick,
  onKeyDown = onClick,
  placeholder,
  reference,
  value,
}) => (
  <InputGroup>
    {labelMessage && (
      <Label htmlFor={inputName}>
        <FormattedMessage {...labelMessage} />
        <Input
          data-testid={dataTestId}
          id={inputName}
          name={inputName}
          onClick={onClick}
          ref={reference}
          onFocus={event => event.currentTarget.blur()}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          readOnly
          type="text"
          value={value}
        />
      </Label>
    )}

    {!labelMessage && (
      <Input
        data-testid={dataTestId}
        id={inputName}
        name={inputName}
        onClick={onClick}
        ref={reference}
        onFocus={event => event.currentTarget.blur()}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        readOnly
        type="text"
        value={value}
      />
    )}
  </InputGroup>
);

InputReadOnly.propTypes = {
  dataTestId: PropTypes.string,
  inputName: PropTypes.string.isRequired,
  labelMessage: PropTypes.shape({ id: PropTypes.string, defaultMessage: PropTypes.string }),
  onClick: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string,
  reference: typeRef,
  value: PropTypes.string,
};

export default InputReadOnly;
