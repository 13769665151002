import { SEND_VOUCHER_SUCCESS, SEND_VOUCHER_REQUEST, SEND_VOUCHER_FAILURE } from './constants';

export const sendVoucherRequestAction = payload => ({
  type: SEND_VOUCHER_REQUEST,
  payload,
});

export const sendVoucherSuccessAction = payload => ({
  type: SEND_VOUCHER_SUCCESS,
  payload,
});

export const sendVoucherFailureAction = payload => ({
  type: SEND_VOUCHER_FAILURE,
  payload,
});
