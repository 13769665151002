import { bool, shape, number, string, arrayOf, oneOfType } from 'prop-types';
import { typePhoto } from 'client/types/property';

export const typeBed = shape({
  type: string,
  description: string,
  quantity: number,
});

export const typeInsurance = shape({
  amount: number,
  currency: string.isRequired,
  description: string,
  terms: string.isRequired,
});

export const typeBoard = shape({
  adults: number,
  allotment: number,
  availabilityId: string,
  beds: arrayOf(typeBed),
  boardName: string,
  cancellation: arrayOf(shape({ from: string, amount: number })),
  checkIn: string,
  checkOut: string,
  children: number,
  code: string,
  currency: string,
  discount: number,
  finalPrice: number,
  id: string,
  initialPrice: number,
  insurance: typeInsurance,
  insuranceSelected: bool,
  isBestPrice: bool,
  isMandatory: bool,
  isPartialPayment: bool,
  isRecheck: bool,
  paymentPolicy: string,
  payRate: number,
  photos: arrayOf(oneOfType([typePhoto, string])),
  pricePerNight: number,
  roomName: string,
  roomsNumber: number,
  sellingRate: number,
  stayWalletCredit: number,
  supplierTerms: string,
  taxes: number,
});

export const typeCapacity = shape({
  adults: number.isRequired,
  children: number.isRequired,
});
