import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import IconClose from 'client/icons/Close';
import messages from './messages';
import { AlertMessageBase } from './styles';

const AlertMessage = ({ messageId, severity = 'error', handleClose, values }) => {
  if (!messageId) {
    return null;
  }

  return (
    <AlertMessageBase role="status" severity={severity}>
      <FormattedMessage {...messages[messageId]} tagName="p" values={values} />
      <IconClose data-testid="button-close" onClick={handleClose} />
    </AlertMessageBase>
  );
};

AlertMessage.propTypes = {
  handleClose: PropTypes.func.isRequired,
  messageId: PropTypes.string,
  severity: PropTypes.string,
  values: PropTypes.instanceOf(Object),
};

export default AlertMessage;
