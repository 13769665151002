import {
  FETCH_PROPERTIES_REQUEST,
  FETCH_PROPERTIES_SUCCESS,
  FETCH_PROPERTIES_FAILURE,
  PROPERTIES_STATE,
} from './constants';
import stateBase from './state';

const initialState = stateBase[PROPERTIES_STATE];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROPERTIES_REQUEST: {
      if (action.payload.clean) {
        return {
          ...state,
          count: 0,
          error: null,
          isFetching: true,
          properties: [],
        };
      }

      return { ...state, isFetching: true, error: null };
    }

    case FETCH_PROPERTIES_SUCCESS: {
      const { count = 0, properties = [], distribution = [] } = action.payload;

      return {
        ...state,
        count,
        distribution,
        error: null,
        isFetching: false,
        properties: [...state.properties, ...properties],
      };
    }

    case FETCH_PROPERTIES_FAILURE:
      return {
        ...state,
        count: 0,
        distribution: [],
        error: action.payload.error,
        isFetching: false,
      };

    default:
      return state;
  }
};

export default reducer;
