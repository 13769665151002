import { requestByPOST } from 'lib/requests';

const OPTIONS = {
  headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
  timeout: 30000,
  withCredentials: true,
};

const END_POINTS = {
  ACTIVATE_ACCOUNT: '/users/activation/update',
};

export function activateAccount(params) {
  return requestByPOST({
    data: { hash: params.data.hash, password: params.data.password },
    endpoint: END_POINTS.ACTIVATE_ACCOUNT,
    hostname: params.hostname,
    options: OPTIONS,
  });
}
