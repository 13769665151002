export function mapUserData(data) {
  return {
    userId: data.id,
    email: data.email,
    last: data.lastName,
    name: data.firstName,
    phone: data.phone,
    subscriptionCurrency: data.subscription?.currency,
    subscriptionExpiration: data.subscription?.expiration,
    subscriptionPrice: data.subscription?.price,
    subscriptionPrime: data.subscription?.prime,
    subscriptionRenewal: data.subscription?.renewal,
  };
}
