export const AVAILABILITY_STATE = 'availability';

export const FETCH_AVAILABILITY_REQUEST = 'FETCH_AVAILABILITY_REQUEST';
export const FETCH_AVAILABILITY_SUCCESS = 'FETCH_AVAILABILITY_SUCCESS';
export const FETCH_AVAILABILITY_FAILURE = 'FETCH_AVAILABILITY_FAILURE';

export const FETCH_BOOKING_RULES_REQUEST = 'FETCH_BOOKING_RULES_REQUEST';
export const FETCH_BOOKING_RULES_SUCCESS = 'FETCH_BOOKING_RULES_SUCCESS';
export const FETCH_BOOKING_RULES_FAILURE = 'FETCH_BOOKING_RULES_FAILURE';

export const ENABLE_INSURANCE = 'ENABLE_INSURANCE';
