import {
  TA_REVIEWS_STATE,
  FETCH_TA_REVIEWS_REQUEST,
  FETCH_TA_REVIEWS_SUCCESS,
  FETCH_TA_REVIEWS_FAILURE,
} from './constants';
import stateBase from './state';

const initialState = stateBase[TA_REVIEWS_STATE];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TA_REVIEWS_REQUEST:
      return {
        ...state,
        error: null,
        isFetching: true,
      };

    case FETCH_TA_REVIEWS_SUCCESS:
      return {
        ...state,
        error: null,
        isFetching: false,
        data: action.payload,
      };

    case FETCH_TA_REVIEWS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isFetching: false,
      };

    default:
      return state;
  }
};

export default reducer;
