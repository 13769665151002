import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';

import { pageView } from 'client/contexts/Tracking/events';
import { sendTrackingRequestAction } from 'client/contexts/Tracking/actions';
import { updateBodyId } from 'lib/utils/page';
import isEmpty from 'lodash/isEmpty';

export function usePageview(page, data, isLogged = false, userData = {}, updateId = true) {
  const dispatch = useDispatch();
  const { query } = useRouter();

  useEffect(() => {
    if (updateId) {
      updateBodyId(page);
    }
    if (!isLogged) {
      dispatch(
        sendTrackingRequestAction({ event: pageView[page]({ ...data, queryParams: query }) })
      );
    }

    if (isLogged && !isEmpty(userData)) {
      dispatch(
        sendTrackingRequestAction({ event: pageView[page]({ ...data, queryParams: query }) })
      );
    }
  }, [isLogged, isEmpty(userData)]); // eslint-disable-line react-hooks/exhaustive-deps
}
