import {
  CANCEL_BOOKING_REQUEST,
  CANCEL_BOOKING_SUCCESS,
  CANCEL_BOOKING_FAILURE,
  CANCEL_BOOKING_REJECTED,
} from './constants';

export const fetchCancelBookingRequestAction = payload => ({
  type: CANCEL_BOOKING_REQUEST,
  payload,
});

export const fetchCancelBookingSuccessAction = payload => ({
  type: CANCEL_BOOKING_SUCCESS,
  payload,
});

export const fetchCancelBookingFailureAction = payload => ({
  type: CANCEL_BOOKING_FAILURE,
  payload,
});

export const fetchCancelBookingRejectedAction = payload => ({
  type: CANCEL_BOOKING_REJECTED,
  payload,
});
