export const CREDIT_CARD_PAYMENT_METHOD = 'creditcard';
export const ALTERNATIVE_PAYMENT_METHOD = 'apm';

export const PAYMENT_METHODS = [CREDIT_CARD_PAYMENT_METHOD, ALTERNATIVE_PAYMENT_METHOD];

export const VOUCHER = 'voucher';
export const CHECKOUT = 'checkout';
export const PRIME = 'prime';

export const bankErrorCodes = [1, 34, 39];

export const PAYMENT_METHOD_VISA_ID = 'visa';
export const PAYMENT_METHOD_MASTERCARD_ID = 'mastercard';
export const PAYMENT_METHOD_AMEX_ID = 'amex';
export const PAYMENT_METHOD_GIROPAY_ID = 'giropay';
export const PAYMENT_METHOD_KLARNA_ID = 'klarna';
export const PAYMENT_METHOD_IDEAL_ID = 'ideal';
export const PAYMENT_METHOD_MULTIBANCO_ID = 'multibanco';
export const PAYMENT_METHOD_BANCONTACT_ID = 'bancontact';
