import { WALLET_STATE } from './constants';

const state = {
  [WALLET_STATE]: {
    amount: 0,
    currency: '',
    generatedAmount: 0,
    generatedCurrency: '',
    isCheck: false,
    isFetching: false,
    error: null,
  },
};

export default state;
