import {
  SUMMARY_STATE,
  FETCH_SUMMARY_FAILURE,
  FETCH_SUMMARY_REQUEST,
  FETCH_SUMMARY_SUCCESS,
} from './constants';
import stateBase from './state';

const initialState = stateBase[SUMMARY_STATE];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUMMARY_REQUEST:
      return {
        ...state,
        error: null,
        isFetching: true,
      };

    case FETCH_SUMMARY_SUCCESS:
      return {
        ...state,
        error: null,
        isFetching: false,
        data: action.payload.summary,
      };

    case FETCH_SUMMARY_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isFetching: false,
        data: {},
      };

    default:
      return state;
  }
};

export default reducer;
