import CookieServer from 'cookie';

export const serializeDeleteCookie = (name, path) => {
  return CookieServer.serialize(name, '', {
    path,
    expires: new Date(0),
  });
};

export * from 'cookie';
