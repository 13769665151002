import { BRAND_MAPPING } from './constants';

export function buildUpdateUserRequest(params) {
  return {
    firstName: params.firstName,
    lastName: params.lastName,
    phone: params.phone,
  };
}

export function buildCreateUserRequest(params) {
  return {
    email: params.email,
    language: params.language,
    market: params.market,
    password: params.password,
  };
}

export function buildChangePasswordRequest(params) {
  return {
    currentPassword: params.currentPassword,
    newPassword: params.newPassword,
  };
}

export function mapCreditCard(data) {
  return {
    brand: BRAND_MAPPING[data?.brand] || data?.brand,
    expiryDate: data?.expirydate,
    holder: data?.holder,
    number: data?.number,
  };
}
