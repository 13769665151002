import { FETCH_SUMMARY_REQUEST, FETCH_SUMMARY_SUCCESS, FETCH_SUMMARY_FAILURE } from './constants';

export const fetchSummaryRequestAction = payload => ({
  type: FETCH_SUMMARY_REQUEST,
  payload,
});

export const fetchSummarySuccessAction = payload => ({
  type: FETCH_SUMMARY_SUCCESS,
  payload,
});

export const fetchSummaryFailureAction = payload => ({
  type: FETCH_SUMMARY_FAILURE,
  payload,
});
