export const LOGIN_GOOGLE_REQUEST = 'LOGIN_GOOGLE_REQUEST';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const FETCH_CUSTOMER_DATA_REQUEST = 'FETCH_CUSTOMER_DATA_REQUEST';
export const FETCH_CUSTOMER_DATA_SUCCESS = 'FETCH_CUSTOMER_DATA_SUCCESS';
export const FETCH_CUSTOMER_DATA_FAILURE = 'FETCH_CUSTOMER_DATA_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const SET_ANONYMOUS_USER = 'SET_ANONYMOUS_USER';

export const LOGIN_STATE = 'login';
export const LOGIN_EVENT_NAME = 'user_logged_in';
