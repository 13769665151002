/* eslint-disable camelcase */
export function buildConfirmationPayload({ aliasId, amount, currency, ip, device, market, code }) {
  return {
    amount,
    browserAcceptHeader: 'application/json',
    browserColorDepth: `${window.screen.colorDepth}`,
    browserJavaEnabled: false,
    browserLanguage: window.navigator.language,
    browserScreenHeight: window.screen.height,
    browserScreenWidth: window.screen.width,
    browserTimeZone: new Date().getTimezoneOffset(),
    currency,
    domain: `${window.location.origin}`,
    id_alias: aliasId,
    ip,
    is_mobile: device === 'mobile',
    market,
    payment_provider: 'flexcheckout',
    user_agent: window.navigator.userAgent,
    code,
  };
}

export function mapConfirmation(data) {
  return {
    securePayment: data?.secure_html_raw || null,
  };
}
