import { call, put, select } from 'redux-saga/effects';

import { fetchMapProperties, fetchMapPropertiesAuthorized } from './requests';
import { fetchMapPropertiesFailureAction, fetchMapPropertiesSuccessAction } from './actions';
import { fetchPropertiesSuccessAction } from 'src/effects/Properties/actions';
import { getPropertiesKafkaSchema } from 'client/contexts/Tracking/utils';
import { loadMapSearchResults } from 'client/contexts/Tracking/events';
import { mapDataSearchMapToPropertiesCollection } from './mappers';
import { sendTrackingRequestAction } from 'client/contexts/Tracking/actions';
import { getProperty } from 'client/contexts/Property/selectors';

export function* fetchMapPropertiesSaga(action) {
  try {
    const { payload } = action;

    const property = yield select(getProperty);
    if (property?.id) {
      payload.highlightedProperty = parseInt(property.id);
    }

    if (!payload.ignoreProperty && !payload.firstSearchDone) {
      if (property?.id) {
        payload.latitude = parseFloat(property.latitude);
        payload.longitude = parseFloat(property.longitude);
      }
    }

    const request = payload?.isLogged ? fetchMapPropertiesAuthorized : fetchMapProperties;

    const { data, status } = yield call(request, payload);

    if (status >= 400) {
      yield put(fetchMapPropertiesFailureAction({ error: data }));
      return;
    }

    const { properties, metadata } = data;
    const propertiesMapped = mapDataSearchMapToPropertiesCollection(properties);
    const distribution = metadata?.price?.distribution;

    yield put(fetchMapPropertiesSuccessAction(propertiesMapped));
    yield put(fetchPropertiesSuccessAction({ distribution }));

    const trackingData = getPropertiesKafkaSchema(propertiesMapped);
    yield put(sendTrackingRequestAction({ event: loadMapSearchResults(trackingData) }));
  } catch (error) {
    yield put(fetchMapPropertiesFailureAction({ error: error.message }));
  }
}
