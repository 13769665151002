import styled, { css } from 'styled-components';
import { colors } from 'client/styles';

export const LoginNavigatorBase = styled.nav``;

export const NavigatorHeader = styled.div`
  padding: 20px;
  border-bottom: 1px solid ${colors.grey6};

  > h3 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 16px;
  }
`;

export const NavigatorList = styled.ul`
  li {
    border-bottom: 1px solid ${colors.grey6};
  }
`;

export const Section = styled.a`
  cursor: pointer;
  display: block;
  padding: 16px;
  text-decoration: none;

  &:hover {
    background-color: ${colors.grey6};
  }

  ${props =>
    props.id === 'link' &&
    css`
      background-color: ${colors.white};
      color: ${colors.grey1};
      font-weight: 500;
    `}

  ${props =>
    props.id === 'logout' &&
    css`
      background-color: ${colors.white};
      color: ${colors.red};
      font-weight: 700;
    `};
`;
