import { requestByGET } from 'lib/requests';
import { buildPathParams, buildQueryParams } from 'lib/utils/url';
import {
  buildAvailabilityRequest,
  mapAvailabilityResponse,
  mapBookingRulesResponse,
} from './mappers';

const OPTIONS = {
  headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
  timeout: 30000,
  withCredentials: true,
};

const END_POINTS = {
  AVAILABILITY: '/v2/availability/property/{propertyId}',
  AVAILABILITY_AUTH: '/v2/availability/property/{propertyId}/member',
  BOOKING_RULES: '/v2/availability/{availabilityId}/bookingRules',
};

export function fetchAvailability({ data }) {
  return requestByGET({
    data: buildAvailabilityRequest(data),
    endpoint: buildPathParams(END_POINTS.AVAILABILITY, { propertyId: data.id }),
    mapper: mapAvailabilityResponse,
  });
}

export function fetchAvailabilityAuthorized({ data }) {
  return requestByGET({
    data: buildAvailabilityRequest(data),
    endpoint: buildPathParams(END_POINTS.AVAILABILITY_AUTH, { propertyId: data.id }),
    mapper: mapAvailabilityResponse,
    options: OPTIONS,
  });
}

export function fetchBookingRules({ id, sessionId }) {
  const urlPath = buildPathParams(END_POINTS.BOOKING_RULES, { availabilityId: id });
  const queryParams = buildQueryParams({ session_id: sessionId });
  return requestByGET({
    endpoint: `${urlPath}${queryParams}`,
    mapper: mapBookingRulesResponse,
  });
}
