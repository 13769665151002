export const routes = {
  home: {
    page: 'home',
    pathname: '/',
  },
  list: {
    page: 'list',
    pathname: '/[country]/[type]/[slug]',
  },
  search: {
    page: 'search',
    pathname: '/search',
  },
  detail: {
    page: 'detail',
    pathname: '/hotel/[country]/[slug]',
  },
  propertyMap: {
    page: 'propertyMap',
    pathname: '/map/hotel/[country]/[slug]',
  },
  checkout: {
    page: 'checkout',
    pathname: '/book/customer',
  },
  payment: {
    page: 'payment',
    pathname: '/book/payment-data',
  },
  voucher: {
    page: 'voucher',
    pathname: '/booking/voucher/[purchaseId]',
  },
  thanks: {
    page: 'thanks',
    pathname: '/booking/thanks/[purchaseId]',
  },
  login: {
    page: 'login',
    pathname: '/login',
  },
  signUp: {
    page: 'signUp',
    pathname: '/signup',
  },
  forgotPassword: {
    page: 'forgotPassword',
    pathname: '/forgot-password',
  },
  blackFriday: {
    page: 'blackFriday',
    pathname: '/black-friday',
  },
  preBlackFriday: {
    page: 'preBlackFriday',
    pathname: '/pre-black-friday',
  },
  newYearDeals: {
    page: 'newYearDeals',
    pathname: '/new-year-deals',
  },
  map: {
    page: 'map',
    pathname: '/map/[country]/[type]/[slug]',
  },
  error: {
    page: 'error',
    pathname: '/error',
  },
  myBookings: {
    page: 'myBookings',
    pathname: '/user-profile/my-bookings',
  },
  prime: {
    page: 'prime',
    pathname: '/user-profile/prime',
  },
  primeReceipts: {
    page: 'primeReceipts',
    pathname: '/user-profile/prime/receipts',
  },
  primeCancelled: {
    page: 'primeCancelled',
    pathname: '/user-profile/prime/cancelled',
  },
  primeCancel: {
    page: 'primeCancel',
    pathname: '/user-profile/prime/cancel',
  },
  hotelsSeen: {
    page: 'hotelsSeen',
    pathname: '/user-profile/hotels-seen',
  },
  myDetails: {
    page: 'myDetails',
    pathname: '/user-profile/my-details',
  },
  myStaywallet: {
    page: 'myStaywallet',
    pathname: '/user-profile/staywallet',
  },
  myCommunicationPreferences: {
    page: 'myCommunicationPreferences',
    pathname: '/user-profile/communication-preferences',
  },
  activateAccount: {
    page: 'activateAccount',
    pathname: '/activate-account/[hash]',
  },
  changePassword: {
    page: 'changePassword',
    pathname: '/change-password',
  },
  resetPassword: {
    page: 'resetPassword',
    pathname: '/forgot-password/[hash]',
  },
  primeLanding: {
    page: 'primeLanding',
    pathname: '/prime/landing',
  },
  primeCheckout: {
    page: 'primeCheckout',
    pathname: '/prime/checkout',
  },
  primeThanks: {
    page: 'primeThanks',
    pathname: '/prime/thanks',
  },
  invoice: {
    page: 'invoice',
    pathname: '/invoice/[hash]',
  },
  promos: {
    page: 'promos',
    pathname: '/promos/[slug]',
  },
  landings: {
    page: 'landings',
    pathname: '/landings/[slug]',
  },
  reviews: {
    page: 'reviews',
    pathname: 'reviews',
  },
  privacy: {
    page: 'privacy',
    pathname: 'privacy',
  },
  generalConditions: {
    page: 'generalConditions',
    pathname: 'general-conditions',
  },
  cookies: {
    page: 'cookies',
    pathname: 'cookies',
  },
};
