import React, { useContext, useEffect, useState, MouseEvent } from 'react';

import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { THEME_DEFAULT, THEMES } from 'client/components/Header/constants';

import { clickAuthentication } from 'client/contexts/Tracking/events';
import { getIsUserDataReady, getLoginData } from 'client/contexts/Login/selectors';
import { logoutRequestAction } from 'client/contexts/Login/actions';
import { PageContext } from 'src/comps/Page/context';
import { sendTrackingRequestAction } from 'client/contexts/Tracking/actions';

import IconPerson from 'client/icons/Person';
import LoginModal from 'src/comps/LoginModal';

import { Icon, LoginButtonBase, LoginLabel, LoginLink } from './styles';
import messages from './messages';
import { addRedirect } from 'src/comps/LoginButton/utils';

import noop from 'lodash/noop';
import { useLogin } from 'src/hooks/useLogin/useLogin';

type LoginButtonProps = {
  isMobile?: boolean;
  theme?: (typeof THEMES)[number];
  onClick?: () => void;
  onClose?: () => void;
  show?: boolean;
  isEbooking?: boolean;
};

const LoginButton = ({
  isMobile,
  theme = THEME_DEFAULT,
  onClick = noop,
  onClose = noop,
  show = false,
  isEbooking = false,
}: LoginButtonProps) => {
  useLogin();

  const { apiHostName, pageId } = useContext(PageContext);
  const dispatch = useDispatch();
  const router = useRouter();

  const [isModalOpen, setModalOpen] = useState(show);

  const user = useSelector(getLoginData);
  const isUserDataReady = useSelector(getIsUserDataReady);

  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event?.stopPropagation();
    if (!isModalOpen) {
      onClick();
    }
    setModalOpen(!isModalOpen);
    dispatch(sendTrackingRequestAction({ event: clickAuthentication() }));
  };

  const handleClose = (event: MouseEvent<HTMLAnchorElement>) => {
    event?.stopPropagation();
    setModalOpen(false);
    onClose();
    dispatch(sendTrackingRequestAction({ event: clickAuthentication() }));
  };

  const handleLogOut = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setModalOpen(false);
    onClose();
    addRedirect(router, pageId);

    dispatch(logoutRequestAction({ hostname: apiHostName }));
  };

  useEffect(() => {
    setModalOpen(false);
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserDataReady]);

  useEffect(() => {
    setModalOpen(show);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const userPlaceholder = user.name ? `${user.name} ${user.last}` : user.email;

  return (
    <LoginButtonBase data-testid="header-login">
      <LoginLink data-testid="header-login-button" onClick={handleClick} theme={theme}>
        <Icon>
          <IconPerson size={14} />
        </Icon>

        {!isMobile && (
          <LoginLabel isLogged={isUserDataReady} isEbooking={isEbooking}>
            {isUserDataReady ? (
              userPlaceholder
            ) : (
              <FormattedMessage tagName="span" {...messages.login} />
            )}
          </LoginLabel>
        )}
      </LoginLink>

      <LoginModal
        handleClose={handleClose}
        handleLogOut={handleLogOut}
        isLoggedIn={isUserDataReady}
        isOpen={isModalOpen}
      />
    </LoginButtonBase>
  );
};

export default LoginButton;
