export const CANCEL_BOOKING_STATE = 'cancelBooking';

export const CANCEL_BOOKING_REQUEST = 'CANCEL_BOOKING_REQUEST';
export const CANCEL_BOOKING_SUCCESS = 'CANCEL_BOOKING_SUCCESS';
export const CANCEL_BOOKING_FAILURE = 'CANCEL_BOOKING_FAILURE';
export const CANCEL_BOOKING_REJECTED = 'CANCEL_BOOKING_REJECTED';

export const STATUS_OK = 'STATUS_OK';
export const STATUS_REJECTED = 'STATUS_REJECTED';
export const STATUS_NOT_OK = 'STATUS_NOT_OK';

export const HTTP_CODE_CANCELLED_FAILED = 422;
