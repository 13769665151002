import {
  ENABLE_INSURANCE,
  FETCH_AVAILABILITY_REQUEST,
  FETCH_AVAILABILITY_SUCCESS,
  FETCH_AVAILABILITY_FAILURE,
  FETCH_BOOKING_RULES_REQUEST,
  FETCH_BOOKING_RULES_SUCCESS,
  AVAILABILITY_STATE,
} from './constants';
import stateBase from './state';
import { updateBookingRulesWithFees } from './mappers';

const initialState = stateBase[AVAILABILITY_STATE];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_AVAILABILITY_REQUEST:
      return {
        ...state,
        error: null,
        isFetching: true,
        meta: {},
        boards: [],
        rulesComplete: 0,
      };

    case FETCH_AVAILABILITY_SUCCESS: {
      const { boards, meta } = action.payload;

      return {
        ...state,
        error: null,
        isFetching: false,
        meta,
        boards,
      };
    }

    case FETCH_AVAILABILITY_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isFetching: false,
        meta: {},
        boards: [],
        rulesComplete: state.rulesComplete + 1,
      };

    case FETCH_BOOKING_RULES_REQUEST:
      return {
        ...state,
        isFetchingRules: true,
      };

    case FETCH_BOOKING_RULES_SUCCESS: {
      const updatedBoards = state.boards.map(board => {
        if (board.availabilityId === action.payload.availabilityId) {
          const { payload } = action;
          const updatedBoard = updateBookingRulesWithFees(board, payload);
          return updatedBoard;
        }
        return board;
      });

      return {
        ...state,
        isFetchingRules: false,
        boards: updatedBoards,
        rulesComplete: state.rulesComplete + 1,
      };
    }

    case ENABLE_INSURANCE: {
      const updatedBoards = state.boards.map(board =>
        board.availabilityId === action.payload.availabilityId
          ? { ...board, ...action.payload }
          : board
      );

      return {
        ...state,
        boards: updatedBoards,
      };
    }

    default:
      return state;
  }
};

export default reducer;
