import {
  FETCH_SUGGESTED_PROPERTIES_REQUEST,
  FETCH_SUGGESTED_PROPERTIES_SUCCESS,
  FETCH_SUGGESTED_PROPERTIES_FAILURE,
  RESET_SUGGESTED_PROPERTIES,
} from './constants';

export const fetchSuggestedPropertiesRequestAction = payload => ({
  type: FETCH_SUGGESTED_PROPERTIES_REQUEST,
  payload,
});

export const fetchSuggestedPropertiesSuccessAction = payload => ({
  type: FETCH_SUGGESTED_PROPERTIES_SUCCESS,
  payload,
});

export const fetchSuggestedPropertiesFailureAction = payload => ({
  type: FETCH_SUGGESTED_PROPERTIES_FAILURE,
  payload,
});

export const resetSuggestedPropertiesAction = payload => ({
  type: RESET_SUGGESTED_PROPERTIES,
  payload,
});
