import React from 'react';

import IconClose from 'client/icons/Close';

import {
  ButtonResetBase
} from './styles';

interface Props {
  handleClick: () => void;
  visible: boolean;
}

const ButtonReset = ({ 
  handleClick,
  visible
} : Props) => {
  if(!visible) {
    return null
  }

  return (
    <ButtonResetBase 
      onClick={handleClick} 
      data-testid="search-reset-button"
      className="button-reset"
    >
      <IconClose />
    </ButtonResetBase>
  )
}

export default ButtonReset;
