import { call, put } from 'redux-saga/effects';

import {
  fetchPropertiesRecommendedSuccessAction,
  fetchPropertiesRecommendedFailureAction,
} from './actions';

import { fetchPropertiesRecommended } from './requests';

export function* fetchPropertiesRecommendedSaga(action) {
  try {
    const response = yield call(fetchPropertiesRecommended, { data: action.payload });

    if (response.status >= 400) {
      throw new Error(response.data || response.status);
    }

    yield put(fetchPropertiesRecommendedSuccessAction({ properties: response.data }));
  } catch (error) {
    yield put(fetchPropertiesRecommendedFailureAction({ error: error.message }));
  }
}
