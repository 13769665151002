import { call, put } from 'redux-saga/effects';

import {
  fetchVerifyEmailSuccessAction,
  fetchVerifyEmailFailureAction,
  fetchVerifyEmailRejectedAction,
} from './actions';

import { HTTP_CODE_VERIFY_UNAUTHORIZED } from './constants';
import { saveEmailAction } from 'src/comps/Voucher/actions';
import { verifyEmail } from './requests';

export function* verifyEmailSaga(action) {
  const { email, hash } = action.payload;

  try {
    const { data, status } = yield call(verifyEmail, { email, hash });

    if (status === HTTP_CODE_VERIFY_UNAUTHORIZED) {
      yield put(fetchVerifyEmailRejectedAction({ error: data, errorCode: status, hash }));
      return;
    }

    if (status >= 400) {
      yield put(fetchVerifyEmailFailureAction({ error: data }));
      return;
    }

    yield put(fetchVerifyEmailSuccessAction({ data, hash }));
    yield put(saveEmailAction({ email }));
  } catch (error) {
    yield put(
      fetchVerifyEmailFailureAction({
        error: error.message,
        errorCode: error.statusCode,
        hash,
      })
    );
  }
}
