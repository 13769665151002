import { useSession } from 'src/hooks/useSession';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getLoggedIn, getLoginData } from 'client/contexts/Login/selectors';

export function useLogin() {
  const { setSession } = useSession();
  const user = useSelector(getLoginData);
  const isLoggedIn = useSelector(getLoggedIn);

  useEffect(() => {
    setSession(currentSession => ({
      id: currentSession.id,
      customer: user,
      isLogged: isLoggedIn,
    }));
  }, [user, isLoggedIn, setSession]);
}
