import styled from 'styled-components';
import { colors, helpers } from 'client/styles';

export const InputGroup = styled.div`
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const Label = styled.label`
  user-select: none;
  height: 100%;

  ${props =>
    props.hidden
      ? helpers.isVisuallyHidden
      : `
        color: ${colors.grey2};
        display: block;
        font-size: 14px;
        line-height: 1.4;
        cursor: pointer;
      `}
`;

export const Input = styled.input`
  cursor: pointer;
  font-weight: 700;
  padding: 0;
  pointer-events: none;
`;
