import { USER_BOOKINGS_FAILED, USER_BOOKINGS_SUCCESS, USER_BOOKINGS_REQUEST } from './constants';

export const fetchUserBookingsRequestAction = payload => ({
  type: USER_BOOKINGS_REQUEST,
  payload,
});

export const fetchUserBookingsSuccessAction = payload => ({
  type: USER_BOOKINGS_SUCCESS,
  payload,
});

export const fetchUserBookingsFailedAction = payload => ({
  type: USER_BOOKINGS_FAILED,
  payload,
});
