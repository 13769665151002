import React from 'react';
import createSvgIcon from './createSvgIcon';

export default createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M2 12C2 6.5 6.5 2 12 2s10 4.5 10 10-4.5 10-10 10S2 17.5 2 12Zm2 0c0 4.4 3.6 8 8 8s8-3.6 8-8-3.6-8-8-8-8 3.6-8 8Zm8.3-.2h2.5c.6 0 1 .5 1 1.1 0 .6-.4 1-1 1h-3.5c-.6 0-1-.4-1-1V8.2c0-.6.4-1 1-1s1 .4 1 1v3.6Z"
  />,
  'Timer'
);
