import styled from 'styled-components';
import { Combobox, ComboboxPopover, ComboboxInput } from '@reach/combobox';
import { colors } from 'client/styles';
import { inputStyles } from 'client/components/LocationSelector/styles';
import { SuggesterItemBase } from 'src/comps/SuggesterItem/styles';

export const SuggesterBase = styled(Combobox)`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const SuggestionsItem = styled.div`
  border-top: 1px solid ${colors.grey4};
  color: ${colors.grey1};
  cursor: pointer;
  display: 'block';
  width: 100%;
`;

export const Input = styled(ComboboxInput)`
  ${inputStyles}
  padding: 17px 60px 17px 8px;
`;

export const PopOverDesktop = styled(ComboboxPopover)`
  background-color: ${colors.white};
  border-radius: 8px;
  border-top: none;
  border: 2px solid ${colors.grey1};
  margin-left: -38px;
  width: 409px !important;
  z-index: 10;
  overflow: hidden;
  margin-top: 10px;

  [data-reach-combobox-option] {
    border-bottom: 1px solid ${colors.grey4};

    &:focus {
      outline: none;
    }

    &:last-child {
      border-bottom: none;

      > ${SuggesterItemBase} {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }
`;

export const PopOverMobile = styled(ComboboxPopover)`
  background-color: ${colors.white};
  border: none;
  position: absolute;
  left: -48px;
  right: 0;
  top: 62px;

  [data-reach-combobox-option] {
    border-bottom: 2px solid ${colors.grey4};

    &:focus {
      outline: none;
    }
  }
`;
