import { SEND_VOUCHER_STATE } from './constants';

const state = {
  [SEND_VOUCHER_STATE]: {
    error: null,
    isFetching: false,
  },
};

export default state;
