import { call, put } from 'redux-saga/effects';

import { CONFLICT, GONE } from 'src/constants/httpcodes';
import { displayAlertAction } from 'src/comps/AlertMessage/actions';

import { resetPassword } from './requests';
import { resetPasswordSuccessAction, resetPasswordFailureAction } from './actions';

export function* resetPasswordSaga(action) {
  try {
    const response = yield call(resetPassword, action.payload);
    const responseMessage = response.data || response.status;

    if (response.status === CONFLICT.status) {
      yield put(displayAlertAction({ messageId: 'errorResetLinkUsed' }));
      yield put(resetPasswordFailureAction({ error: responseMessage }));
      return;
    }

    if (response.status === GONE.status) {
      yield put(displayAlertAction({ messageId: 'errorResetLinkExpired' }));
      yield put(resetPasswordFailureAction({ error: responseMessage }));
      return;
    }

    if (response.status >= 400) {
      yield put(displayAlertAction({ messageId: 'errorDefault' }));
      yield put(resetPasswordFailureAction({ error: responseMessage }));
      return;
    }

    yield put(resetPasswordSuccessAction());
  } catch (error) {
    yield put(displayAlertAction({ messageId: 'errorDefault' }));
    yield put(resetPasswordFailureAction({ error: error.message }));
  }
}
