import { call, put, delay, select } from 'redux-saga/effects';
import { getMarket } from 'src/comps/Page/selectors';
import { fetchSuggestionsSuccessAction, fetchSuggestionsFailureAction } from './actions';
import { MARKETS_FEATURE_SEARCH_ENGINE } from './constants';
import { fetchSuggestions } from './requests';

export function* fetchSuggestionsSaga(action) {
  try {
    yield delay(200); // debounce
    const { payload } = action;
    const countryFeatureSearchEngine = yield select(getMarket);

    let payloadUpdated = payload;
    if (MARKETS_FEATURE_SEARCH_ENGINE.includes(countryFeatureSearchEngine)) {
      payloadUpdated = { ...payload, new_locations:true };
    }

    const response = yield call(fetchSuggestions, payloadUpdated);

    if (response.status >= 400) {
      yield put(fetchSuggestionsFailureAction({ error: response.data }));
      return;
    }

    yield put(fetchSuggestionsSuccessAction({ suggestions: response.data }));
  } catch (error) {
    yield put(fetchSuggestionsFailureAction({ error: error.message }));
  }
}
