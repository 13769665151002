import {
  FETCH_SUGGESTED_PROPERTIES_REQUEST,
  FETCH_SUGGESTED_PROPERTIES_SUCCESS,
  FETCH_SUGGESTED_PROPERTIES_FAILURE,
  SUGGESTED_PROPERTIES_STATE,
  RESET_SUGGESTED_PROPERTIES,
} from './constants';
import stateBase from './state';

const initialState = stateBase[SUGGESTED_PROPERTIES_STATE];

const suggestedReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUGGESTED_PROPERTIES_REQUEST: {
      return {
        ...state,
        properties: [],
        isFetching: true,
        error: null,
      };
    }

    case FETCH_SUGGESTED_PROPERTIES_SUCCESS: {
      return {
        ...state,
        count: action.payload.properties.length || 0,
        properties: action.payload.properties || [],
        isFetching: false,
        error: null,
      };
    }

    case FETCH_SUGGESTED_PROPERTIES_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };
    }

    case RESET_SUGGESTED_PROPERTIES: {
      return {
        ...state,
      };
    }

    default: {
      return state;
    }
  }
};

export default suggestedReducer;
