import { PRIME_RECEIPTS_STATE } from './constants';

const state = {
  [PRIME_RECEIPTS_STATE]: {
    error: null,
    isFetching: false,
    data: [],
  },
};

export default state;
