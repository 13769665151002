import { requestByGET } from 'lib/requests';

import { buildSummaryRequest } from './utils';
import { mapSummary } from './mappers';

const END_POINTS = {
  SUMMARY: '/purchase/totals',
};

export function fetchSummary(params) {
  const data = buildSummaryRequest(params.data);

  return requestByGET({
    ...params,
    data,
    endpoint: END_POINTS.SUMMARY,
    mapper: mapSummary,
  });
}
