import { createSelector } from 'reselect';
import { removeEmptyKeys } from 'client/contexts/Tracking/utils';
import { FILTERS_STATE } from './constants';

export const getFacilities = state => state[FILTERS_STATE]?.facilities;
export const getIsFamilyFriendly = state => state[FILTERS_STATE]?.family;
export const getOrderBy = state => state[FILTERS_STATE]?.orderBy;
export const getStars = state => state[FILTERS_STATE]?.stars;
export const getAccommodationTypes = state => state[FILTERS_STATE]?.accommodationTypes;
export const getRatings = state => state[FILTERS_STATE]?.ratings;
export const getMinPrice = state => state[FILTERS_STATE]?.minPrice;
export const getMaxPrice = state => state[FILTERS_STATE]?.maxPrice;
export const getReset = state => state[FILTERS_STATE]?.reset;

export const getFilters = createSelector(
  [
    getIsFamilyFriendly,
    getFacilities,
    getOrderBy,
    getStars,
    getReset,
    getAccommodationTypes,
    getRatings,
    getMinPrice,
    getMaxPrice,
  ],
  (family, facilities, orderBy, stars, reset, accommodationTypes, ratings, minPrice, maxPrice) => {
    return removeEmptyKeys({
      family,
      facilities,
      orderBy,
      stars,
      reset,
      accommodationTypes,
      ratings,
      minPrice,
      maxPrice,
    });
  }
);

export const getSetFiltersCount = createSelector(
  [
    getIsFamilyFriendly,
    getFacilities,
    getStars,
    getAccommodationTypes,
    getRatings,
    getMinPrice,
    getMaxPrice,
  ],
  (family, facilities, stars, accommodationTypes, ratings, minPrice, maxPrice) => {
    const price = [minPrice, maxPrice].filter(v => v !== undefined);

    const filtersApplied = removeEmptyKeys({
      family,
      facilities,
      stars,
      accommodationTypes,
      ratings,
      price,
    });

    return Object.keys(filtersApplied).length;
  }
);
