import { AffiliateData } from 'src/pages/_middleware/affiliate';
import { affiliateDataKeyTransformations, affiliateDataKeysToDelete } from './constants';

/**
 * Apply the keys transformation to the affiliate data object to reduce the
 * amount of data that is stored in the cookie
 * @param rawObject
 * @returns affiliate data with transfomed keys
 */
export function getAffiliateInfoReduced(rawObject: { [k: string]: string }) {
  return Object.entries(rawObject).reduce((acc, [key, value]) => {
    // if the key is in the list of keys to delete or the value is empty, we
    // don't include it in the reduced object
    if (affiliateDataKeysToDelete.includes(key) || !value) {
      return acc;
    }

    // include the value with the new key if it exists, otherwise use the
    // original key to keep all the data
    const afterKey = affiliateDataKeyTransformations[key];
    return {
      ...acc,
      [afterKey ?? key]: value,
    };
  }, {});
}

/**
 * Revert the keys transformation to the affiliate data object to restore the
 * original keys from the query params object and maintain backwards
 * compatibility with the old keys in the cookie to avoid breaking changes
 * for users with old cookies
 * @param reducedInfo
 * @returns affiliate data with restored keys
 */
export function getAffiliateInfoRestored(reducedInfo: { [k: string]: string }) {
  const restored = Object.entries(reducedInfo).reduce((acc, [key, value]) => {
    const transformation = Object.entries(affiliateDataKeyTransformations).find(
      ([, after]) => after === key
    );

    if (transformation) {
      const [beforeKey] = transformation;
      return {
        ...acc,
        [beforeKey]: value,
      };
    }

    return {
      ...acc,
      [key]: value,
    };
  }, {}) as AffiliateData;

  return {
    ...restored,
    ...(restored.aid && { id_affiliate: restored.aid }),
  };
}
