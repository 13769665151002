import styled from 'styled-components';
import { colors } from 'client/styles';

export const AlertMessageBase = styled.div`
  align-items: center;
  background-color: ${props => props.severity === 'error' && colors.red2};
  background-color: ${props => props.severity === 'success' && colors.green};
  background-color: ${props => props.severity === 'warning' && colors.yellow4};
  display: flex;
  justify-content: space-between;
  padding: 20px;
  width: 100%;

  p {
    color: ${props => (props.severity === 'warning' ? colors.grey0 : colors.white)};
    font-size: 16px;
    line-height: 1.3;
    margin-right: 20px;
  }

  svg {
    cursor: pointer;
    color: ${props => (props.severity === 'warning' ? colors.grey0 : colors.white)};
  }
`;
