import Cookies from 'js-cookie';

import { COOKIE_AFFILIATES } from 'src/constants/cookies';
import isEmpty from 'lib/functions/isEmpty';

import {
  getPropertiesIdsFromCookie,
  transformPropertiesIds,
} from 'src/effects/PropertiesVisited/utils';

import {
  mapKafkaData,
  mapSFLData,
  mapConversionGA,
  mapEcommerceData,
  mapFiltersToTracking,
  mapIterableData,
  mapTrackingAffiliateData,
} from './mappers';

import { ALERTS_STATE } from 'src/comps/AlertMessage/constants';
import { AVAILABILITY_STATE } from 'src/effects/Availability/constants';
import { LOCATION_STATE } from 'client/contexts/Location/constants';

import { buildEmailHash, getAffiliateRate } from './utils';
import { dataLayerPush } from '.';
import { getAffiliateTrackingFromCookie } from 'lib/utils/affiliate';
import { trackingRequest as kafkaSend, sendTrackingIterable, sendTrackingKafka } from './requests';
import { DEFAULT_AFFILIATE_ID } from 'src/pages/_middleware/defaultAffiliate';
import { SEARCH_STATE } from 'src/effects/Search/constants';
import { noop } from 'lodash';
import { SUMMARY_STATE } from '../Summary/constants';
import { VOUCHER_STATE } from 'src/comps/Voucher/constants';
import { CONFIG_STATE } from 'src/comps/Page/constants';
import { PROPERTY_STATE } from '../Property/constants';
import { PURCHASE_STATE } from '../Purchase/constants';
import { PROPERTIES_STATE } from 'src/effects/Properties/constants';
import { parseCookieValue } from 'src/utils/parseCookieValue';

const middleware = store => next => action => {
  if (!action.track) {
    return next(action);
  }

  const {
    [ALERTS_STATE]: { messageId: alertMessage },
    [AVAILABILITY_STATE]: { boards },
    [CONFIG_STATE]: {
      affiliateId,
      currency,
      device,
      ip,
      isBot,
      language,
      market,
      pageId,
      mobileRate,
    },
    login: { data: dataEmailLogin, isLoggedIn },
    pages: { pageNumber },
    [PROPERTY_STATE]: { property },
    [PURCHASE_STATE]: { email: purchaseEmail, isMandatory },
    [AVAILABILITY_STATE]: {
      meta: { bestPriceData },
    },
    filters,
    [SEARCH_STATE]: { adults, children, checkIn, checkOut, slug, type, country, term },
    [LOCATION_STATE]: {
      data: { latitude, longitude, offset, radius, city, citySlug, zone },
    },
    [PROPERTIES_STATE]: { properties },
    [SUMMARY_STATE]: { data: summaryData },
    [VOUCHER_STATE]: { voucher },
  } = store.getState();

  const affiliateData = parseCookieValue(Cookies.get(COOKIE_AFFILIATES) || '{}');

  const visitedProperties = getPropertiesIdsFromCookie();
  const visitedPropertiesArray = transformPropertiesIds({ properties: visitedProperties });

  const appData = {
    adults,
    affiliateData,
    affiliateId,
    alertMessage,
    bestPriceData,
    checkIn,
    checkOut,
    children,
    country,
    currency,
    city,
    citySlug,
    device,
    emailLogin: dataEmailLogin?.email,
    filters: mapFiltersToTracking(filters),
    isLoggedIn,
    language,
    market,
    pageId,
    properties,
    property,
    purchaseEmail,
    purchaseEmailHash: purchaseEmail ? buildEmailHash(purchaseEmail) : '',
    rooms: adults.length,
    searchTerm: term,
    slug,
    type,
    userId: dataEmailLogin?.userId || null,
    visited: visitedPropertiesArray.length ? visitedPropertiesArray : null,
    search: {
      country: country ? country.toUpperCase() : '',
      type,
      slug,
      page: pageNumber,
    },
    mapSearch: {
      latitude,
      longitude,
      offset,
      radius,
    },
    ...(mobileRate && {
      catalogue: 'mobile',
    }),
    summaryData,
    voucher,
  };

  const { event } = action.payload;

  const { dataLayer, kafka, emt, kafkaDirect } = event;

  function getStoreData(fields) {
    const storedAppData = {};
    const fieldsLength = fields.length;

    for (let i = 0; i < fieldsLength; i += 1) {
      const key = fields[i];
      storedAppData[key] = appData[key];
    }

    return storedAppData;
  }

  function getEcommercePayload(ecommerce) {
    if (!ecommerce) {
      return null;
    }

    const { appStateItems, data } = ecommerce;
    const appStoreData = getStoreData(appStateItems);
    const eventEcommercePayload = mapEcommerceData({ ...appStoreData, ...data });

    return eventEcommercePayload;
  }

  function buildDataLayerEventPayload(dataLayerEvent) {
    const {
      sfl,
      conversionGA,
      ecommerce,
      iterable,
      tripadvisor,
      experiment,
      conversionAffiliate,
      ...plain
    } = dataLayerEvent;
    const tracking = getAffiliateTrackingFromCookie();
    const payload = [];

    if (experiment) {
      payload.push({
        event: 'sflExperiment',
        data: experiment,
      });
    }

    if (iterable) {
      payload.push({
        event: 'iterable',
        itbl: {
          itbl_cmp: tracking.itbl_cmp,
          itbl_tpl: tracking.itbl_tpl,
        },
      });
    }

    if (tripadvisor) {
      payload.push({
        event: 'tripadvisor',
        ta: { refid: tracking.refid },
      });
    }

    if (sfl) {
      const { appStateItems, data } = sfl;
      const appStoreData = getStoreData(appStateItems);
      const eventPayload = mapSFLData({
        affiliateId,
        ip,
        isBot,
        isMandatory,
        ...appStoreData,
        ...data,
      });

      payload.push({
        event: 'sflDataLoadedEvent',
        sfl: eventPayload,
        ecommerce: getEcommercePayload(ecommerce),
      });
    }

    if (conversionGA) {
      const { eventData } = conversionGA;
      const eventPayload = mapConversionGA(eventData);

      payload.push({ event: 'conversionGA', ...eventPayload });
    }

    if (conversionAffiliate && affiliateId !== DEFAULT_AFFILIATE_ID) {
      const eventPayload = mapTrackingAffiliateData(affiliateId, tracking);
      payload.push({
        event: 'conversionAffiliate',
        affiliate: affiliateId,
        data: { ...eventPayload },
      });
    }

    return isEmpty(plain) ? [...payload] : [...payload, plain];
  }

  if (kafka) {
    const { eventName, eventType, eventData, appStateItems = [] } = kafka;
    const appStoreData = getStoreData(appStateItems);
    const affiliateRate = ['hotel_availability', 'search_results'].includes(eventName)
      ? getAffiliateRate()
      : undefined;
    const eventsWithUtcTimestamp = [
      'hotel_availability',
      'hotel_details',
      'hotel_list_results',
      'search_results',
      'map',
      'map_search_results',
      'customer_checkout',
      'booking_thanks',
      'payment_checkout',
    ];
    const checkInDate = new Date(checkIn);
    const checkOutDate = new Date(checkOut);

    const eventPayload = mapKafkaData({
      affiliateId,
      affiliateRate,
      device,
      eventName,
      eventType,
      ip,
      isBot,
      language,
      market,
      isMandatory,
      ...eventData,
      ...appStoreData,
      ...(eventsWithUtcTimestamp.includes(eventName) && {
        checkIn: Date.UTC(checkInDate.getFullYear(), checkInDate.getMonth(), checkInDate.getDate()),
        checkOut: Date.UTC(
          checkOutDate.getFullYear(),
          checkOutDate.getMonth(),
          checkOutDate.getDate()
        ),
      }),
    });

    kafkaSend(eventPayload);
  }

  if (kafkaDirect || (kafka && kafka.eventType === 'pageview')) {
    const { eventName, eventType, eventData, appStateItems = [] } = kafkaDirect ?? kafka;
    const appStoreData = getStoreData(appStateItems);
    const affiliateRate = eventName === 'hotel_availability' ? getAffiliateRate() : undefined;
    const { userAgent } = window.navigator;

    const eventPayload = mapKafkaData({
      affiliateId,
      affiliateData,
      affiliateRate,
      device,
      eventName: kafka?.eventType === 'pageview' ? 'PageView' : eventName,
      eventType,
      ip,
      isBot,
      userAgent,
      language,
      market,
      isMandatory,
      ...eventData,
      ...appStoreData,
    });

    const locationType = (city && 'city') || (zone && 'zone') || undefined;

    sendTrackingKafka({
      ...eventPayload,
      payload: {
        ...eventPayload.payload,
        summaryData,
        voucher,
        property,
        locationType,
      },
      checkIn,
      checkOut,
      boards: eventData.boards ?? boards,
    })
      .then(noop)
      .catch(noop);
  }

  if (emt) {
    const { eventName, eventData, appStateItems = [] } = emt;
    const appStoreData = getStoreData(appStateItems);
    const eventPayload = mapIterableData({
      language,
      market,
      ...eventData,
      ...appStoreData,
    });
    const hash = buildEmailHash(appStoreData.emailLogin || appStoreData.purchaseEmail);

    if (hash !== 'bnVsbA==@placeholder.email') {
      sendTrackingIterable({ data: eventPayload, path: { eventName, hash } });
    }
  }

  if (dataLayer) {
    dataLayerPush(buildDataLayerEventPayload(dataLayer));
  }

  return next(action);
};

export default middleware;
