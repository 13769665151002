export const FACILITIES = [
  { id: 1, name: 'carpark', icon: 'parking', slug: 'car-park' },
  { id: 2, name: 'gym', icon: 'gym', slug: 'gym' },
  { id: 3, name: 'indoorpool', icon: 'pool', slug: 'indoor-pool' },
  { id: 4, name: 'outdoorpool', icon: 'pool', slug: 'outdoor-pool' },
  { id: 5, name: 'airportshuttle', icon: 'airplane', slug: 'airport-shuttle' },
  { id: 6, name: 'spa', icon: 'spa', slug: 'spa' },
  { id: 8, name: 'wifi', icon: 'wifi-alt', slug: 'wi-fi' },
  { id: 7, name: 'wheelchair', icon: 'wheelchair', slug: 'wheelchair-access' },
];
