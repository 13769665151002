import React, { createContext, ReactElement, useMemo } from 'react';

import { Experiment, MissingExperiment } from 'src/types/experiment';
import { MISSING_EXPERIMENT } from './constants';

const ExperimentContext = createContext<Experiment | MissingExperiment | null>(null)

const ExperimentProvider = ({
  children,
  value: { id, key, variant }
}: { children: ReactElement, value: Experiment | Record<string, unknown> }) => {

  const experimentValue = useMemo(() => {
    if (!id || !key || !Number.isInteger(variant)) {
      return MISSING_EXPERIMENT;
    }

    return {
      id,
      key,
      variant
    } as Experiment;
  }, [id, key, variant]);

  return (
    <ExperimentContext.Provider value={experimentValue}>
      {children}
    </ExperimentContext.Provider>
  );
};

export { ExperimentContext, ExperimentProvider }
