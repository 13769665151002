import isEmpty from "lodash/isEmpty";
import pick from "lodash/pick"
import { SessionType } from "src/types/session";


export const getSessionContextValue = (config: unknown & { customer: { data: SessionType | null }; sessionId: string }) => {
  const customerData = pick(config, ['customer', 'sessionId'])
  const contextValue = pick(customerData.customer, ['data'])

  if (isEmpty(contextValue)) {
    return null;
  }

  const isLogged = Boolean(contextValue.data)

  return { customer: contextValue.data, isLogged, id: customerData.sessionId }
}
