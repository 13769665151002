import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { addMonths, getYear, getMonth, isSameMonth } from 'date-fns';

import { getMonthMatrix, getWeekDays, getWeekStartsOn } from 'client/utils';

import Day from './Day';
import IconChevronLeft from 'client/icons/ChevronLeft';
import IconChevronRight from 'client/icons/ChevronRight';

import { CalendarBase, Month, Week, WeekDays } from './styles';

export const WeekDay = ({ day }) => {
  const intl = useIntl();
  const currentLocale = intl.locale;
  const weekday = intl.formatDate(day, { weekday: 'short' });

  if (currentLocale === 'pt-PT') {
    return <>{weekday.slice(0, 3)}</>;
  }

  return <>{weekday}</>;
};

WeekDay.propTypes = {
  day: PropTypes.instanceOf(Date),
};

const Calendar = ({
  index,
  position,
  isCheckInMonth,
  isFocusedCheckIn,
  onNavigate,
  onSelect,
  scrollToMonth,
  selectedDates,
  variant = 'vertical',
  market,
  dataTestId = 'calendar',
  currentDate = new Date(),
  className = 'calendar',
  minBookingDate = new Date(),
}) => {
  const intl = useIntl();
  const weekStartsOn = getWeekStartsOn(market);
  const weekDays = getWeekDays(weekStartsOn);
  const month = addMonths(currentDate, index);
  const matrix = getMonthMatrix(getYear(currentDate), getMonth(currentDate) + index, weekStartsOn);

  const currentMonthRef = useCallback(
    (node, isCheckIn) => {
      if (node !== null && isCheckIn) {
        scrollToMonth(node);
      }
    },
    [scrollToMonth]
  );

  function areAllWeekDaysInMonth(week, currentMonth) {
    return week.some(day => isSameMonth(day, currentMonth));
  }

  const formattedMonthYear = `${intl.formatDate(month, { month: 'long' })} ${intl.formatDate(
    month,
    { year: 'numeric' }
  )}`;

  return (
    <CalendarBase data-testid={dataTestId} variant={variant} className={className}>
      <Month data-testid="calendar-month" ref={node => currentMonthRef(node, isCheckInMonth)}>
        {position === 0 && !isSameMonth(new Date(), month) && (
          <IconChevronLeft role="button" onClick={() => onNavigate('prev')} />
        )}
        <p>{formattedMonthYear}</p>
        {position === 1 && <IconChevronRight role="button" onClick={() => onNavigate('next')} />}
      </Month>

      {variant === 'horizontal' && (
        <WeekDays id="desktop" data-testid="week-days-desktop">
          {weekDays.map(day => (
            <li key={day}>
              <WeekDay day={day} />
            </li>
          ))}
        </WeekDays>
      )}

      <ul>
        {matrix.map(week => (
          <li key={week.join()}>
            {areAllWeekDaysInMonth(week, month) && (
              <Week>
                {week.map(day => (
                  <Day
                    day={day}
                    isFocusedCheckIn={isFocusedCheckIn}
                    isVisible={isSameMonth(day, month)}
                    key={day.toString()}
                    onSelect={onSelect}
                    selected={selectedDates}
                    market={market}
                    minBookingDate={minBookingDate}
                  />
                ))}
              </Week>
            )}
          </li>
        ))}
      </ul>
    </CalendarBase>
  );
};

Calendar.propTypes = {
  index: PropTypes.number.isRequired,
  isCheckInMonth: PropTypes.bool,
  isFocusedCheckIn: PropTypes.bool,
  onNavigate: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  position: PropTypes.number,
  scrollToMonth: PropTypes.func,
  selectedDates: PropTypes.instanceOf(Object),
  variant: PropTypes.oneOf(['vertical', 'horizontal']),
  market: PropTypes.string,
  dataTestId: PropTypes.string,
  currentDate: PropTypes.instanceOf(Date),
  minBookingDate: PropTypes.instanceOf(Date),
  className: PropTypes.string,
};

export default Calendar;
