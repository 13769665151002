/* eslint-disable camelcase */
import { call, put } from 'redux-saga/effects';

import {
  modifyDatesFailureAction,
  modifyDatesSuccessAction,
  modifyDatesConfirmationSuccessAction,
  modifyDatesConfirmationFailureAction,
  modifyDatesCancellationFailureAction,
} from './actions';

import { CONFLICT } from 'src/constants/httpcodes';
import { modifyDates, modifyDatesConfirmation } from './requests';
import { refetchVoucherAction, cleanEmailAction } from 'src/comps/Voucher/actions';

export function* modifyDatesSaga(action) {
  try {

    const { data, status } = yield call(modifyDates, action.payload);

    if (status >= 400) {
      yield put(modifyDatesFailureAction({ error: data, errorCode: status }));
      return;
    }

    yield put(modifyDatesSuccessAction({ data }));
  } catch (error) {
    yield put(modifyDatesFailureAction({ error: error.message, errorCode: error.statusCode }));
  }
}

export function* modifyDatesConfirmationSaga(action) {
  try {
    const { data, status } = yield call(modifyDatesConfirmation, action.payload);

    if (status === CONFLICT.status && data.errorCode === 'cancellation') {
      yield put(modifyDatesCancellationFailureAction({ error: data.error }));
      return;
    }

    if (status === CONFLICT.status && data.errorCode === 'confirmation') {
      yield put(modifyDatesConfirmationFailureAction({ error: data.error }));
      return;
    }

    if (status >= 400) {
      yield put(modifyDatesConfirmationFailureAction({ error: data, errorCode: status }));
      return;
    }

    yield put(modifyDatesConfirmationSuccessAction());
    yield put(cleanEmailAction());
    yield put(refetchVoucherAction());
  } catch (error) {
    yield put(
      modifyDatesConfirmationFailureAction({ error: error.message, errorCode: error.statusCode })
    );
  }
}
