import { RatingOptions, SortOptions } from 'src/pages/api/trpc/search/schema';

export enum AccommodationOptions {
  Hotel = 'hotel',
  Apartment = 'apartment',
  Villa = 'villa',
  Home = 'homes',
  Hostel = 'hostel',
  Resort = 'resort',
  ApartHotel = 'apthotel',
}

export enum FacilitiesOptions {
  CarPark = 'car-park',
  Gym = 'gym',
  IndoorPool = 'indoor-pool',
  OutdoorPool = 'outdoor-pool',
  AirportShuttle = 'airport-shuttle',
  Spa = 'spa',
  WiFi = 'wi-fi',
  WheelChairAccess = 'wheelchair-access',
}

export enum SearchActions {
  Fetch = 'fetch',
  Cancel = 'cancel',
  Error = 'error',
  Success = 'success',
}

export enum SearchStatus {
  Idle = 'idle',
  Fetching = 'fetching',
  Error = 'error',
  Success = 'success',
}

export enum LocationTypes {
  City = 'city',
  Country = 'country',
  Region = 'region',
  Zone = 'zone',
  Neighborhood = 'neighborhood',
  POI = 'point_of_interest',
  Geo = 'geo',
}

export type PriceRangeType = {
  min: number;
  max?: number;
};

export type SetPriceRange = (priceRange: PriceRangeType) => Promise<void>;
export type ResetPriceRange = () => Promise<void>;

export type SearchUrl = {
  href: {
    pathname: string;
    query: Record<string, any>;
  };
  shallow?: boolean;
};

export interface SearchType {
  checkin: Date;
  draftCheckin: Date;
  checkout: Date;
  draftCheckout: Date;
  setDates: ({ checkIn, checkOut }: { checkIn: Date; checkOut: Date }) => void;
  minBookingDate: Date;
  daysOfBooking: number;
  rooms: Room[];
  draftRooms: Room[];
  setPaxes: (paxes: Room[]) => void;
  market: string;
  setPriceRange: SetPriceRange;
  resetPriceRange: ResetPriceRange;
  priceRange?: PriceRangeType;
  facilities: Set<FacilitiesOptions>;
  setFacilities: (facilities: Set<FacilitiesOptions>) => Promise<void>;
  resetFacilities: () => Promise<void>;
  familyFriendly: boolean;
  setFamilyFriendly: (family: boolean) => Promise<void>;
  accommodations: Set<AccommodationOptions>;
  setAccommodations: (accommodations: Set<AccommodationOptions>) => Promise<void>;
  resetAccommodations: () => Promise<void>;
  ratings: Set<RatingOptions>;
  setRatings: (stars: Set<RatingOptions>) => Promise<void>;
  resetRatings: () => Promise<void>;
  stars: Set<StarsOptions>;
  setStars: (stars: Set<StarsOptions>) => Promise<void>;
  resetStars: () => Promise<void>;
  sortBy: SortOptions;
  setSortBy: (sortBy: SortOptions) => Promise<void>;
  location: SuggestionType | null;
  draftLocation: SuggestionType | null;
  setLocation: (location: SuggestionType | null) => void;
  searchStatus: SearchStatus;
  setSearchStatus: (searchStatus: SearchStatus) => void;
  changeState: (action: SearchActions) => void;
  page: number;
  setPage: (page: number) => void;
  nextPage: () => void;
  resetPage: () => void;
  highlightedProperty?: number;
  setFilters: (filters: Record<string, any>) => Promise<void>;
  resetFilters: () => Promise<void>;
  searchUrl: SearchUrl;
  oldSearchUrl: SearchUrl;
  searchAffiliate: { affiliate: string; source?: string };
}

export const starsOptions = ['1', '2', '3', '4', '5'] as const;
export type StarsOptions = (typeof starsOptions)[number];

export interface Room {
  adults: number;
  children: number[];
}

export interface SearchParams {
  adults: number;
  checkin: string;
  checkout: string;
  children: number;
  hotelId: string;
  market: string;
  page: string;
  relativeInDate: string;
  relativeOutDate: string;
}

export interface BoxShapeType {
  topLeft: {
    lat: number;
    lon: number;
  };
  bottomRight: {
    lat: number;
    lon: number;
  };
}

export interface SuggestionType {
  ID: string;
  Coordinate: {
    lat: number;
    lon: number;
  };
  Country: string;
  Slug: string;
  Type: string;
  Text: string;
  Description: string;
  Stars: number;
  CitySlug: string;
  BoxShape?: BoxShapeType;
}

export enum SearchMode {
  Readonly = 'readonly',
  Full = 'full',
  Borderless = 'borderless',
}

export enum Filters {
  Stars = 'stars',
  AccommodationType = 'accommodation_type',
  PricePerNight = 'price_per_night',
  SortBy = 'sort_by',
  GuestsRating = 'guests_rating',
  Facilities = 'facilities',
  FamilyFriendly = 'family_friendly',
}
