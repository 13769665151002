import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import { MarketType } from 'src/types/market';

export const getMarketContextValue = (config: unknown) => {
  const contextValue = pick(config, [
    'countryCode',
    'countryLanguage',
    'countryName',
    'contactEmail',
    'currency',
    'language',
    'locale',
    'market',
    'phone',
    'measurementSystem',
    'subdomain',
    'topdomain',
    'helpCenterUrl',
    'faqsUrl',
    'daysOfBooking',
    'inanitionDays',
    'defaultBookingDays',
    'chatbot',
    'overrideCreditCardLogos',
  ]);

  if (isEmpty(contextValue)) {
    return null;
  }

  return contextValue as MarketType;
};
