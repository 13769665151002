/* 2021-01-15 -> 01/15/2021 || 1/15/2021 (US) */
export const DATE_INTL_FORMAT_DEFAULT = 'DATE_INTL_FORMAT_DEFAULT';

/* 2021-01-15 -> 01/15/2021 */
export const DATE_INTL_FORMAT_TWO_DIGITS = 'DATE_INTL_FORMAT_TWO_DIGITS';

/* 2021-01-15 -> 01/15/21 */
export const DATE_INTL_FORMAT_TWO_DIGITS_ONLY = 'DATE_INTL_FORMAT_TWO_DIGITS_ONLY';

/* 2021-01-15 -> 15 January 2021 */
export const DATE_INTL_FORMAT_MONTH_TEXT = 'DATE_INTL_FORMAT_MONTH_TEXT';

/* 2021-01-15 -> 15 Jan */
export const DATE_INTL_FORMAT_DAY_MONTH_SHORT_TEXT = 'DATE_INTL_FORMAT_DAY_MONTH_SHORT_TEXT';

/* 2021-01-15 -> 15 Jan 2021 */
export const DATE_INTL_FORMAT_MONTH_SHORT_TEXT = 'DATE_INTL_FORMAT_MONTH_SHORT_TEXT';

/* 2021-01-15 -> Friday, 15 January 2021 */
export const DATE_INTL_FORMAT_WEEKDAY_MONTH_TEXT = 'DATE_INTL_FORMAT_WEEKDAY_MONTH_TEXT';

/* 2021-01-15 -> Fri, 15 Jan 2021 */
export const DATE_INTL_FORMAT_WEEKDAY_MONTH_SHORT_TEXT =
  'DATE_INTL_FORMAT_WEEKDAY_MONTH_SHORT_TEXT';

/* 2021-01-15 -> Fri, 15 Jan */
export const DATE_INTL_FORMAT_WEEKDAY_MONTH_NO_YEAR_SHORT_TEXT =
  'DATE_INTL_FORMAT_WEEKDAY_MONTH_NO_YEAR_SHORT_TEXT';

export const VALID_DATE_INTL_FORMATS = [
  DATE_INTL_FORMAT_DEFAULT,
  DATE_INTL_FORMAT_TWO_DIGITS,
  DATE_INTL_FORMAT_TWO_DIGITS_ONLY,
  DATE_INTL_FORMAT_DAY_MONTH_SHORT_TEXT,
  DATE_INTL_FORMAT_MONTH_TEXT,
  DATE_INTL_FORMAT_MONTH_SHORT_TEXT,
  DATE_INTL_FORMAT_WEEKDAY_MONTH_TEXT,
  DATE_INTL_FORMAT_WEEKDAY_MONTH_SHORT_TEXT,
  DATE_INTL_FORMAT_WEEKDAY_MONTH_NO_YEAR_SHORT_TEXT,
];

export const formatMappings = {
  [DATE_INTL_FORMAT_DEFAULT]: {},
  [DATE_INTL_FORMAT_TWO_DIGITS]: {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  },
  [DATE_INTL_FORMAT_TWO_DIGITS_ONLY]: {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  },

  [DATE_INTL_FORMAT_MONTH_TEXT]: {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  },
  [DATE_INTL_FORMAT_DAY_MONTH_SHORT_TEXT]: {
    day: '2-digit',
    month: 'short',
  },
  [DATE_INTL_FORMAT_MONTH_SHORT_TEXT]: {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  },
  [DATE_INTL_FORMAT_WEEKDAY_MONTH_TEXT]: {
    day: 'numeric',
    month: 'long',
    weekday: 'long',
    year: 'numeric',
  },
  [DATE_INTL_FORMAT_WEEKDAY_MONTH_SHORT_TEXT]: {
    day: '2-digit',
    month: 'short',
    weekday: 'short',
    year: 'numeric',
  },
  [DATE_INTL_FORMAT_WEEKDAY_MONTH_NO_YEAR_SHORT_TEXT]: {
    day: '2-digit',
    month: 'short',
    weekday: 'short',
  },
};
