import React from 'react';
import PropTypes from 'prop-types';
import { FlagBase, Name, Item, IconPlaceholder } from './styles';

const Flag = ({ country = {}, isUserNav = false, onClick, textLabel = true }) => {
  const { countryCode, countryLanguage, countryName } = country;

  return (
    <FlagBase
      className="flag"
      isPlaceholder={countryCode === 'zz'}
      isUserNav={isUserNav}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
    >
      {countryCode === 'zz' ? <IconPlaceholder /> : <Item className={`flag-${countryCode}`} />}
      {textLabel && <Name isUserNav={isUserNav}>{`${countryName} (${countryLanguage})`}</Name>}
    </FlagBase>
  );
};

Flag.propTypes = {
  country: PropTypes.instanceOf(Object),
  isUserNav: PropTypes.bool,
  onClick: PropTypes.func,
  textLabel: PropTypes.bool,
};

export default Flag;
