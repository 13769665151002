import { call, put } from 'redux-saga/effects';

import { fetchRefundWalletFailureAction, fetchRefundWalletSuccessAction } from './actions';
import { refundWalletRequest } from './requests';
import { refetchVoucherAction } from 'src/comps/Voucher/actions';

export function* fetchRefundWalletSaga(action) {
  try {
    const response = yield call(refundWalletRequest, action.payload);

    if (response.status >= 400) {
      throw new Error(response.data || response.status);
    }

    yield put(fetchRefundWalletSuccessAction());
    yield put(refetchVoucherAction());
  } catch (error) {
    yield put(fetchRefundWalletFailureAction({ error: error.message }));
    yield put(refetchVoucherAction());
  }
}
