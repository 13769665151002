export const VARIANTS = [
  'alone',
  'custom',
  'default',
  'extended',
  'fullscreen',
  'fullscreenPartial',
  'midScreen',
  'newModal',
];

export const VARIANT_ALONE = 'alone';
export const VARIANT_CUSTOM = 'custom';
export const VARIANT_DEFAULT = 'default';
export const VARIANT_EXTENDED = 'extended';
export const VARIANT_FULLSCREEN = 'fullscreen';
export const VARIANT_FULLSCREEN_PARTIAL = 'fullscreenPartial';
export const VARIANT_MID_SCREEN = 'midScreen';
export const VARIANT_NEW_MODAL = 'newModal';
