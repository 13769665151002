import cloneDeep from 'lodash/cloneDeep';

import { UPDATE_SEARCH, SEARCH_STATE } from './constants';
import stateBase from './state';
import { FETCH_LOCATION_SUCCESS } from 'client/contexts/Location/constants';

const initialState = stateBase[SEARCH_STATE];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SEARCH:
      return {
        ...state,
        ...cloneDeep(action.payload),
      };


    // Upate latitude and longitude in the search state when user moves through the map
    // to avoid re-center the map when user changes the filters and refetch the properties    
    case FETCH_LOCATION_SUCCESS:
      return {
        ...state,
        latitude: action.payload.latitude,
        longitude: action.payload.longitude,
      };

    default:
      return state;
  }
};

export default reducer;
