import { CONFIG_STATE, SET_CONFIG } from './constants';
import stateBase from './state';

const initialState = stateBase[CONFIG_STATE];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONFIG: {
      const {
        currency,
        device,
        ip,
        language,
        locale,
        market,
        pageId,
        phone,
        affiliateId,
        mobileRate,
        featureFlag,
      } = action.payload;

      return {
        ...state,
        affiliateId: affiliateId || '',
        currency: currency || state.currency,
        device: device || state.device,
        ip: ip || '',
        language: language || state.language,
        locale: locale || state.locale,
        market: market || state.market,
        pageId: pageId || state.pageId,
        phone: phone || state.phone,
        mobileRate: mobileRate || state.mobileRate,
        featureFlag: featureFlag || state.featureFlag,
      };
    }

    default:
      return state;
  }
};

export default reducer;
