export const BRAND_NAME = 'stayforlong';
export const BRAND_NAME_TITLE = 'Stayforlong';

export const PROPERTIES_PER_PAGE = 18;
export const PROPERTIES_FOUND_THRESHOLD_BOOST_SUGGESTED = 6;
export const DEFAULT_SUGGESTED_SEARCH_DISTANCE = 100;
export const BOOSTED_SUGGESTED_SEARCH_DISTANCE = 300;

export const DAYS_TO_START = 4;
export const DAYS_OF_BOOKING = 3;

export const MARKETS_TO_RECOMMEND_SFL_OFFERS = ['uk'];

const MARKETS_1_DAY_BOOKING_ALLOWED = [
  'at',
  'au',
  'be',
  'br',
  'ca',
  'co',
  'de',
  'es',
  'fi',
  'fr',
  'gr',
  'ie',
  'it',
  'lu',
  'mx',
  'nl',
  'pt',
  'sk',
  'uk',
  'us',
  'dk',
  'no',
  'pl',
  'ro',
  'ch',
  'se',
  'tr',
  'za',
];

export function getDaysOfBookings(market) {
  return MARKETS_1_DAY_BOOKING_ALLOWED.includes(market) ? 1 : DAYS_OF_BOOKING;
}
