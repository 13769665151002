import { call, put } from 'redux-saga/effects';

import { fetchVoucherSuccessAction, fetchVoucherFailureAction } from './actions';
import { fetchVoucher } from './requests';

export function* fetchVoucherSaga(action) {
  try {
    const response = yield call(fetchVoucher, action.payload);

    if (response.status >= 400) {
      yield put(fetchVoucherFailureAction({ error: response.data }));
      return;
    }

    yield put(fetchVoucherSuccessAction(response.data));
  } catch (error) {
    yield put(fetchVoucherFailureAction({ error: error.message }));
  }
}
