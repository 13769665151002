import {
  FETCH_PROPERTIES_REQUEST,
  FETCH_PROPERTIES_SUCCESS,
  FETCH_PROPERTIES_FAILURE,
} from './constants';

export const fetchPropertiesRequestAction = payload => ({
  type: FETCH_PROPERTIES_REQUEST,
  payload,
});

export const fetchPropertiesSuccessAction = payload => ({
  type: FETCH_PROPERTIES_SUCCESS,
  payload,
});

export const fetchPropertiesFailureAction = payload => ({
  type: FETCH_PROPERTIES_FAILURE,
  payload,
});
