import { call, put } from 'redux-saga/effects';

import {
  changePasswordFailureAction,
  changePasswordSuccessAction,
  createUserFailureAction,
  createUserSuccessAction,
  updateUserFailureAction,
  updateUserSuccessAction,
  fetchCustomerCreditCardFailureAction,
  fetchCustomerCreditCardSuccessAction,
} from './actions';

import { CONFLICT, UNAUTHORIZED } from 'src/constants/httpcodes';
import { createUser, updateUser, changePassword, fetchCustomerCreditCard } from './requests';
import { displayAlertAction } from 'src/comps/AlertMessage/actions';

export function* createUserSaga(action) {
  try {
    const { data, status } = yield call(createUser, action.payload);

    if (status === CONFLICT.status) {
      yield put(displayAlertAction({ messageId: 'errorAlreadyRegistered' }));
      yield put(createUserFailureAction({ error: data }));
      return;
    }

    if (status >= 400) {
      yield put(displayAlertAction({ messageId: 'errorDefault' }));
      yield put(createUserFailureAction({ error: data }));
      return;
    }

    yield put(createUserSuccessAction({ created: true, data }));
  } catch (error) {
    yield put(displayAlertAction({ messageId: 'errorDefault' }));
    yield put(createUserFailureAction({ error: error.message }));
  }
}

export function* updateUserSaga(action) {
  try {
    const { data, status } = yield call(updateUser, action.payload);

    if (status >= 400) {
      yield put(displayAlertAction({ messageId: 'errorDefault' }));
      yield put(updateUserFailureAction({ error: data }));
      return;
    }

    yield put(displayAlertAction({ messageId: 'successUpdatedUser', severity: 'success' }));
    yield put(updateUserSuccessAction({ updated: true, data: action.payload.data }));
  } catch (error) {
    yield put(displayAlertAction({ messageId: 'errorDefault' }));
    yield put(updateUserFailureAction({ error: error.message }));
  }
}

export function* changePasswordSaga(action) {
  try {
    const { data, status } = yield call(changePassword, action.payload);

    if (status >= 400) {
      yield put(displayAlertAction({ messageId: 'errorDefault' }));
      yield put(changePasswordFailureAction({ error: data }));
      return;
    }

    yield put(displayAlertAction({ messageId: 'successChangePassword', severity: 'success' }));
    yield put(changePasswordSuccessAction({ passwordChanged: true }));
  } catch (error) {
    yield put(displayAlertAction({ messageId: 'errorDefault' }));
    yield put(changePasswordFailureAction({ error: error.message }));
  }
}

export function* fetchCustomerCreditCardSaga(action) {
  try {
    const { data, status } = yield call(fetchCustomerCreditCard, action.payload);

    if (status >= UNAUTHORIZED.status) {
      yield put(fetchCustomerCreditCardFailureAction({ error: UNAUTHORIZED.message }));
      return;
    }

    if (status >= 400) {
      yield put(fetchCustomerCreditCardFailureAction({ error: data }));
      return;
    }

    yield put(fetchCustomerCreditCardSuccessAction({ creditCard: data }));
  } catch (error) {
    yield put(fetchCustomerCreditCardFailureAction({ error: error.message }));
  }
}
