import { getAffiliateInfoRestored } from './affiliateInfoTransform';
import { fromBase64 } from './fromBase64';

/**
 * Function that receives a string and returns a parsed object
 * @param dataToParse base64url string or a encodeURIComponent string
 * @returns object
 */
export function parseCookieValue(dataToParse?: string) {
  if (!dataToParse) return {};

  let parsed;
  try {
    parsed = JSON.parse(fromBase64(dataToParse));
  } catch (b64Error) {
    try {
      parsed = JSON.parse(dataToParse);
    } catch (jsonError) {
      parsed = {};
    }
  }

  return getAffiliateInfoRestored(parsed);
}
