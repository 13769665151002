import {
  REFUND_WALLET_STATE,
  REFUND_WALLET_FAILURE,
  REFUND_WALLET_REQUEST,
  REFUND_WALLET_SUCCESS,
  STATUS_REFUND_WALLET_OK,
  STATUS_REFUND_WALLET_NOT_OK,
} from './constants';
import stateBase from './state';

const initialState = stateBase[REFUND_WALLET_STATE];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REFUND_WALLET_REQUEST:
      return {
        ...state,
        error: null,
        isFetching: true,
        status: null,
      };

    case REFUND_WALLET_SUCCESS: {
      return {
        ...state,
        error: null,
        isFetching: false,
        status: STATUS_REFUND_WALLET_OK,
      };
    }

    case REFUND_WALLET_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isFetching: false,
        status: STATUS_REFUND_WALLET_NOT_OK,
      };

    default:
      return state;
  }
};

export default reducer;
