export const PAYMENT_STATE = 'payment';

export const PAYMENT_REQUEST = 'PAYMENT_REQUEST';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
export const PAYMENT_FAILURE = 'PAYMENT_FAILURE';

export const CONFIRMATION_REQUEST = 'CONFIRMATION_REQUEST';
export const CONFIRMATION_SUCCESS = 'CONFIRMATION_SUCCESS';
export const CONFIRMATION_SUCCESS_3DS = 'CONFIRMATION_SUCCESS_3DS';
export const CONFIRMATION_FAILURE = 'CONFIRMATION_FAILURE';

export const STATUS_OK = 'STATUS_OK';
export const STATUS_KO = 'STATUS_KO';
export const STATUS_3DS_REQUIRED = 'STATUS_3DS_REQUIRED';

export const RESET_PAYMENT = 'RESET_PAYMENT';
