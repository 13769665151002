export const buildPropertiesFromBookingsRequest = params => {
  return {
    id_properties: params.propertiesIds.join(),
    lang: params.language,
  };
};

export function mapFromDataToPropertyInfo(data) {
  return data.properties.map(item => {
    const { available_paths: availablePaths, ...rest } = item.images[0];
    const photo = { ...rest, availablePaths };

    return {
      idProperty: item.id_property,
      name: item.name,
      city: item.city,
      photo,
    };
  });
}

export function mapFromDataToUserBookingList(bookings, propertiesInfo) {
  return bookings.map(booking => {
    const propertyFound = propertiesInfo.find(
      item => item.idProperty === parseInt(booking.idProperty)
    );

    return {
      ...booking,
      ...propertyFound,
    };
  });
}

export function mapFromMultipleBookingStatuses(data) {
  return data.reduce((accumulator, current) => {
    if (accumulator === '') {
      return current.booking_status;
    }
    return accumulator === current.booking_status ? accumulator : null;
  }, '');
}

export function mapFromDataToUserBooking(data) {
  if (!data || Object.keys(data).length === 0) {
    return {};
  }
  return Object.keys(data).map(key => ({
    purchaseId: key,
    checkIn: data[key][0].checkin,
    checkOut: data[key][0].checkout,
    idProperty: data[key][0].id_property,
    sellingRate: data[key][0].selling_rate,
    currency: data[key][0].currency,
    bookingStatus: mapFromMultipleBookingStatuses(data[key]),
    paxes: {
      rooms: data[key][0].rooms,
      adults: data[key][0].adults,
      children: data[key][0].children,
    },
  }));
}
