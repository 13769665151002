import React, { FC, memo } from 'react';
import SvgIcon from './SvgIcon';

type Props = {
  size?: number;
  [key: string]: any;
};

type CreateSvgIcon = (path: string, displayName?: string, size?: number) => FC<Props>;

const createSvgIcon: CreateSvgIcon = (path, displayName = '', size = 24) => {
  const viewBox = `0 0 ${size} ${size}`;

  const Component: FC<Props> = memo((props: Props) => {
    return (
      <SvgIcon data-testid={`${displayName}Icon`} size={size} viewBox={viewBox} {...props}>
        {path}
      </SvgIcon>
    );
  });

  if (process.env.NODE_ENV !== 'production' && displayName) {
    Component.displayName = `${displayName}Icon`;
  }

  return Component;
};

export default createSvgIcon;
