import { defineMessages } from 'react-intl';

const messages = defineMessages({
  faqs: {
    id: 'support.faqs',
  },
  help: {
    id: 'global.help',
  },
  cookiesMoreInfo: {
    id: 'legal.click-cookies',
  },
  privacy: {
    id: 'global.privacy',
  },
  supplierTerms: {
    id: 'global.supplier-terms',
  },
  generalConditions: {
    id: 'legal.terms',
  },
  cookiesPolicy: {
    id: 'legal.cookies',
  },
  contact: {
    id: 'global.contact',
  },
  reviews: {
    id: 'global.reviews',
  },
});

export default messages;
