import { requestByGET } from 'lib/requests';
import { buildPropertiesRecommendedRequest } from './mappers';
import { mapProperties } from 'client/contexts/Property/mappers';

const END_POINTS = {
  PROPERTIES_RECOMMENDED: '/properties/ids',
};

export function fetchPropertiesRecommended(params) {
  return requestByGET({
    data: buildPropertiesRecommendedRequest(params.data),
    endpoint: END_POINTS.PROPERTIES_RECOMMENDED,
    mapper: mapProperties,
  });
}
