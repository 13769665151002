import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';

import { buildLocation } from 'lib/utils/page';
import { clickLogoHeader } from 'client/contexts/Tracking/events';
import { getPageTarget } from './utils';
import { getSearchQuery } from 'src/effects/Search/selectors';
import { sendTrackingRequestAction } from 'client/contexts/Tracking/actions';
import { THEME_DEFAULT, THEMES } from '../Header/constants';
import { typeDestination } from 'client/types/search';

import IconArrowBack from 'client/icons/ArrowBack';
import LogoBrand from '../LogoBrand';

import { LogoLink } from './styles';
import { useBusiness } from 'src/hooks/useBusiness';
import { AFIILIATE_EBOOKING } from 'src/constants/affiliates';
import { EbookingLogo } from '../EbookingLogo';
import { EbookingLink } from '../EbookingLink';
import { routes } from 'src/constants/routes';

const HeaderLogo = ({
  backButton = false,
  destinationData = {},
  logoLinkQueryParams = {},
  pageId,
  theme = THEME_DEFAULT,
  isPrime = false,
}) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const searchQuery = useSelector(getSearchQuery);
  const { affiliate } = useBusiness();
  const isEbooking = affiliate === AFIILIATE_EBOOKING;

  const logoLinkProps = useMemo(() => {
    return buildLocation({
      page: getPageTarget(pageId),
      path: destinationData,
      query: { ...searchQuery, ...logoLinkQueryParams },
    });
  }, [destinationData, pageId, searchQuery, logoLinkQueryParams]);

  function handleClick(event) {
    event.preventDefault();
    dispatch(sendTrackingRequestAction({ event: clickLogoHeader() }));
    if ((!destinationData || !Object.keys(destinationData).length) && pageId === 'payment') {
      router.back();
    } else {
      router.push({ pathname: logoLinkProps.as, query: logoLinkProps?.href?.query || {} });
    }
  }

  if (isEbooking && pageId === routes.list.page) {
    return (
      <EbookingLink>
        <EbookingLogo />
      </EbookingLink>
    );
  }

  return (
    <Link {...logoLinkProps}>
      <LogoLink isEbooking={isEbooking} data-testid={`logo-link-${pageId}`} onClick={handleClick}>
        {backButton && (
          <span>
            <IconArrowBack id="arrow-back" data-testid="arrow-back-button" />
          </span>
        )}
        {isEbooking ? <EbookingLogo /> : <LogoBrand theme={theme} isPrime={isPrime} />}
      </LogoLink>
    </Link>
  );
};

HeaderLogo.propTypes = {
  backButton: PropTypes.bool,
  destinationData: typeDestination,
  logoLinkQueryParams: PropTypes.objectOf(PropTypes.any),
  pageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  theme: PropTypes.oneOf(THEMES),
  isPrime: PropTypes.bool,
};

export default HeaderLogo;
