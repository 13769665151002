import React from 'react';

import Stars from 'client/components/Stars';

import { getSuggestionIcon } from './utils';
import { typeSuggesterItem } from './types';
import { LocationInfo, LocationName, SuggesterItemBase, TextContent } from './styles';

const SuggesterItem = ({ item }) => {
  if (!item) {
    return null;
  }

  const LocationTypeIcon = getSuggestionIcon(item.type);

  return (
    <SuggesterItemBase data-testid="suggester-option">
      <LocationTypeIcon size={20} />

      <TextContent>
        <LocationName>
          <p>{item.text}</p>
          {item.type === 'hotel' && <Stars stars={item.stars} color="grey1" />}
        </LocationName>

        <LocationInfo>{item.description}</LocationInfo>
      </TextContent>
    </SuggesterItemBase>
  );
};

SuggesterItem.propTypes = {
  item: typeSuggesterItem,
};

export default SuggesterItem;
