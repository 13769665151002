import {
  CLEAN_EMAIL_VOUCHER,
  FETCH_VOUCHER_FAILURE,
  FETCH_VOUCHER_REQUEST,
  FETCH_VOUCHER_SUCCESS,
  REFETCH_VOUCHER,
  SAVE_EMAIL_VOUCHER,
  UPDATE_VOUCHER,
  VOUCHER_STATE,
} from './constants';
import stateBase from './state';

const initialState = stateBase[VOUCHER_STATE];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VOUCHER_REQUEST:
      return {
        ...state,
        error: null,
        isFetching: true,
        refetch: false,
      };

    case FETCH_VOUCHER_SUCCESS:
    case UPDATE_VOUCHER:
      return {
        ...state,
        error: null,
        isFetching: false,
        refetch: false,
        voucher: action.payload,
      };

    case FETCH_VOUCHER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        errorCode: action.payload.errorCode,
        isFetching: false,
        refetch: false,
      };

    case REFETCH_VOUCHER:
      return {
        ...state,
        refetch: true,
      };

    case SAVE_EMAIL_VOUCHER:
      return {
        ...state,
        email: action.payload.email,
      };

    case CLEAN_EMAIL_VOUCHER:
      return {
        ...state,
        email: null,
      };

    default:
      return state;
  }
};

export default reducer;
