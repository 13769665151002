import {
  SPECIAL_REQUESTS_STATE,
  SPECIAL_REQUESTS_REQUEST,
  SPECIAL_REQUESTS_SUCCESS,
  SPECIAL_REQUESTS_FAILURE,
  STATUS_OK,
  STATUS_NOT_OK,
} from './constants';
import defaultState from './state';

const initialState = defaultState[SPECIAL_REQUESTS_STATE];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SPECIAL_REQUESTS_REQUEST:
      return {
        ...state,
        bookings: {
          ...state.bookings,
          [action.payload.hash]: {
            isFetching: true,
            status: null,
            error: null,
          },
        },
      };

    case SPECIAL_REQUESTS_SUCCESS:
      return {
        ...state,
        bookings: {
          ...state.bookings,
          [action.payload.hash]: {
            status: STATUS_OK,
            isFetching: false,
            error: null,
          },
        },
      };

    case SPECIAL_REQUESTS_FAILURE:
      return {
        ...state,
        bookings: {
          ...state.bookings,
          [action.payload.hash]: {
            status: STATUS_NOT_OK,
            error: action.payload.error,
            isFetching: false,
          },
        },
      };

    default:
      return state;
  }
};

export default reducer;
