export const ERROR_CODES = {
  BAD_USER: 30,
  CONFIRMATION_NOT_PAYABLE: 65,
  CONFIRMATION: 60,
  PAYMENT_PROVIDER: 40,
  PAYMENT: 45,
  PURCHASE_LOCKED: 50,
  PARTIAL_PAYMENT_NOT_ALLOWED: 48,
  INVALID_CARD: 2,
  CONFIRMATION_RULES: 61,
};
