import { call, put } from 'redux-saga/effects';
import { fetchPrimeReceiptsFailureAction, fetchPrimeReceiptsSuccessAction } from './actions';
import { fetchPrimeTickets } from './requests';

export function* primeReceiptsSaga() {
  try {
    const response = yield call(fetchPrimeTickets);

    if (response.status >= 400) {
      yield put(fetchPrimeReceiptsFailureAction({ error: response.data }));
      return;
    }

    yield put(fetchPrimeReceiptsSuccessAction(response.data));
  } catch (error) {
    yield put(fetchPrimeReceiptsFailureAction({ error: error.message }));
  }
}
