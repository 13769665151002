import { DOMAIN } from 'src/constants/environment';
import { queryStringify } from 'lib/utils/page';

export function isTestInstance(hostname) {
  const regex = /\.test/;
  return regex.test(hostname);
}

export function buildLocationURL(country, toHomepage, windowLocation = null) {
  const { hostname, pathname, protocol } = windowLocation || window.location;
  const isInstance = isTestInstance(hostname);
  const domain = isInstance ? hostname : DOMAIN;

  const { market, language, subdomain, topdomain } = country;
  const pathName = toHomepage ? '' : pathname;

  if(isInstance){
    return `${protocol}//${domain}${pathName}?market=${market}&lang=${language}`;
  }

  const subDomain = subdomain || 'www';
  const topDomain = topdomain && !isInstance ? topdomain : 'com';

  return `${protocol}//${subDomain}.${domain}.${topDomain}${pathName}?market=${market}&lang=${language}`;
}

export function buildSearchQuery({ checkIn, checkOut, adults, children }) {
  const dates = { checkin: checkIn, checkout: checkOut };
  const paxes = queryStringify({ adults, children });

  return new URLSearchParams({ ...dates, ...paxes }).toString();
}
