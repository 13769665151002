import {
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CREATE_USER_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  RESET_USER,
  UPDATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  FETCH_CUSTOMER_CREDIT_CARD_REQUEST,
  FETCH_CUSTOMER_CREDIT_CARD_SUCCESS,
  FETCH_CUSTOMER_CREDIT_CARD_FAILURE,
} from './constants';

export const createUserRequestAction = payload => ({
  type: CREATE_USER_REQUEST,
  payload,
});

export const createUserSuccessAction = payload => ({
  type: CREATE_USER_SUCCESS,
  payload,
});

export const createUserFailureAction = payload => ({
  type: CREATE_USER_FAILURE,
  payload,
});

export const updateUserRequestAction = payload => ({
  type: UPDATE_USER_REQUEST,
  payload,
});

export const updateUserSuccessAction = payload => ({
  type: UPDATE_USER_SUCCESS,
  payload,
});

export const updateUserFailureAction = payload => ({
  type: UPDATE_USER_FAILURE,
  payload,
});

export const changePasswordRequestAction = payload => ({
  type: CHANGE_PASSWORD_REQUEST,
  payload,
});

export const changePasswordSuccessAction = payload => ({
  type: CHANGE_PASSWORD_SUCCESS,
  payload,
});

export const changePasswordFailureAction = payload => ({
  type: CHANGE_PASSWORD_FAILURE,
  payload,
});

export const fetchCustomerCreditCardRequestAction = payload => ({
  type: FETCH_CUSTOMER_CREDIT_CARD_REQUEST,
  payload,
});

export const fetchCustomerCreditCardSuccessAction = payload => ({
  type: FETCH_CUSTOMER_CREDIT_CARD_SUCCESS,
  payload,
});

export const fetchCustomerCreditCardFailureAction = payload => ({
  type: FETCH_CUSTOMER_CREDIT_CARD_FAILURE,
  payload,
});

export const resetUserAction = payload => ({
  type: RESET_USER,
  payload,
});
