import { call, put } from 'redux-saga/effects';

import {
  fetchPropertiesVisitedSuccessAction,
  fetchPropertiesVisitedFailureAction,
} from './actions';

import { fetchPropertiesVisited } from './requests';

export function* fetchPropertiesVisitedSaga(action) {
  try {
    const response = yield call(fetchPropertiesVisited, { data: action.payload });

    if (response.status >= 400) {
      throw new Error(response.data || response.status);
    }

    yield put(fetchPropertiesVisitedSuccessAction({ properties: response.data }));
  } catch (error) {
    yield put(fetchPropertiesVisitedFailureAction({ error: error.message }));
  }
}
