export function getCookieDomain(host) {
  if (/localhost/.test(host)) {
    return 'localhost';
  }

  return host.replace(/[www|es|de|ie]+\./, '');
}

export function buildPathParams(url, params) {
  let updatedURL = url;

  if (params) {
    Object.keys(params).forEach(param => {
      const regex = new RegExp(`[{|[]${param}[}|\\]]`, 'g'); // valid for placeholders {} and []
      updatedURL = updatedURL.replace(regex, params[param]);
      return updatedURL;
    });
  }

  return updatedURL;
}

export function serializeData(params) {
  return Object.keys(params)
    .map(param => (params[param] ? `${param}=${params[param]}` : ''))
    .filter(param => param)
    .join('&');
}

export function buildQueryParams(params) {
  if (!params || !Object.keys(params).length) {
    return '';
  }

  const queryParams = serializeData(params);
  return queryParams === '' ? '' : `?${queryParams}`;
}

export const formatDateQueryParams = dateString => {
  if (!dateString || typeof dateString !== 'string') return '';

  const day = dateString.substring(0, 2);
  const month = dateString.substring(2, 4);
  const year = dateString.substring(4, 8);
  return `${year}-${month}-${day}`;
};
