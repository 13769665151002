import React, { useContext } from 'react';
import { useMedia } from 'react-use';
import { useSelector } from 'react-redux';

import { getLoginData } from 'client/contexts/Login/selectors';
import { PageContext } from 'src/comps/Page/context';

import CommonHeader, { HeaderProps } from '../CommonHeader';
import CountrySelector from 'client/components/CountrySelector';
import LoginButton from 'src/comps/LoginButton';

import { UserActions } from '../CommonHeader/styles';

import { breakpoint } from 'client/styles';
import { THEME_DEFAULT } from './constants';
import { AFIILIATE_EBOOKING } from 'src/constants/affiliates';
import { useBusiness } from 'src/hooks/useBusiness';

const Header: React.FC<HeaderProps> = props => {
  const { hasLogin = true, theme = THEME_DEFAULT, pageId } = props;
  const { device } = useContext(PageContext);
  const isWide = useMedia(breakpoint.tablet, device === 'desktop') && device !== 'tablet';
  const isErrorPage = typeof pageId === 'number' && pageId >= 400;
  const user = useSelector(getLoginData);
  const { affiliate } = useBusiness();
  const isEbooking = affiliate === AFIILIATE_EBOOKING;

  return (
    <CommonHeader
      {...props}
      isPrime={user?.subscriptionPrime}
      isEbooking={isEbooking}
      userActions={
        <UserActions>
          {hasLogin && <LoginButton theme={theme} isMobile={!isWide} isEbooking={isEbooking} />}
          <CountrySelector pageId={pageId} toHomepage={isErrorPage} />
        </UserActions>
      }
    />
  );
};

export default Header;
