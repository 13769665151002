import { call, put } from 'redux-saga/effects';

import { displayAlertAction } from 'src/comps/AlertMessage/actions';
import {
  primeCancelSuccessAction,
  primeCancelFailureAction,
  primeReactivateSuccessAction,
  primeReactivateFailureAction,
} from './actions';
import { fetchCustomerDataRequestAction } from 'client/contexts/Login/actions';
import { primeCancel, primeReactivation } from './requests';

export function* primeCancelSaga(action) {
  try {
    const response = yield call(primeCancel, action.payload);

    if (response.status >= 400) {
      yield put(primeCancelFailureAction({ error: response.data }));
      return;
    }

    yield put(primeCancelSuccessAction());
    yield put(fetchCustomerDataRequestAction({ hostname: action.payload.hostname }));
  } catch (error) {
    yield put(primeCancelFailureAction({ error: error.message }));
  }
}

export function* primeReactivateSaga(action) {
  try {
    const response = yield call(primeReactivation, action.payload);

    if (response.status >= 400) {
      yield put(primeReactivateFailureAction({ error: response.data }));
      return;
    }

    yield put(primeReactivateSuccessAction());
    yield put(
      displayAlertAction({
        messageId: 'successPrimeReactivation',
        severity: 'success',
        values: { prime: 'Prime', stayforlong: 'Stayforlong' },
      })
    );

    yield put(fetchCustomerDataRequestAction({ hostname: action.payload.hostname }));
  } catch (error) {
    yield put(primeReactivateFailureAction({ error: error.message }));
  }
}
