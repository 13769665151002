import { SAVE_CUSTOMER_DATA, PURCHASE_STATE, CLEAN_CUSTOMER_DATA } from './constants';
// TODO: Extract and use it as a generic localStorage middleware
const middleware = () => next => action => {
  if ((action.type === SAVE_CUSTOMER_DATA || action.type === CLEAN_CUSTOMER_DATA) && typeof window !== 'undefined' && localStorage) {
    localStorage.setItem(PURCHASE_STATE, JSON.stringify(action.payload));
  }

  return next(action);
};

export default middleware;
