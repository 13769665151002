import React, { createContext, ReactElement, useMemo } from "react";

import { SystemType } from "src/types/system";

const SystemContext = createContext<SystemType | null>(null)

const SystemProvider = ({ children, value }: { children: ReactElement, value: SystemType | null }) => {

    if (value === null) {
        throw new Error("SystemContext provider can not have a null value")
    }

    const { apiHostName, cookieReturnUrl, host } = value;

    const systemContextValue = useMemo(() => ({ apiHostName, cookieReturnUrl, host }), [apiHostName, cookieReturnUrl, host])

    return (
        <SystemContext.Provider value={systemContextValue}>
            {children}
        </SystemContext.Provider>
    );
};

export { SystemContext, SystemProvider }