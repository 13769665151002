const fontSizes = {
  xs: '12px',
  s: '14px',
  m: '16px',
  l: '21px',
  xl: '28px',
  xxl: '38px',
  xxxl: '51px',
  xxxxl: '67px',
  xxxxxl: '90px',
};

export default fontSizes;
