import { call, put } from 'redux-saga/effects';

import {
  fetchAppliedWalletSuccessAction,
  fetchAppliedWalletFailureAction,
  fetchWalletSuccessAction,
  fetchWalletFailureAction,
  checkPurchaseWalletSuccessAction,
  checkPurchaseWalletFailureAction,
  uncheckPurchaseWalletSuccessAction,
  uncheckPurchaseWalletFailureAction,
} from './actions';

import {
  fetchAppliedWalletByPurchaseId,
  fetchWalletByPurchaseId,
  checkPurchaseWallet,
  uncheckPurchaseWallet,
} from './requests';

export function* fetchAppliedWalletCreditSaga(action) {
  try {
    const response = yield call(fetchAppliedWalletByPurchaseId, action.payload);

    if (response.status >= 400) {
      throw new Error(response.data);
    }

    yield put(fetchAppliedWalletSuccessAction(response.data));
  } catch (error) {
    yield put(fetchAppliedWalletFailureAction({ error: error.message }));
  }
}

export function* fetchWalletCreditSaga(action) {
  try {
    const response = yield call(fetchWalletByPurchaseId, action.payload);

    if (response.status >= 400) {
      throw new Error(response.data);
    }

    yield put(fetchWalletSuccessAction(response.data));
  } catch (error) {
    yield put(fetchWalletFailureAction({ error: error.message }));
  }
}

export function* checkWalletByPurchaseSaga(action) {
  try {
    const response = yield call(checkPurchaseWallet, action.payload);

    if (response.status >= 400) {
      throw new Error(response.data);
    }

    yield put(checkPurchaseWalletSuccessAction());
  } catch (error) {
    yield put(checkPurchaseWalletFailureAction({ error: error.message }));
  }
}

export function* uncheckWalletByPurchaseSaga(action) {
  try {
    const response = yield call(uncheckPurchaseWallet, action.payload);

    if (response.status >= 400) {
      throw new Error(response.data);
    }

    yield put(uncheckPurchaseWalletSuccessAction());
  } catch (error) {
    yield put(uncheckPurchaseWalletFailureAction({ error: error.message }));
  }
}
