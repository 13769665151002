import axios from 'axios';
import { requestByPOST } from 'lib/requests';
import { buildTrackingJSON } from './utils';
import { buildPathParams } from 'lib/utils/url';
import { ORIGIN_DIRECT } from './constants';

const OPTIONS = {
  timeout: 30000,
  headers: {
    'Content-Type': 'application/vnd.kafka.json.v2+json',
    Accept: 'application/vnd.kafka.v2+json',
  },
};

const END_POINTS = {
  ITERABLE: '/lead/{hash}/event/{eventName}',
  TRACK: '/api/kafka/tracking',
};

function requestByPOSTDeprecated(url, data) {
  return axios
    .post(url, data, OPTIONS)
    .then(res => res)
    .catch(error => {
      console.error(error.message); // eslint-disable-line no-console
    });
}

export function trackingRequest(params) {
  const requestParams = buildTrackingJSON(params);
  return requestByPOSTDeprecated(`${process.env.NEXT_PUBLIC_TRACKING_ENDPOINT}`, requestParams);
}

export function sendTrackingKafka(params) {
  const {
    payload: {
      voucher,
      errorCode,
      generatedWallet,
      url,
      rate_class: rateClass,
      is_logged: isLogged,
      affiliate_data: affiliateData,
      summaryData,
      property: propertyData,
      locationType,
      citySlug,
      country,
      term,
      city,
      type,
      occupancies,
      origin = ORIGIN_DIRECT,
    },
    checkIn: checkin,
    checkOut: checkout,
    boards,
    device,
  } = params;

  const locationFromProperty = propertyData.country
    ? {
        country: propertyData.country,
        name: propertyData.city,
        slug: propertyData.citySlug,
        type: locationType,
      }
    : null;

  const locationFromVoucher = voucher?.hotelPreview
    ? {
        country: voucher?.hotelPreview?.propertyCountry,
        name: voucher?.hotelPreview?.propertyName,
        slug: voucher?.hotelPreview?.propertySlug,
        type: voucher?.hotelPreview?.propertyType,
      }
    : null;

  const location = locationFromProperty ||
    locationFromVoucher || {
      name: city,
      slug: citySlug,
      type,
      country,
      description: term,
      text: city,
    };

  const property = params.payload.property || {
    id: voucher?.hotelPreview?.propertyId,
    name: voucher?.hotelPreview?.propertyName,
    accommodationType: voucher?.hotelPreview?.propertyType,
    country: voucher?.hotelPreview?.propertyCountry,
    facilities: voucher?.hotelPreview?.propertyFacilities,
  };

  const bestPriceBoard = boards?.find(board => board.isBestPrice);
  const selectedBoard = boards?.find(board => board.total === summaryData?.total);

  const boardToUse = selectedBoard || bestPriceBoard || boards?.[0];

  const bookingInformation = voucher?.bookingInformation?.[0] ?? {};
  const boardName = boardToUse?.boardName ?? bookingInformation?.boardName ?? '';
  const rateClassValue =
    rateClass ?? boardToUse?.paymentPolicy ?? bookingInformation?.rateClass ?? '';
  const price = {
    amount: voucher?.priceSummary?.total ?? boardToUse?.total ?? 0,
    currencyCode: voucher?.priceSummary?.currency ?? boardToUse?.totalCurrency ?? '',
  };
  const tax = {
    amount: voucher?.priceSummary?.taxes ?? boardToUse?.taxes ?? 0,
    currencyCode: voucher?.priceSummary?.currency ?? boardToUse?.taxesCurrency ?? '',
  };
  const fees = {
    amount:
      voucher?.priceSummary?.additionalFees?.reduce((acc, fee) => acc + fee.amount, 0) ??
      boardToUse?.additionalFees.reduce((acc, fee) => acc + fee.amount, 0),
    currencyCode: voucher?.priceSummary?.currency ?? boardToUse?.additionalFees?.[0].currency ?? '',
  };

  const rateData = {
    boardName,
    rateClass: rateClassValue,
    price,
    tax,
    fees,
  };

  const rooms = occupancies?.adults?.map((adults, index) => ({
    adults,
    childrenAges: occupancies?.childrenAges?.[index],
  }));

  const data = {
    eventName: params.event_name,
    hasError: !!errorCode,
    checkin,
    checkout,
    rooms,
    device,
    property: { ...property, photos: [] },
    location,
    affiliateId: params.payload.affiliate,
    country: params?.payload?.country ?? params?.payload?.search?.country,
    ...(params.catalogue && { catalogue: params.catalogue }),
    market: params.market,
    language: params.language,
    origin,
    isLogged,
    session: params.session_id,
    ip: params.ip,
    isBot: params.is_bot,
    userAgent: params.user_agent,
    url,
    voucher,
    affiliateData,
    generatedWallet,
    rateData,
    summaryData,
  };

  return axios.post(END_POINTS.TRACK, data, OPTIONS).catch(error => {
    console.error(error.message); // eslint-disable-line no-console
  });
}

export function sendTrackingIterable(params) {
  return requestByPOST({
    data: params.data,
    endpoint: buildPathParams(END_POINTS.ITERABLE, {
      hash: params.path.hash,
      eventName: params.path.eventName,
    }),
  });
}
