import { requestByPOST } from 'lib/requests';

const END_POINTS = {
  RESET_PASSWORD: '/users/forgot/update',
};

export function resetPassword(params) {
  return requestByPOST({
    data: { hash: params.data.hash, password: params.data.password },
    endpoint: END_POINTS.RESET_PASSWORD,
    hostname: params.hostname,
  });
}
