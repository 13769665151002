import { LOCALSTORAGE_SEARCH_KEY } from 'src/constants/search';
import { report } from 'src/utils/clientErrorReporter';

export function getLocalStorage(key) {
  if (typeof window === 'undefined' || !localStorage) {
    return {};
  }

  const item = localStorage.getItem(key);
  if (!item) {
    return {};
  }

  try {
    return JSON.parse(item) || {};
  } catch (error) {
    report(error)
    return {};
  }
}

export function getSearchParamsLocalStorage() {
  const searchParams = getLocalStorage(LOCALSTORAGE_SEARCH_KEY);

  if (Object.keys(searchParams).length === 0) {
    return {};
  }

  return searchParams;
}

export function setSearchParamsLocalStorage(data) {
  if (typeof window === 'undefined' || !localStorage) {
    return;
  }
  if (!data) {
    report(new Error('searchParams is being set empty'), {
      extra: { [LOCALSTORAGE_SEARCH_KEY]: data },
    });
    return;
  }

  localStorage.setItem(LOCALSTORAGE_SEARCH_KEY, JSON.stringify(data));
}
