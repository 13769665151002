import { shape, number, string, bool, arrayOf, oneOfType } from 'prop-types';

const baseFacilityTypes = {
  name: string.isRequired,
  icon: string.isRequired,
  featured: bool.isRequired,
  free: bool.isRequired,
};

const facilityTypes = {
  ...baseFacilityTypes,
  categoryCode: number,
  categoryName: string,
};

const descriptionTypes = {
  generic: string,
  amenities: string,
  dining: string,
  renovations: string,
  businessAmenities: string,
  rooms: string,
  attractions: string,
  location: string,
  general: string,
  pool: string,
  activity: string,
  additional: string,
};

export const typeFacilityBase = shape(baseFacilityTypes);
export const typeFacility = shape(facilityTypes);

export const typeDescription = shape(descriptionTypes);

export const typePhoto = shape({
  roomCode: string,
  id: string,
  width: number,
  height: number,
  availablePaths: arrayOf(string),
});

export const typeProperty = shape({
  accommodationType: string,
  address: string.isRequired,
  city: string.isRequired,
  citySlug: string,
  cityUrl: string,
  comments: number,
  description: typeDescription,
  distance: number,
  facilities: arrayOf(typeFacility),
  facilitiesRoom: arrayOf(typeFacilityBase),
  featured: bool,
  free: bool,
  highlighted: bool,
  id: number.isRequired,
  isInCenter: bool,
  isOutsideCity: bool,
  latitude: number.isRequired,
  longitude: number.isRequired,
  name: string.isRequired,
  photo: oneOfType([typePhoto, string]),
  photos: arrayOf(oneOfType([typePhoto, string])),
  postalCode: string,
  ratings: shape({}),
  score: number,
  slug: string,
  stars: number,
  price: number,
  currency: string,
  url: string,
});
