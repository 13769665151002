import { call, put } from 'redux-saga/effects';

import {
  fetchCancelBookingSuccessAction,
  fetchCancelBookingFailureAction,
  fetchCancelBookingRejectedAction,
} from './actions';

import { HTTP_CODE_CANCELLED_FAILED } from './constants';
import { UNAUTHORIZED } from 'src/constants/httpcodes';

import { cancelBooking } from './requests';
import { refetchVoucherAction, cleanEmailAction } from 'src/comps/Voucher/actions';

export function* cancelBookingSaga(action) {
  try {
    const response = yield call(cancelBooking, { data: action.payload });

    yield put(fetchCancelBookingSuccessAction({ data: response.data, hash: action.payload.hash }));
    yield put(cleanEmailAction());
    yield put(refetchVoucherAction());
  } catch (error) {
    if (
      error.statusCode === HTTP_CODE_CANCELLED_FAILED ||
      error.statusCode === UNAUTHORIZED.status
    ) {
      yield put(
        fetchCancelBookingRejectedAction({
          error: error.message,
          errorCode: error.statusCode,
          hash: action.payload.hash,
        })
      );
      yield put(cleanEmailAction());
      return;
    }

    yield put(
      fetchCancelBookingFailureAction({
        error: error.message,
        errorCode: error.statusCode,
        hash: action.payload.hash,
      })
    );
    yield put(cleanEmailAction());
  }
}
