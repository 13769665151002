import qs from 'query-string';
import { ORDER_OPTIONS } from './constants';
import { FACILITIES } from 'client/components/FilterFacilities/constants';
import { ACCOMMODATION_TYPES } from 'client/components/FilterAccommodationTypes/constants';

function validateFilterUrlParam(param, data) {
  if (!param || !data || typeof data !== 'string') {
    return false;
  }

  const dataArray = data.split(',');

  switch (param) {
    case 'stars':
      return dataArray.every(item => {
        const currentStarValue = parseInt(item);
        return currentStarValue > 0 && currentStarValue <= 5;
      });
    case 'facilities': {
      return dataArray.every(item => {
        const formattedItem = item.replace('-', '').toLocaleLowerCase();
        const names = FACILITIES.map(facility => facility.name);
        return names.indexOf(formattedItem) !== -1;
      });
    }
    case 'accommodationTypes': {
      return dataArray.every(item => {
        const formattedItem = item.replace('-', '').toLocaleLowerCase();
        const slugs = ACCOMMODATION_TYPES.map(accommodation => accommodation.slug);
        return slugs.indexOf(formattedItem) !== -1;
      });
    }
    case 'minPrice':
      return parseInt(data) >= 0;
    case 'maxPrice':
      return parseInt(data) > 0;
    case 'ratings':
      return dataArray.every(item => {
        const currentStarValue = parseInt(item);
        return currentStarValue > 0 && currentStarValue <= 10;
      });
    case 'family':
      return data === 'true';
    case 'orderBy':
      return ORDER_OPTIONS.includes(data);
    default:
      return false;
  }
}

export function getValidFiltersFromUrl(queryString) {
  if (!queryString) {
    return {};
  }

  const filters = {};
  const params = qs.parse(queryString);
  const paramKeys = Object.keys(params);
  const paramsLength = paramKeys.length;

  for (let i = 0; i < paramsLength; i += 1) {
    const filterName = paramKeys[i];
    if (validateFilterUrlParam(filterName, params[filterName])) {
      filters[filterName] = params[filterName];
    }
  }

  return filters;
}
