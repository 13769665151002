import { call, put } from 'redux-saga/effects';

import { fetchSummary } from './requests';
import { fetchSummarySuccessAction, fetchSummaryFailureAction } from './actions';

export function* fetchSummarySaga(action) {
  try {
    const response = yield call(fetchSummary, action.payload);

    if (response.status >= 400) {
      throw new Error(response.data);
    }

    yield put(fetchSummarySuccessAction({ summary: response.data }));
  } catch (error) {
    yield put(fetchSummaryFailureAction({ error: error.message }));
  }
}
