type DataDogRUM = {
  onReady: (fn: () => void) => void
  addError: (error: Error, ctx?: unknown) => void
  startView: ({ name, service, version }: { name: string, service?: string, version?: string }) => void
}

export type DataDogAPI = {
  DD_RUM: DataDogRUM,
}

export const addError = (error: Error, ctx?: unknown) => {
  const { DD_RUM } = global as unknown as DataDogAPI;
  DD_RUM.addError(error, ctx);
}

export const report = (error: Error, ctx?: unknown) => {
  const { DD_RUM } = global as unknown as DataDogAPI;
  DD_RUM.onReady(addError.bind(null, error, ctx))
};

export const startView = (path: string) => {
  const { DD_RUM } = global as unknown as DataDogAPI;

  DD_RUM.startView({
    name: path,
    service: process.env.NEXT_PUBLIC_DD_SERVICE,
    version: process.env.NEXT_PUBLIC_DD_VERSION,
  });
}

export const reportPageView = (path: string) => {
  const { DD_RUM } = global as unknown as DataDogAPI;
  DD_RUM?.onReady(startView.bind(null, path))
};
