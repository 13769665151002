export function buildVoucherPayRequest({ purchaseId, language, hash }) {
  return {
    purchase_id: purchaseId,
    hash,
    language,
    browserColorDepth: `${window.screen.colorDepth}`,
    browserTimeZone: `${new Date().getTimezoneOffset()}`,
    browserAcceptHeader: 'application/json',
    browserJavaEnabled: false,
    browserScreenHeight: window.screen.height,
    browserScreenWidth: window.screen.width,
    browserLanguage: language,
  };
}

export function sumGeneratedWallet(bookings) {
  return bookings.reduce((total, booking) => {
    return booking.bookingStatus === 'confirmed' ? total + booking.generatedWallet : total;
  }, 0);
}
