import styled from 'styled-components';

export const ButtonResetBase = styled.div`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  padding: 7px 8px;
  background-color: ${({theme}) => theme.colors.grey6};
  border-radius: 8px;
  cursor: pointer;

  &.button-reset svg {
    fill: ${({theme}) => theme.colors.grey2};
    height: 20px;
    width: 20px;
    padding-left: 0;
  }

  &:hover {
    background-color: ${({theme}) => theme.colors.grey5};
  }
`;
