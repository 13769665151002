import { call, put } from 'redux-saga/effects';

import { addSpecialRequests } from './requests';
import { fetchSpecialRequestsSuccessAction, fetchSpecialRequestsFailureAction } from './actions';

export function* addSpecialRequestsSaga(action) {
  const { hash } = action.payload;

  try {
    const { data, status } = yield call(addSpecialRequests, action.payload);

    if (status >= 400) {
      yield put(fetchSpecialRequestsFailureAction({ error: data, hash }));
      return;
    }

    yield put(fetchSpecialRequestsSuccessAction({ data, hash }));
  } catch (error) {
    yield put(fetchSpecialRequestsFailureAction({ error: error.message, hash }));
  }
}
