import { createSelector } from 'reselect';
import { CONFIG_STATE } from './constants';

export const getCurrency = state => state[CONFIG_STATE]?.currency;
export const getDevice = state => state[CONFIG_STATE]?.device;
export const getFilters = state => state[CONFIG_STATE]?.filters;
export const getLanguage = state => state[CONFIG_STATE]?.language;
export const getMarket = state => state[CONFIG_STATE]?.market;
export const getPhone = state => state[CONFIG_STATE]?.phone;
export const getMobileRate = state => state[CONFIG_STATE]?.mobileRate;
export const getFeatureFlag = state => state[CONFIG_STATE]?.featureFlag;

export const getBaseParams = createSelector(
  [getDevice, getLanguage, getMarket, getCurrency, getMobileRate],
  (device, language, market, currency, mobileRate) => ({
    device,
    language,
    market,
    currency,
    mobileRate,
  })
);

export const getSearchFilters = createSelector([getFilters], ({ stars, boards, facilities }) => ({
  stars,
  boards,
  facilities,
}));
