import { requestByPOST } from 'lib/requests';
import { mapSuggestions } from './mappers';

import { DEFAULT_AFFILIATE_ID } from 'src/pages/_middleware/defaultAffiliate';

const END_POINTS = {
  SUGGESTER: '/v2/suggester',
};

export function fetchSuggestions({
  affiliate = DEFAULT_AFFILIATE_ID,
  language,
  market,
  search,
  new_locations = false, // eslint-disable-line camelcase
}) {
  return requestByPOST({
    data: { affiliate, lang: language, market, keyword: search, new_locations }, // eslint-disable-line camelcase
    endpoint: END_POINTS.SUGGESTER,
    mapper: mapSuggestions,
  });
}
