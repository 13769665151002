/* eslint-disable no-continue */
/* eslint-disable no-plusplus */
/* eslint-disable camelcase */
import { updatedBreakpoint } from 'client/styles';
import isObject from 'lib/functions/isObject';
import type { ImageApiObject } from 'src/types/image';
import { convertToBase64 } from 'src/utils/toBase64';
import { PATH_HOTELMEDIA_CDN } from 'src/constants/environment';

export type ImageSizeMode = 'cover' | 'contain' | 'fill' | 'inside' | 'outside';

export interface ImageHandlerObject {
  imgObj: ImageApiObject;
  width?: number;
  height?: number;
  fit?: ImageSizeMode;
}

export const ImagePathPriority = ['raw', 'big', 'medium', 'thumb'] as const;
export const ALLOWED_DOMAINS = ['static.stayforlong.com', 'hotelmedia.stayforlong.com'] as const;
export const CLOUDFRONT_ORIGIN = process.env.NEXT_PUBLIC_IMAGE_SERVICE;

export type AcceptedDomain = (typeof ALLOWED_DOMAINS)[number];
export interface AmazonImageTransformationObject {
  bucket: AcceptedDomain;
  key: string;
  edits: {
    toFormat: string;
    resize: {
      width: number;
      height?: number;
      fit: ImageSizeMode;
    };
  };
}

// Type inference utilities
const hasOwnProperty = <T>(obj: T, prop: keyof T) =>
  Object.prototype.hasOwnProperty.call(obj, prop);
const isAllowedDomain = (url: string): url is AcceptedDomain =>
  ALLOWED_DOMAINS.includes(url as AcceptedDomain);

/**
 * @description maps the image object from the api to a cloudfront url with image transformation
 * @example amazonImageHandlerMapper({
 *  imgObj: {
 *   url: 'https://static.stayforlong.com/medium/1234/somewhere.jpg',
 *   id: '1234/somewhere.jpg',
 *   availablePaths: ['thumb', 'medium', 'big'],
 *   width: 100,
 *   height: 100
 *  },
 *  width: 50,
 *  height: 100,
 *  fit: 'cover'
 * })
 * // returns https://dbxzmiajozysf.cloudfront.net/eyJidWNrZXQiOiJzdGF0aWMuc3RhZnNmb3Jsb25nLmNvbSIsImtleSI6ImJpZy8xMjM0L3NvbWV3aGVyZS5qcGciLCJlZGl0cyI6eyJ0b0Zvcm1hdCI6IndlYnAiLCJyZXNpemUiOnsid2lkdGgiOjEwMCwiaGVpZ2h0Ijo1MCwiZml0IjoiY292ZXIifX19
 *
 * @param {ImageHandlerObject} imgHandlerObj
 * @return {string}  url for the image using cloudfront and amazon image transformation
 */
export const amazonImageHandlerMapper = (imgHandlerObj: ImageHandlerObject): string => {
  const { imgObj, width: originalWidth, height, fit = 'cover' } = imgHandlerObj;

  if (
    (typeof originalWidth !== 'undefined' && typeof originalWidth !== 'number') ||
    (typeof height !== 'undefined' && typeof height !== 'number')
  )
    return `${CLOUDFRONT_ORIGIN}/not-found`;

  if (
    !imgObj ||
    !isObject(imgObj) ||
    !hasOwnProperty(imgObj, 'id') ||
    !hasOwnProperty(imgObj, 'availablePaths') ||
    !hasOwnProperty(imgObj, 'width') ||
    !hasOwnProperty(imgObj, 'height') ||
    typeof imgObj.id !== 'string' ||
    imgObj.id.length === 0 ||
    !Array.isArray(imgObj.availablePaths) ||
    imgObj.availablePaths.length === 0 ||
    imgObj.availablePaths.some(p => !ImagePathPriority.includes(p)) ||
    typeof imgObj.width !== 'number' ||
    typeof imgObj.height !== 'number'
  )
    return `${CLOUDFRONT_ORIGIN}/not-found`;

  const width = originalWidth ?? imgObj.width;
  let url: URL;
  try {
    url = new URL(`${PATH_HOTELMEDIA_CDN}/medium/${imgObj.id}`);
  } catch (error) {
    return `${CLOUDFRONT_ORIGIN}/not-found`;
  }

  const { hostname } = url;
  if (!isAllowedDomain(hostname)) return `${CLOUDFRONT_ORIGIN}/not-found`;
  const imgSizePath = ImagePathPriority.find(p => imgObj.availablePaths.includes(p)) || 'big';

  const json: AmazonImageTransformationObject = {
    bucket: hostname,
    key: `${imgSizePath}/${imgObj.id}`,
    edits: {
      toFormat: 'webp',
      resize: {
        width: width > imgObj.width ? imgObj.width : width,
        fit,
      },
    },
  };

  if (height) json.edits.resize.height = height > imgObj.height ? imgObj.height : height;

  const encoded = convertToBase64(json);

  return `${CLOUDFRONT_ORIGIN}/${encoded}`;
};

export const renderSrc = (imgHandlerObj: ImageHandlerObject, widthIndicator = false) => {
  const url = amazonImageHandlerMapper(imgHandlerObj);

  if (!widthIndicator) return url;

  const { width } = imgHandlerObj;
  const originalWidth = imgHandlerObj.imgObj.width;
  return `${url} ${typeof width === 'undefined' || width > originalWidth ? originalWidth : width}w`;
};

export const renderSrcSet = (imgObj: ImageApiObject, ...widths: number[]): string[] => {
  const filteredWidths = widths.filter(width => typeof width === 'number');

  if (filteredWidths.length === 0) return [''];
  const srcSet: string[] = [];
  let hasAlreadyOriginalWidth = false;

  widths.forEach(width => {
    for (let dpr = 1; dpr <= 3; dpr++) {
      // save all srcSet that are lower than original image
      if (width * dpr < imgObj.width) {
        srcSet.push(renderSrc({ imgObj, width: width * dpr }, true));
        continue;
      }
      // any combination of width and dpr that is higher than original image
      // will only be added once as the original width and ignore the rest
      if (width * dpr >= imgObj.width && !hasAlreadyOriginalWidth) {
        hasAlreadyOriginalWidth = true;
        srcSet.push(renderSrc({ imgObj, width: imgObj.width }, true));
      }
    }
  });
  return srcSet;
};

export const renderSizes = ({
  tablet,
  desktop,
  mobile,
}: {
  tablet?: string;
  desktop?: string;
  mobile: string;
}): string[] => {
  const sizes = [mobile];
  if (desktop) sizes.unshift(`${updatedBreakpoint.desktop} ${desktop}`);
  if (tablet) sizes.unshift(`${updatedBreakpoint.onlyTablet} ${tablet}`);
  return sizes;
};

export const imageHandler = {
  renderSrc,
  renderSrcSet,
  renderSizes,
};
