import {
  FETCH_MAP_PROPERTIES_REQUEST,
  FETCH_MAP_PROPERTIES_SUCCESS,
  FETCH_MAP_PROPERTIES_FAILURE,
  RESET_MAP,
} from './constants';

export const fetchMapPropertiesRequestAction = payload => ({
  type: FETCH_MAP_PROPERTIES_REQUEST,
  payload,
});

export const fetchMapPropertiesSuccessAction = payload => ({
  type: FETCH_MAP_PROPERTIES_SUCCESS,
  payload,
});

export const fetchMapPropertiesFailureAction = payload => ({
  type: FETCH_MAP_PROPERTIES_FAILURE,
  payload,
});

export const resetMapAction = () => ({
  type: RESET_MAP,
});
