import Cookies from 'js-cookie';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import getTime from 'date-fns/getTime';
import md5 from 'md5';
import { getSha256Hash } from 'src/utils/toSha256';
import queryString from 'query-string';

import { COOKIE_TRACKING } from 'src/constants/cookies';
import { CENTER_RADIUS } from 'src/comps/Property/constants';

import { routes } from 'src/constants/routes';

import isEmpty from 'lib/functions/isEmpty';
import isValidArray from 'lib/functions/isValidArray';

export function getPropertiesInfo({ pageId, properties, property, price }) {
  if (routes.list.page === pageId && isValidArray(properties)) {
    const ids = [];
    const names = [];
    const prices = [];
    const types = [];

    properties.forEach(p => {
      ids.push(p.id);
      names.push(p.name);
      prices.push(p.price);
      types.push(p.accommodationType);
    });

    return {
      accommodation_type: types.join(),
      name: names.join(),
      price: prices.join(),
      property_id: ids.join(),
    };
  }

  if (property) {
    return {
      accommodation_type: property.accommodationType,
      name: property.name,
      price: `${price}`,
      property_id: `${property.id}`,
    };
  }

  return {
    accommodation_type: '',
    name: '',
    price: '',
    property_id: '',
  };
}

export function buildTrackingJSON(params) {
  return {
    records: [{ key: null, value: params }],
  };
}

/* eslint-disable no-param-reassign */
export function removeEmptyKeys(obj) {
  Object.keys(obj).forEach(key => {
    const value = obj[key];

    if (typeof value === 'number' && value >= 0) {
      return value;
    }

    if (typeof value === 'boolean') {
      return value;
    }

    if (isEmpty(value)) {
      delete obj[key];
    }

    return value;
  });

  return obj;
}

export function getPropertiesKafkaSchema(properties) {
  return properties.map(
    ({
      citySlug,
      cityUrl,
      isOutsideCity,
      id,
      distance,
      isInCenter,
      highlighted,
      price,
      pricing,
      rooms,
    }) => ({
      id_property: id,
      distance,
      in_center: isInCenter ?? CENTER_RADIUS > distance,
      city_url: cityUrl,
      city_slug: citySlug,
      highlighted,
      is_outside_city: isOutsideCity,
      selling_rate: price ?? pricing?.price,
      total: rooms[0]?.summary?.total?.amount,
      subtotal: rooms[0]?.summary?.subtotal?.amount,
      taxes: rooms[0]?.summary?.taxes?.amount,
      additional_fees:
        rooms[0]?.summary?.additional_fees?.amount ?? rooms[0]?.summary?.additionalFees?.amount,
      provider: rooms[0]?.provider_name ?? rooms[0]?.providerName,
      rate_class: rooms[0]?.rate_class ?? rooms[0]?.rateClass,
      catalogue: rooms[0]?.catalogue,
      credential_source: rooms[0]?.credential_source,
    })
  );
}

export function getTrackingFromSource(trackingSource) {
  switch (trackingSource) {
    case 'visited':
      return { page: 'homepage', section: 'visited' };
    case 'related':
      return { page: 'hotel_details', section: 'related' };
    case 'map':
      return { page: 'map', section: 'map_result' };
    default:
      return { page: 'hotel_list_results', section: 'list_result_item' };
  }
}

export function getKafkaRoomsAvailability(boards) {
  return {
    boards: boards.map(
      ({
        availabilityId,
        cancellation,
        code,
        credentialSource,
        pricePerNight,
        finalPrice,
        photos,
        roomName,
        roomUniqueId,
        stayWalletCredit,
        taxes,
        total,
        subtotal,
        additionalFees,
        provider,
        paymentPolicy,
      }) => {
        const cancellationPolicy = cancellation[0];
        const mappedCancellation = cancellationPolicy
          ? {
              ...cancellationPolicy,
              from: getTime(new Date(cancellationPolicy.from)),
            }
          : {};

        return {
          id_availability: availabilityId,
          board_code: code,
          avg_rate: pricePerNight,
          selling_rate: finalPrice,
          total,
          subtotal,
          additional_fees: additionalFees[0]?.client
            ? additionalFees[0].client.amount
            : additionalFees[0]?.amount,
          provider,
          rate_class: paymentPolicy,
          taxes,
          wallet_credit: stayWalletCredit,
          cancellation: mappedCancellation,
          images: photos,
          room_unique_id: roomUniqueId,
          room_name: roomName,
          credential_source: credentialSource,
        };
      }
    ),
  };
}

export function getPropertyId({ property, propertyId } = {}) {
  if (property && Object.keys(property).length) {
    return property.id;
  }

  if (propertyId) {
    return propertyId;
  }

  return null;
}

export function sumAdults(adults = []) {
  return adults.reduce(
    (previous, current) => (parseInt(previous) || 0) + (parseInt(current) || 0),
    0
  );
}

export function getNights(checkIn, checkOut) {
  if (!checkIn || !checkOut) {
    return '';
  }

  const checkInDate = new Date(checkIn);
  const checkOutDate = new Date(checkOut);

  return `${differenceInCalendarDays(checkOutDate, checkInDate)}`;
}

export function getChildren(children) {
  if (isValidArray(children)) {
    return children.flat().length;
  }

  return children;
}

export function getChildrenAge(children) {
  if (isValidArray(children)) {
    return children.flat().length ? children.join('!') : '';
  }

  return '';
}

export function getIsMandatory(isMandatory) {
  if (typeof isMandatory === 'boolean') {
    return isMandatory ? 1 : 0;
  }

  return null;
}

export function getSessionId() {
  return Cookies.get(COOKIE_TRACKING) || null;
}

export function buildEmailHash(email, encoding = 'md5') {
  if (email) {
    if (encoding === 'sha-256') {
      return getSha256Hash(email);
    }
    return md5(email);
  }

  const sessionId = getSessionId();
  const sessionIdEncoded = window.btoa(sessionId);

  return `${sessionIdEncoded}@placeholder.email`;
}

export function getAffiliateRate() {
  const {
    p,
    total,
    subtotal,
    f,
    taxes,
    cs,
    r,
    offer_type: offerType,
  } = queryString.parse(window.location.search);
  const isValidNumber = n => !Number.isNaN(parseInt(n));
  if (!p && !total && !subtotal && !f && !taxes) {
    return undefined;
  }
  const affiliateRate = {};
  if (p) {
    affiliateRate.provider = p;
  }
  if (cs) {
    affiliateRate.credential_source = cs;
  }
  if (isValidNumber(total)) {
    affiliateRate.total = parseInt(total);
  }
  if (isValidNumber(subtotal)) {
    affiliateRate.subtotal = parseInt(subtotal);
  }
  if (isValidNumber(f)) {
    affiliateRate.additional_fees = parseInt(f);
  }
  if (isValidNumber(taxes)) {
    affiliateRate.taxes = parseInt(taxes);
  }
  if (r) {
    affiliateRate.rate = r;
  }
  if (offerType) {
    affiliateRate.offer_type = offerType;
  }

  return affiliateRate;
}

export const getCheckoutTracking = ({
  affiliateId,
  board,
  device,
  ip,
  isMandatory,
  language,
  market,
  price,
  property,
  eventName,
}) => ({
  kafka: {
    eventName,
    eventType: 'pageview',
    eventData: {
      affiliateId,
      board,
      device,
      ip,
      isMandatory,
      language,
      market,
      price,
      property,
    },
    appStateItems: [
      'isLoggedIn',
      'adults',
      'checkIn',
      'checkOut',
      'currency',
      'children',
      'nights',
      'rooms',
      'userId',
    ],
  },
  dataLayer: {
    sfl: {
      data: {
        affiliateId,
        board,
        device,
        ip,
        language,
        market,
        price,
        property,
      },
      appStateItems: [
        'adults',
        'checkIn',
        'checkOut',
        'children',
        'country',
        'currency',
        'slug',
        'purchaseEmail',
      ],
    },
    ecommerce: {
      data: {
        board,
        price,
        property,
      },
      appStateItems: ['adults', 'bestPriceData', 'currency', 'property'],
    },
  },
  ...(eventName !== 'customer_checkout' && {
    emt: {
      eventName,
      eventType: 'pageview',
      appStateItems: [
        'adults',
        'affiliateId',
        'alertMessage',
        'bestPriceData',
        'checkIn',
        'checkOut',
        'children',
        'childrenAges',
        'city',
        'country',
        'currency',
        'emailLogin',
        'isLoggedIn',
        'language',
        'market',
        'property',
        'purchaseEmail',
        'slug',
      ],
    },
  }),
});
