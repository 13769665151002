export function mapPrimeReceipts(response) {
  if (!response?.length) {
    return [];
  }

  return response.map(ticket => {
    return {
      total: ticket.amount,
      currency: ticket.currency,
      paymentMethod: ticket.paymentMethod,
      lastCreditCardNumber: ticket.cardDetails.number,
      paymentDate: ticket.paymentDate,
    };
  });
}
