import { requestByPOST } from 'lib/requests';

const END_POINTS = {
  SEND_VOUCHER: '/booking/v2/send-voucher',
};

export function sendVoucher(params) {
  return requestByPOST({
    data: { purchase_id: params.purchaseId },
    endpoint: END_POINTS.SEND_VOUCHER,
  });
}
