import axios from 'axios';
import { API_BASE_URL } from 'src/constants/environment';
import { requestByGET } from 'lib/requests';
import { mapVoucherResponse } from './mappers';
import { buildPathParams } from 'lib/utils/url';
import { buildVoucherPayRequest } from './utils';

const OPTIONS = {
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};

const END_POINTS = {
  VOUCHER_PAY: '/v2/voucher/pay',
  VOUCHER: '/voucher/details/{purchaseId}',
};

function requestByPOST(endpoint, data) {
  return axios
    .post(`${API_BASE_URL}${endpoint}`, data, OPTIONS)
    .then(res => res)
    .catch(error => error.response);
}

export function fetchVoucher(params) {
  return requestByGET({
    endpoint: buildPathParams(END_POINTS.VOUCHER, { purchaseId: params.purchaseId }),
    mapper: mapVoucherResponse,
  });
}

export function voucherPay(params) {
  const queryParams = buildVoucherPayRequest(params);
  return requestByPOST(END_POINTS.VOUCHER_PAY, queryParams);
}
