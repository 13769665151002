import { shape, string, number, bool } from 'prop-types';

export const typeExperiment = shape({ id: string, variant: number, key: string });

export const typeConfig = shape({
  affiliateId: string.isRequired,
  countryCode: string.isRequired,
  currency: string.isRequired,
  device: string,
  experiment: typeExperiment,
  language: string.isRequired,
  locale: string.isRequired,
  market: string,
  phone: string,
  mobileRate: bool,
  featureFlag: string,
});
