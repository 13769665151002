import { PROPERTIES_VISITED_STATE } from './constants';

const state = {
  [PROPERTIES_VISITED_STATE]: {
    error: null,
    isFetching: false,
    data: [],
  },
};

export default state;
