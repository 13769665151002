import {
  USER_STATE,
  RESET_USER,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CREATE_USER_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  FETCH_CUSTOMER_CREDIT_CARD_REQUEST,
  FETCH_CUSTOMER_CREDIT_CARD_SUCCESS,
  FETCH_CUSTOMER_CREDIT_CARD_FAILURE,
} from './constants';
import stateBase from './state';

const initialState = stateBase[USER_STATE];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_USER_REQUEST:
    case UPDATE_USER_REQUEST:
    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        error: null,
        isFetching: true,
        isPasswordChanged: null,
        isUserCreated: null,
        isUserUpdated: null,
      };

    case CREATE_USER_SUCCESS:
    case UPDATE_USER_SUCCESS:
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        error: null,
        isFetching: false,
        isPasswordChanged: action.payload.passwordChanged || false,
        isUserCreated: action.payload.created || false,
        isUserUpdated: action.payload.updated || false,
      };

    case CREATE_USER_FAILURE:
    case UPDATE_USER_FAILURE:
    case CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isFetching: false,
        isPasswordChanged: false,
        isUserCreated: false,
        isUserUpdated: false,
      };

    case RESET_USER:
      return {
        ...state,
        error: null,
        isFetching: false,
        isPasswordChanged: null,
        isUserCreated: null,
        isUserUpdated: null,
        creditCard: null
      };

    case FETCH_CUSTOMER_CREDIT_CARD_REQUEST:
      return {
        ...state,
        error: null,
        isFetching: true,
        creditCard: null,
      };

    case FETCH_CUSTOMER_CREDIT_CARD_SUCCESS:
      return {
        ...state,
        error: null,
        isFetching: false,
        creditCard: action.payload.creditCard,
      };

    case FETCH_CUSTOMER_CREDIT_CARD_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isFetching: false,
        creditCard: null,
      };

    default:
      return state;
  }
};

export default reducer;
