import {
  SUBSCRIPTION_STATUS_FAILURE,
  SUBSCRIPTION_STATUS_REQUEST,
  SUBSCRIPTION_STATUS_SUCCESS,
  SUBSCRIPTION_SUBSCRIBE_FAILURE,
  SUBSCRIPTION_SUBSCRIBE_REQUEST,
  SUBSCRIPTION_SUBSCRIBE_SUCCESS,
  SUBSCRIPTION_UNSUBSCRIBE_FAILURE,
  SUBSCRIPTION_UNSUBSCRIBE_REQUEST,
  SUBSCRIPTION_UNSUBSCRIBE_SUCCESS,
  SUBSCRIPTION_CONFIRM_REQUEST,
  SUBSCRIPTION_CONFIRM_SUCCESS,
  SUBSCRIPTION_CONFIRM_FAILURE,
  ACTIVATE_SUBSCRIPTION_TOPIC_REQUEST,
  ACTIVATE_SUBSCRIPTION_TOPIC_SUCCESS,
  ACTIVATE_SUBSCRIPTION_TOPIC_FAILURE,
  DEACTIVATE_SUBSCRIPTION_TOPIC_REQUEST,
  DEACTIVATE_SUBSCRIPTION_TOPIC_SUCCESS,
  DEACTIVATE_SUBSCRIPTION_TOPIC_FAILURE,
} from './constants';

export const fetchSubscriptionStatusRequestAction = payload => ({
  type: SUBSCRIPTION_STATUS_REQUEST,
  payload,
});

export const fetchSubscriptionStatusSuccessAction = payload => ({
  type: SUBSCRIPTION_STATUS_SUCCESS,
  payload,
});

export const fetchSubscriptionStatusFailureAction = payload => ({
  type: SUBSCRIPTION_STATUS_FAILURE,
  payload,
});

export const subscribeRequestAction = payload => ({
  type: SUBSCRIPTION_SUBSCRIBE_REQUEST,
  payload,
});

export const subscribeSuccessAction = payload => ({
  type: SUBSCRIPTION_SUBSCRIBE_SUCCESS,
  payload,
});

export const subscribeFailureAction = payload => ({
  type: SUBSCRIPTION_SUBSCRIBE_FAILURE,
  payload,
});

export const unsubscribeRequestAction = payload => ({
  type: SUBSCRIPTION_UNSUBSCRIBE_REQUEST,
  payload,
});

export const unsubscribeSuccessAction = payload => ({
  type: SUBSCRIPTION_UNSUBSCRIBE_SUCCESS,
  payload,
});

export const unsubscribeFailureAction = payload => ({
  type: SUBSCRIPTION_UNSUBSCRIBE_FAILURE,
  payload,
});

export const confirmRequestAction = payload => ({
  type: SUBSCRIPTION_CONFIRM_REQUEST,
  payload,
});

export const confirmSuccessAction = payload => ({
  type: SUBSCRIPTION_CONFIRM_SUCCESS,
  payload,
});

export const confirmFailureAction = payload => ({
  type: SUBSCRIPTION_CONFIRM_FAILURE,
  payload,
});

export const fetchActivateTopicRequestAction = payload => ({
  type: ACTIVATE_SUBSCRIPTION_TOPIC_REQUEST,
  payload,
});

export const fetchActivateTopicSuccessAction = payload => ({
  type: ACTIVATE_SUBSCRIPTION_TOPIC_SUCCESS,
  payload,
});

export const fetchActivateTopicFailureAction = payload => ({
  type: ACTIVATE_SUBSCRIPTION_TOPIC_FAILURE,
  payload,
});

export const fetchDeactivateTopicRequestAction = payload => ({
  type: DEACTIVATE_SUBSCRIPTION_TOPIC_REQUEST,
  payload,
});

export const fetchDeactivateTopicSuccessAction = payload => ({
  type: DEACTIVATE_SUBSCRIPTION_TOPIC_SUCCESS,
  payload,
});

export const fetchDeactivateTopicFailureAction = payload => ({
  type: DEACTIVATE_SUBSCRIPTION_TOPIC_FAILURE,
  payload,
});
