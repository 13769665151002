import React from 'react';
import PropTypes from 'prop-types';

import { NotificationsBase } from './styles';

const Notifications = ({ reverse = false, children }) => {
  return <NotificationsBase reverse={reverse}>{children}</NotificationsBase>;
};

Notifications.propTypes = {
  reverse: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default Notifications;
