import { call, put } from 'redux-saga/effects';

import {
  fetchSuggestedPropertiesSuccessAction,
  fetchSuggestedPropertiesFailureAction,
} from './actions';

import { mapSearchDataToPropertiesCollection } from 'client/contexts/Property/mappers';
import { fetchSuggestedProperties, fetchSuggestedPropertiesAuthorized } from './requests';

export function* fetchSuggestedPropertiesSaga(action) {
  try {
    const { payload } = action;

    const request = payload?.isLogged
      ? fetchSuggestedPropertiesAuthorized
      : fetchSuggestedProperties;

    const { data, status } = yield call(request, payload);

    if (status >= 400) {
      yield put(fetchSuggestedPropertiesFailureAction({ error: data }));
      return;
    }

    const { properties } = data;
    const propertiesMapped = mapSearchDataToPropertiesCollection(properties);

    yield put(fetchSuggestedPropertiesSuccessAction({ properties: propertiesMapped }));
  } catch (error) {
    yield put(fetchSuggestedPropertiesFailureAction({ error: error.message }));
  }
}
