import {
  VERIFY_EMAIL_REQUEST,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_FAILURE,
  VERIFY_EMAIL_REJECTED,
  STATUS_OK,
  STATUS_REJECTED,
  STATUS_NOT_OK,
  VERIFY_EMAIL_STATE,
} from './constants';
import stateBase from './state';

const initialState = stateBase[VERIFY_EMAIL_STATE];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case VERIFY_EMAIL_REQUEST: {
      return {
        ...state,
        bookings: {
          ...state.bookings,
          [action.payload.hash]: {
            isFetching: true,
            status: null,
            error: null,
          },
        },
      };
    }

    case VERIFY_EMAIL_SUCCESS: {
      return {
        ...state,
        bookings: {
          ...state.bookings,
          [action.payload.hash]: {
            status: STATUS_OK,
            isFetching: false,
            error: null,
          },
        },
      };
    }

    case VERIFY_EMAIL_REJECTED: {
      return {
        ...state,
        bookings: {
          ...state.bookings,
          [action.payload.hash]: {
            status: STATUS_REJECTED,
            isFetching: false,
            error: action.payload.error,
            errorCode: action.payload.errorCode,
          },
        },
      };
    }

    case VERIFY_EMAIL_FAILURE: {
      return {
        ...state,
        bookings: {
          ...state.bookings,
          [action.payload.hash]: {
            status: STATUS_NOT_OK,
            error: action.payload.error,
            errorCode: action.payload.errorCode,
            isFetching: false,
          },
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
