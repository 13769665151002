export function mapSummary(data) {
  return {
    insurance: data.additional_products?.insurance || null,
    insuranceCurrency: data.currency || null,
    payLate: data.remaining_payment || null,
    payNow: data.pay_rate || null,
    staywallet: data.discounts?.staywallet || null,
    staywalletCurrency: data.currency || null,
    total: data.total || 0,
    totalCurrency: data.currency || null,
    totalWithFees: data.total_with_fees || null,
    totalWithFeesCurrency: data.currency || null,
  };
}
