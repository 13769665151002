import styled from 'styled-components';
import { colors } from 'client/styles';
import { UserDetailBase } from 'src/comps/UserDetail/styles';

const TextBase = styled.div`
  color: ${colors.grey2};
  margin-left: 32px;
`;

export const SidebarMenuBase = styled.div`
  ${UserDetailBase} {
    border-bottom: 1px solid ${colors.grey4};
  }
`;

export const Icon = styled.span`
  fill: ${colors.grey2};
  position: absolute;
`;

export const Section = styled.a`
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 12px 20px;
  text-decoration: none;
  border-bottom: 1px solid ${colors.grey4};

  &:first-child {
    border-top: 1px solid ${colors.grey4};
  }

  &:last-child {
    border-bottom: 1px solid ${colors.grey4};
  }

  &:hover {
    background-color: ${colors.grey5};
  }

  background-color: ${props => props.backgroundColor && `${colors.grey5};`};
`;

export const SubTitle = styled(TextBase)`
  font-size: 14px;
  line-height: 140%;
`;

export const Title = styled(TextBase)`
  font-size: 16px;
  line-height: 150%;
`;
