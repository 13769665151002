import { useEffect, useRef } from 'react';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from 'client/rootReducer';
import rootSaga from 'client/rootSagas';
import rootMiddleware from 'client/rootMiddleware';

export const getComposer = () => (
  process.env.NODE_ENV === 'development' && typeof window === 'object' ? 
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : 
    compose
);

function configureStore({ initialState }) {
  const sagaMiddleware = createSagaMiddleware();
  const composeEnhancers = getComposer();

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(sagaMiddleware, ...rootMiddleware))
  );

  store.sagaTask = sagaMiddleware.run(rootSaga);

  return store;
}

let store;

export const resetStore = () => {
  store = undefined;
}

export default function useStore({ initialState }) {
  const mounted = useRef(false);

  if (!mounted.current) {
    store = configureStore({ initialState });
  }

  useEffect(() => {
    mounted.current = true;
  }, []);

  return {
    store
  }
}
