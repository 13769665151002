import { call, put, select } from 'redux-saga/effects';

import {
  loadPropertyWithPrice,
  loadHotelAvailabilityWithBookingRules,
  pageviewIterableHotelPage,
} from 'client/contexts/Tracking/events';

import { getIsFetching } from 'client/contexts/Login/selectors';

import {
  fetchAvailabilityFailureAction,
  fetchAvailabilitySuccessAction,
  fetchBookingRulesFailureAction,
  fetchBookingRulesSuccessAction,
} from './actions';

import { fetchAvailability, fetchAvailabilityAuthorized, fetchBookingRules } from './requests';
import { sendTrackingRequestAction } from 'client/contexts/Tracking/actions';

export function* fetchAvailabilitySaga(action) {
  try {
    const isFetchingUser = yield select(getIsFetching);
    if (isFetchingUser) {
      return;
    }
    const request = action.payload?.isLogged ? fetchAvailabilityAuthorized : fetchAvailability;
    const { data, status } = yield call(request, { data: action.payload });

    if (status >= 400) {
      yield put(fetchAvailabilityFailureAction({ error: data }));
      return;
    }

    yield put(fetchAvailabilitySuccessAction(data));
    yield put(sendTrackingRequestAction({ event: loadPropertyWithPrice() }));
    
    if(action.payload?.isLogged && !action.payload?.isBot) {
      yield put(sendTrackingRequestAction({ event: pageviewIterableHotelPage() }));
    }

    const {
      boards,
      meta: {
        bestPriceData: { totalDiscounted: bestPrice },
      },
    } = data;
    const dateFeed = parseInt(action.payload?.queryParams?.date_feed) || undefined;

    if (!boards || boards?.length === 0) {
      yield put(
        sendTrackingRequestAction({
          event: loadHotelAvailabilityWithBookingRules({
            boards: [],
            bestPriceBoard: -1,
            dateFeed,
          }),
        })
      );
    } else {
      const payload = {
        boards,
        bestPriceBoard: boards.indexOf(boards.find(board => board.finalPrice === bestPrice)),
        dateFeed,
      };

      yield put(
        sendTrackingRequestAction({ event: loadHotelAvailabilityWithBookingRules(payload) })
      );
    }
  } catch (error) {
    yield put(fetchAvailabilityFailureAction({ error: error.message }));
  }
}

export function* fetchBookingRulesSaga(action) {
  try {
    const { availabilityId, sessionId } = action.payload;
    const { data, status } = yield call(fetchBookingRules, { id: availabilityId, sessionId });

    if (status >= 400) {
      yield put(fetchBookingRulesFailureAction({ error: data }));
      return;
    }

    yield put(fetchBookingRulesSuccessAction({ ...data, availabilityId }));
  } catch (error) {
    yield put(fetchBookingRulesFailureAction({ error: error.message }));
  }
}
