import { combineReducers } from 'redux';

import activateAccount from 'src/effects/ActivateAccount/reducer';
import alerts from 'src/comps/AlertMessage/reducer';
import availability from 'src/effects/Availability/reducer';
import cancelBooking from 'client/contexts/CancelBooking/reducer';
import config from 'src/comps/Page/reducer';
import filters from 'client/components/FiltersContainer/reducer';
import forgotPassword from 'src/effects/ForgotPassword/reducer';
import location from 'client/contexts/Location/reducer';
import login from 'client/contexts/Login/reducer';
import map from 'src/effects/SearchMap/reducer';
import modifyDates from 'src/effects/ModifyDates/reducer';
import pages from 'client/contexts/Pages/reducer';
import payment from 'src/effects/Payment/reducer';
import payNow from 'src/comps/PayNow/reducer';
import prime from 'src/effects/PrimeSubscription/reducer';
import primeReceipts from 'src/effects/PrimeReceipts/reducer';
import properties from 'src/effects/Properties/reducer';
import propertiesRecommended from 'src/effects/PropertiesRecommended/reducer';
import propertiesVisited from 'src/effects/PropertiesVisited/reducer';
import property from 'client/contexts/Property/reducer';
import purchase from 'client/contexts/Purchase/reducer';
import refundCreditCard from 'src/effects/RefundCreditCard/reducer';
import refundWallet from 'src/effects/RefundWallet/reducer';
import resetPassword from 'src/effects/ResetPassword/reducer';
import search from 'src/effects/Search/reducer';
import sendVoucher from 'src/comps/SendVoucher/reducer';
import specialRequests from 'src/effects/SpecialRequests/reducer';
import subscriptionStatus from 'src/effects/SubscriptionStatus/reducer';
import suggested from 'src/effects/Suggested/reducer';
import suggester from 'src/effects/Suggester/reducer';
import summary from 'client/contexts/Summary/reducer';
import TAReviews from 'src/effects/TAReviews/reducer';
import user from 'src/effects/User/reducer';
import userBookings from 'client/contexts/UserBookings/reducer';
import userWallet from 'client/contexts/UserWallet/reducer';
import verifyEmail from 'src/effects/VerifyEmail/reducer';
import voucher from 'src/comps/Voucher/reducer';
import wallet from 'client/contexts/Wallet/reducer';

const rootReducer = combineReducers({
  activateAccount,
  alerts,
  availability,
  cancelBooking,
  config,
  filters,
  forgotPassword,
  location,
  login,
  map,
  modifyDates,
  pages,
  payment,
  payNow,
  prime,
  primeReceipts,
  properties,
  propertiesRecommended,
  propertiesVisited,
  property,
  purchase,
  refundCreditCard,
  refundWallet,
  resetPassword,
  search,
  sendVoucher,
  specialRequests,
  subscriptionStatus,
  suggested,
  suggester,
  summary,
  TAReviews,
  user,
  userBookings,
  userWallet,
  verifyEmail,
  voucher,
  wallet,
});

export default rootReducer;
