import {
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
} from './constants';

export const resetPasswordRequestAction = payload => ({
  type: RESET_PASSWORD_REQUEST,
  payload,
});

export const resetPasswordSuccessAction = payload => ({
  type: RESET_PASSWORD_SUCCESS,
  payload,
});

export const resetPasswordFailureAction = payload => ({
  type: RESET_PASSWORD_FAILURE,
  payload,
});
