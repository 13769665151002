/* eslint-disable no-nested-ternary */
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';

import { displayAlertAction } from 'src/comps/AlertMessage/actions';
import messages from 'src/comps/AlertMessage/messages';

export function useAlertMessage() {
  const dispatch = useDispatch();

  const router = useRouter();
  const { from } = router.query;

  useEffect(() => {
    const alerts = Object.keys(messages);
    const severity = /success/.test(from) ? 'success' : /error/.test(from) ? 'error' : 'warning';

    if (alerts.includes(from)) {
      dispatch(displayAlertAction({ messageId: from, severity }));
      router.push(window.location.pathname, null, { shallow: true });
    }
  }, [dispatch, from, router]);
}
