/* eslint-disable no-underscore-dangle */
/**
 *  Get Translations either from a parameter in SSR or in the browser from the window
 *  context where react stores the values generated by the server in SSR.
 *
 * @param {Record<string, string>} [messages]
 * @return {messages}  {Record<string, string>}
 */
export const getTranslationMessages = (
  messages?: Record<string, string>
): Record<string, string> => {
  const translations =
    typeof window !== 'undefined' && window?.__NEXT_DATA__?.props?.pageProps?.translations
      ? window?.__NEXT_DATA__?.props?.pageProps?.translations
      : messages;

  if (!translations) {
    throw new Error('Translations messages are not provided either by window or by ssr parameter');
  }

  return translations;
};
