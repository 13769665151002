import React, { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMedia } from 'react-use';

import {
  THEMES,
  THEME_DEFAULT,
  LINK_ATTRIBUTES,
  VARIANTS,
  FULL,
  ONLY_LOGO,
} from 'client/components/Header/constants';

import { useMarket } from 'src/hooks/useMarket';
import { useDevice } from 'src/hooks/useDevice';
import { LocationPropertyType } from 'src/types/checkout';

import HeaderLogo from 'client/components/HeaderLogo';
import IconHelp from 'client/icons/Help';
import IconPhone from 'client/icons/Phone';

import HeaderWithSlots from 'client/components/HeaderWithSlots';

import { RightSideWrapper, Section, SectionLabel, SectionLink, Text } from './styles';

import { breakpoint } from 'client/styles';
import messages from './messages';

type QueryParams = { [name: string]: string };
type Theme = (typeof THEMES)[number];
type Variant = (typeof VARIANTS)[number];

export interface HeaderProps {
  backButton?: boolean;
  destinationData?: LocationPropertyType;
  hasLogin?: boolean;
  isLogged?: boolean;
  logoLinkQueryParams?: QueryParams;
  pageId: string | number;
  phone?: string;
  contactEmail?: string;
  theme?: Theme;
  variant?: Variant;
}

interface CommonHeaderProps extends HeaderProps {
  isPrime: boolean;
  userActions?: ReactNode;
  isEbooking?: boolean;
}

const CommonHeader: React.FC<CommonHeaderProps> = ({
  backButton = false,
  destinationData = {} as LocationPropertyType,
  logoLinkQueryParams = {},
  pageId,
  phone,
  contactEmail,
  theme = THEME_DEFAULT,
  variant = FULL,
  isPrime,
  userActions,
  isEbooking = false,
}) => {
  const { device } = useDevice();
  const { helpCenterUrl } = useMarket();
  const isWide = useMedia(breakpoint.tablet, device === 'desktop') && device !== 'tablet';

  return (
    <HeaderWithSlots
      theme={theme}
      left={
        <HeaderLogo
          backButton={backButton}
          destinationData={destinationData}
          logoLinkQueryParams={logoLinkQueryParams}
          pageId={pageId}
          theme={theme}
          isPrime={isPrime}
        />
      }
      right={
        <>
          {variant !== ONLY_LOGO && (
            <RightSideWrapper>
              {isWide && (
                <Section>
                  {contactEmail && (
                    <SectionLink href={`mailto:${contactEmail}`}>
                      <SectionLabel isEbooking={isEbooking}>
                        <IconPhone />
                        <Text>{contactEmail}</Text>
                      </SectionLabel>
                    </SectionLink>
                  )}
                  {phone && (
                    <SectionLink href={`tel:${phone}`}>
                      <SectionLabel isEbooking={isEbooking}>
                        <IconPhone />
                        <Text>{phone}</Text>
                      </SectionLabel>
                    </SectionLink>
                  )}
                  <SectionLink href={helpCenterUrl} {...LINK_ATTRIBUTES}>
                    <SectionLabel isEbooking={isEbooking}>
                      <IconHelp />
                      <Text>
                        <FormattedMessage {...messages.help} />
                      </Text>
                    </SectionLabel>
                  </SectionLink>
                </Section>
              )}
              {userActions && <>{userActions}</>}
            </RightSideWrapper>
          )}
        </>
      }
    />
  );
};

export default CommonHeader;
