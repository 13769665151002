import { formatISO } from 'lib/dates';
import { DEFAULT_SOURCE } from 'src/constants/sources';
import { DEFAULT_AFFILIATE_ID } from 'src/pages/_middleware/defaultAffiliate';
import { SortOptions } from 'src/pages/api/trpc/search/schema';

export function mapDatesParams({ checkIn, checkOut }) {
  return {
    checkin: typeof checkIn === 'string' ? checkIn : formatISO(checkIn),
    checkout: typeof checkOut === 'string' ? checkOut : formatISO(checkOut),
  };
}

export function mapLocationParams({ slug, type }) {
  return { location: slug, locationType: type };
}

export function mapMarketParams({ language, country, market }) {
  return { lang: language, country, market };
}

export function mapPaxesParams({ adults, children }) {
  return { rooms: adults.map((a, i) => ({ adults: a, children: children[i] })) };
}

export function mapSortByParams({ orderBy }) {
  return { sortBy: orderBy === 'relevance' ? SortOptions.Recommended : orderBy };
}

export function mapPaginationParams({ page, offset }) {
  return { page, offset };
}

export function mapFilterParams({
  accommodationTypes,
  facilities,
  family,
  maxPrice,
  minPrice,
  ratings,
  stars,
}) {
  return {
    accommodations: accommodationTypes,
    facilities,
    isFamiliar: family === 'true', // Enable family mode with "true" value
    ...(maxPrice && { maxPrice }),
    minPrice,
    rating: Object.assign([], ratings).sort()[0],
    stars,
  };
}

export function buildSearchPropertiesPayload({
  affiliate,
  distance,
  excludedIDs,
  filtersParams,
  highlightedProperty,
  includedIDs,
  offset,
  reservationParams,
  device,
  offerType,
  priceType,
  total,
  minSellingRate,
  source,
  currency,
}) {
  const params = { ...reservationParams, ...filtersParams, offset, distance };
  return {
    affiliate: affiliate ?? DEFAULT_AFFILIATE_ID,
    source: source ?? DEFAULT_SOURCE,
    offer_type: offerType,
    price_type: priceType,
    total,
    excludedIDs,
    includedIDs,
    device,
    highlighted: highlightedProperty,
    ...mapDatesParams(params),
    ...mapLocationParams(params),
    ...mapMarketParams(params),
    ...mapPaxesParams(params),
    ...mapFilterParams(params),
    ...mapSortByParams(params),
    ...mapPaginationParams(params),
    ...(minSellingRate && { minSellingRate }),
    currency,
  };
}

export const mapAffiliateParams = ({ affiliate, source }) => {
  return {
    affiliate: affiliate ?? DEFAULT_AFFILIATE_ID,
    source: source ?? DEFAULT_SOURCE,
  };
};
