import { createSelector } from 'reselect';
import { toDate } from 'date-fns-tz';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

export const getSearch = state => state.search;

export const getDates = createSelector([getSearch], ({ checkIn, checkOut }) => ({
  checkIn: toDate(checkIn),
  checkOut: toDate(checkOut),
}));

export const getSearchQuery = createSelector(
  [getSearch],
  ({ adults, children, checkIn, checkOut }) => ({ adults, checkIn, checkOut, children })
);

export const getSearchPaxes = createSelector([getSearch], ({ adults, children }) => ({
  rooms: adults.length,
  adults: adults.reduce((previous, current) => previous + current, 0),
  children: children.flat().length,
}));

export const getNights = createSelector([getDates], ({ checkIn, checkOut }) => {
  return differenceInCalendarDays(checkOut, checkIn);
});

export const getDaysUntilCheckIn = createSelector([getDates], ({ checkIn }) => {
  const today = new Date();
  return differenceInCalendarDays(checkIn, today);
});
