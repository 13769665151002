export const mapReview = (review = {}) => ({
  stars: review.rating,
  title: review.title,
  starsImage: review.rating_image_url,
  text: review.text,
  author: review.user.username,
  publishedDate: review.published_date,
  readMore: review.url,
});

export const mapTAReviews = (data = {}) => {
  const reviews = data.reviews || [];

  return {
    propertyUrl: data.hotel_booking.booking_url,
    writeReview: data.write_review,
    reviews: reviews.map(review => mapReview(review)),
  };
};
