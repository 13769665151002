import { defineMessages } from 'react-intl';

const messages = defineMessages({
  adult: {
    id: 'global.adult',
  },
  adults: {
    id: 'global.adults',
  },
  child: {
    id: 'global.child',
  },
  children: {
    id: 'global.children',
  },
  room: {
    id: 'global.room',
  },
  rooms: {
    id: 'global.rooms',
  },
  search: {
    id: 'global.search',
  },
  for: {
    id: 'search.for',
  },
  people: {
    id: 'global.people',
  },
  guests: {
    id: 'global.guests',
  },
  roomsAvailable: {
    id: 'global.rooms-available',
  },
});

export default messages;
