import {
  SUBSCRIPTION_STATUS_REQUEST,
  SUBSCRIPTION_STATUS_SUCCESS,
  SUBSCRIPTION_SUBSCRIBE_FAILURE,
  SUBSCRIPTION_SUBSCRIBE_REQUEST,
  SUBSCRIPTION_SUBSCRIBE_SUCCESS,
  SUBSCRIPTION_UNSUBSCRIBE_FAILURE,
  SUBSCRIPTION_UNSUBSCRIBE_REQUEST,
  SUBSCRIPTION_UNSUBSCRIBE_SUCCESS,
  SUBSCRIPTION_CONFIRM_REQUEST,
  SUBSCRIPTION_CONFIRM_SUCCESS,
  SUBSCRIPTION_CONFIRM_FAILURE,
  ACTIVATE_SUBSCRIPTION_TOPIC_SUCCESS,
  DEACTIVATE_SUBSCRIPTION_TOPIC_SUCCESS,
  DEACTIVATE_SUBSCRIPTION_TOPIC_FAILURE,
  ACTIVATE_SUBSCRIPTION_TOPIC_FAILURE,
  DEACTIVATE_SUBSCRIPTION_TOPIC_REQUEST,
  ACTIVATE_SUBSCRIPTION_TOPIC_REQUEST,
  SUBSCRIPTION_STATUS_STATE,
  SUBSCRIBED,
  UNSUBSCRIBED,
  PENDING,
  ACTIVE,
  INACTIVE,
} from './constants';
import stateBase from './state';

const initialState = stateBase[SUBSCRIPTION_STATUS_STATE];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBSCRIPTION_STATUS_REQUEST:
    case SUBSCRIPTION_SUBSCRIBE_REQUEST:
    case SUBSCRIPTION_UNSUBSCRIBE_REQUEST:
    case SUBSCRIPTION_CONFIRM_REQUEST:
    case ACTIVATE_SUBSCRIPTION_TOPIC_REQUEST:
    case DEACTIVATE_SUBSCRIPTION_TOPIC_REQUEST:
      return {
        ...state,
        error: null,
        isFetching: true,
      };

    case SUBSCRIPTION_STATUS_SUCCESS: {
      const { subscription_status: subscriptionStatus, subscriptions: subs } = action.payload;
      return {
        error: null,
        isFetching: false,
        status: subscriptionStatus,
        subscriptions: {
          specialOffers: {
            uuid: subs?.special_offers?.uuid,
            status: subs?.special_offers?.status,
            type: subs?.special_offers?.type,
          },
          specialDayOffers: {
            uuid: subs?.special_day_offers?.uuid,
            status: subs?.special_day_offers?.status,
            type: subs?.special_day_offers?.type,
          },
          stayforlongNews: {
            uuid: subs?.stayforlong_news?.uuid,
            status: subs?.stayforlong_news?.status,
            type: subs?.stayforlong_news?.type,
          },
          destinations: {
            uuid: subs?.destinations?.uuid,
            status: subs?.destinations?.status,
            type: subs?.destinations?.type,
          },
          contests: {
            uuid: subs?.contests?.uuid,
            status: subs?.contests?.status,
            type: subs?.contests?.type,
          },
          primeOffers: {
            uuid: subs?.prime_offers?.uuid,
            status: subs?.prime_offers?.status,
            type: subs?.prime_offers?.type,
          },
          primeGuides: {
            uuid: subs?.prime_guides?.uuid,
            status: subs?.prime_guides?.status,
            type: subs?.prime_guides?.type,
          },
        },
      };
    }
    case SUBSCRIPTION_SUBSCRIBE_SUCCESS:
      return {
        error: null,
        isFetching: false,
        status: action.payload.status === 202 ? PENDING : SUBSCRIBED,
      };

    case SUBSCRIPTION_UNSUBSCRIBE_SUCCESS:
      return {
        error: null,
        isFetching: false,
        status: UNSUBSCRIBED,
      };

    case SUBSCRIPTION_CONFIRM_SUCCESS:
      return {
        error: null,
        isFetching: false,
        status: SUBSCRIBED,
      };
    case ACTIVATE_SUBSCRIPTION_TOPIC_SUCCESS:
      return {
        ...state,
        error: null,
        isFetching: false,
        subscriptions: {
          ...state.subscriptions,
          ...Object.entries(state.subscriptions)
            // eslint-disable-next-line no-unused-vars
            .find(([_, value]) => value.uuid === action.payload.uuid)
            ?.reduce((key, value) => ({ [key]: { ...value, status: ACTIVE } })),
        },
      };

    case DEACTIVATE_SUBSCRIPTION_TOPIC_SUCCESS:
      return {
        ...state,
        error: null,
        isFetching: false,
        subscriptions: {
          ...state.subscriptions,
          ...Object.entries(state.subscriptions)
            // eslint-disable-next-line no-unused-vars
            .find(([_, value]) => value.uuid === action.payload.uuid)
            ?.reduce((key, value) => ({ [key]: { ...value, status: INACTIVE } })),
        },
      };

    case SUBSCRIPTION_SUBSCRIBE_FAILURE:
    case SUBSCRIPTION_UNSUBSCRIBE_FAILURE:
    case SUBSCRIPTION_CONFIRM_FAILURE:
    case ACTIVATE_SUBSCRIPTION_TOPIC_FAILURE:
    case DEACTIVATE_SUBSCRIPTION_TOPIC_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        isFetching: false,
      };

    default:
      return state;
  }
};

export default reducer;
